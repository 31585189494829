import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import Validator from "validatorjs";
import { NotificationManager } from "react-notifications";
import GradeIcon from "@material-ui/icons/Grade";

import {
  Container,
  Box,
  Button,
  Modal,
  TextField,
  FormControl,
  FormHelperText,
  Typography,
} from "@material-ui/core";

import { createExam, getExams, updateExam } from "fetch/teacher/groups/apies";

//modals
function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },
}));
function CreateExam(props) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const groupId = props.groupId;
  const groupName = props.groupName;
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const navigateBtn = (e, rowData) => {
    e.preventDefault();
    console.log(rowData);
    props.history.push(
      `${process.env.PUBLIC_URL}/app/teacher/examGrade/${groupId}/${groupName}/${rowData.id}/${rowData.title}`
    );
  };

  // modals
  const [open, setOpen] = useState(false);
  const [changeBtn, setChangeBtn] = useState({ change: "" });

  const [allExams, setAllExams] = useState();
  useEffect(() => {
    if (groupId) {
      getExams(token, groupId).then((res) => {
        setAllExams(res.data);
      });
    }
  }, [groupId]);

  const columns = [
    { title: "Exam Title", field: "title" },
    { title: "Date", field: "startDate" },
  ];
  const actions = [
    {
      icon: "edit",
      tooltip: "Edit exam",
      onClick: (e, rowData) => openModalForEdit(e, rowData),
    },
    {
      icon: () => <GradeIcon />,
      tooltip: "Exam grade",
      onClick: (e, rowData) => navigateBtn(e, rowData),
    },
  ];

  // Form
  //title
  const [titleInputs, setTitleInputs] = useState({
    title: "",
    rule: "required",
    startValidate: false,
  });
  let validationTitle = new Validator(
    { title: titleInputs.title },
    { title: titleInputs.rule }
  );
  const handleTitle = (e) => {
    setTitleInputs((prevState) => ({
      ...prevState,
      title: e.target.value,
      startValidate: true,
    }));
  };

  // Start date
  const [startDateInputs, setStartDateInputs] = useState({
    startDate: "",
    rule: "required",
    startValidate: false,
  });

  const validationStartDate = new Validator(
    { startDate: startDateInputs.startDate },
    { startDate: startDateInputs.rule }
  );
  function formatDate(date) {
    let newDate = date.split("-");

    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
  }
  const handleStartDate = (e) => {
    setStartDateInputs((prevState) => ({
      ...prevState,
      startDate: e.target.value,
      startValidate: true,
    }));
  };

  const openModalForCreate = () => {
    setChangeBtn({ change: "create" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setChangeBtn({ change: "" });
    setTitleInputs((prevState) => ({
      ...prevState,
      title: "",
      startValidate: false,
    }));
    setStartDateInputs((prevState) => ({
      ...prevState,
      startDate: "",
      startValidate: false,
    }));
  };

  const createExamBtn = (e) => {
    e.preventDefault();
    if (validationTitle.passes() && validationStartDate.passes()) {
      const data = {
        title: titleInputs.title,
        startDate: startDateInputs.startDate,
        groupId: Number(groupId),
      };
      createExam(token, data).then((res) => {
        handleClose();
        NotificationManager.success("Exam created successfully");
        setAllExams((prevState) => [res.data, ...prevState]);
      });
    } else {
      setTitleInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setStartDateInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  const [examID, setExamID] = useState();
  const openModalForEdit = (e, rowData) => {
    e.preventDefault();
    setChangeBtn({ change: "edit" });
    setOpen(true);
    setExamID(rowData.id);
    setTitleInputs((prevState) => ({
      ...prevState,
      title: rowData.title,
    }));
    setStartDateInputs((prevState) => ({
      ...prevState,
      startDate: rowData.startDate,
    }));
  };
  const updateExamBtn = (e) => {
    e.preventDefault();
    if (validationTitle.passes() && validationStartDate.passes()) {
      const data = {
        title: titleInputs.title,
        startDate: formatDate(startDateInputs.startDate),
        groupId: Number(groupId),
      };
      if (examID) {
        updateExam(token, examID, data).then((res) => {
          if (res.status === 200) {
            const newItem = {
              id: examID,
              title: data.title,
              startDate: data.startDate,
            };
            const newArr = allExams.filter((el) => el.id !== examID);
            const newSortedArr = [...newArr, newItem].sort(function (a, b) {
              return a.id - b.id;
            });
            handleClose();
            NotificationManager.success("Exam created successfully");
            setAllExams((prevState) => newSortedArr);
          }
        });
      }
    } else {
      setTitleInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));

      setStartDateInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  return (
    <div>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={openModalForCreate}
        >
          Add Exam
        </Button>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div style={modalStyle} className={classes.paper}>
            <Typography variant="h6">
              {changeBtn.change === "create" ? "Create Exam" : "Update Exam"}
            </Typography>
            <Box my={3}>
              <FormControl error fullWidth>
                <TextField
                  required
                  fullWidth
                  type="text"
                  variant="outlined"
                  className="outlined-input"
                  label="Exam title: "
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={titleInputs.title}
                  onChange={(e) => handleTitle(e)}
                  error={
                    titleInputs.startValidate &&
                    (validationTitle.passes() === true ? false : true)
                  }
                />
                <FormHelperText>
                  {titleInputs.startValidate &&
                    validationTitle.errors.first("title")}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box my={3}>
              <FormControl fullWidth error>
                <TextField
                  required
                  fullWidth
                  type="date"
                  id="outlined-required"
                  variant="outlined"
                  className="outlined-input"
                  label="Start date:"
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={startDateInputs.startDate}
                  onChange={(e) => handleStartDate(e)}
                  error={
                    startDateInputs.startValidate &&
                    (validationStartDate.passes() === true ? false : true)
                  }
                />

                <FormHelperText style={{ marginLeft: 0 }}>
                  {startDateInputs.startValidate &&
                    validationStartDate.errors.first("startDate")}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box my={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={(e) =>
                  changeBtn.change === "create"
                    ? createExamBtn(e)
                    : updateExamBtn(e)
                }
              >
                Create
              </Button>
              <Button
                color="secondary"
                variant="contained"
                style={{ marginLeft: "10px" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </div>
        </Modal>
      </Box>
      <Box px={{ xs: "12px", lg: 0 }} pt={4} className="page-space">
        <MaterialTable
          title={`Exams`}
          columns={columns}
          data={allExams}
          actions={actions}
          options={{
            actionsColumnIndex: -1,
          }}
        />
      </Box>
    </div>
  );
}

export default withRouter(CreateExam);
