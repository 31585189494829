/**
 * User Detail
 */
import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Fab, Box, Divider } from "@material-ui/core";
import { getUserById, getContract } from "fetch/parent/children/apies";
import { getStudentCourses } from "fetch/admin/students/apies";

const useStyles = makeStyles((theme) => ({
  fab: {
    boxShadow: "none",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  socialIcons: {
    "& a": {
      fontSize: 32,
      marginLeft: "5px",
      marginRight: "5px",
      "&:first-child": {
        marginLeft: 0,
      },
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
}));

export default function UserDetail() {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  let currentURL = window.location.href;
  const studentId = currentURL.split("/")[6];

  const [userPassport, setUserPassport] = useState();
  useEffect(() => {
    getUserById(token, studentId).then((res) => {
      setUserPassport(res.data);
    });
  }, []);
  const classes = useStyles();

  const [studentCourses, setStudentCourses] = useState([]);
  useEffect(() => {
    if (studentId) {
      getStudentCourses(token, studentId).then((res) => {
        setStudentCourses(res.data);
      });
    }
  }, [studentId]);
  console.log(studentCourses);
  let studentCoursesCounter = 0;
  studentCourses?.forEach((element) => {
    if (element.course !== null) {
      studentCoursesCounter++;
    }
  });

  // useEffect(() => {
  //   getContract(studentId, token).then((res) => {
  //     console.log("log-1", res);
  //   });
  // }, []);
  return (
    <div className="">
      <Box py={3} className="user-detail">
        <div className="user-avatar">
          <img
            src={require(`assets/Images/avatars/user.png`).default}
            alt="user images"
            className="avatar-wrap"
          />
        </div>
        <Typography variant="h6" style={{ marginBottom: "5px" }}>
          {userPassport && userPassport.first_name}{" "}
          {userPassport && userPassport.last_name}
        </Typography>
        <Fab className={classes.fab} size="small" aria-label="phone">
          <a
            href={`Tel: ${userPassport?.phone_number}`}
            className="d-flex justif-content-center align-items-center"
          >
            <i className="material-icons-outlined" style={{ color: "#717275" }}>
              phone_enabled
            </i>
          </a>
        </Fab>
        {/* <Fab className={classes.fab} size="small" aria-label="email">
          <i className="material-icons-outlined">mail_outline</i>
        </Fab> */}
      </Box>
      <Divider />
      <div>
        <Box py={2}>
          <Box mb={2}>
            {studentCoursesCounter > 0 ? (
              <a
                target={"_blank"}
                href={`https://cec.inha.uz/api/students/${studentId}/contract`}
                className="btn btn-primary"
              >
                {t("parent.downloadContract")}
              </a>
            ) : (
              ""
            )}
          </Box>
          <Box mb={2} fontSize="subtitle1.fontSize">
            {t("parent.contactInformation")}
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle2">
              {t("parent.emailAddres")}:
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {userPassport && userPassport.email}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle2">{t("parent.phone")}:</Typography>
            <Typography variant="subtitle2" color="textPrimary">
              +{userPassport && userPassport.phone_number}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">{t("footer2")}:</Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {userPassport && userPassport.address}
            </Typography>
          </Box>
        </Box>
        <Divider />
      </div>
    </div>
  );
}
