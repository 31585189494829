import React, { useEffect, useState } from "react";
import { Grid, Box, Container, Button } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
//Components
import UserDetail from "./Components/UserDetail";
import TodaysTasks from "./Components/TodaysTasks";
import TodaysThought from "./Components/TodaysThought";
import AppliedCourses from "./Components/AppliedCourses";
import UserActivities from "./Components/UserActivities";
import { t } from "i18next";

import { getStudent, getStudentCourses } from "fetch/admin/students/apies";
import { getUserById } from "fetch/parent/children/apies";

export default function Standard(props) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const studentId = props.match.params.id;

  const goBack = (e) => {
    e.preventDefault();
    props.history.goBack();
  };

  const [studentData, setStudentData] = useState();
  const [studentGroups, setStudentGroups] = useState([]);
  useEffect(() => {
    getStudent(token, studentId).then((res) => {
      setStudentData(res.data);
    });
  }, []);

  // const [userPassport, setUserPassport] = useState();
  // console.log(userPassport);
  // useEffect(() => {
  //   getUserById(token, studentId).then((res) => {
  //     setUserPassport(res.data);
  //   });
  // }, []);

  useEffect(() => {
    if (studentData) {
      const { groupStudents } = studentData;
      setStudentGroups(groupStudents);
    }
  }, [studentData]);

  const [studentCourses, setStudentCourses] = useState([]);
  useEffect(() => {
    if (studentId) {
      getStudentCourses(token, studentId).then((res) => {
        setStudentCourses(res.data);
        console.log(res.data, "STUDENT COURSE");
      });
    }
  }, [studentId]);

  return (
    <div className="hk-user-profile">
      <Container maxWidth="lg">
        <Box px={{ xs: "12px", lg: 0 }} className="page-space">
          <Button color="primary" onClick={goBack}>
            <Icon style={{ fontSize: 18, marginRight: "2px" }}>
              arrow_back{" "}
            </Icon>
            {t("parent.back")}
          </Button>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={3}>
              <UserDetail studentId={studentId} studentData={studentData} />
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
              <UserActivities
                studentGroups={studentGroups}
                studentId={studentId}
                setStudentGroups={setStudentGroups}
              />
              {/* {userPassport?.passport_image == null ? (
                <span>No Passport</span>
              ) : (
                <a
                  href={`${process.env.REACT_APP_PRE_UNI_API}/users/${studentId}/passport`}
                  target="_blank"
                >
                  <img
                    src={`${process.env.REACT_APP_PRE_UNI_API}/users/${studentId}/passport`}
                    alt=""
                    style={{
                      width: "60px",
                    }}
                  />
                </a>
              )} */}
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              {/* <Box mb={3}>
                <TodaysTasks />
              </Box> */}
              {/* <Box mb={3}>
                <TodaysThought />
              </Box> */}
              <Box mb={3}>
                <AppliedCourses courses={studentCourses} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
