import React, { useEffect, useState } from "react";
import { Grid, Box, Container, Button } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import UserDetail from "./UserDetail";
import UserActivities from "./UserActivities";

import { getStudent } from "fetch/admin/students/apies";
import { t } from "i18next";

export default function ChildGroups(props) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const studentId = props.match.params.id;

  const goBack = (e) => {
    e.preventDefault();
    props.history.goBack();
  };

  const [studentData, setStudentData] = useState();
  const [studentGroups, setStudentGroups] = useState([]);
  useEffect(() => {
    getStudent(token, studentId).then((res) => {
      setStudentData(res.data);
    });
  }, []);

  useEffect(() => {
    if (studentData) {
      const { groupStudents } = studentData;
      setStudentGroups(groupStudents);
    }
  }, [studentData]);

  return (
    <div className="hk-user-profile">
      <Container maxWidth="lg">
        <Box px={{ xs: "12px", lg: 0 }} className="page-space">
          <Button color="primary" onClick={goBack}>
            <Icon style={{ fontSize: 18, marginRight: "2px" }}>
              arrow_back{" "}
            </Icon>
            {t("parent.back")}
          </Button>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={3}>
              <UserDetail studentId={studentId} studentData={studentData} />
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
              <UserActivities
                studentGroups={studentGroups}
                studentId={studentId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}></Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
