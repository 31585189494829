import appLocaleData from 'react-intl/locale-data/ru';
import ruMessages from '../locales/ru';

const RuLang = {
    messages: {
        ...ruMessages
    },
    locale: 'ru',
    data: appLocaleData
};
export default RuLang;