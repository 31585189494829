import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Validator from "validatorjs";
import { NotificationManager } from "react-notifications";
import MaterialTable from "material-table";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import moment from "moment";
import styles from "./createLesson.module.scss";
import {
  MenuItem,
  Select,
  InputLabel,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import {
  createLesson,
  getLessonsByGroupId,
  updateLesson,
} from "fetch/teacher/groups/apies";
import { t } from "i18next";
import { deleteLesson } from "fetch/admin/students/apies";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },
  paperDelete: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },
}));

function CreateLesson(props) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const groupId = props.groupId;
  const groupName = props.groupName;
  const [openDelete, setOpenDelete] = useState(false);
  const [studentDeleteId, setStudentDeleteId] = useState();

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const navigateBtn = (e, rowData) => {
    e.preventDefault();
    props.history.push(
      `${
        process.env.PUBLIC_URL
      }/app/admin/teacher/groups/${groupId}/${encodeURIComponent(groupName)}/${
        rowData.id
      }/${rowData.startDate}`
    );
  };

  const [allLessons, setAllLessons] = useState();

  const openModalForDelete = (e, id) => {
    setStudentDeleteId(id);
    setOpenDelete(true);
  };

  // All lessons
  useEffect(() => {
    if (groupId) {
      getLessonsByGroupId(token, groupId).then((res) => {
        setAllLessons(res?.data?.reverse());
        // console.log(res.data);
      });
    }
  }, [groupId]);
  function formatDate(date) {
    let newDate = date.split("-");

    return `${newDate[1]}-${newDate[2]}-${newDate[0]}`;
  }
  // Lesson table
  const columns = [
    { title: "Title", field: "name" },
    {
      title: "Date",
      field: "startDate",
      render: (rowData) => (
        <p>
          {moment(rowData.startDate?.substring(0, 10)).format("DD-MM-YYYY")}
        </p>
      ),
    },
    {
      title: "Type",
      field: "startDate",
      render: (rowData) => <p>{rowData.isExam ? "Exam" : "Lesson"}</p>,
    },
  ];

  const actions = [
    // {
    //   icon: "edit",
    //   tooltip: "Edit Lesson",
    //   onClick: (e, rowData) => openModalForEdit(e, rowData),
    // },
    {
      icon: "delete",
      tooltip: `${t("admin.deleteLesson")}`,
      onClick: (e, rowData) => openModalForDelete(e, rowData.id),
    },
    {
      icon: () => <HowToRegIcon />,
      tooltip: "Take Attendance",
      onClick: (e, rowData) => navigateBtn(e, rowData),
    },
  ];

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const deleteStudentBtn = () => {
    console.log("Lesson id: ", studentDeleteId);
    deleteLesson(token, studentDeleteId)
      .then((res) => {
        if (res.status === 200) {
          setOpenDelete(false);
          const newArr = allLessons.filter((el) => el.id !== studentDeleteId);
          setAllLessons((prevState) => newArr);
          NotificationManager.success(`${t("admin.notifDeletedSuccLs")}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Modal
  // const [open, setOpen] = useState(false);
  // const [changeBtn, setChangeBtn] = useState({ change: "" });

  // Form
  // Lesson name
  // const [lessonNameInputs, setLessonNameInputs] = useState({
  //   name: "",
  //   rule: "required",
  //   startValidate: false,
  // });

  // const validationLessonName = new Validator(
  //   { name: lessonNameInputs.name },
  //   { name: lessonNameInputs.rule }
  // );
  // const handleLessonName = (e) => {
  //   setLessonNameInputs((prevState) => ({
  //     ...prevState,
  //     name: e.target.value,
  //     startValidate: true,
  //   }));
  // };

  // isExam
  // const [isExamInputs, setIsExamInputs] = useState({
  //   isExam: "",
  //   rule: "required",
  //   startValidate: false,
  // });
  // let validationIsExam = new Validator(
  //   { isExam: isExamInputs.isExam },
  //   { isExam: isExamInputs.rule }
  // );
  // const handleIsExam = (e) => {
  //   setIsExamInputs((prevState) => ({
  //     ...prevState,
  //     isExam: e.target.value,
  //     startValidate: true,
  //   }));
  // };
  // function formatDate(date) {
  //   let newDate = date.split("-");

  //   return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
  // }
  // Start date
  // const [startDateInputs, setStartDateInputs] = useState({
  //   startDate: "",
  //   rule: "required",
  //   startValidate: false,
  // });

  // const validationStartDate = new Validator(
  //   { startDate: startDateInputs.startDate },
  //   { startDate: startDateInputs.rule }
  // );

  // const handleStartDate = (e) => {
  //   setStartDateInputs((prevState) => ({
  //     ...prevState,
  //     startDate: e.target.value,
  //     startValidate: true,
  //   }));
  // };

  // Modal open and close
  // const openModalForCreate = () => {
  //   setChangeBtn({ change: "create" });
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   setChangeBtn({ change: "" });
  //   setLessonNameInputs((prevState) => ({
  //     ...prevState,
  //     name: "",
  //     startValidate: false,
  //   }));
  //   setIsExamInputs((prevState) => ({
  //     ...prevState,
  //     isExam: "",
  //     startValidate: false,
  //   }));
  //   setStartDateInputs((prevState) => ({
  //     ...prevState,
  //     startDate: "",
  //     startValidate: false,
  //   }));
  // };

  // const createLessonBtn = () => {
  //   if (
  //     validationLessonName.passes() &&
  //     validationIsExam.passes() &&
  //     validationStartDate.passes()
  //   ) {
  //     const data = {
  //       name: lessonNameInputs.name,
  //       startDate: startDateInputs.startDate,
  //       groupId: Number(groupId),
  //       isExam: isExamInputs.isExam == "exam" ? true : false,
  //     };

  //     createLesson(token, data).then((res) => {
  //       handleClose();
  //       NotificationManager.success("Lesson created successfully");
  //       setAllLessons((prevState) => [res.data, ...prevState]);
  //       window.location.reload();
  //     });
  //   } else {
  //     setLessonNameInputs((prevState) => ({
  //       ...prevState,
  //       startValidate: true,
  //     }));
  //     setIsExamInputs((prevState) => ({
  //       ...prevState,
  //       startValidate: true,
  //     }));
  //     setStartDateInputs((prevState) => ({
  //       ...prevState,
  //       startValidate: true,
  //     }));
  //   }
  // };

  // function formatDate(date) {
  //   let newDate = date.split("-");

  //   return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
  // }

  // const [lessonID, setLessonID] = useState();
  // const openModalForEdit = (e, rowData) => {
  //   e.preventDefault();
  //   setOpen(true);
  //   setChangeBtn({ change: "edit" });
  //   setLessonID(rowData.id);
  //   setLessonNameInputs((prevState) => ({
  //     ...prevState,
  //     name: rowData.name,
  //   }));
  //   // setIsExamInputs((prevState) => ({
  //   //   ...prevState,
  //   //   isExam: rowData.isExam ? "exam" : "lesson",
  //   // }));
  //   // setStartDateInputs((prevState) => ({
  //   //   ...prevState,
  //   //   startDate: moment(rowData.startDate).format().substring(0, 10),
  //   // }));
  //   console.log(startDateInputs.startDate);
  // };

  // const updateLessonBtn = (e) => {
  //   e.preventDefault();
  //   if (validationLessonName.passes()) {
  //     const data = {
  //       name: lessonNameInputs.name,
  //       groupId: Number(groupId),
  //     };
  //     if (lessonID) {
  //       updateLesson(token, lessonID, data).then((res) => {
  //         if (res.status === 200) {
  //           const newItem = {
  //             id: lessonID,
  //             name: data.name,
  //             isExam: data.isExam,
  //           };
  //           const newArr = allLessons.filter((el) => el.id !== lessonID);
  //           const newSortedArr = [...newArr, newItem].sort(function (a, b) {
  //             return a.id - b.id;
  //           });
  //           handleClose();
  //           NotificationManager.success("Lesson updated successfully");
  //           setAllLessons((prevState) => newSortedArr);
  //         }
  //       });
  //     }
  //   } else {
  //     setLessonNameInputs((prevState) => ({
  //       ...prevState,
  //       startValidate: true,
  //     }));
  //     // setIsExamInputs((prevState) => ({
  //     //   ...prevState,
  //     //   startValidate: true,
  //     // }));
  //   }
  // };

  return (
    <div>
      <Box px={{ xs: "12px", lg: 0 }} p={4} className="page-space">
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openDelete}
          onClose={handleCloseDelete}
        >
          <div style={modalStyle} className={`${classes.paperDelete}`}>
            <h1 style={{ textAlign: "center" }}>
              {t("admin.agreeDeleteLesson")}
            </h1>

            <Box my={3} style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "20px" }}
                onClick={(e) => setOpenDelete(false)}
              >
                {t("admin.no")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#dc3545" }}
                onClick={(e) => deleteStudentBtn(e)}
              >
                {t("admin.yes")}
              </Button>
            </Box>
          </div>
        </Modal>
        <MaterialTable
          title={t("teacher.lessons")}
          columns={columns}
          data={allLessons}
          actions={actions}
          options={{
            actionsColumnIndex: -1,
          }}
        />
      </Box>
    </div>
  );
}

export default withRouter(CreateLesson);
