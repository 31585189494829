const axios = require("axios").default;

//Get Student APi
const getUsers = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/users`, {
    headers: {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getUser = async (token, userId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/users/${userId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const updateUser = async (token, userId, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.put(
    `${process.env.REACT_APP_PRE_UNI_API}/users/${userId}`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const deleteUser = async (token, userId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.delete(
    `${process.env.REACT_APP_PRE_UNI_API}/users/${userId}`,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const changePassword = async (token, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/users/reset-password`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

export { getUsers, getUser, updateUser, deleteUser, changePassword };
