import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Container } from "@material-ui/core";
import { Grid, Box, Typography, Button } from "@material-ui/core";
import { CustomCard } from "components/GlobalComponents";
import LinearProgress from "@material-ui/core/LinearProgress";
import { t } from "i18next";
import { getUser } from "fetch/users";

import IntlMessages from "util/IntlMessages";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: 12,
    backgroundColor: theme.palette.primary.main,
    marginBottom: 12,
  },
  Paper: {
    padding: "0.75rem",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:last-child": {
      paddingBottom: "30px",
    },
  },
  thoughtBg: {
    backgroundImage: "url(" + require("assets/Images/balls.jpg") + ").default",
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    position: "relative",
  },
  taskList: {
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));
const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: "rgba(239, 108, 0, 0.5)",
  },
  bar: {
    backgroundColor: "#ef6c00",
  },
})(LinearProgress);

function StudentDashboard() {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const userId = JSON.parse(localStorage.getItem("preUni_user_id"));
  const [userInfo, setUserInfo] = useState();

  const classes = useStyles();
  const tasks = [
    {
      id: 1,
      tasks:
        "Meet Louise kate at beach with your baggage and please be on time.",
      time: "At 15.00PM",
      bgColor: "secondary.main",
    },
    {
      id: 2,
      tasks:
        "New users from March is promoted as special benefit under promotional offer of 30%.",
      time: "At 17.00PM",
      bgColor: "info.main",
    },
    {
      id: 3,
      tasks:
        "Site goes is down for 6 hours due to maintainance and bug fixing. Please Check ",
      time: "At 02.00AM",
      bgColor: "primary.main",
    },
    {
      id: 4,
      tasks:
        "Bug detected from the development team at the cart module of Fashion store. ",
      time: "At 16.00PM",
      bgColor: "success.main",
    },
  ];
  useEffect(() => {
    getUser(token, userId).then((res) => {
      setUserInfo(res.data.first_name);
    });
  }, []);

  return (
    <div className="ecommerce-dashboard">
      <Container maxWidth="lg">
        <Box px={2} pt={4}>
          <Typography variant="h3">{`${t("admin.hiAdmin1")}, ${
            userInfo !== undefined ? `${userInfo},` : ""
          } ${t("admin.hiAdmin2")}`}</Typography>
        </Box>
        {/* <Paper className={classes.Paper} square>
          <Grid container spacing={3} className="stats-wrap">
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={3} className="stats-wrap mt-1">
                <Grid item xs={12} sm={12} md={4}>
                  <CustomCard>
                    <Typography variant="h3">14,458</Typography>
                    <Box mb={2}>
                      <Typography
                        variant="h6"
                        className="font-weight-med text-disabled"
                      >
                        Average grade
                      </Typography>
                    </Box>
                    <BorderLinearProgress
                      variant="determinate"
                      color="primary"
                      value={50}
                    />
                  </CustomCard>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <CustomCard>
                    <Typography variant="h3">24.3%</Typography>
                    <Box mb={2}>
                      <Typography
                        variant="h6"
                        className="font-weight-med text-disabled"
                      >
                        My rank
                      </Typography>
                    </Box>
                    <BorderLinearProgress
                      variant="determinate"
                      color="primary"
                      value={50}
                    />
                  </CustomCard>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <Box textAlign="center">
                    <Box
                      className={classes.thoughtBg}
                      borderRadius={5}
                      py={4}
                      px={4}
                    >
                      <div className="overlay-dark"></div>
                      <Box
                        fontSize="h6.fontSize"
                        mb={2}
                        className="text-white relative"
                      >
                        Quote of the day
                      </Box>
                      <Typography
                        variant="body2"
                        className="text-white relative"
                      >
                        Make your dreams come true
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.Paper} square>
          <CustomCard title="Today's Classes" showDivider={true}>
            <Box pt={3}>
              {tasks.map((task, index) => (
                <Box key={index} mb="20px" className={classes.taskList}>
                  <Box
                    borderRadius={4}
                    bgcolor="primary.main"
                    className="text-white font-sm"
                    p={1}
                    mb={1}
                  >
                    {task.tasks}
                  </Box>
                  <Typography
                    className="font-sm"
                    style={{ textAlign: "right" }}
                  >
                    {task.time}
                  </Typography>
                </Box>
              ))}
            </Box>
          </CustomCard>
        </Paper> */}
      </Container>
    </div>
  );
}

export default StudentDashboard;
