import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "../../../../assets/Images/icons/down-chevron.png";
import KeyboardArrowUpIcon from "../../../../assets/Images/icons/up-chevron.png";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  FormControl,
  Select,
  Modal,
  MenuItem,
  Input,
} from "@material-ui/core";
import { SmallTitleBar } from "components/GlobalComponents";
import {
  getCourses,
  getStudents,
  createAddStudentToCourse,
} from "fetch/student";
import { getCourseById } from "fetch/AdminApi/course";

function rand() {
  return Math.round(Math.random() * 10) - 10;
}

function getModalStyle() {
  const top = 40 + rand();
  const left = 45 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-paddingNone": {
      padding: "0 16px",
    },
    "& .MuiTableCell-body": {
      lineHeight: 1,
    },
    "& .MuiToolbar-root": {
      paddingRight: 20,
      "& >div:first-child": {
        fontSize: "1.25rem",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 500,
      },
    },
  },
  paper: {
    position: "absolute",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%, -50%) !important",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  content: {},
  menuButton: {},
}));
function ParentAddToCourse(props) {
  let history = useHistory();
  const token = JSON.parse(localStorage.getItem("preUni_token"));

  const courseId = props.match.params.id;

  const classes = useStyles();

  ///// TABLE /////

  const [columns] = useState([
    {
      title: "Avatar",
      field: "imageUrl",
      render: (rowData) => (
        <img
          alt="user-thumb"
          src={require(`assets/Images/avatars/${rowData.imageUrl}`).default}
          className="img-50 bdr-rad-50"
        />
      ),
    },
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email", field: "email" },
    {
      title: "Date Added",
      field: "dateAdded",
      render: (rowData) => (
        <div>
          <span className="thisIsClass">{rowData.dateAdded}</span>
          {rowData.icon ? (
            <span className="custom-table-arrow">
              <i className="material-icons">arrow_forward_ios</i>
            </span>
          ) : (
            <span></span>
          )}
        </div>
      ),
    },
  ]);
  //   const [data] = useState(customTable.data);
  const [selectedRow, setSelectedRow] = useState({
    firstName: "Zachery",
    lastName: "Terrell",
    designation: "Web Developer",
    city: "Chakwal",
    postal: "352950",
    address: "Ap #262-5976 Elementum Rd.",
    country: "Virgin Islands",
    imageUrl: "user-1.jpg",
    contactNo: "9876543210",
    lastModified: "17/3/2019",
    tableData: {
      id: 0,
    },
  });

  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [courseData, setCourseData] = useState();

  useEffect(() => {
    getCourseById(token, courseId).then((response) => {
      setCourseData(response.data);
    });
  }, []);

  const [allStudents, setAllStudents] = useState();
  useEffect(() => {
    getStudents(token)
      .then((res) => {
        setAllStudents(res.data);
      })
      .catch((error) => {});
  }, []);

  const [addToCourse, setAddToCourse] = useState([]);

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setAddToCourse(typeof value === "string" ? value.split(",") : value);
  };

  const addTocourseBtn = async (e) => {
    e.preventDefault();

    addToCourse.map((id) => {
      const data = {
        student_id: id,
        course_id: Number(courseId),
      };
      createAddStudentToCourse(data, token)
        .then((res) => {
          if (res.status === 201) {
            console.log(res.data);
            NotificationManager.success(
              "The student has joined successfully the course"
            );
            history.push("/app/parent/dashboard");
          }
        })
        .catch((err) => {
          console.log(err);
          NotificationManager.error(
            "The student has already joined to this course"
          );
        });
      // setTimeout(() => {

      // }, 1000);
    });
  };

  console.log(allStudents);

  const goBack = (e) => {
    e.preventDefault();
    props.history.goBack();
  };

  const [openOverview, setOpenOverview] = useState(false);

  // formatToSum
  // const resultPrice = [];
  // let priceeee = courseData?.prices?.[0].cost;
  return (
    <div className={classes.root}>
      <SmallTitleBar
        title={`${courseData && courseData.name_en}`}
        center={true}
      />
      <Box
        className={`title-contact-block ${classes.searchBarWrap}`}
        pt={0}
        bgcolor="background.paper"
        px={{ xs: "12px", md: 0 }}
        pb={3}
      >
        <Container>
          <Box
            textAlign={{ xs: "center", sm: "right" }}
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Button variant="outlined" color="default" onClick={goBack}>
              <Icon style={{ fontSize: 18, marginRight: "2px" }}>
                arrow_back{" "}
              </Icon>
              Back
            </Button>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box px={{ xs: "12px", lg: 0 }} style={{ marginLeft: "0" }}>
          <div
            style={{ display: "flex" }}
            className="page-space  payment-int-wrap"
          >
            <img
              className="img-fluid"
              alt="img"
              style={{ width: "400px", height: "300px" }}
              src={`${process.env.REACT_APP_PRE_UNI_API}/courses/${
                courseData && courseData.id
              }/image`}
            />
            {/* <CustomCard
              style={{ marginLeft: "100px", width: '400px', background: 'red' }}
              cardClasses="border-t border-l border-r"
            > */}
            <Box
              style={{
                background: "white",
                marginLeft: "3%",
                padding: "10px",
                width: "50%",
              }}
            >
              <Box mb={1}>
                <Typography variant="h3">
                  <p style={{ margin: 0, padding: 0, textAlign: "center" }}>
                    About Course
                  </p>
                </Typography>
              </Box>
              <Box style={{}} variant="body2">
                <p>{courseData && courseData.description}</p>
                <h4>
                  Duration in month: {courseData?.duration_in_month}-month
                </h4>
                <h4>Price: {courseData?.prices?.[0].cost} so'm</h4>
              </Box>
              <Box
                mt={0}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                style={{ height: "170px" }}
              >
                <Button
                  style={{
                    marginRight: "5%",
                    marginTop: "30px",
                    fontSize: "14px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Apply to Course
                </Button>
                <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={open}
                  onClose={handleClose}
                >
                  <div style={modalStyle} className={classes.paper}>
                    <Box>
                      <h2>Choose your child</h2>
                      <Box style={{ marginBottom: "10px" }}>
                        <FormControl className={classes.formControl} fullWidth>
                          <Select
                            fullWidth
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            value={addToCourse}
                            onChange={handleChange}
                            input={<Input />}
                          >
                            {allStudents && allStudents.length > 0 ? (
                              allStudents.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                  {`${item.first_name} ${item.last_name}`}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem>No Child</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box style={{ marginTop: "30px" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        // disabled={diabledBtn}
                        // onClick={(e) =>
                        //   change === true ? createCourseBtn(e) : editCourseBtn(e)
                        // }
                        onClick={addTocourseBtn}
                      >
                        Add to course
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        style={{ marginLeft: "10px" }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      {/* <p>{errorMessage}</p> */}
                    </Box>
                  </div>
                </Modal>
              </Box>
            </Box>
            {/* </CustomCard> */}
          </div>
        </Box>
      </Container>
      <Box mt={0}>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div style={modalStyle} className={classes.paper}>
            <Box>
              <h2>Choose your child</h2>
              <Box style={{ marginBottom: "10px" }}>
                <FormControl className={classes.formControl} fullWidth>
                  <Select
                    fullWidth
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={addToCourse}
                    onChange={handleChange}
                    input={<Input />}
                  >
                    {allStudents && allStudents.length > 0 ? (
                      allStudents.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {`${item.first_name} ${item.last_name}`}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Child</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box style={{ marginTop: "30px" }}>
              <Button
                color="primary"
                variant="contained"
                // disabled={diabledBtn}
                // onClick={(e) =>
                //   change === true ? createCourseBtn(e) : editCourseBtn(e)
                // }
                onClick={addTocourseBtn}
              >
                Add to course
              </Button>
              <Button
                color="secondary"
                variant="contained"
                style={{ marginLeft: "10px" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              {/* <p>{errorMessage}</p> */}
            </Box>
          </div>
        </Modal>
      </Box>
      <Box style={{ width: "86%", marginLeft: "3%", marginTop: "-2.5%" }}>
        <Accordion>
          <AccordionSummary onClick={() => setOpenOverview(!openOverview)}>
            <Typography
              style={{
                width: "100%",
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              color="primary"
            >
              <span>Overview</span>
              <img
                style={{ width: "20px" }}
                src={openOverview ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
                alt={
                  openOverview ? "KeyboardArrowUpIcon" : "KeyboardArrowDownIcon"
                }
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ fontSize: "20px" }}>
              {courseData?.overview ? courseData.overview : "No Description"}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
}

export default ParentAddToCourse;
