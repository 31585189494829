/**
 * CustomCard Component
 */
import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  Divider,
  Button,
  Box,
} from "@material-ui/core";
import {
  Fab,
  Modal,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import Validator from "validatorjs";
import { NotificationManager } from "react-notifications";
import { t } from "i18next";

import { getGroups } from "fetch/student";

import {
  addStudentToGroup,
  getStudentCourses,
  getGroupsByCourseId,
} from "fetch/admin/students/apies";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "1.25rem",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    borderRadius: 4,
  },
  cardContent: {
    padding: "0 !important",
  },
  divider: {
    marginTop: "0.625rem",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useModalStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },
}));

function AddToGroup(props) {
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const [modalStyle] = useState(getModalStyle);
  const token = JSON.parse(localStorage.getItem("preUni_token"));

  // Modal
  const [open, setOpen] = useState(false);

  const [groupOptions, setGroupOptions] = useState([]);
  useEffect(() => {
    if (props.studentId) {
      getStudentCourses(token, props.studentId).then((res) => {
        // console.log(res.data, "data");
        const list = [];
        res.data.map((item) => {
          if (item.course) {
            getGroupsByCourseId(token, item?.course?.id).then((res) => {
              console.log(res.data, "RES DATA");
              list.push(...res.data.groups);
            });
          }
        });
        setGroupOptions(list);
        console.log(list, "jjjs");
      });
    }
  }, [props.studentId]);

  // form
  // groupId
  const [groupIdInputs, setGroupIdInputs] = useState({
    groupId: "",
    rule: "required",
    startValidate: false,
  });
  let validationGroupId = new Validator(
    { groupId: groupIdInputs.groupId },
    { groupId: groupIdInputs.rule }
  );
  const handleGroupId = (e) => {
    setGroupIdInputs((prevState) => ({
      ...prevState,
      groupId: e.target.value,
      startValidate: true,
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setGroupIdInputs((prevState) => ({
      ...prevState,
      groupId: "",
      startValidate: false,
    }));
  };

  const openModal = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const addStudentToGroupBtn = (e) => {
    e.preventDefault();
    if (validationGroupId.passes()) {
      const data = {
        studentId: parseInt(props.studentId, 10),
        groupId: parseInt(groupIdInputs.groupId, 10),
      };
      addStudentToGroup(token, data)
        .then((res) => {
          handleClose();
          NotificationManager.success(`${t("admin.notifAddSuccGr")}`);
          props.setStudentGroups((prevState) => [res.data, ...prevState]);
        })
        .catch((err) => {
          if (err.response.status === 409) {
            NotificationManager.error(`${t("admin.notifAddErrGr")}`);
          }
        });
    } else {
      setGroupIdInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  return (
    <Card
      className={`${classes.card} ${
        props.cardClasses ? props.cardClasses : ""
      }`}
    >
      <Fragment>
        {props.title ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h6">{props.title}</Typography>
            <Box my={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => openModal(e)}
              >
                {`+ ${t("admin.addToGroup")}`}
              </Button>
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
              >
                <div style={modalStyle} className={modalClasses.paper}>
                  <Box my={3}>
                    <Typography variant="h6">
                      {t("admin.addToGroup")}
                    </Typography>
                  </Box>

                  <Box my={3}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={
                        groupIdInputs.startValidate &&
                        (validationGroupId.passes() === true ? false : true)
                      }
                    >
                      <InputLabel id="select-teacher-label" shrink={true}>
                        {t("admin.selectGroup")}:
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="select-teacher-label"
                        label={`${t("admin.selectTeacher")}:`}
                        variant="outlined"
                        value={groupIdInputs.groupId}
                        onChange={(e) => handleGroupId(e)}
                      >
                        {groupOptions &&
                          groupOptions.map((option, index) => (
                            <MenuItem key={index} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText style={{ marginLeft: 0 }}>
                        {groupIdInputs.startValidate &&
                          validationGroupId.errors.first("groupId")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Box my={3}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => addStudentToGroupBtn(e)}
                    >
                      {t("admin.addBtn")}
                    </Button>
                    <Button
                      style={{ marginLeft: "10px" }}
                      color="secondary"
                      variant="contained"
                      onClick={handleClose}
                    >
                      {t("parent.cancel")}
                    </Button>
                  </Box>
                </div>
              </Modal>
            </Box>
          </Box>
        ) : null}
      </Fragment>
      {props.showDivider ? <Divider className={classes.divider} /> : null}
      <CardContent className={classes.cardContent}>
        {props.children}
      </CardContent>
    </Card>
  );
}

AddToGroup.defaultProps = {
  title: "",
  titleAlign: "left",
};

export { AddToGroup };
