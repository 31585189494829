import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Validator from "validatorjs";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import { SmallTitleBar } from "components/GlobalComponents";
import { t } from "i18next";
import "./index.css";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Container,
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  IconButton,
  Avatar,
  OutlinedInput,
  InputAdornment,
  Paper,
  Grid,
  Chip,
  Input,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
} from "@material-ui/core";

import {
  getRegions,
  getCoursesType,
  createAddStudentToCourse,
} from "fetch/student";

import {
  createStudent,
  updateStudent,
  studentEmailValidation,
  getStudents,
  getStudentAppliedCourses,
  createImage,
  createParentPassport,
  getContract,
} from "fetch/parent/children/apies";

import { CustomCard } from "components/GlobalComponents";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { getUser } from "fetch/users";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },

  Paper: {
    padding: "0.75rem",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:first-child": {
      paddingTop: "24px",
    },
    "&:last-child": {
      paddingBottom: "30px",
    },
  },
}));

function ParentChildren(props) {
  let history = useHistory();
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const parentId = JSON.parse(localStorage.getItem("preUni_user_id"));
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [disableBtn, setDisableBtn] = useState(false);
  const [loader, setLoader] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [changeBtn, setChangeBtn] = useState({ change: "" });

  const [regionOptions, setRegionOptions] = useState();
  const [regionParentOptions, setRegionParentOptions] = useState();
  const [districtOptions, setDistrictOptions] = useState();
  const [districtParentOptions, setDistrictParentOptions] = useState();
  const [parentInformation, setParentInformation] = useState();

  useEffect(() => {
    getRegions(token).then((res) => {
      setRegionOptions(res.data);
      setRegionParentOptions(res.data);
    });
  }, []);
  // get parent information
  useEffect(() => {
    getUser(token, parentId).then((res) => {
      setParentInformation(res.data);
      console.log(res.data);
    });
  }, []);

  // form
  // email
  const [emailInputs, setEmailInputs] = useState({
    email: "",
    rule: "required|email",
    startValidate: false,
  });

  const validationEmail = new Validator(
    { email: emailInputs.email },
    { email: emailInputs.rule }
  );

  const [emailTaken, setEmailTaken] = useState(false);
  const handleEmail = (e) => {
    setEmailTaken(false);
    // if (e.target.value !== "") {
    //   if (changeBtn.change === "create") {
    //     studentEmailValidation(e.target.value).then((res) => {
    //       setEmailTaken(res.data.isExisting);
    //     });
    //   } else if (changeBtn.change === "edit") {
    //     if (emailInputs.prevEmail !== e.target.value) {
    //       studentEmailValidation(e.target.value).then((res) => {
    //         setEmailTaken(res.data.isExisting);
    //       });
    //     }
    //   }
    // }

    setEmailInputs((prevState) => ({
      ...prevState,
      email: e.target.value,
      startValidate: true,
    }));
  };

  // password
  const [passwordInputs, setPasswordInputs] = useState({
    password: "",
    rule: "required|min:8",
    startValidate: false,
  });

  const validationPassword = new Validator(
    { password: passwordInputs.password },
    { password: passwordInputs.rule }
  );

  const handlePassword = (e) => {
    e.target.maxLength = 32;
    setPasswordInputs((prevState) => ({
      ...prevState,
      password: e.target.value,
      startValidate: changeBtn.change === "create" ? true : false,
    }));
  };

  // show password
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // firstname
  const [firstnameInputs, setFirstnameInputs] = useState({
    firstname: "",
    rule: "required",
    startValidate: false,
  });
  let validationFirstname = new Validator(
    { firstname: firstnameInputs.firstname },
    { firstname: firstnameInputs.rule }
  );
  const handleFirstname = (e) => {
    const result = e.target.value.toUpperCase().replace(/[^a-z " ' " "]/gi, "");
    setFirstnameInputs((prevState) => ({
      ...prevState,
      firstname: result,
      startValidate: true,
    }));
  };
  //   lastname
  const [lastnameInputs, setLastnameInputs] = useState({
    lastname: "",
    rule: "required",
    startValidate: false,
  });
  let validationLastname = new Validator(
    { lastname: lastnameInputs.lastname },
    { lastname: lastnameInputs.rule }
  );
  const handleLastname = (e) => {
    const result = e.target.value.toUpperCase().replace(/[^a-z " ' " "]/gi, "");
    setLastnameInputs((prevState) => ({
      ...prevState,
      lastname: result,
      startValidate: true,
    }));
  };
  //   middlename
  const [middlenameInputs, setMiddlenameInputs] = useState({
    middlename: "",
    rule: "required",
    startValidate: false,
  });
  let validationMiddlename = new Validator(
    { middlename: middlenameInputs.middlename },
    { middlename: middlenameInputs.rule }
  );
  const handleMiddlename = (e) => {
    const result = e.target.value.toUpperCase().replace(/[^a-z ' " "]/gi, "");
    setMiddlenameInputs((prevState) => ({
      ...prevState,
      middlename: result,
      startValidate: true,
    }));
  };

  // gender
  const [genderInputs, setGenderInputs] = useState({
    gender: "",
    rule: "required",
    startValidate: false,
  });
  let validationGender = new Validator(
    { gender: genderInputs.gender },
    { gender: genderInputs.rule }
  );
  const handleGender = (e) => {
    setGenderInputs((prevState) => ({
      ...prevState,
      gender: e.target.value,
      startValidate: true,
    }));
  };

  // birthday
  const [birthdayInputs, setBirthdayInputs] = useState({
    birthday: "",
    rule: "required",
    startValidate: false,
  });
  let validationBirthday = new Validator(
    { birthday: birthdayInputs.birthday },
    { birthday: birthdayInputs.rule }
  );
  function formatDate(date) {
    let newDate = date.split("-");

    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
  }
  const handleBirthday = (e) => {
    setBirthdayInputs((prevState) => ({
      ...prevState,
      birthday: e.target.value,
      startValidate: true,
    }));
  };

  // ========== STUDENT ADDRESS START ==========
  // region
  const [regionInputs, setRegionInputs] = useState({
    regionId: "",
    rule: "required",
    startValidate: false,
  });
  let validationRegion = new Validator(
    { regionId: regionInputs.regionId },
    { regionId: regionInputs.rule }
  );
  const handleRegion = (e) => {
    setRegionInputs((prevState) => ({
      ...prevState,
      regionId: e.target.value,
      startValidate: true,
    }));
    setDistrictInputs((prevState) => ({
      ...prevState,
      districtId: "",
    }));
  };

  useEffect(() => {
    if (regionInputs.regionId) {
      const { districts } = regionOptions.find(
        (option) => option.id === regionInputs.regionId
      );
      setDistrictOptions(districts);
    }
  }, [regionInputs.regionId]);

  // district
  const [districtInputs, setDistrictInputs] = useState({
    districtId: "",
    rule: "required",
    startValidate: false,
  });
  let validationDistrict = new Validator(
    { districtId: districtInputs.districtId },
    { districtId: districtInputs.rule }
  );
  const handleDistrict = (e) => {
    setDistrictInputs((prevState) => ({
      ...prevState,
      districtId: e.target.value,
      startValidate: true,
    }));
  };

  // Address
  const [addressInputs, setAddressInputs] = useState({
    address: "",
    rule: "required",
    startValidate: false,
  });
  let validationAddress = new Validator(
    { address: addressInputs.address },
    { address: addressInputs.rule }
  );
  const handleAddress = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setAddressInputs((prevState) => ({
      ...prevState,
      address: result,
      startValidate: true,
    }));
  };

  // Phone
  Validator.register(
    "telephone",
    function (value) {
      return value.match("998[0-9]{0,}");
    },
    "The phone format is invalid"
  );

  const [phoneInputs, setPhoneInputs] = useState({
    phone: "",
    rule: "required|telephone|min:12|max:12",
    startValidate: false,
  });

  let validationPhone = new Validator(
    { phone: phoneInputs.phone },
    { phone: phoneInputs.rule }
  );

  const handlePhone = (e) => {
    e.target.maxLength = 12;
    const result = e.target.value.toUpperCase().replace(/[^0-9]/gi, "");
    setPhoneInputs((prevState) => ({
      ...prevState,
      phone: result,
      startValidate: true,
    }));
  };
  // ========== STUDENT ADDRESS END ==========
  // ========== PARENT ADDRESS START ==========
  // region
  const [regionParentInputs, setRegionParentInputs] = useState({
    regionId: "",
    rule: "required",
    startValidate: false,
  });
  let validationRegionParent = new Validator(
    { regionId: regionParentInputs.regionId },
    { regionId: regionParentInputs.rule }
  );
  const handleParentRegion = (e) => {
    setRegionParentInputs((prevState) => ({
      ...prevState,
      regionId: e.target.value,
      startValidate: true,
    }));
    setDistrictParentInputs((prevState) => ({
      ...prevState,
      districtId: "",
    }));
  };

  useEffect(() => {
    if (regionParentInputs.regionId) {
      const { districts } = regionParentOptions.find(
        (option) => option.id === regionParentInputs.regionId
      );
      setDistrictParentOptions(districts);
    }
  }, [regionParentInputs.regionId]);

  // district
  const [districtParentInputs, setDistrictParentInputs] = useState({
    districtId: "",
    rule: "required",
    startValidate: false,
  });
  let validationDistrictParent = new Validator(
    { districtId: districtParentInputs.districtId },
    { districtId: districtParentInputs.rule }
  );
  const handleDistrictParent = (e) => {
    setDistrictParentInputs((prevState) => ({
      ...prevState,
      districtId: e.target.value,
      startValidate: true,
    }));
  };

  // Address
  const [addressParentInputs, setAddressParentInputs] = useState({
    address: "",
    rule: "required",
    startValidate: false,
  });
  let validationParentAddress = new Validator(
    { address: addressParentInputs.address },
    { address: addressParentInputs.rule }
  );
  const handleAddressParent = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setAddressParentInputs((prevState) => ({
      ...prevState,
      address: result,
      startValidate: true,
    }));
  };

  // Phone
  Validator.register(
    "telephone",
    function (value) {
      return value.match("998[0-9]{0,}");
    },
    "The phone format is invalid"
  );

  const [phoneParentInputs, setPhoneParentInputs] = useState({
    phone: "",
    rule: "required|telephone|min:12|max:12",
    startValidate: false,
  });
  let validationParentPhone = new Validator(
    { phone: phoneParentInputs.phone },
    { phone: phoneParentInputs.rule }
  );

  const handleParentPhone = (e) => {
    e.target.maxLength = 12;
    const result = e.target.value.toUpperCase().replace(/[^0-9]/gi, "");
    setPhoneParentInputs((prevState) => ({
      ...prevState,
      phone: result,
      startValidate: true,
    }));
  };
  // ========== PARENT ADDRESS END ==========

  // course
  const [courseInputs, setCourseInputs] = useState({
    course: "",
    rule: "required",
    startValidate: false,
  });
  let validationCourse = new Validator(
    { course: courseInputs.course },
    { course: courseInputs.rule }
  );
  // Get course Pre-1 and Pre-2
  const [socieCourse, setSocieCourse] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedCoursesPrice, setSelectedCoursesPrice] = useState(0);
  const resultPrice = [];

  const handleCourseType = (e) => {
    setCourseInputs((prevState) => ({
      ...prevState,
      course: e.target.value,
      startValidate: true,
    }));
    if (e.target.value == "kids") {
      setDisableBtn(false);
      setSocieCourse(false);
    } else if (e.target.value == "socie") {
      getCoursesType(token, "socie").then((res) => {
        setSocieCourse(res.data);
        console.log(res);
      });
      setSocieCourse(false);
      setSelectedCourses([]);
      setSelectedCoursesPrice(0);
      setDisableBtn(true);
    } else if (e.target.value == "sol") {
      getCoursesType(token, "sol").then((res) => {
        setSocieCourse(res.data);
        console.log(res);
      });
      setSocieCourse(false);
      setSelectedCourses([]);
      setSelectedCoursesPrice(0);
      setDisableBtn(true);
    }
  };

  const formatToCurrency = (amount) => {
    return resultPrice.push(
      "" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&.")
    );
  };
  formatToCurrency(selectedCoursesPrice);
  // inc Price
  const incrementCounter = (clickedPrice) => {
    setSelectedCoursesPrice(selectedCoursesPrice + clickedPrice);
    // setDiscount(((selectedCoursesPrice + clickedPrice) / 100) * 10);
    // console.log(discount);
  };
  // dex Price
  const decrementCounter = (clickedPrice) =>
    setSelectedCoursesPrice(selectedCoursesPrice - clickedPrice);

  const getPrice = (e) => {
    for (let i = 0; i < socieCourse.length; i++) {
      if (socieCourse[i].id == e.target.value) {
        if (socieCourse[i].prices.length > 0) {
          const ids = socieCourse[i].prices.map((object) => {
            return object.id;
          });
          const max = Math.max(...ids);
          for (let j = 0; j < socieCourse[i].prices.length; j++) {
            if (socieCourse[i].prices[j].id == max) {
              if (e.target.checked) {
                incrementCounter(socieCourse[i].prices[j].cost);
              } else {
                decrementCounter(socieCourse[i].prices[j].cost);
              }
            }
          }
        } else {
          console.log("No Prices");
        }
      }
      // if (socieCourse[i].id == e.target.value) {
      //   if (socieCourse[i].prices.length > 0) {
      //     let clickedPrice = socieCourse[i].prices[0].cost;
      //     for (let j = 0; j < socieCourse[i].prices.length; j++) {
      //       if (socieCourse[i]?.id == socieCourse[i]?.prices[j]?.id) {
      //         console.log(socieCourse[i].id);
      //         if (e.target.checked) {
      //           incrementCounter(socieCourse[i]?.prices[j]?.cost);
      //         } else {
      //           decrementCounter(socieCourse[i]?.prices[j]?.cost);
      //         }
      //       }
      //     }
      //   } else {
      //     console.log("No Prices");
      //   }
      // }
    }
  };
  const handleCheckbox = (e) => {
    getPrice(e);
    if (e.target.checked) {
      if (!selectedCourses.includes(Number(e.target.value))) {
        selectedCourses.push(Number(e.target.value));
      }
    } else {
      if (selectedCourses.includes(Number(e.target.value))) {
        const elPosition = selectedCourses.indexOf(Number(e.target.value));
        selectedCourses.splice(elPosition, 1);
      }
    }

    setSelectedCourses(selectedCourses);
    // console.log(selectedCoursesPrice);
    // console.log(selectedCourses);

    // const disCount = (selectedCoursesPrice / 100) * 10;
    if (selectedCourses.length > 0) {
      setDisableBtn(false);

      // if (selectedCourses.length >= 3) {
      //   setDiscount((selectedCoursesPrice / 100) * 10);
      // }
    } else {
      setDisableBtn(true);
    }
  };

  // passport serial code
  const [passportSerialCodeInputs, setPassportSerialCodeInputs] = useState({
    serialNumber: "",
    rule: "required",
    startValidate: false,
  });
  let validationSerialCode = new Validator(
    { serialNumber: passportSerialCodeInputs.serialNumber },
    { serialNumber: passportSerialCodeInputs.rule }
  );
  const handlePassportSerailCode = (e) => {
    e.target.maxLength = 4;
    const result = e.target.value.toUpperCase().replace(/[^A-Z]/gi, "");

    setPassportSerialCodeInputs((prevState) => ({
      ...prevState,
      serialNumber: result,
      startValidate: true,
    }));
  };
  // passport serial number
  const [passportSerialInputs, setPassportSerialInputs] = useState({
    serialNumber: "",
    rule: "required",
    startValidate: false,
  });
  let validationSerialNumber = new Validator(
    { serialNumber: passportSerialInputs.serialNumber },
    { serialNumber: passportSerialInputs.rule }
  );
  const handlePassportSerail = (e) => {
    e.target.maxLength = 7;
    const result = e.target.value.toUpperCase().replace(/[^0-9]/gi, "");
    setPassportSerialInputs((prevState) => ({
      ...prevState,
      serialNumber: result,
      startValidate: true,
    }));
  };

  // passport given date
  const [givenDateInputs, setGivenDateInputs] = useState({
    givenDate: "",
    rule: "required",
    startValidate: false,
  });
  let validationGivenDate = new Validator(
    { givenDate: givenDateInputs.givenDate },
    { givenDate: givenDateInputs.rule }
  );
  const handleGivenDate = (e) => {
    setGivenDateInputs((prevState) => ({
      ...prevState,
      givenDate: e.target.value,
      startValidate: true,
    }));
  };

  // passport due date
  const [dueDateInputs, setDueDateInputs] = useState({
    dueDate: "",
    rule: "required",
    startValidate: false,
  });
  let validationDueDate = new Validator(
    { dueDate: dueDateInputs.dueDate },
    { dueDate: dueDateInputs.rule }
  );
  const handleDueDate = (e) => {
    setDueDateInputs((prevState) => ({
      ...prevState,
      dueDate: e.target.value,
      startValidate: true,
    }));
  };

  // passport department
  const [passportDepartmentInputs, setpassportDepartmentInputs] = useState({
    department: "",
    rule: "required",
    startValidate: false,
  });
  let validationDepartment = new Validator(
    { department: passportDepartmentInputs.department },
    { department: passportDepartmentInputs.rule }
  );
  const handlePassportDepartment = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 " ' " "]/gi, "");
    setpassportDepartmentInputs((prevState) => ({
      ...prevState,
      department: result,
      startValidate: true,
    }));
  };
  // ========== PASSPORT DATA PARENT START ==========
  // passport serial code
  const [passportSerialCodeParentInputs, setPassportSerialCodeParentInputs] =
    useState({
      serialNumber: "",
      rule: "required",
      startValidate: false,
    });
  let validationSerialCodeParent = new Validator(
    { serialNumber: passportSerialCodeParentInputs.serialNumber },
    { serialNumber: passportSerialCodeParentInputs.rule }
  );
  const handlePassportSerailCodeParent = (e) => {
    e.target.maxLength = 2;
    const result = e.target.value.toUpperCase().replace(/[^A-Z]/gi, "");

    setPassportSerialCodeParentInputs((prevState) => ({
      ...prevState,
      serialNumber: result,
      startValidate: true,
    }));
  };
  // passport serial number parent
  const [passportSerialParentInputs, setPassportSerialParentInputs] = useState({
    serialNumber: "",
    rule: "required",
    startValidate: false,
  });
  let validationSerialNumberParent = new Validator(
    { serialNumber: passportSerialParentInputs.serialNumber },
    { serialNumber: passportSerialParentInputs.rule }
  );
  const handlePassportSerailParent = (e) => {
    e.target.maxLength = 7;
    const result = e.target.value.toUpperCase().replace(/[^0-9]/gi, "");
    setPassportSerialParentInputs((prevState) => ({
      ...prevState,
      serialNumber: result,
      startValidate: true,
    }));
  };

  // passport given date
  const [givenDateParentInputs, setGivenDateParentInputs] = useState({
    givenDate: "",
    rule: "required",
    startValidate: false,
  });
  let validationGivenDateParent = new Validator(
    { givenDate: givenDateParentInputs.givenDate },
    { givenDate: givenDateParentInputs.rule }
  );
  const handleGivenDateParent = (e) => {
    setGivenDateParentInputs((prevState) => ({
      ...prevState,
      givenDate: e.target.value,
      startValidate: true,
    }));
  };

  // passport due date
  const [dueDateParentInputs, setDueDateParentInputs] = useState({
    dueDate: "",
    rule: "required",
    startValidate: false,
  });
  let validationDueDateParent = new Validator(
    { dueDate: dueDateParentInputs.dueDate },
    { dueDate: dueDateParentInputs.rule }
  );
  const handleDueDateParent = (e) => {
    setDueDateParentInputs((prevState) => ({
      ...prevState,
      dueDate: e.target.value,
      startValidate: true,
    }));
  };

  // passport department
  const [passportDepartmentParentInputs, setPassportDepartmentParentInputs] =
    useState({
      department: "",
      rule: "required",
      startValidate: false,
    });
  let validationDepartmentParent = new Validator(
    { department: passportDepartmentParentInputs.department },
    { department: passportDepartmentParentInputs.rule }
  );
  const handlePassportDepartmentParent = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 " ' " "]/gi, "");
    setPassportDepartmentParentInputs((prevState) => ({
      ...prevState,
      department: result,
      startValidate: true,
    }));
  };
  // ========== PASSPORT DATA PARENT END ==========

  // course image
  const [imageInputs, setImageInputs] = useState({
    file: "",
    preview: "",
    error: "",
  });

  const handleCourseImage = (e) => {
    setImageInputs((prevState) => ({
      ...prevState,
      file: "",
      preview: "",
      error: "",
    }));
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      var extension = file.name.split(".").pop().toLowerCase();
      var isSuccess = ["jpg", "jpeg", "png"].indexOf(extension) > -1;
    }
    if (isSuccess) {
      reader.onloadend = () => {
        setImageInputs((prevState) => ({
          ...prevState,
          file: file,
          preview: reader.result,
        }));
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      console.log("error img");
      setImageInputs((prevState) => ({
        ...prevState,
        error: "Wrong format",
      }));
    }
  };

  // end form

  // handle modal
  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
    setChangeBtn({ change: "" });
    setEmailTaken(false);
    setDisableBtn(false);
    setSocieCourse(false);
    setEmailInputs((prevState) => ({
      ...prevState,
      email: "",
      startValidate: false,
    }));
    setPasswordInputs((prevState) => ({
      ...prevState,
      password: "",
      startValidate: false,
    }));
    setFirstnameInputs((prevState) => ({
      ...prevState,
      firstname: "",
      startValidate: false,
    }));
    setLastnameInputs((prevState) => ({
      ...prevState,
      lastname: "",
      startValidate: false,
    }));
    setMiddlenameInputs((prevState) => ({
      ...prevState,
      middlename: "",
      startValidate: false,
    }));
    setGenderInputs((prevState) => ({
      ...prevState,
      gender: "",
      startValidate: false,
    }));
    setBirthdayInputs((prevState) => ({
      ...prevState,
      birthday: "",
      startValidate: false,
    }));
    setRegionInputs((prevState) => ({
      ...prevState,
      regionId: "",
      startValidate: false,
    }));
    setDistrictInputs((prevState) => ({
      ...prevState,
      districtId: "",
      startValidate: false,
    }));
    setPhoneInputs((prevState) => ({
      ...prevState,
      phone: "",
      startValidate: false,
    }));
    setCourseInputs((prevState) => ({
      ...prevState,
      course: "",
      startValidate: false,
    }));
    setCourseInputs((prevState) => ({
      ...prevState,
      gender: "",
      startValidate: false,
    }));
    setAddressInputs((prevState) => ({
      ...prevState,
      address: "",
      startValidate: false,
    }));
    setImageInputs((prevState) => ({
      ...prevState,
      file: "",
      preview: "",
      error: "",
    }));
    setPassportSerialCodeInputs((prevState) => ({
      ...prevState,
      serialNumber: "",
      startValidate: false,
    }));
    setPassportSerialInputs((prevState) => ({
      ...prevState,
      serialNumber: "",
      startValidate: false,
    }));
    setGivenDateInputs((prevState) => ({
      ...prevState,
      givenDate: "",
      startValidate: false,
    }));
    setDueDateInputs((prevState) => ({
      ...prevState,
      dueDate: "",
      startValidate: false,
    }));
    setpassportDepartmentInputs((prevState) => ({
      ...prevState,
      department: "",
      startValidate: false,
    }));
    setPassportSerialCodeParentInputs((prevState) => ({
      ...prevState,
      serialNumber: "",
      startValidate: false,
    }));
    setPassportSerialParentInputs((prevState) => ({
      ...prevState,
      serialNumber: "",
      startValidate: false,
    }));
    setGivenDateParentInputs((prevState) => ({
      ...prevState,
      givenDate: "",
      startValidate: false,
    }));
    setDueDateParentInputs((prevState) => ({
      ...prevState,
      dueDate: "",
      startValidate: false,
    }));
    setPassportDepartmentParentInputs((prevState) => ({
      ...prevState,
      department: "",
      startValidate: false,
    }));
  };

  const openModalForCreate = () => {
    setOpen(true);
    setChangeBtn({ change: "create" });
    setImageInputs((prevState) => ({
      ...prevState,
      preview: "",
      previewChange: "",
    }));
  };

  const [studentID, setStudentID] = useState();
  const openModalForEdit = (e, child) => {
    e.preventDefault();
    setOpen(true);
    setChangeBtn({ change: "edit" });
    setStudentID(child.id);
    console.log(child);

    setEmailInputs((prevState) => ({
      ...prevState,
      email: child.email,
      prevEmail: child.email,
    }));
    setPasswordInputs((prevState) => ({
      ...prevState,
      password: "",
    }));
    setFirstnameInputs((prevState) => ({
      ...prevState,
      firstname: child.first_name,
    }));
    setMiddlenameInputs((prevState) => ({
      ...prevState,
      middlename: child.middle_name,
    }));
    setLastnameInputs((prevState) => ({
      ...prevState,
      lastname: child.last_name,
    }));
    setGenderInputs((prevState) => ({
      ...prevState,
      gender: child.gender,
    }));
    setBirthdayInputs((prevState) => ({
      ...prevState,
      birthday: child.birth_date.substring(0, 10),
    }));
    setRegionInputs((prevState) => ({
      ...prevState,
      regionId: child.region.id,
    }));
    setDistrictInputs((prevState) => ({
      ...prevState,
      districtId: child.district.id,
    }));
    setAddressInputs((prevState) => ({
      ...prevState,
      address: child.address,
    }));
    setPhoneInputs((prevState) => ({
      ...prevState,
      phone: child.phone_number,
    }));
    setPassportSerialCodeInputs((prevState) => ({
      ...prevState,
      serialNumber: child.passport_serial_number.slice(0, 2),
    }));
    setPassportSerialInputs((prevState) => ({
      ...prevState,
      serialNumber: child.passport_serial_number.slice(2, 9),
    }));
    setGivenDateInputs((prevState) => ({
      ...prevState,
      givenDate: child.passport_given_date,
    }));
    setDueDateInputs((prevState) => ({
      ...prevState,
      dueDate: child.passport_due_date,
    }));
    setpassportDepartmentInputs((prevState) => ({
      ...prevState,
      department: child.passport_department,
    }));
    if (child.passport_image == null) {
      setImageInputs((prevState) => ({
        ...prevState,
        preview: "",
        previewChange: "",
      }));
    } else {
      setImageInputs((prevState) => ({
        ...prevState,
        preview: `${process.env.REACT_APP_PRE_UNI_API}/users/${child.id}/passport`,
        previewChange: `${process.env.REACT_APP_PRE_UNI_API}/users/${child.id}/passport`,
      }));
    }
  };

  // stepper
  const [activeStep, setActiveStep] = useState(0);

  const nextButton = (e) => {
    e.preventDefault();
    if (
      validationEmail.passes() &&
      validationFirstname.passes() &&
      validationLastname.passes() &&
      validationMiddlename.passes() &&
      validationBirthday.passes()
    ) {
      if (
        changeBtn.change == "create" &&
        validationEmail.passes() &&
        validationPassword.passes()
      ) {
        setActiveStep(1);
      }
      if (changeBtn.change == "edit") {
        setActiveStep(1);
      }
    } else {
      setEmailInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));

      if (changeBtn.change == "create") {
        setPasswordInputs((prevState) => ({
          ...prevState,
          startValidate: true,
        }));
      }
      setFirstnameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setLastnameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setMiddlenameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setGenderInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setBirthdayInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };
  const nextButton2 = (e) => {
    e.preventDefault();
    if (
      validationGender.passes() &&
      validationRegion.passes() &&
      validationDistrict.passes() &&
      validationAddress.passes() &&
      validationPhone.passes() &&
      emailTaken === false
    ) {
      setActiveStep(2);
    } else {
      setRegionInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setDistrictInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setAddressInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPhoneInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };
  const nextButton3 = (e) => {
    e.preventDefault();
    if (
      validationSerialCode.passes() &&
      validationSerialNumber.passes() &&
      validationGivenDate.passes() &&
      validationDepartment.passes() &&
      emailTaken === false
    ) {
      setActiveStep(3);
    } else {
      setPassportSerialCodeInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPassportSerialInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setGivenDateInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setpassportDepartmentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };
  const nextButton4 = (e) => {
    e.preventDefault();
    if (validationCourse.passes()) {
      setActiveStep(4);
    } else {
      setCourseInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  const [allStudents, setAllStudents] = useState([]);
  const createStudentBtn = (e) => {
    e.preventDefault();
    console.log(imageInputs);

    if (validationCourse.passes()) {
      const data = {
        email: emailInputs.email,
        password: passwordInputs.password,
        first_name: firstnameInputs.firstname,
        last_name: lastnameInputs.lastname,
        middle_name: middlenameInputs.middlename,
        gender: genderInputs.gender,
        birth_date: birthdayInputs.birthday,
        regionId: regionInputs.regionId,
        districtId: districtInputs.districtId,
        address: addressInputs.address,
        phone_number: phoneInputs.phone,
        course_type: courseInputs.course,
        passport_serial_number: `${passportSerialCodeInputs.serialNumber}${passportSerialInputs.serialNumber}`,
        passport_given_date: givenDateInputs.givenDate,
        passport_due_date: dueDateInputs.dueDate,
        passport_department: passportDepartmentInputs.department,
      };
      setDisableBtn(true);
      setLoader(true);
      createStudent(token, data).then((res) => {
        if (res.status === 201) {
          getContract(res.data.id, token).then((res) => {
            // console.log("log-1", res);
          });
          getContract(res.data.id, token).then((res) => {
            // console.log("log-1", res);
          });
          getContract(res.data.id, token).then((res) => {
            // console.log("log-1", res);
          });
          console.log(selectedCourses, "selectedCourses-1");
          selectedCourses.forEach((courseId) => {
            // allStudents.forEach((userId) => {
            const data = {
              student_id: res.data.id,
              course_id: courseId,
            };
            createAddStudentToCourse(data, token)
              .then((res) => {
                if (res.status === 201) {
                  console.log(res.data, "createAddStudentToCourse-1");
                  NotificationManager.success(
                    "The student has joined successfully the course"
                  );
                }
              })
              .catch((err) => {
                console.log(err);
                NotificationManager.warning(
                  `The ${firstnameInputs.firstname} has already joined to this course`
                );
              });
          });
          handleClose();
          NotificationManager.success("Child created successfully");

          setDisableBtn(false);
          setAllStudents((prevState) => [res.data, ...prevState]);
          createImage(token, imageInputs.file, res.data.id);
          setLoader(false);
          if (courseInputs.course != "kids") {
            history.push(`/app/parent/children/${res.data.id}`);
          } else {
            window.location.reload();
          }
        }
      });
    } else {
      setCourseInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };
  const createStudentParentBtn = (e) => {
    e.preventDefault();
    console.log(imageInputs);

    if (
      validationSerialCodeParent.passes() &&
      validationSerialNumberParent.passes() &&
      validationGivenDateParent.passes() &&
      validationDepartmentParent.passes() &&
      validationRegionParent.passes() &&
      validationDistrictParent.passes() &&
      validationParentAddress.passes() &&
      validationParentPhone.passes()
    ) {
      setLoader(true);
      const data = {
        email: emailInputs.email,
        password: passwordInputs.password,
        first_name: firstnameInputs.firstname,
        last_name: lastnameInputs.lastname,
        middle_name: middlenameInputs.middlename,
        gender: genderInputs.gender,
        birth_date: birthdayInputs.birthday,
        regionId: regionInputs.regionId,
        districtId: districtInputs.districtId,
        address: addressInputs.address,
        phone_number: phoneInputs.phone,
        course_type: courseInputs.course,
        passport_serial_number: `${passportSerialCodeInputs.serialNumber}${passportSerialInputs.serialNumber}`,
        passport_given_date: givenDateInputs.givenDate,
        passport_due_date: dueDateInputs.dueDate,
        passport_department: passportDepartmentInputs.department,
      };
      const dataParent = {
        first_name: parentInformation?.first_name,
        last_name: parentInformation?.last_name,
        gender: parentInformation?.gender,
        birth_date: parentInformation?.birth_date,
        regionId: regionParentInputs.regionId,
        districtId: districtParentInputs.districtId,
        address: addressParentInputs.address,
        phone_number: phoneParentInputs.phone,
        passport_serial_number: `${passportSerialCodeParentInputs.serialNumber}${passportSerialParentInputs.serialNumber}`,
        passport_given_date: givenDateParentInputs.givenDate,
        passport_due_date: dueDateParentInputs.dueDate,
        passport_department: passportDepartmentParentInputs.department,
      };
      setDisableBtn(true);
      createParentPassport(token, parentId, dataParent).then((res) => {
        console.log(res);
      });
      createStudent(token, data).then((res) => {
        console.log(res);
        if (res.status === 201) {
          getContract(res.data.id, token).then((res) => {
            // console.log("log-1", res);
          });
          getContract(res.data.id, token).then((res) => {
            // console.log("log-1", res);
          });
          getContract(res.data.id, token).then((res) => {
            // console.log("log-1", res);
          });
          console.log(selectedCourses, "selectedCourses");
          selectedCourses.forEach((courseId) => {
            // allStudents.forEach((userId) => {
            const data = {
              student_id: res.data.id,
              course_id: courseId,
            };
            createAddStudentToCourse(data, token)
              .then((res) => {
                if (res.status === 201) {
                  console.log(res.data);
                  NotificationManager.success(
                    "The student has joined successfully the course"
                  );
                }
              })
              .catch((err) => {
                console.log(err);
                NotificationManager.warning(
                  `The ${firstnameInputs.firstname} has already joined to this course`
                );
              });
          });
          handleClose();
          NotificationManager.success("Child created successfully");
          setDisableBtn(false);
          setAllStudents((prevState) => [res.data, ...prevState]);
          createImage(token, imageInputs.file, res.data.id);
          setLoader(false);
          if (courseInputs.course != "kids") {
            history.push(`/app/parent/children/${res.data.id}`);
          } else {
            window.location.reload();
          }
          // window.location.reload();
        }
      });
    } else {
      setPassportSerialCodeParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPassportSerialParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setGivenDateParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPassportDepartmentParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setRegionParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setDistrictParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setAddressParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPhoneParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  const updateStudentBtn = (e) => {
    e.preventDefault();
    if (
      validationEmail.passes() &&
      validationFirstname.passes() &&
      validationLastname.passes() &&
      validationMiddlename.passes() &&
      validationGender.passes() &&
      validationBirthday.passes() &&
      validationRegion.passes() &&
      validationDistrict.passes() &&
      validationAddress.passes() &&
      validationPhone.passes() &&
      validationSerialNumber.passes() &&
      validationGivenDate.passes() &&
      validationDepartment.passes()
    ) {
      const data = {
        email: emailInputs.email,
        first_name: firstnameInputs.firstname,
        last_name: lastnameInputs.lastname,
        middle_name: middlenameInputs.middlename,
        gender: genderInputs.gender,
        birth_date: birthdayInputs.birthday,
        regionId: regionInputs.regionId,
        districtId: districtInputs.districtId,
        address: addressInputs.address,
        phone_number: phoneInputs.phone,
        passport_serial_number: `${passportSerialCodeInputs.serialNumber}${passportSerialInputs.serialNumber}`,
        passport_given_date: givenDateInputs.givenDate,
        passport_due_date: dueDateInputs?.dueDate,
        passport_department: passportDepartmentInputs.department,
      };

      console.log("data", data);
      if (passwordInputs.password !== "") {
        data.password = passwordInputs.password;
      }
      if (studentID) {
        setDisableBtn(true);
        updateStudent(token, studentID, data).then((res) => {
          if (res.status === 200) {
            if (imageInputs.preview !== imageInputs.previewChange) {
              createImage(token, imageInputs.file, studentID).then((res) => {
                console.log(res);
              });
            }

            const regionIndex = regionOptions.filter(
              (el) => el.id === data.regionId
            );
            const districtIndex = districtOptions.filter(
              (el) => el.id === data.districtId
            );
            const newData = {
              ...data,
              id: studentID,
              region: regionIndex[0],
              district: districtIndex[0],
            };
            const newArr = allStudents.filter((el) => el.id !== studentID);
            const newSortedArr = [...newArr, newData].sort(function (a, b) {
              return a.id - b.id;
            });

            handleClose();
            NotificationManager.success("Child updated successfully");
            setAllStudents(newSortedArr);
            setDisableBtn(false);

            window.location.reload();
          }
        });
      }
    } else {
      setEmailInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setFirstnameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setLastnameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setMiddlenameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setGenderInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setBirthdayInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setRegionInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setDistrictInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setAddressInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPhoneInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPassportSerialInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setGivenDateInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));

      setpassportDepartmentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  const [appliedCourses, setAppliedCourses] = useState(new Map());
  useEffect(() => {
    getStudents(token).then((res) => {
      setAllStudents(res.data);
    });
  }, []);

  useEffect(() => {
    if (allStudents.length !== 0) {
      allStudents.map((student) => {
        getStudentAppliedCourses(token, student.id).then((res) => {
          setAppliedCourses((map) => new Map(map.set(student.id, res.data)));
        });
      });
    }
  }, [allStudents]);

  return (
    <div className={classes.root}>
      <SmallTitleBar title={t("parent.myChildren")} />
      <Container maxWidth="lg">
        <Box mt={5} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={openModalForCreate}
          >
            {t("parent.addChild")}
          </Button>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={(_, reason) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }}
          >
            <div
              style={modalStyle}
              className={`${classes.paper} modalAddChild`}
            >
              <Box my={3} display="flex" justifyContent="space-between">
                <Typography variant="h6">
                  {changeBtn.change === "create"
                    ? `${t("parent.addChild")}`
                    : `${t("parent.updateChild")}`}
                </Typography>
                <CloseIcon
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </Box>
              <Box mb={3}>
                {allStudents?.length > 0 && changeBtn?.change == "create" ? (
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    style={{ padding: 0 }}
                  >
                    <Step>
                      <StepLabel>{t("parent.step")}-1</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t("parent.step")}-2</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t("parent.step")}-3</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t("parent.step")}-4</StepLabel>
                    </Step>
                  </Stepper>
                ) : allStudents?.length > 0 && changeBtn?.change == "edit" ? (
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    style={{ padding: 0 }}
                  >
                    <Step>
                      <StepLabel>{t("parent.step")}-1</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t("parent.step")}-2</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t("parent.step")}-3</StepLabel>
                    </Step>
                  </Stepper>
                ) : allStudents?.length == 0 &&
                  changeBtn?.change == "create" ? (
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    style={{ padding: 0 }}
                  >
                    <Step>
                      <StepLabel>{t("parent.step")}-1</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t("parent.step")}-2</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t("parent.step")}-3</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t("parent.step")}-4</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t("parent.step")}-5</StepLabel>
                    </Step>
                  </Stepper>
                ) : (
                  ""
                )}
              </Box>
              <Box>
                <h5 style={{ textAlign: "center" }}>
                  {activeStep == 0
                    ? `${t("parent.studentInfo")}`
                    : activeStep == 1
                    ? `${t("parent.studentAddress")}`
                    : activeStep == 2
                    ? `${t("parent.studentPassport")}`
                    : activeStep == 3
                    ? `${t("parent.studentSelectCourse")}`
                    : `${t("parent.parentPassport")}`}
                </h5>
              </Box>
              {activeStep === 0 && (
                <Box>
                  {/*Email*/}
                  <Box my={3}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="email"
                        variant="outlined"
                        className="outlined-input"
                        placeholder={t("parent.emailPlaceholder")}
                        label={`${t("parent.email")}:`}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={emailInputs.email}
                        onChange={(e) => handleEmail(e)}
                        error={
                          (emailInputs.startValidate &&
                            (validationEmail.passes() === true
                              ? false
                              : true)) ||
                          (emailTaken === true ? true : false)
                        }
                      />
                      <FormHelperText>
                        {emailInputs.startValidate &&
                          validationEmail.errors.first("email")}
                      </FormHelperText>
                      {/* <FormHelperText>
                          {emailTaken && "The email is already taken."}
                        </FormHelperText> */}
                    </FormControl>
                  </Box>

                  {/*Pass*/}
                  {changeBtn.change == "create" ? (
                    <Box my={3}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        size="small"
                        error={
                          passwordInputs.startValidate &&
                          (validationPassword.passes() === true ? false : true)
                        }
                      >
                        <InputLabel
                          htmlFor="standard-adornment-password"
                          shrink={true}
                        >
                          {t("parent.password")}:
                        </InputLabel>
                        <OutlinedInput
                          id="standard-adornment-password"
                          required
                          fullWidth
                          labelWidth={70}
                          placeholder={`${t("parent.passPlaceholder")}`}
                          type={showPassword ? "text" : "password"}
                          value={passwordInputs.password}
                          onChange={(e) => handlePassword(e)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText style={{ marginLeft: 0 }}>
                          {passwordInputs.startValidate &&
                            validationPassword.errors.first("password")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  ) : (
                    ""
                  )}

                  {/*First Name*/}
                  <Box my={3}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.firsname")}: `}
                        placeholder={`${t("parent.firsnamePlaceholder")}`}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={firstnameInputs.firstname}
                        onChange={(e) => handleFirstname(e)}
                        error={
                          firstnameInputs.startValidate &&
                          (validationFirstname.passes() === true ? false : true)
                        }
                      />
                      <FormHelperText>
                        {firstnameInputs.startValidate &&
                          validationFirstname.errors.first("firstname")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Last Name*/}
                  <Box my={3}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.lastName")}: `}
                        placeholder={t("registerForm.lastnamePlaceholder")}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={lastnameInputs.lastname}
                        onChange={(e) => handleLastname(e)}
                        error={
                          lastnameInputs.startValidate &&
                          (validationLastname.passes() === true ? false : true)
                        }
                      />
                      <FormHelperText>
                        {lastnameInputs.startValidate &&
                          validationLastname.errors.first("lastname")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Middle Name*/}
                  <Box my={3}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.middleName")}: `}
                        placeholder={t("registerForm.middlenamePlaceholder")}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={middlenameInputs.middlename}
                        onChange={(e) => handleMiddlename(e)}
                        error={
                          middlenameInputs.startValidate &&
                          (validationMiddlename.passes() === true
                            ? false
                            : true)
                        }
                      />
                      <FormHelperText>
                        {middlenameInputs.startValidate &&
                          validationMiddlename.errors.first("lastname")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Gender*/}
                  <Box my={3}>
                    <FormControl
                      required
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={
                        genderInputs.startValidate &&
                        (validationGender.passes() === true ? false : true)
                      }
                    >
                      <InputLabel id="select-gender-label" shrink={true}>
                        {t("parent.selectGender")}:
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="select-gender-label"
                        label="Select gender: "
                        variant="outlined"
                        value={genderInputs.gender}
                        onChange={(e) => handleGender(e)}
                      >
                        <MenuItem value="male">{t("parent.male")}</MenuItem>
                        <MenuItem value="female">{t("parent.female")}</MenuItem>
                      </Select>
                      <FormHelperText style={{ marginLeft: "0" }}>
                        {genderInputs.startValidate &&
                          validationGender.errors.first("gender")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Birthday*/}
                  <Box my={3}>
                    <FormControl fullWidth error>
                      <TextField
                        required
                        fullWidth
                        type="date"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.birthday")}:`}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={birthdayInputs.birthday}
                        onChange={(e) => handleBirthday(e)}
                        error={
                          birthdayInputs.startValidate &&
                          (validationBirthday.passes() === true ? false : true)
                        }
                      />
                      <FormHelperText style={{ marginLeft: 0 }}>
                        {birthdayInputs.startValidate &&
                          validationBirthday.errors.first("birthday")}
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <Box my={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => nextButton(e)}
                    >
                      {t("parent.next")}
                    </Button>
                  </Box>
                </Box>
              )}
              {activeStep === 1 && (
                <Box>
                  {/*Regions*/}
                  <Box my={3}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={
                        regionInputs.startValidate &&
                        (validationRegion.passes() === true ? false : true)
                      }
                    >
                      <InputLabel id="select-region-label" shrink={true}>
                        {t("parent.selectRegion")}:
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="select-region-label"
                        label="Select region:"
                        variant="outlined"
                        value={regionInputs.regionId}
                        onChange={(e) => handleRegion(e)}
                      >
                        {regionOptions &&
                          regionOptions.map((option, index) => (
                            <MenuItem key={index} value={option.id}>
                              {option.name_en}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText style={{ marginLeft: 0 }}>
                        {regionInputs.startValidate &&
                          validationRegion.errors.first("regionId")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*District*/}
                  <Box my={3}>
                    <FormControl
                      required
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={
                        districtInputs.startValidate &&
                        (validationDistrict.passes() === true ? false : true)
                      }
                    >
                      <InputLabel id="select-district-label" shrink={true}>
                        {t("parent.selectDistrict")}:
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="select-district-label"
                        label="Select district:"
                        variant="outlined"
                        value={districtInputs.districtId}
                        onChange={(e) => handleDistrict(e)}
                      >
                        {districtOptions &&
                          districtOptions.map((option, index) => (
                            <MenuItem key={index} value={option.id}>
                              {option.name_en}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText style={{ marginLeft: 0 }}>
                        {districtInputs.startValidate &&
                          validationDistrict.errors.first("districtId")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Address*/}
                  <Box my={3}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.address")}:`}
                        placeholder={t("parent.addressPlaceholder")}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={addressInputs.address}
                        onChange={(e) => handleAddress(e)}
                        error={
                          addressInputs.startValidate &&
                          (validationAddress.passes() === true ? false : true)
                        }
                      />
                      <FormHelperText>
                        {addressInputs.startValidate &&
                          validationAddress.errors.first("address")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Phone Number*/}
                  <Box my={3}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.phone")}:`}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        placeholder="998901234567"
                        value={`${phoneInputs.phone}`}
                        onChange={(e) => handlePhone(e)}
                        error={
                          phoneInputs.startValidate &&
                          (validationPhone.passes() === true ? false : true)
                        }
                      />
                      <FormHelperText>
                        {phoneInputs.startValidate &&
                          validationPhone.errors.first("phone")}
                      </FormHelperText>
                    </FormControl>

                    <Box my={3}>
                      <Button
                        style={{ marginRight: "10px" }}
                        onClick={() => setActiveStep(0)}
                      >
                        {t("parent.back")}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => nextButton2(e)}
                      >
                        {t("parent.next")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
              {activeStep === 2 && (
                <Box>
                  {/*Pass serial number*/}
                  <Box my={3} style={{ display: "flex" }}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.passportSerial")}:`}
                        placeholder={t("parent.passportSerialPlaceh")}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={passportSerialCodeInputs.serialNumber}
                        onChange={(e) => handlePassportSerailCode(e)}
                        error={
                          passportSerialCodeInputs.startValidate &&
                          (validationSerialCode.passes() === true
                            ? false
                            : true)
                        }
                      />
                      <FormHelperText>
                        {passportSerialCodeInputs.startValidate &&
                          validationSerialCode.errors.first("passport serial")}
                      </FormHelperText>
                    </FormControl>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.passportSerialNum")}:`}
                        placeholder={t("parent.passportSerialNumPlaceh")}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={passportSerialInputs.serialNumber}
                        onChange={(e) => handlePassportSerail(e)}
                        error={
                          passportSerialInputs.startValidate &&
                          (validationSerialNumber.passes() === true
                            ? false
                            : true)
                        }
                      />
                      <FormHelperText>
                        {passportSerialInputs.startValidate &&
                          validationSerialNumber.errors.first(
                            "passport serial number"
                          )}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Pass given date*/}
                  <Box my={3}>
                    <FormControl fullWidth error>
                      <TextField
                        required
                        fullWidth
                        type="date"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={t("parent.passportGiven")}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={givenDateInputs.givenDate}
                        onChange={(e) => handleGivenDate(e)}
                        error={
                          givenDateInputs.startValidate &&
                          (validationGivenDate.passes() === true ? false : true)
                        }
                      />
                      <FormHelperText style={{ marginLeft: 0 }}>
                        {givenDateInputs.startValidate &&
                          validationGivenDate.errors.first("given date")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Pass due date*/}
                  <Box my={3}>
                    <FormControl fullWidth error>
                      <TextField
                        required
                        fullWidth
                        type="date"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={t("parent.passportDue")}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={dueDateInputs.dueDate}
                        onChange={(e) => handleDueDate(e)}
                        error={
                          dueDateInputs.startValidate &&
                          (validationDueDate.passes() === true ? false : true)
                        }
                      />
                      <FormHelperText style={{ marginLeft: 0 }}>
                        {dueDateInputs.startValidate &&
                          validationDueDate.errors.first("given date")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Pass department*/}
                  <Box my={3}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={t("parent.passportDeparment")}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={passportDepartmentInputs.department}
                        onChange={(e) => handlePassportDepartment(e)}
                        error={
                          passportDepartmentInputs.startValidate &&
                          (validationDepartment.passes() === true
                            ? false
                            : true)
                        }
                      />
                      <FormHelperText>
                        {passportDepartmentInputs.startValidate &&
                          validationDepartment.errors.first(
                            "passport department"
                          )}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/* Pass Image  */}
                  <Box mt={2} mb={1}>
                    <Typography variant="subtitle2">
                      {t("parent.uploadPassport")}:
                    </Typography>
                  </Box>
                  <Input
                    type="file"
                    accept="image/*"
                    id="courseImg"
                    style={{ display: "none" }}
                    onChange={handleCourseImage}
                  />
                  <label
                    htmlFor="courseImg"
                    style={{ width: "100%", cursor: "pointer" }}
                  >
                    <div
                      style={{
                        height: "200px",
                        backgroundColor: "#e3f2fd",
                        color: "#2196f3",
                        borderRadius: "8px",
                        fontSize: "24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {imageInputs.preview ? (
                        <img
                          src={imageInputs.preview}
                          height={"100%"}
                          width={"100%"}
                          style={{ objectFit: "cover", borderRadius: "8px" }}
                        />
                      ) : (
                        "+"
                      )}
                    </div>
                  </label>
                  {imageInputs.error && (
                    <p style={{ color: "#e53935", fontSize: "11px" }}>
                      {imageInputs.error}
                    </p>
                  )}

                  <Box my={3}>
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => setActiveStep(1)}
                    >
                      {t("parent.back")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={(e) => nextButton3(e)}
                      onClick={(e) =>
                        changeBtn.change === "create"
                          ? nextButton3(e)
                          : updateStudentBtn(e)
                      }
                    >
                      {changeBtn.change === "create"
                        ? `${t("parent.next")}`
                        : `${t("parent.update")}`}
                    </Button>

                    {/* <Button
                      color="secondary"
                      variant="contained"
                      style={{ marginLeft: "10px" }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button> */}
                  </Box>
                </Box>
              )}
              {activeStep === 3 && (
                <Box>
                  {/*Course*/}
                  <Box my={3}>
                    <FormControl
                      required
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={
                        courseInputs.startValidate &&
                        (validationCourse.passes() === true ? false : true)
                      }
                    >
                      <InputLabel id="select-gender-label" shrink={true}>
                        {t("parent.studentSelectCourse")}
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="select-course-label"
                        label={t("parent.studentSelectCourse")}
                        variant="outlined"
                        value={courseInputs.course}
                        onChange={(e) => handleCourseType(e)}
                      >
                        <MenuItem value="kids">{t("parent.kids")} </MenuItem>
                        <MenuItem value="socie">{t("parent.pre1")}</MenuItem>
                        <MenuItem value="sol">{t("parent.pre2")}</MenuItem>
                      </Select>
                      <FormHelperText style={{ marginLeft: "0" }}>
                        {courseInputs.startValidate &&
                          validationCourse.errors.first("course")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Box>
                    {!socieCourse ? (
                      "No selected course"
                    ) : (
                      <Typography>
                        {socieCourse?.length > 0 ? (
                          <h3 style={{ textAlign: "center" }}>
                            {t("parent.prices")}
                          </h3>
                        ) : (
                          ""
                        )}

                        {/* <span style={{ fontSize: "18px" }}>
                          {socieCourse?.[0]?.course_type == "socie"
                            ? `${t("parent.socie")}: `
                            : `${t("parent.sol")}: `}
                        </span>
                        {socieCourse?.length > 0
                          ? socieCourse.map((item, index) => (
                              <span style={{ fontSize: "18px" }}>
                                {localStorage.getItem("lng") == "en"
                                  ? `${item.name_en} `
                                  : localStorage.getItem("lng") == "ru"
                                  ? `${item.name_ru} `
                                  : `${item.name_uz} `}
                              </span>
                            ))
                          : ""} */}

                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h6 style={{ color: "#dc3545" }}>
                            {resultPrice[0]} {t("parent.valyuta")}
                          </h6>
                          <h6 style={{ color: "#0d6efd", textAlign: "center" }}>
                            {selectedCourses.length > 2
                              ? `${
                                  (parseInt(selectedCoursesPrice) / 100) * 10
                                } `
                              : "0 "}
                            {t("parent.valyuta")} {t("parent.discount")}
                          </h6>
                          <h6 style={{ color: "#198754" }}>
                            {selectedCourses.length > 2 ? (
                              <span>10% {t("parent.discount")}</span>
                            ) : (
                              <span>0% {t("parent.discount")}</span>
                            )}
                          </h6>
                        </Box>
                        <Box style={{ marginRight: "20px", display: "flex" }}>
                          {socieCourse &&
                            socieCourse.map((item, index) => (
                              <Box
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography>
                                  {localStorage.getItem("lng") == "en"
                                    ? item?.name_en
                                    : localStorage.getItem("lng") == "ru"
                                    ? item?.name_ru
                                    : item?.name_uz}
                                </Typography>
                                <Checkbox
                                  value={item.id}
                                  onChange={handleCheckbox}
                                />
                              </Box>
                            ))}
                        </Box>
                      </Typography>
                    )}
                  </Box>
                  <Box my={3}>
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => setActiveStep(2)}
                    >
                      {t("parent.back")}
                    </Button>
                    {allStudents?.length > 0 ? (
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={disableBtn}
                        onClick={(e) =>
                          changeBtn.change === "create"
                            ? createStudentBtn(e)
                            : updateStudentBtn(e)
                        }
                      >
                        {loader ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          `${t("parent.create")}`
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={disableBtn}
                        onClick={(e) => nextButton4(e)}
                      >
                        {t("parent.next")}
                      </Button>
                    )}

                    {/* <Button
                      color="secondary"
                      variant="contained"
                      style={{ marginLeft: "10px" }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button> */}
                  </Box>
                </Box>
              )}
              {activeStep === 4 && (
                <Box>
                  {/*Pass serial number*/}
                  <Box my={3} style={{ display: "flex" }}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.passportSerial")}:`}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={passportSerialCodeParentInputs.serialNumber}
                        onChange={(e) => handlePassportSerailCodeParent(e)}
                        error={
                          passportSerialCodeParentInputs.startValidate &&
                          (validationSerialCodeParent.passes() === true
                            ? false
                            : true)
                        }
                      />
                      <FormHelperText>
                        {passportSerialCodeParentInputs.startValidate &&
                          validationSerialCodeParent.errors.first(
                            "passport serial"
                          )}
                      </FormHelperText>
                    </FormControl>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.passportSerialNum")}:`}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={passportSerialParentInputs.serialNumber}
                        onChange={(e) => handlePassportSerailParent(e)}
                        error={
                          passportSerialParentInputs.startValidate &&
                          (validationSerialNumberParent.passes() === true
                            ? false
                            : true)
                        }
                      />
                      <FormHelperText>
                        {passportSerialParentInputs.startValidate &&
                          validationSerialNumberParent.errors.first(
                            "passport serial number"
                          )}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Pass given date*/}
                  <Box my={3}>
                    <FormControl fullWidth error>
                      <TextField
                        required
                        fullWidth
                        type="date"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.passportGiven")}:`}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={givenDateParentInputs.givenDate}
                        onChange={(e) => handleGivenDateParent(e)}
                        error={
                          givenDateParentInputs.startValidate &&
                          (validationGivenDateParent.passes() === true
                            ? false
                            : true)
                        }
                      />
                      <FormHelperText style={{ marginLeft: 0 }}>
                        {givenDateParentInputs.startValidate &&
                          validationGivenDateParent.errors.first("given date")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Pass due date*/}
                  <Box my={3}>
                    <FormControl fullWidth error>
                      <TextField
                        required
                        fullWidth
                        type="date"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.passportDue")}:`}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={dueDateParentInputs.dueDate}
                        onChange={(e) => handleDueDateParent(e)}
                        error={
                          dueDateParentInputs.startValidate &&
                          (validationDueDateParent.passes() === true
                            ? false
                            : true)
                        }
                      />
                      <FormHelperText style={{ marginLeft: 0 }}>
                        {dueDateParentInputs.startValidate &&
                          validationDueDateParent.errors.first("given date")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Pass department*/}
                  <Box my={3}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.passportDeparment")}:`}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={passportDepartmentParentInputs.department}
                        onChange={(e) => handlePassportDepartmentParent(e)}
                        error={
                          passportDepartmentParentInputs.startValidate &&
                          (validationDepartmentParent.passes() === true
                            ? false
                            : true)
                        }
                      />
                      <FormHelperText>
                        {passportDepartmentParentInputs.startValidate &&
                          validationDepartmentParent.errors.first(
                            "passport department"
                          )}
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <h5 style={{ textAlign: "center" }}>
                    {t("parent.parentAddress")}
                  </h5>
                  {/*Regions*/}
                  <Box my={3}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={
                        regionParentInputs.startValidate &&
                        (validationRegionParent.passes() === true
                          ? false
                          : true)
                      }
                    >
                      <InputLabel id="select-region-label" shrink={true}>
                        {t("parent.selectRegion")}:
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="select-region-label"
                        label={`${t("parent.selectRegion")}:`}
                        variant="outlined"
                        value={regionParentInputs.regionId}
                        onChange={(e) => handleParentRegion(e)}
                      >
                        {regionParentOptions &&
                          regionParentOptions.map((option, index) => (
                            <MenuItem key={index} value={option.id}>
                              {option.name_en}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText style={{ marginLeft: 0 }}>
                        {regionParentInputs.startValidate &&
                          validationRegionParent.errors.first("regionId")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*District*/}
                  <Box my={3}>
                    <FormControl
                      required
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={
                        districtParentInputs.startValidate &&
                        (validationDistrictParent.passes() === true
                          ? false
                          : true)
                      }
                    >
                      <InputLabel id="select-district-label" shrink={true}>
                        {t("parent.selectDistrict")}:
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="select-district-label"
                        label="Select district:"
                        variant="outlined"
                        value={districtParentInputs.districtId}
                        onChange={(e) => handleDistrictParent(e)}
                      >
                        {districtParentOptions &&
                          districtParentOptions.map((option, index) => (
                            <MenuItem key={index} value={option.id}>
                              {option.name_en}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText style={{ marginLeft: 0 }}>
                        {districtInputs.startValidate &&
                          validationDistrict.errors.first("districtId")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Address*/}
                  <Box my={3}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={`${t("parent.address")}:`}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        value={addressParentInputs.address}
                        onChange={(e) => handleAddressParent(e)}
                        error={
                          addressParentInputs.startValidate &&
                          (validationParentAddress.passes() === true
                            ? false
                            : true)
                        }
                      />
                      <FormHelperText>
                        {addressParentInputs.startValidate &&
                          validationParentAddress.errors.first("address")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/*Phone Number*/}
                  <Box my={3}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        variant="outlined"
                        className="outlined-input"
                        label={t("parent.phone")}
                        InputLabelProps={{
                          shrink: true,
                          pl: "2px",
                        }}
                        placeholder="998"
                        value={`${phoneParentInputs.phone}`}
                        onChange={(e) => handleParentPhone(e)}
                        error={
                          phoneParentInputs.startValidate &&
                          (validationParentPhone.passes() === true
                            ? false
                            : true)
                        }
                      />
                      <FormHelperText>
                        {phoneParentInputs.startValidate &&
                          validationParentPhone.errors.first("phone")}
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <Box my={3}>
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => setActiveStep(3)}
                    >
                      {t("parent.back")}
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      disabled={disableBtn}
                      onClick={(e) =>
                        changeBtn.change === "create"
                          ? createStudentParentBtn(e)
                          : updateStudentBtn(e)
                      }
                    >
                      {loader ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        `${t("parent.create")}`
                      )}
                    </Button>

                    {/* <Button
                      color="secondary"
                      variant="contained"
                      style={{ marginLeft: "10px" }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button> */}
                  </Box>
                </Box>
              )}
            </div>
          </Modal>
        </Box>

        <Box pt={3}>
          <Paper className={classes.Paper} square>
            <div className="social-widget-wrap">
              <Grid container spacing={3}>
                {allStudents.length !== 0
                  ? allStudents.map((student, i) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        key={i}
                        className="mb-3"
                      >
                        <CustomCard>
                          <Box display="flex" flexDirection="column" mb={2}>
                            <Box>
                              <Box>
                                <Avatar
                                  alt="user-thumb"
                                  className="avatar-wrap"
                                  src={
                                    require(`assets/Images/avatars/user.png`)
                                      .default
                                  }
                                />
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                              className="content-wrap"
                            >
                              <Box fontSize="h5.fontSize" color="#3c5a9a">
                                {student.first_name} {student.last_name}
                              </Box>

                              <Box lineHeight={1.2}>{student.email}</Box>
                            </Box>
                          </Box>
                          <Box mb={2}>
                            <Box color="text.secondary">
                              {t("parent.appliedCourses")}:
                            </Box>
                          </Box>
                          <Box
                            height={"100px"}
                            ml={2}
                            display="flex"
                            flexWrap="wrap"
                          >
                            {appliedCourses &&
                              appliedCourses.get(student.id) &&
                              (appliedCourses.get(student.id)?.length !== 0 ? (
                                appliedCourses.get(student.id)?.map((item) =>
                                  item.course == null ? (
                                    ""
                                  ) : (
                                    <Chip
                                      label={item?.course?.name_uz}
                                      style={{
                                        marginLeft: "5px",
                                      }}
                                    />
                                  )
                                )
                              ) : (
                                <Typography>
                                  {t("parent.dontAppliedCourses")}...<br></br>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/app/parent/courses`}
                                  >
                                    {t("parent.applyNow")}
                                  </Link>
                                </Typography>
                              ))}
                          </Box>
                          <Box>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={(e) => openModalForEdit(e, student)}
                            >
                              {t("parent.updateChild")}
                            </Button>
                            <Button
                              color="secondary"
                              variant="contained"
                              style={{ marginLeft: "10px" }}
                              onClick={(e) =>
                                props.history.push(
                                  `/app/parent/children/${student.id}`
                                )
                              }
                            >
                              {t("parent.seeResults")}
                            </Button>
                          </Box>
                        </CustomCard>
                      </Grid>
                    ))
                  : `${t("parent.noChild")}`}
              </Grid>
            </div>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}
export default ParentChildren;
