import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "./footer.module.scss";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <footer id="footer" style={{ position: "relative" }}>
        {/* <div className="footer-newsletter">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h4>{t("footer_header")}</h4>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>{t("footer1")}</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />
                    <a href="#headerTop">{t("home")}</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />
                    <a href="#ourCourse">{t("courses")}</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />
                    <a href="#teachers">{t("teachers")}</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />
                    <a href="#news">{t("news")}</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />
                    <a href="https://inha.uz/">inha.uz</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>{t("footer2")}</h4>
                <p>
                  {t("address.name1")} <br />
                  {t("address.name2")}
                  <br />
                  {t("address.name3")} <br />
                  <br />
                </p>
                {/* <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="#">Web Design</a>
                  </li>
                </ul> */}
              </div>

              <div className="col-lg-3 col-md-6 footer-contact">
                <h4>{t("footer3")}</h4>
                <p>
                  <strong style={{ marginRight: "5px" }}>Phone:</strong>
                  <a href="tel:+998712899995">+998 71 289 99 95</a> <br />
                  <strong
                    style={{
                      opacity: "0",
                      userSelect: "none",
                      marginRight: "5px",
                    }}
                  >
                    Phone:
                  </strong>
                  <a href="tel:+998712899999">+998 71 289 99 99</a>
                </p>
              </div>
              <div className="col-lg-3 col-md-6 footer-info">
                <h3>{t("footer4")}</h3>
                {/* <p>
                  Cras fermentum odio eu feugiat lide par naso tierra. Justo
                  eget nada terra videa magna derita valies darta donna mare
                  fermentum iaculis eu non diam phasellus.
                </p> */}
                <div className="social-links mt-3">
                  <a
                    href="https://t.me/cec_inha"
                    target="_blank"
                    style={{ marginRight: "12px" }}
                    className="twitter"
                  >
                    <img
                      style={{ width: "18px" }}
                      src="https://res.cloudinary.com/apple-angren/image/upload/v1658320937/my-icons/telegram_jgalmd.png"
                      alt="telegram-channel: cec_inha"
                    />
                  </a>
                  <a
                    href="https://t.me/cec_inha_bot"
                    target="_blank"
                    style={{ marginRight: "12px" }}
                    className="facebook"
                  >
                    <img
                      style={{ width: "18px" }}
                      src="https://res.cloudinary.com/apple-angren/image/upload/v1658320938/my-icons/bot_l3hehi.png"
                      alt="telegram-bot: cec_inha_bot"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/inha.cec/"
                    target="_blank"
                    className="instagram"
                  >
                    <img
                      style={{ width: "18px" }}
                      src="https://res.cloudinary.com/apple-angren/image/upload/v1658320939/my-icons/instagram_b6tlbj.png"
                      alt="instagram: inha.cec"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container ">
          <div className={`copyright `}>
            © Copyright
            <strong>
              <span> IT SOLUTIONS</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
        <a
          href="#headerTop"
          className={`back-to-top d-flex align-items-center justify-content-center ${styles.backToTop}`}
        >
          <img
            src="https://res.cloudinary.com/apple-angren/image/upload/v1658321603/my-icons/top_l5mbto.png"
            alt="toTop"
            style={{ width: "18px" }}
          />
        </a>
      </footer>
    </Fragment>
  );
};

export default Footer;
