import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import Validator from "validatorjs";
import GradeIcon from '@material-ui/icons/Grade';
import { NotificationManager } from "react-notifications";
import {
  Container,
  Box,
  Button,
  Modal,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import {
  getHomeworksByGroupId,
  getLessonsByGroupId,
  createHomework,
  updateHomework,
} from "fetch/teacher/groups/apies";

//modals
function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },
}));

function CreateHomework(props) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const groupId = props.groupId;
  const groupName = props.groupName;

  const navigateBtn = (e, rowData) => {
    e.preventDefault();
    console.log(rowData)
    props.history.push(
      `${process.env.PUBLIC_URL}/app/teacher/grade/${groupId}/${groupName}/${rowData.id}/${rowData.title}`
    );
  };

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  //modals
  const [open, setOpen] = useState(false);
  const [changeBtn, setChangeBtn] = useState({ change: "" });

  //Table
  const [allHomeworks, setAllHomeworks] = useState();
  useEffect(() => {
    if (groupId) {
      getHomeworksByGroupId(token, groupId).then((res) => {
        setAllHomeworks(res.data);
      });
    }
  }, [groupId]);
  const columns = [
    { title: "Homework Title", field: "title" },
    { title: "Lesson", field: "lesson.name" },
  ];
  const actions = [
    {
      icon: "edit",
      tooltip: "Edit homework",
      onClick: (e, rowData) => openModalForEdit(e, rowData),
    },
    {
      icon: () => <GradeIcon />,
      tooltip: "Homework grade",
      onClick: (e, rowData) => navigateBtn(e, rowData),
    },
  ];

  // Lesson options
  const [lessonOptions, setLessonOptions] = useState();
  useEffect(() => {
    getLessonsByGroupId(token, groupId).then((res) => {
      setLessonOptions(res.data);
    });
  }, []);

  // Form
  // Homework Title
  const [titleInputs, setTitleInputs] = useState({
    title: "",
    rule: "required",
    startValidate: false,
  });
  let validationTitle = new Validator(
    { title: titleInputs.title },
    { title: titleInputs.rule }
  );
  const handleTitle = (e) => {
    setTitleInputs((prevState) => ({
      ...prevState,
      title: e.target.value,
      startValidate: true,
    }));
  };

  // lessonId
  const [lessonIdInputs, setLessonIdInputs] = useState({
    lessonId: "",
    rule: "required",
    startValidate: false,
  });
  let validationLessonId = new Validator(
    { lessonId: lessonIdInputs.lessonId },
    { lessonId: lessonIdInputs.rule }
  );

  const handleLessonId = (e) => {
    setLessonIdInputs((prevState) => ({
      ...prevState,
      lessonId: e.target.value,
      startValidate: true,
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setChangeBtn({ change: "" });
    setTitleInputs((prevState) => ({
      ...prevState,
      title: "",
      startValidate: false,
    }));
    setLessonIdInputs((prevState) => ({
      ...prevState,
      lessonId: "",
      startValidate: false,
    }));
  };

  const openModalForCreate = () => {
    setChangeBtn({ change: "create" });
    setOpen(true);
  };

  const createHomeworkBtn = (e) => {
    e.preventDefault();
    if (validationTitle.passes() && validationLessonId.passes()) {
      const data = {
        title: titleInputs.title,
        lessonId: lessonIdInputs.lessonId,
        groupId: Number(groupId),
      };
      createHomework(token, data).then((res) => {
        handleClose();
        NotificationManager.success("Homework created successfully");
        setAllHomeworks((prevState) => [res.data, ...prevState]);
      });
    } else {
      setTitleInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setLessonIdInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  const [homeworkID, setHomeworkID] = useState();

  const openModalForEdit = (e, rowData) => {
    e.preventDefault();
    setOpen(true);
    setChangeBtn({ change: "edit" });
    setHomeworkID(rowData.id);
    setTitleInputs((prevState) => ({
      ...prevState,
      title: rowData.title,
    }));
    setLessonIdInputs((prevState) => ({
      ...prevState,
      lessonId: rowData.lesson.id,
    }));
  };

  const updateHomeworkBtn = (e) => {
    e.preventDefault();
    if (validationTitle.passes() && validationLessonId.passes()) {
      const data = {
        title: titleInputs.title,
        lessonId: lessonIdInputs.lessonId,
        groupId: Number(groupId),
      };
      if (homeworkID) {
        updateHomework(token, homeworkID, data).then((res) => {
          if (res.status === 200) {
            const lessonIndex = lessonOptions.find(
              (item) => item.id === data.lessonId
            );
            const newItem = {
              id: homeworkID,
              title: data.title,
              lesson: lessonIndex,
            };
            const newArr = allHomeworks.filter((el) => el.id !== homeworkID);
            const newSortedArr = [...newArr, newItem].sort(function (a, b) {
              return a.id - b.id;
            });
            handleClose();
            NotificationManager.success("Homework updated successfully");
            setAllHomeworks((prevState) => newSortedArr);
          }
        });
      }
    } else {
      setTitleInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setLessonIdInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  return (
    <div>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={openModalForCreate}
        >
          Add Homework
        </Button>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div style={modalStyle} className={classes.paper}>
            <Typography variant="h6">
              {changeBtn.change === "create"
                ? "Create Lesson"
                : "Update Lesson"}
            </Typography>
            <Box my={3}>
              <FormControl error fullWidth>
                <TextField
                  required
                  fullWidth
                  type="text"
                  variant="outlined"
                  className="outlined-input"
                  label="Homework title: "
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={titleInputs.title}
                  onChange={(e) => handleTitle(e)}
                  error={
                    titleInputs.startValidate &&
                    (validationTitle.passes() === true ? false : true)
                  }
                />
                <FormHelperText>
                  {titleInputs.startValidate &&
                    validationTitle.errors.first("title")}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box my={3}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={
                  lessonIdInputs.startValidate &&
                  (validationLessonId.passes() === true ? false : true)
                }
              >
                <InputLabel id="select-course-label" shrink={true}>
                  Select lesson:
                </InputLabel>
                <Select
                  fullWidth
                  labelId="select-course-label"
                  label="Select course:"
                  variant="outlined"
                  value={lessonIdInputs.lessonId}
                  onChange={(e) => handleLessonId(e)}
                >
                  {lessonOptions && lessonOptions.length !== 0 ? (
                    lessonOptions.map((option, index) => (
                      <MenuItem key={index} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No lessons found</MenuItem>
                  )}
                </Select>
                <FormHelperText style={{ marginLeft: 0 }}>
                  {lessonIdInputs.startValidate &&
                    validationLessonId.errors.first("lessonId")}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box my={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={(e) =>
                  changeBtn.change === "create"
                    ? createHomeworkBtn(e)
                    : updateHomeworkBtn(e)
                }
              >
                {changeBtn.change === "create" ? "Create" : "Update"}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                style={{ marginLeft: "10px" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </div>
        </Modal>
      </Box>
      <Box px={{ xs: "12px", lg: 0 }} pt={4} className="page-space">
        <MaterialTable
          title={`Homeworks`}
          columns={columns}
          actions={actions}
          data={allHomeworks}
          options={{
            actionsColumnIndex: -1,
          }}
        />
      </Box>
    </div>
  );
}

export default withRouter(CreateHomework);
