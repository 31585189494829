import { authHeader } from "../_helpers";

export const userService = {
  signup,
  login,
  getAll,
  refreshToken,
  register,
};

function signup(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`http://localhost:4000/users/signup`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user_id", JSON.stringify(user));

      return user;
    });
}

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${process.env.REACT_APP_PRE_UNI_API}/auth/login`, requestOptions)
    .then(handleLoginResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("preUni_token", JSON.stringify(user.access_token));
      localStorage.setItem("preUni_user_role", JSON.stringify(user.role));

      return user;
    });
}

function register(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_PRE_UNI_API}/users`, requestOptions)
    .then(handleRegisterResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem("preUni_token", JSON.stringify(user.access_token));
      // localStorage.setItem("preUni_user_role", JSON.stringify(user.role));
      console.log(user)
      return user;
    });
}


function refreshToken() {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify({ username, password })
  };
  return fetch(`http://localhost:4000/users/refreshToken`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if (!localStorage.getItem("user_id") === null) {
        localStorage.setItem("user_id", JSON.stringify(user));
      }
      return user;
    });
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`http://localhost:4000/users`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        localStorage.removeItem("user_id");
        // location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}



function handleLoginResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}


function handleRegisterResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
