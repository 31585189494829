const es_ES = {
   "sidebar.dashboard": "Tablero",
   "sidebar.dashboard1": "Tablero 1",
   "sidebar.dashboard2": "Tablero 2",
   "sidebar.dashboard3": "Tablero 3",
   "sidebar.videoPlayer" : "Reproductor de video",
   "sidebar.multilevelmenu":"Menú multinivel",
   "sidebar.level1": "Nivel 1",
   "sidebar.level2": "Nivel 2",
   "sidebar.webanalytics": "analista de la red",
   "sidebar.saas": "SAAS",
   "sidebar.ecommerce": "Comercio electrónico",
   "sidebar.shop": "tienda",
   "sidebar.cart": "Carro",
   "sidebar.checkout": "Revisa",
   "sidebar.productdetails": "Detalles de producto",
   "sidebar.invoice": "factura",
   "sidebar.chat": "Charla",
   "sidebar.email": "Email",
   "sidebar.calendar": "Calendario",
   "sidebar.userManagement": "Gestión de usuarios",
   "sidebar.contactGrid": "Cuadrícula de contacto",
   "sidebar.userProfile": "Perfil del usuario",
   "sidebar.payment": "Pago",
   "sidebar.signaturePad": "Pad de firma",
   "sidebar.socialFeeds": "Alimentaciones sociales",
   "sidebar.userSettings": "Ajustes de usuario",
   "sidebar.pages": "PaPáginases",
   "sidebar.faq": "Preguntas más frecuentes",
   "sidebar.pricing": "Precios",
   "sidebar.pricingv1": "Precios V1",
   "sidebar.pricingv2": "Precios V2",
   "sidebar.pricingUpgrade": "Actualización de precios",
   "sidebar.verticalTimeline": "Línea de tiempo vertical",
   "sidebar.horizontalTimeline": "Cronología horizontal",
   "sidebar.horizontalStepper": "Paso a paso horizontal",
   "sidebar.verticalStepper": "Paso a paso vertical",
   "sidebar.blog": "Blog",
   "sidebar.blogGrid": "Blog Grid",
   "sidebar.blogDetails": "Detalles del blog",
   "sidebar.uiComponents": "componentes ui",
   "sidebar.appBar": "Barra de aplicacione",
   "sidebar.avatars": "Avatares",
   "sidebar.buttons": "Botones",
   "sidebar.bottomNavigations": "Navegaciones inferiores",
   "sidebar.chip": "Chip",
   "sidebar.lists": "Lista",
   "sidebar.modals": "modales",
   "sidebar.tables": "Mesas",
   "sidebar.basicTable": "Mesa basica",
   "sidebar.agGrid": "AgGrid",
   "sidebar.searchTable": "mesa personalizada",
   "sidebar.customTable": "Mesa personalizada",
	"sidebar.sessions": "Sesiones",	
	"sidebar.layout1":"Diseño 1",
	"sidebar.layout2":"Diseño 2",
   "sidebar.signIn": "Registrarse",
   "sidebar.signUp": "Regístrate",
   "sidebar.forgotPassword": "Se te olvidó tu contraseña",
   "sidebar.errorPages": "Páginas de error",
   "sidebar.basic": "Básico",
   "sidebar.upgrade": "Potenciar",
   "sidebar.fourzerofour": "404",
   "sidebar.fivezerozero": "500",
   "sidebar.timeline": "Cronograma",
   "sidebar.stepper": "Paso a paso",
   "sidebar.profile": "Perfil",
   "sidebar.modern": "Moderno",
   "sidebar.standard": "Estándar",
   "sidebar.style1": "Estilo 1",
   "sidebar.style2": "Estilo 2",
   "sidebar.fullpagemenu" : "Menú de página completa",


   "widgets.newVisitors": "Visitantes nuevos",
   "widgets.newSales": "nuevas ventas",
   "widgets.bounceRate": "Porcentaje de rebote",
   "widgets.earning": "Ganadora",
   "widgets.QuickLinks": "enlaces rápidos",
   "widgets.frequentlyUsedSectiopns": "Secciones de uso frecuente",
   "widgets.newUser": "Nuevas usuarias",
   "widgets.welcome": "Hola john bienvenido de nuevo",
   "widgets.quickStats": "Estadísticas rápidas de hoy",
   "widgets.views": "Puntos de vista",
   "widgets.growth": "Crecimiento",
   "widgets.rating": "Calificaciones",
   "widgets.userGrowth": "Usuario% de crecimiento",
   "widgets.avgUsers": "Media Los usuarios",
   "widgets.avgTime": "Media Hora",
   "widgets.customTable": "Mesa personalizada",
   "widgets.users": "Los usuarios",
   "widgets.revenue": "Ingresos",
   "widgets.conversionRate": "Tasa de conversión",
   "widgets.sessions": "sesiones",

   "widgets.totalRevenue": "Los ingresos totales",
   "widgets.totalOrders": "Pedidos totales",
   "widgets.totalUsers": "Total de usuarias",
   "widgets.overallTrafficStatus": "Estado general del tráfico",
   "widgets.liveChat": "Gráfico en vivo",
   "widgets.tasksList": "Lista de tareas",
   "widgets.newUsers": "Nuevas usuarias",
   "widgets.dynamicBarChart": "Gráfico de barras dinámico",
   "widgets.visitorsStats": "Estadísticas de visitante",
   "widgets.recipients": "Destinatarios",
   "widgets.openRate": "Rango abierto",
   "widgets.marketing": "Márketing",
   "widgets.sales": "Ventas",
   "widgets.topProducts": "Productos principales",
   "widgets.trends": "Tendencias",
   "widgets.analyticsWebSessionsByRegion": "Sesiones web de análisis por región",
   "widgets.keywordSearch": "Búsqueda por palabra clave",
   "widgets.visitorsDevicesUsed": "Dispositivos utilizados por los visitantes",
   "widgets.howmanyuserscamefromABC?": "¿Cuántas usuarias vinieron de ABC?",
   "widgets.totalViews": "Vistas totales",
   "widgets.totalSales": "Ventas totales",
   "widgets.viewMore": "Ver más",
   "widgets.newAccounts": "Nuevas cuentas",

   "component.products": "Productos",
   "component.signup": "Regístrate",
   "component.loadmore": "carga más",
   "component.categories": "Categorias",
   "component.rating": "Clasificación",
   "component.priceFilter": "Filtro de precios",
   "component.productDetails": "Detalles de producto",
   "component.backToProducts": "Volver a productos",
   "component.addToCart": "Añadir al carrito",
   "component.viewCart": "Ver carrito",
   "component.addtowishlist": "Añadir a la lista de deseos",
   "component.yourProductCart": "Su carro de productos",
   "component.continueShopping": "Seguir comprando",
   "component.myShoppingBag": "Mi bolsa de compras",
   "component.yourBill": "Su cuenta",
   "component.saveShoppingBag": "Guardar bolsa de compras",
   "component.proceedToCheckout": "Pasar por la caja",   
   "component.subtotal": "Total parcial",
   "component.tax": "Impuesto",
   "component.total": "Total",
   "component.shippingAddress": "Dirección de Envío",
   "component.payment": "Pago",
   "component.continueToPayment": "Continuar al pago",
   "component.invoice": "Factura",
   "component.getMembership": "Consigue membresía",
   "component.alreadyHaveAccount?": "¿Ya tienes cuenta?",
   "component.continueAsGuest?": "¿Continuar como invitada?",
	"components.compose": "Componer",
	"component.firebase" : "base de fuego",
	"component.auth0" : "autenticación 0",
	"component.youtubevideoplayer":"Reproductor de video de Youtube",
	"component.vimeovideoplayer":"Vimeo Video Player",

   "module.inbox": "Bandeja de entrada",
   "module.drafts": "Borradores",
   "module.sent": "Expedida",
   "module.trash": "Basura",
   "module.spam": "Correo no deseado",
   "module.primary": "Primaria",
   "module.social": "Social",
   "module.marketing": "Márketing",

   "component.contactGrid": "Cuadrícula de contacto",
   "component.addContact": "Agregar contacto",
   "component.allContacts": "Todos los contactos",
   "component.favourite": "Favorita",
   "component.recentlyAdded": "Recientemente añadido",
   "component.todaysTasks": "Tareas de hoy",
   "component.recentFollowers": "Seguidores recientes",
   "component.settings": "Configuraciones",
   "component.home": "Hogar",
   "component.general": "General",
   "component.account": "Cuenta",
   "component.email": "correo electrónico",
   "component.faq": "Preguntas frecuentes",
   "component.generalEnquiries": "Consultas generales",
   "component.accounts": "Cuentas",
   "component.features": "Caracteristicas",
   "component.rulesAndPolicies": "Reglas y Políticas",
   "component.chooseYourPlan": "Elige tu plan",
   "component.starter": "INICIO",
   "component.professional": "PROFESIONAL",
   "component.team": "EQUIPO",
   "component.enterprise": "EMPRESA",
   "component.monthly": "MENSUAL",
   "component.yearly": "ANUAL",
   "component.stillHaveQueries": "¿Todavía tienes consultas?",
   "component.callUsAnyTime": "Llamanos",
   "component.dropUsALine": "Escríbanos",
   "component.backToPricingV1": "Volver a precios V1",
   "component.basic": "Básico",
   "component.standard": "Estándar",
   "component.premium": "Prima",
   "component.purchasePlan": "Plan de compra",
   "component.personal": "Personal",
   "component.finish": "Terminar",
   "component.accountInformation": "Información de la cuenta",
   "component.personalInformation": "Informacion personal",
   "component.editBlog": "Editar blog",
   "component.deleteBlog": "Eliminar blog",
   "component.recentBlog": "Blog reciente",
   "component.simpleAppBar": "Barra de aplicaciones simple",
   "component.appBarWithAPrimarySearchField": "Barra de aplicaciones con un campo de búsqueda principal",
   "component.appBarWithMenu": "Barra de aplicaciones con menú",
   "component.imagesAvatar": "Imágenes Avatar",
   "component.letterAvatar": "Carta Avatar",
   "component.sizes": "Tamaños",
   "component.iconAvatar": "Avatar de icono",
   "component.grouped": "Agrupadas",
   "component.withBadge": "Avartares Con Insignia",
   "component.containedButtons": "Contained Buttons",
   "component.textButtons": "Botones de texto",
   "component.outlinedButtons": "Botones contorneados",
   "component.uploadButton": "Botón de carga",
   "component.buttonsWithIconsAndLabel": "Botones con iconos y etiquetas",
   "component.iconButtons": "Botones de icono",
   "component.complexButtons": "Botones complejos",
   "component.bottomNavigation": "Navegación inferior",
   "component.bottomNavigationWithNoLabel": "Navegación inferior sin etiqueta",
   "component.outlinedChip": "Outlined Chip",
   "component.chipArray": "Chip Array",
   "component.simpleList": "Lista simple",
   "component.nesteList": "Lista anidada",
   "component.alignListItems": "Alinear elementos de la lista",
   "component.listWithCheckBox": "Lista con casilla de verificación",
   "component.listWithSwitch": "Lista con interruptor",
   "component.simpleModal": "Modal simple",
   "component.transition": "Transición",
   "component.aggridTable": "Mesa Ag-Grid",
   "component.megaMenu": "Mega menú",
   "horizontalMenu.general": "General",
   "horizontalMenu.modules": "Módulos",
   "horizontalMenu.components": "Componentes",
   "horizontalMenu.applications": "Aplicaciones",
   "component.upgradeYourPlanNow": "Actualice su plan ahora ",
   "component.addYourDebitCreditCard" : "Agregue su tarjeta de débito o crédito",
   "component.termsandconditions" : "Términos y Condiciones",
   "components.paymentGateways" : "Via de pago",
   "components.connectPaymentGateways" : "Conectar pasarela de pago",
   "components.tutorials": "Lista de Verificación",
	"components.checklist": "Tutorial de lista de verificación",
   "components.welcomeToHulk" : "Bienvenida a Hulk",
   "components.advancedTable" : "Mesa avanzada",
   "components.chatPanel" : "Panel de chat",
   "components.signaturePad": "Pad de firma",
   "components.help" : "Ayuda",
   "components.clicktoknowmore" : "Haga clic para saber más",
   "components.clickhereifyouneedanyhelp" : "Haga clic aquí si necesita ayuda"
}

export default es_ES;