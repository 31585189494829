import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./teacher.module.scss";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardContent,
  CardActions,
  Icon,
  Avatar,
  IconButton,
  Box,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  thumb: {
    width: 100,
    height: 100,
    marginBottom: 10,
  },
}));

const dataTeacher = [
  {
    avatar:
      "https://res.cloudinary.com/apple-angren/image/upload/v1658215389/INHA%20teachers/Rasul_cskmaq.jpg",
    name: "Ganikhodjaev Rasul Nabievich",
    description: "Doctor of Physics and Mathematics, Full professor",
  },
  {
    avatar:
      "https://res.cloudinary.com/apple-angren/image/upload/v1658215389/INHA%20teachers/Nilufar_fampbb.jpg",
    name: "Rakhmatullaeva Nilufar Alisherovna",
    description:
      "Candidate of Physico-Mathematical Sciences, Associate professor",
  },
  {
    avatar:
      "https://res.cloudinary.com/apple-angren/image/upload/v1658215390/INHA%20teachers/Temur_s0eqms.jpg",
    name: "Djalalov Temur Asfandiyarovich",
    description:
      "Candidate of physico-mathematical sciences, associate professor, winner of the Presidential Award “Shukhrat Medal” September 30, 2020",
  },
  {
    avatar:
      "https://res.cloudinary.com/apple-angren/image/upload/v1658215389/INHA%20teachers/Elena_ogktqp.jpg",
    name: "Skvortsova Elena Vladislavovna",
    description:
      "Physics teacher of the highest category, Master of Public Education of the Republic of Uzbekistan, leader of the national team in the International Tournament of Young Physicists",
  },
  {
    avatar:
      "https://res.cloudinary.com/apple-angren/image/upload/v1658215390/INHA%20teachers/Shokirjon_aaqsnl.jpg",
    name: "Sodikov Shokirjon Zokirovich",
    description: "Highly-qualiﬁedteacher (Mathteacherofthehighestcategory)",
  },
  {
    avatar:
      "https://res.cloudinary.com/apple-angren/image/upload/v1658215390/INHA%20teachers/Anastasia_ijddc3.jpg",
    name: "Rodkina Anastasia Andreevna",
    description: "Senior lecturer in Physics and Maths",
  },
  {
    avatar:
      "https://res.cloudinary.com/apple-angren/image/upload/v1658215389/INHA%20teachers/OzodaRakhim_cokhrr.jpg",
    name: "Isamukhamedova Ozoda Rakhimjon kizi",
    description:
      "Master of Artsin English Philology. TESOL Advanced certiﬁed (American TESOL Institute) British Council Future Learn Award in Teaching for Success",
  },
  {
    avatar:
      "https://res.cloudinary.com/apple-angren/image/upload/v1658215390/INHA%20teachers/Angelina_esmdqo.jpg",
    name: "Saydasheva Angelina Anvarovna",
    description:
      "Senior lecturer, Certiﬁed IELTS/ESL instructor (TOEFL Certiﬁcate) MasterofArtsinTeachingEnglishasaSecondlanguage (Webster University)",
  },
  {
    avatar:
      "https://res.cloudinary.com/apple-angren/image/upload/v1658215389/INHA%20teachers/Malika_itgnwu.jpg",
    name: "Rakhimova Malika Anvarovna",
    description:
      "Senior lecturer, Certiﬁed ESL instructor (TEFL Certiﬁcate) MasterofArtsinTeachingEnglishasaSecondlanguage (Webster University)",
  },
  {
    avatar:
      "https://res.cloudinary.com/apple-angren/image/upload/v1658215389/INHA%20teachers/Durdona_hziylu.jpg",
    name: "Durdona Nuritdinovna Umarova",
    description: "Senior lecturer in English",
  },
  {
    avatar:
      "https://res.cloudinary.com/apple-angren/image/upload/v1658918729/INHA%20teachers/Teacher_INHA_copy_h4mahx.jpg",
    name: "Karimova Ziyoda",
    description: "Scratch and Python programming teacher",
  },
];

export default function Teachers() {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();

  const loginBtn = () => {
    history.push("/auth");
  };
  return (
    <div id="teachers">
      <Box style={{ marginTop: "30px" }}>
        <div className="section-title mt-3">
          <h2>{t("ourTeachers")}</h2>
        </div>
      </Box>
      <div className={`contact-grid-item ${styles.teachers}`}>
        {dataTeacher?.map((teacher, index) => (
          <Card key={index} className={styles.card}>
            <CardContent>
              <Avatar
                alt="Remy Sharp"
                className={classes.thumb}
                src={teacher?.avatar}
              />
              <div className="contact-grid-content">
                <Box
                  fontSize="subtitle1.fontSize"
                  fontWeight="h6.fontWeight"
                  mb="5px"
                  className={styles.name}
                >
                  {t(`teacherInfo.teacher${index}.name`)}
                </Box>
                <Typography variant="subtitle2" className={styles.desc}>
                  {t(`teacherInfo.teacher${index}.description`)}
                </Typography>
                {/* <Box display="flex" alignItems="center" justifyContent="center">
                <Box
                  component="span"
                  pr="5px"
                  color="text.secondary"
                  fontSize="body1.fontSize"
                  className="icon fas fa-street-view"
                ></Box>
                <Typography variant="subtitle2">Teacher</Typography>
              </Box> */}
              </div>
            </CardContent>
            {/* <CardActions
              disableSpacing
              className={`footer-icon ${styles.cardAction}`}
            >
              <IconButton onClick={() => loginBtn()} size="small">
                <Icon style={{ fontSize: 20 }}>phone_iphone</Icon>
              </IconButton>
              <IconButton onClick={() => loginBtn()} size="small">
                <Icon style={{ fontSize: 20 }}>chat_bubble</Icon>
              </IconButton>
              <IconButton onClick={() => loginBtn()} size="small">
                <Icon style={{ fontSize: 20 }}>forum</Icon>
              </IconButton>
            </CardActions> */}
          </Card>
        ))}
      </div>
    </div>
  );
}
