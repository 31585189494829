import React from "react";
import { Route, Switch } from "react-router-dom";

import StudentDashboard from "./dashboard";
import StudentTimetable from "./timetable";
import StudentGroups from "./groups";
import StudentResults from "./groups/components/index"
const Student = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/dashboard`}
        component={StudentDashboard}
      ></Route>
      <Route
        exact
        path={`${match.url}/timetable`}
        component={StudentTimetable}
      ></Route>
      <Route
        exact
        path={`${match.url}/groups`}
        component={StudentGroups}
      ></Route>
      <Route
        exact
        path={`${match.url}/groups/:groupId/:groupName`}
        component={StudentResults}
      ></Route>
    </Switch>
  );
};
export default Student;
