/**
 * Code splitting Components
 * AsyncComponents
 */
import React from "react";
import Loadable from "react-loadable";
import { HulkPageLoader } from "components/GlobalComponents";

// Dasboard Urls

// start admin routes

const AsyncAdminDashboardComponent = Loadable({
  loader: () => import("Users/admin/dashboard"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncAdminTimetableComponent = Loadable({
  loader: () => import("Users/admin/timetable"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncAdminCoursesComponent = Loadable({
  loader: () => import("Users/admin/courses"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncAdminGroupsComponent = Loadable({
  loader: () => import("Users/admin/groups"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncAdminTeachersComponent = Loadable({
  loader: () => import("Users/admin/teachers"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncAdminRoomsComponent = Loadable({
  loader: () => import("Users/admin/rooms"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncAdminRolePermissionsComponent = Loadable({
  loader: () => import("Users/admin/permissions"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// end admin routes

// parent routes start
const AsyncParentDashboardComponent = Loadable({
  loader: () => import("Users/parent/dashboard"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncParentTimetableComponent = Loadable({
  loader: () => import("Users/parent/timetable"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// parent routes end

const AsyncFullPageUrlsComponent = Loadable({
  loader: () => import("routes/FullPageUrls"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncCoursesComponent = Loadable({
  loader: () => import("Users/admin/courses"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncGroupsComponent = Loadable({
  loader: () => import("Users/admin/groups"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncRoomsComponent = Loadable({
  loader: () => import("Users/admin/rooms"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncAdminLogsComponent = Loadable({
  loader: () => import("Users/admin/logs"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncChatComponent = Loadable({
  loader: () => import("Users/chat"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncSetRoleComponent = Loadable({
  loader: () => import("Users/role"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncUserSettingsComponent = Loadable({
  loader: () => import("Users/role/UserSettings/index"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

export {
  AsyncFullPageUrlsComponent,
  AsyncCoursesComponent,
  AsyncGroupsComponent,
  AsyncRoomsComponent,
  AsyncChatComponent,
  AsyncSetRoleComponent,
  AsyncUserSettingsComponent,
  AsyncAdminDashboardComponent,
  AsyncAdminTimetableComponent,
  AsyncAdminCoursesComponent,
  AsyncAdminRoomsComponent,
  AsyncAdminGroupsComponent,
  AsyncAdminRolePermissionsComponent,
  AsyncAdminTeachersComponent,
  AsyncParentDashboardComponent,
  AsyncParentTimetableComponent,
  AsyncAdminLogsComponent,
};
