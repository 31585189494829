const axios = require("axios").default;

const getStudents = async (token, take, page) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/students?take=${take}&skip=${page}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
const getStudentsLength = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/students`, {
    headers: {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
const getStudentPassport = async (studentId) => {
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/users/${studentId}/passport`
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getStudentsAttend = async (groupId, from, to, token) => {
  // console.log(groupId);
  // console.log(token);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/attendances/excel?groupId=${groupId}&from=${from}&to=${to}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getGroupAttend = async (token, groupId, from, to) => {
  console.log(`From: `, Boolean(from));
  console.log(`To: `, to);
  // console.log(groupId);
  // console.log(token);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/attendances/excel?groupId=${groupId}${
      from && to ? `&from=${from}&to=${to}` : ""
    }`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getStudentsExams = async (groupId, from, to, token) => {
  console.log(groupId);
  console.log(token);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/lessons/excel?groupId=${groupId}&from=${from}&to=${to}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const createStudent = async (token, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/students`,
    data,
    { headers: { Authorization: AuthStr } }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const updateStudent = async (token, studentId, data) => {
  console.log(data);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.put(
    `${process.env.REACT_APP_PRE_UNI_API}/students/${studentId}`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const deleteStudent = async (token, studentId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.delete(
    `${process.env.REACT_APP_PRE_UNI_API}/students/${studentId}`,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const deleteLesson = async (token, studentId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.delete(
    `${process.env.REACT_APP_PRE_UNI_API}/lessons/${studentId}`,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getStudent = async (token, studentId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/students/${studentId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const addStudentToGroup = async (token, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/students/group`,
    data,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );

  try {
    const result = await req;
    return result;
  } catch (err) {
    return err.json();
  }
};

const removeStudentFromGroup = async (token, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.delete(
    `${process.env.REACT_APP_PRE_UNI_API}/students/group`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
      data,
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const removeStudentCourse = async (token, data) => {
  console.log(data);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.delete(
    `${process.env.REACT_APP_PRE_UNI_API}/students/course`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
      data,
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getAttendance = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/attendances?groupId=${groupId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

// const getAttendanceChild = async (token, groupId, parentId) => {
//   const AuthStr = "Bearer ".concat(token);
//   const req = await axios.get(
//     `${process.env.REACT_APP_PRE_UNI_API}/attendances?groupId=${groupId}&parentId=${parentId}`,
//     {
//       headers: {
//         Authorization: AuthStr,
//         "Content-Type": "application/json",
//       },
//     }
//   );
//   try {
//     const result = await req;
//     return result;
//   } catch (err) {
//     console.log(err);
//   }
// };

const getHomeworkGrades = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/homework-grades?groupId=${groupId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getExamGrades = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/exam-grades?groupId=${groupId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getStudentCourses = async (token, studentId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/students/courses?studentId=${studentId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getGroupsByCourseId = async (token, courseId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/courses/${courseId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const findUserByFirstname = async (fullName, token) => {
  const AuthStr = "Bearer ".concat(token);
  console.log(fullName);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/students/findstudent?userType=student&firstName=${fullName}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "aplication/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

export {
  getGroupAttend,
  findUserByFirstname,
  getStudentsLength,
  getStudentsAttend,
  getStudentsExams,
  getStudents,
  createStudent,
  updateStudent,
  deleteStudent,
  getStudent,
  addStudentToGroup,
  removeStudentFromGroup,
  getAttendance,
  getHomeworkGrades,
  getExamGrades,
  getStudentCourses,
  getGroupsByCourseId,
  removeStudentCourse,
  getStudentPassport,
  deleteLesson,
};
