/**
 * Forget Password
*/
import React, { useState } from 'react';



function Failure(props) {
    return (
        <div>
            404
            Gmail Failure
        </div>
    );
}



export default Failure;