const axios = require("axios").default;

const getCourses = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/courses`, {
    headers: {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const createGroup = async (data, token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/groups`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    return err.json();
  }
};

const getTeachers = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/teachers`, {
    headers: {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getGroups = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/groups`, {
    headers: {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getGroupsById = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/groups/${groupId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const updateGroup = async (groupId, data, token) => {
  console.log(data);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.put(
    `${process.env.REACT_APP_PRE_UNI_API}/groups/${groupId}`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const deleteGroup = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.delete(
    `${process.env.REACT_APP_PRE_UNI_API}/groups/${groupId}`,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const unarchiveGroup = async (data, token, id) => {
  console.log(data);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.put(
    `${process.env.REACT_APP_PRE_UNI_API}/groups/${id}`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    return err.json();
  }
};
const getFromToAtten = async (from, to, groupId, token) => {
  console.log(from, to, groupId);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/attendances?groupId=${groupId}&from=${from}&to=${to}`,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    return err.json();
  }
};
const getUserByFirstname = async (firstname, groupId, token) => {
  console.log(firstname);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/attendances?groupId=${groupId}&firstName=${firstname}`,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    return err.json();
  }
};

const getFromToExam = async (from, to, groupId, token) => {
  console.log(from);
  console.log(to);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/exam-grades?groupId=${groupId}&from=${from}&to=${to}`,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    return err.json();
  }
};
const getFromToExamByFirstname = async (
  from,
  to,
  groupId,
  firstname,
  token,
  lessonId
) => {
  console.log("from: ", from);
  console.log("to: ", to);
  console.log("groupId: ", groupId);
  console.log("firstname: ", firstname);
  console.log("token: ", token);
  console.log("lessonId: ", lessonId);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/lesson-grades?groupId=${groupId}&from=${from}&to=${to}&firstName=${firstname}&lessonId=${lessonId}`,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    return err.json();
  }
};

export {
  getUserByFirstname,
  getFromToExamByFirstname,
  getGroupsById,
  getCourses,
  getGroups,
  getTeachers,
  createGroup,
  updateGroup,
  deleteGroup,
  unarchiveGroup,
  getFromToAtten,
  getFromToExam,
};
