import Teachers from "../../../components/Teachers/Teacher";

const TeachersLayout = () => {
  return (
    <div style={{ padding: "0 20px", boxSizing: "border-box" }}>
      <Teachers />
    </div>
  );
};

export default TeachersLayout;
