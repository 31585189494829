import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import { Grid, Fab, Icon, Avatar } from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { t } from "i18next";
import {
  getCourseById,
  getCoursePagination,
  getCourses,
} from "fetch/AdminApi/course";
import { LocalDining } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: "1.125rem",
  },
  thumbWrap: {
    lineHeight: 1,
    position: "relative",
    "& img": {
      height: 250,
      objectFit: "cover",
      width: "100%",
    },
  },
  overlayContent: {
    position: "absolute",
    top: 19,
    right: 16,
  },
  avatar: {
    width: 45,
    height: 45,
  },
  icon: {
    color: theme.palette.common.white,
    transform: "rotateY(180deg)",
  },
}));
function ParentAllCourses(props) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));

  const history = useHistory();

  const classes = useStyles();
  const [allBlogs] = useState();
  const [initialNo, setinitialNo] = useState(9);
  const loadMore = (event) => {
    setinitialNo(initialNo + 3);
  };

  const [allCourses, setAllCourses] = useState();
  const [coursePagination, setCoursePagination] = useState(4);
  const [isMore, setIsMore] = useState(false);
  useEffect(() => {
    getCoursePagination(token, coursePagination).then((res) => {
      setAllCourses(res.data);
    });
  }, [coursePagination]);
  // useEffect(() => {
  //   getCourses(token)
  //     .then((res) => {
  //       setAllCourses(res.data);
  //     })
  //     .catch((error) => {});
  // }, []);

  const showMoreBtn = () => {
    setCoursePagination((prevState) => prevState + 4);
  };
  console.log(allCourses, coursePagination);

  const [courseId, setCourseId] = useState();
  const ToCourseBtn = (e, courseId) => {
    props.history.push(`courses/${courseId}`);
  };
  return (
    <Box px={{ xs: "12px", lg: 0 }} className="page-space">
      <Box>
        <Typography>
          <h1
            style={{ marginLeft: "2%", fontSize: "25px", marginTop: "-2.8%" }}
          >
            {t("teacher.kidsCourses")}
          </h1>
        </Typography>
        <Box
          color="primary.main"
          style={{ display: "flex", flexWrap: "wrap", marginLeft: "10px" }}
        >
          {allCourses && allCourses.length > 0
            ? allCourses.map((item, index) => (
                <Card
                  key={index}
                  className="blog-grid-item"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Box className={classes.thumbWrap}>
                    <img
                      className="img-fluid"
                      alt="img"
                      src={`${process.env.REACT_APP_PRE_UNI_API}/courses/${item.id}/image`}
                    />
                  </Box>
                  <Box>
                    <CardContent>
                      <Box>
                        <Box mb={1} fontSize="h6.fontSize" fontWeight="500">
                          {localStorage.getItem("lng") == "en"
                            ? item?.name_en
                            : localStorage.getItem("lng") == "ru"
                            ? item?.name_ru
                            : item?.name_uz}
                        </Box>
                        <Typography style={{ height: "10vh" }}>
                          <p>{item.description}</p>
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography>
                          <h6
                            style={{
                              margin: 0,
                              padding: 0,
                              marginTop: "10px",
                            }}
                          >
                            {t("teacher.durationOfCourse")}:{" "}
                            <b>
                              {" "}
                              {item.duration_in_month} {t("parent.month")}{" "}
                            </b>
                          </h6>
                          <h6 style={{ margin: 0, padding: 0 }}>
                            {t("teacher.courseType")}:{" "}
                            {item.course_type == "socie" ? "PRE-1" : "PRE-2"}
                          </h6>
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginLeft: "10px", fontSize: "10px" }}
                          onClick={(e) => ToCourseBtn(e, item.id)}
                        >
                          {t("parent.details")}
                        </Button>
                      </Box>
                    </CardContent>
                  </Box>
                </Card>
              ))
            : `${t("teacher.noCourses")}`}
        </Box>
        <Box
          style={{
            textAlign: "center",
            fontSize: "20px",
            marginTop: "1%",
          }}
        >
          {allCourses && allCourses.length < coursePagination ? (
            <Typography></Typography>
          ) : (
            <Button onClick={showMoreBtn} style={{ color: "#4d7df2" }}>
              {t("teacher.showMore")}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
export default ParentAllCourses;
