/**
 * Router service file
 */
import Admin from "Users/admin";
import Parent from "Users/parent";
import Student from "Users/student";
import Teacher from "Users/teacher";
import {
  AsyncUserSettingsComponent,
} from "Users";
const routes = [
  {
    path: "user-settings",
    component: AsyncUserSettingsComponent,
  },
  {
    path: "admin",
    component: Admin,
  },
  {
    path: "parent",
    component: Parent,
  },
  {
    path: "teacher",
    component: Teacher
  },
  {
    path: "student",
    component: Student
  }
];


export default routes;
