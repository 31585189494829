const axios = require("axios").default;

const getTeacher = async (token, studentId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/teachers/${studentId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
const getTeacherInfo = async (token, teacherId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/teachers/${teacherId}/info`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getLessonsByGroupId = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const res = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/lessons?groupId=${groupId}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: AuthStr,
      },
    }
  );
  try {
    const result = await res;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const createLesson = async (token, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/lessons`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const updateLesson = async (token, lessonId, data) => {
  console.log(data);
  const AuthStr = "Bearer ".concat(token);
  const edit = await axios.put(
    `${process.env.REACT_APP_PRE_UNI_API}/lessons/${lessonId}`,
    data,
    {
      headers: {
        Authorization: AuthStr,
      },
    }
  );
  try {
    const result = await edit;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getHomeworksByGroupId = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const res = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/homeworks?groupId=${groupId}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: AuthStr,
      },
    }
  );
  try {
    const result = await res;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const createHomework = async (token, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/homeworks`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const updateHomework = async (token, homeworkId, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.put(
    `${process.env.REACT_APP_PRE_UNI_API}/homeworks/${homeworkId}`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const createExam = async (token, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/exams`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

//get Exams
const getExams = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const res = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/lessons?groupId=${groupId}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: AuthStr,
      },
    }
  );
  try {
    const result = await res;
    return result;
  } catch (err) {
    console.log(err);
  }
};

//get Lesson grade
const getLessonsGrade = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const res = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/lesson-grades?groupId=${groupId}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: AuthStr,
      },
    }
  );
  try {
    const result = await res;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const updateExam = async (token, examId, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.put(
    `${process.env.REACT_APP_PRE_UNI_API}/exams/${examId}`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getGroupStudents = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/groups/${groupId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const postAttendance = async (token, data) => {
  console.log("DATAA: ", data);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/attendances`,
    data,
    { headers: { Authorization: AuthStr } }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getAttendance = async (token, groupId, lessonId) => {
  // console.log(groupId);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/attendances?groupId=${groupId}&lessonId=${lessonId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
const getAttendanceByGroupId = async (token, groupId) => {
  // console.log(groupId);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/attendances?groupId=${groupId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const postHomeworkGrade = async (token, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/homework-grades`,
    data,
    { headers: { Authorization: AuthStr } }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getHomeworkGrades = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/homework-grades?groupId=${groupId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const postExamGrade = async (token, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/exam-grades`,
    data,
    { headers: { Authorization: AuthStr } }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getExamGrades = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/exam-grades?groupId=${groupId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
const getLessonGrades = async (token, groupId, lessonId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/lesson-grades?groupId=${groupId}&lessonId=${lessonId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
const getLessonGradesByStudentId = async (
  token,
  groupId,
  parentId,
  lessonId
) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/lesson-grades?groupId=${groupId}&parentId=${parentId}&lessonId=${lessonId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
const postLessonGrades = async (token, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/lesson-grades`,
    data,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
const finishedLesson = async (token, lessonId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/lessons/${lessonId}/finished`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

export {
  getLessonGradesByStudentId,
  getAttendanceByGroupId,
  getLessonsGrade,
  getTeacherInfo,
  finishedLesson,
  postLessonGrades,
  getLessonGrades,
  getTeacher,
  createLesson,
  getLessonsByGroupId,
  updateLesson,
  getHomeworksByGroupId,
  createHomework,
  updateHomework,
  createExam,
  getExams,
  updateExam,
  getGroupStudents,
  postAttendance,
  getAttendance,
  postHomeworkGrade,
  getHomeworkGrades,
  postExamGrade,
  getExamGrades,
};
