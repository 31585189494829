const axios = require("axios").default;
//get Region API
const getRegions = async () => {
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/regions`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
//Get Student APi
const getStudents = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/students`, {
    headers: {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

//Get Student APi for dashboard
const getStudentsDash = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/students?take=10&&skip=0`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
//Get Teacher API
const getTeachers = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/teachers`, {
    headers: {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

/// Get Teacher By ID

const getTeacherById = async (token, teacherId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/teachers/${teacherId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
//Get Courses API
const getCourses = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/courses`, {
    headers: {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

// Get Courses_Type
const getCoursesType = async (token, courseType) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/courses?courseType=${courseType}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
//Get Group API
const getGroups = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/groups`, {
    headers: {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getStudentGroups = async (token, student_Id) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/groups?studentId=${student_Id}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

//Get Group API
const getGroupStudents = async (token, groupId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/groups?studentId=${groupId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

//Post Student to course
const createAddStudentToCourse = async (data, token) => {
  console.log(data);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/students/course`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
//get added students to course
const getAddStudentToCourse = async (token, courseId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/courses/students?courseId=${courseId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getStudentToCourse = async (token, studentId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/students/courses?studentId=${studentId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

export {
  getRegions,
  getStudents,
  getCourses,
  getCoursesType,
  getStudentToCourse,
  getTeachers,
  getTeacherById,
  getGroups,
  getGroupStudents,
  getAddStudentToCourse,
  createAddStudentToCourse,
  getStudentGroups,
  getStudentsDash,
};
