/**
 * Simple App Bar
 */
import React, { useEffect } from "react";
import "./index.css";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
} from "@material-ui/core";
import image from "./image.jpg";
import inhaImg from "./inha.png";
import logo from "./logo.png";
import Courses from "components/Courses/Courses";
import Navbar from "components/Navbar/Navbar";
import Teachers from "components/Teachers/Teacher";
import Header from "components/Header/HeaderNew";
import News from "components/News/News";
import Footer from "components/Footer/Footer";

export default function SimpleAppBar() {
  const history = useHistory();

  const loginBtn = () => {
    history.push("/auth");
  };

  return (
    <div>
      {/* <Navbar /> */}
      <Header />
      <Container>
        <Courses />
        <Teachers />
        <News />
      </Container>
      <Footer />
    </div>
  );
}
