/**
 * User Activities
 */
import React, { Fragment } from "react";
import { t } from "i18next";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider, Avatar, Box, Button } from "@material-ui/core";

//Component
import { TeacherGroupCard } from "./TeacherGroupCard";

const useractivities = [
  {
    id: 1,
    userimage: "user-4.jpg",
    name: "Louise Kate",
    username: "@louisekate",
    postimage: "",
    desc: "The new common language will be more simple and regular than the existing European languages. It will be as simple as Occiental; in fact, it will be Occidental.",
  },
  {
    id: 2,
    userimage: "user-4.jpg",
    name: "Louise Kate",
    username: "@louisekate",
    postimage: "balls.jpg",
    desc: "Posted New Photo",
  },
  {
    id: 3,
    userimage: "user-4.jpg",
    name: "Louise Kate",
    username: "@louisekate",
    postimage: "",
    desc: "The new common language will be more simple and regular than the existing European languages. It will be as simple as Occiental; in fact, it will be Occidental.",
  },
  {
    id: 4,
    userimage: "user-4.jpg",
    name: "Louise Kate",
    username: "@louisekate",
    postimage: "balls.jpg",
    desc: "Posted 2 New Photos",
  },
];

const useStyles = makeStyles((theme) => ({
  pad0: {
    padding: 0,
  },
  thumbWrap: {
    "& img": {
      borderRadius: 5,
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function UserActivities({ teacherGroups, history }) {
  const classes = useStyles();

  const navigateBtn = (e, link) => {
    console.log("🚀 ~ file: UserActivities.js:67 ~ navigateBtn ~ link:", link);
    e.preventDefault();
    history.push(`${process.env.PUBLIC_URL}/app/teacher/groups/${link}`);
  };
  const formatToEnter = (text) => {
    const resultDesc = text.split("\n");

    return (
      <div>
        {resultDesc?.map((item, index) => (
          <p>{item}</p>
        ))}
      </div>
    );
  };
  return (
    <div>
      <TeacherGroupCard
        title={`${t("sidebarItemTeacher.bar2")}`}
        showDivider={true}
      >
        {teacherGroups && teacherGroups.length !== 0
          ? teacherGroups.map((item, index) => (
              <Box key={index} className="user-activity">
                <Box display="flex" p={{ xs: 2, md: 3 }}>
                  <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">
                    <Box>
                      <Box
                        display={{ xs: "block", sm: "flex" }}
                        alignItems="center"
                        mb={1}
                      >
                        <Box
                          pr="5px"
                          className="user-name"
                          component="span"
                          fontWeight={500}
                        >
                          {item.name}
                        </Box>
                        {/* <Typography variant="subtitle2">{item.username}</Typography> */}
                      </Box>
                      <Typography variant="body2" color="textPrimary">
                        {formatToEnter(item.description)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="end" mb="10px">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) =>
                      navigateBtn(
                        e,
                        // `${item.id}/${item.name.replace("/", "+")}`
                        `${`${item.id}/${encodeURIComponent(item.name)}`}`
                      )
                    }
                  >
                    {t("teacher.manageGroup")}
                  </Button>
                </Box>
                <Divider />
              </Box>
            ))
          : `${t("teacher.noGorups")}`}
      </TeacherGroupCard>
    </div>
  );
}

export default withRouter(UserActivities);
