/**
 * Extended profile
 */
import React, { useState, useEffect } from "react";
import { Grid, Box, Container } from "@material-ui/core";

//Components
import UserDetail from "./Components/UserDetail";
import TodaysTasks from "./Components/TodaysTasks";
import TodaysThought from "./Components/TodaysThought";
import RecentFollowers from "./Components/RecentFollowes";
import UserActivities from "./Components/UserActivities";

import { getTeacher } from "fetch/teacher/groups/apies";
export default function TeacherGroups() {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const userId = JSON.parse(localStorage.getItem("preUni_user_id"));
  const [teacherGroups, setTeacherGroups] = useState();

  useEffect(() => {
    getTeacher(token, userId).then((res) => {
      setTeacherGroups(res.data.groups);
    });
  }, []);

  return (
    <div className="hk-user-profile">
      <Container maxWidth="lg">
        <Box px={{ xs: "12px", lg: 0 }} className="page-space">
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={4} md={3}>
                            <UserDetail />
                        </Grid> */}
            <Grid item xs={12} sm={8} md={6}>
              <UserActivities teacherGroups={teacherGroups} />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={3}>
                            <Box mb={3}>
                                <TodaysTasks />
                            </Box>
                            <Box mb={3}>
                                <TodaysThought />
                            </Box>
                            <Box mb={3}>
                                <RecentFollowers />
                            </Box>
                        </Grid> */}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
