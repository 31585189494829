import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { SmallTitleBar } from "components/GlobalComponents";
import Icon from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "../../../../assets/Images/icons/down-chevron.png";
import KeyboardArrowUpIcon from "../../../../assets/Images/icons/up-chevron.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  Input,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@material-ui/core";
import sol from "../Sol.jpg";
import {
  createAddStudentToCourse,
  getCoursesType,
  getStudents,
} from "fetch/student";
import { NotificationManager } from "react-notifications";
import Validator from "validatorjs";
import { t } from "i18next";

function rand() {
  return Math.round(Math.random() * 10) - 10;
}

function getModalStyle() {
  const top = 40 + rand();
  const left = 45 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-paddingNone": {
      padding: "0 16px",
    },
    "& .MuiTableCell-body": {
      lineHeight: 1,
    },
    "& .MuiToolbar-root": {
      paddingRight: 20,
      "& >div:first-child": {
        fontSize: "1.25rem",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 500,
      },
    },
  },
  paper: {
    position: "absolute",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  content: {},
  menuButton: {},
}));

function Sol(props) {
  let history = useHistory();

  const token = JSON.parse(localStorage.getItem("preUni_token"));

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [disable, setDisable] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [allStudents, setAllStudents] = useState();
  useEffect(() => {
    getStudents(token)
      .then((res) => {
        setAllStudents(res.data);
      })
      .catch((error) => {});
  }, []);

  const [addToCourseStudent, setAddToCourseStudents] = useState({
    add_student: "",
    student_name: "",
    rule: "required",
    startValidate: false,
  });

  const validationAddCourse = new Validator(
    { add_student: addToCourseStudent.add_student },
    { add_student: addToCourseStudent.rule }
  );

  // console.log("addToCourseStudent: ", addToCourseStudent.add_student);

  const handleChange = (e, item) => {
    // const {
    //   target: { value },
    // } = e;
    // setAddToCourse(typeof value === "string" ? value.split(",") : value);
    setAddToCourseStudents((prevState) => ({
      ...prevState,
      add_student: e.target.value,
      student_name: item?.props?.children,
      startValidate: true,
    }));
  };
  const [solCourse, setSolCourse] = useState();
  // console.log(solCourse);

  useEffect(() => {
    getCoursesType(token, "sol").then((res) => {
      setSolCourse(res.data);
    });
  }, []);
  // console.log(addToCourseStudent.student_name);

  const [selectedCourses, setSelectedCourses] = useState([]);

  const [selectedCoursesPrice, setSelectedCoursesPrice] = useState(0);

  const resultPrice = [];

  const formatToCurrency = (amount) => {
    return resultPrice.push(
      "" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&.")
    );
  };
  console.log(formatToCurrency(selectedCoursesPrice).result);
  // inc Price
  const incrementCounter = (clickedPrice) =>
    setSelectedCoursesPrice(selectedCoursesPrice + clickedPrice);
  // dex Price
  const decrementCounter = (clickedPrice) =>
    setSelectedCoursesPrice(selectedCoursesPrice - clickedPrice);

  const getPrice = (e) => {
    console.log(resultPrice);
    for (let i = 0; i < solCourse.length; i++) {
      if (solCourse[i].id == e.target.value) {
        let clickedPrice = solCourse[i].prices[0].cost;
        if (e.target.checked) {
          console.log("+ courser price", clickedPrice);
          incrementCounter(clickedPrice);
        } else {
          decrementCounter(clickedPrice);
          console.log("- courser price", clickedPrice);
        }
      }
    }
  };

  const handleCheckbox = (e) => {
    getPrice(e);
    if (e.target.checked) {
      if (!selectedCourses.includes(Number(e.target.value))) {
        selectedCourses.push(Number(e.target.value));
      }
    } else {
      if (selectedCourses.includes(Number(e.target.value))) {
        const elPosition = selectedCourses.indexOf(Number(e.target.value));
        selectedCourses.splice(elPosition, 1);
      }
    }
    setSelectedCourses(selectedCourses);
    if (selectedCourses.length >= 1) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  const applyBtn = () => {
    if (validationAddCourse.passes()) {
      selectedCourses.forEach((courseId) => {
        // addToCourse.forEach((userId) => {
        const data = {
          student_id: addToCourseStudent.add_student,
          course_id: courseId,
        };
        createAddStudentToCourse(data, token)
          .then((res) => {
            if (res.status === 201) {
              NotificationManager.success(
                "The student has joined successfully the course"
              );
              // history.push("/app/parent/dashboard");
            }
          })
          .catch((err) => {
            NotificationManager.warning(
              `The ${addToCourseStudent.student_name} has already joined to this course`
            );
          });
        setTimeout(() => {
          handleClose();
        }, 1000);
        // });
      });
    } else {
      validationAddCourse.errors.first("add_student");

      setAddToCourseStudents((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };
  const goBack = (e) => {
    e.preventDefault();
    props.history.goBack();
  };

  const [openOverview, setOpenOverview] = useState(false);

  return (
    <div className={classes.root}>
      <SmallTitleBar title={t("parent.sol")} center={true} />
      <Box
        className={`title-contact-block ${classes.searchBarWrap}`}
        pt={0}
        bgcolor="background.paper"
        px={{ xs: "12px", md: 0 }}
        pb={3}
      >
        <Container>
          <Box
            textAlign={{ xs: "center", sm: "right" }}
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Button variant="outlined" color="default" onClick={goBack}>
              <Icon style={{ fontSize: 18, marginRight: "2px" }}>
                arrow_back{" "}
              </Icon>
              Back
            </Button>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box px={{ xs: "12px", lg: 0 }} style={{ marginLeft: "0" }}>
          <div
            style={{ display: "flex" }}
            className="page-space  payment-int-wrap"
          >
            {/* <img
              className="img-fluid"
              alt="img"
              style={{ width: "400px", height: "300px" }}
              src={sol}
            /> */}
            <Box
              style={{
                background: "white",
                padding: "10px",
                width: "100%",
              }}
            >
              <Box mb={1}>
                <Typography variant="h3">
                  <p style={{ margin: 0, padding: 0, textAlign: "center" }}>
                    {t("parent.aboutCourse")}
                  </p>
                </Typography>
              </Box>
              <Box style={{ marginBottom: "50px" }} variant="body2">
                {solCourse?.map((item, index) => (
                  <p key={index}>
                    {localStorage.getItem("lng") == "en"
                      ? item?.description_en
                      : localStorage.getItem("lng") == "ru"
                      ? item?.description_ru
                      : item?.description_uz}
                  </p>
                ))}
                <h4 style={{ textAlign: "center" }}>
                  {t("parent.durationInMonth")}:
                </h4>
                <div>
                  {solCourse?.map((item, index) => (
                    <span key={index} style={{ marginRight: "5px" }}>
                      {localStorage.getItem("lng") == "uz"
                        ? item.name_uz
                        : localStorage.getItem("lng") == "ru"
                        ? item.name_ru
                        : item.name_en}{" "}
                      {item?.duration_in_month}-{t("parent.month")}
                    </span>
                  ))}
                </div>
                <div>
                  <h4 style={{ textAlign: "center" }}>{t("parent.prices")}:</h4>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginRight: "8px",
                      color: "#dc3545",
                    }}
                  >
                    {resultPrice[0]} {t("parent.valyuta")}
                  </span>
                  {console.log(typeof resultPrice[0])}
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginRight: "8px",
                      color: "#0d6efd",
                    }}
                  >
                    {selectedCourses.length > 2
                      ? `${(parseInt(selectedCoursesPrice) / 100) * 10} ${t(
                          "parent.valyuta"
                        )} ${t("parent.discount")}`
                      : ""}
                  </span>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "#198754",
                    }}
                  >
                    {selectedCourses.length > 2
                      ? `10% ${t("parent.discount")}`
                      : `0% ${t("parent.discount")}`}
                  </span>
                </div>
              </Box>
              <Box
                mt={0}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                style={{ height: "auto" }}
              >
                <Box style={{ marginRight: "20px", display: "flex" }}>
                  {solCourse &&
                    solCourse.map((item, index) => (
                      <Box
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography>
                          {localStorage.getItem("lng") == "uz"
                            ? item.name_uz
                            : localStorage.getItem("lng") == "ru"
                            ? item.name_ru
                            : item.name_en}
                        </Typography>
                        <Checkbox value={item.id} onChange={handleCheckbox} />
                      </Box>
                    ))}
                </Box>
                <Button
                  disabled={disable}
                  style={{
                    marginRight: "5%",
                    marginTop: "30px",
                    fontSize: "14px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}

                  // onClick={applyBtn}
                >
                  {t("parent.apply")}
                </Button>
              </Box>
            </Box>
          </div>
        </Box>
      </Container>

      <Box mt={0}>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div style={modalStyle} className={classes.paper}>
            <Box>
              <h2>{t("parent.chooseChild")}</h2>
              <Box style={{ marginBottom: "10px" }}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  error={
                    addToCourseStudent.startValidate &&
                    (validationAddCourse.passes() === true ? false : true)
                  }
                >
                  <Select
                    fullWidth
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    value={addToCourseStudent.add_student}
                    onChange={(e, item) => handleChange(e, item)}
                    input={<Input />}
                  >
                    {allStudents && allStudents.length > 0 ? (
                      allStudents.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {`${item.first_name} ${item.last_name}`}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>{t("parent.noChild")}</MenuItem>
                    )}
                  </Select>
                  <FormHelperText style={{ marginLeft: 0 }}>
                    {addToCourseStudent.startValidate &&
                      validationAddCourse.errors.first("add_student")}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Box>
            <Box style={{ marginTop: "30px" }}>
              <Button color="primary" variant="contained" onClick={applyBtn}>
                {t("parent.apply")}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                style={{ marginLeft: "10px" }}
                onClick={handleClose}
              >
                {t("parent.cancel")}
              </Button>
              {/* <p>{errorMessage}</p> */}
            </Box>
          </div>
        </Modal>
      </Box>

      <Container>
        <Box px={{ xs: "12px", lg: 0 }} style={{ marginLeft: "0" }}>
          <Box
            style={{
              width: "100%",
              marginTop: "-2.5%",
              marginBottom: "30px",
            }}
          >
            <Accordion>
              <AccordionSummary onClick={() => setOpenOverview(!openOverview)}>
                <Typography
                  style={{
                    width: "100%",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  color="primary"
                >
                  <span>{t("parent.overview")}</span>
                  <img
                    style={{ width: "20px" }}
                    src={
                      openOverview ? KeyboardArrowUpIcon : KeyboardArrowDownIcon
                    }
                    alt={
                      openOverview
                        ? "KeyboardArrowUpIcon"
                        : "KeyboardArrowDownIcon"
                    }
                  />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ fontSize: "20px" }}>
                  {solCourse?.map((item, index) => (
                    <p key={index}>
                      {localStorage.getItem("lng") == "en"
                        ? item?.overview_en
                        : localStorage.getItem("lng") == "ru"
                        ? item?.overview_ru
                        : item?.overview_uz}
                    </p>
                  ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default Sol;
