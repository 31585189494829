/**
 * Blog Grid Page
 */
import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import styles from "./courses.module.scss";
import {
  Grid,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Fab,
  Icon,
  Container,
  Avatar,
} from "@material-ui/core";
import { getAllCourses } from "fetch/landing/apies";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: "1.125rem",
  },
  thumbWrap: {
    lineHeight: 1,
    position: "relative",
    "& img": {
      height: 250,
      objectFit: "cover",
      width: "100%",
    },
  },
  overlayContent: {
    position: "absolute",
    top: 19,
    right: 16,
  },
  avatar: {
    width: 45,
    height: 45,
  },
  icon: {
    color: theme.palette.common.white,
    transform: "rotateY(180deg)",
  },
}));

function BlogGrid() {
  const { t } = useTranslation();
  const token = JSON.parse(localStorage.getItem("preUni_token"));

  const classes = useStyles();

  const [allCourses, setAllCourses] = useState();
  const [coursePagination, setCoursePagination] = useState(6);

  useEffect(() => {
    getAllCourses(coursePagination)
      .then((res) => {
        setAllCourses(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [coursePagination]);
  // const showMoreBtn = () => {
  //   setCoursePagination((prevState) => prevState + 4);
  // };
  // console.log(localStorage.getItem("lng"));

  return (
    <div className="blog-grid-wrap" id="ourCourse">
      <Box>
        <div className="section-title mt-3">
          <h2>{t("ourCourses")}</h2>
        </div>
      </Box>
      <Container maxWidth="lg">
        <div className={styles.coursesBox}>
          {allCourses && allCourses.length > 0
            ? allCourses?.map((course, index) => (
                <Link to={"auth"}>
                  <Card className="blog-grid-item" key={index}>
                    <Box className={`${classes.thumbWrap} ${styles.imgBox}`}>
                      <img
                        className="img-fluid"
                        alt="img"
                        height="250"
                        src={`${process.env.REACT_APP_PRE_UNI_API}/courses/${course?.id}/image`}
                      />
                      <Box
                        className={`icon-wrap ${classes.overlayContent}`}
                      ></Box>
                    </Box>
                    <Box>
                      <CardContent>
                        <Box
                          mb={1}
                          component={Link}
                          to={`/app/blog/blog-detail/`}
                          fontSize="h6.fontSize"
                          fontWeight="500"
                        ></Box>
                        <Typography variant="body2">
                          {localStorage.getItem("lng") == "en"
                            ? course?.name_en
                            : localStorage.getItem("lng") == "ru"
                            ? course?.name_ru
                            : course?.name_uz}
                        </Typography>
                        <Box pt={2} display="flex" alignItems="center">
                          {/* <Box>
                            <Avatar
                              className={classes.avatar}
                              alt="user-thumb"
                              src=""
                            />
                          </Box> */}
                          <Box px={2}>
                            <Typography variant="subtitle2" color="textPrimary">
                              {localStorage.getItem("lng") == "en" ? (
                                <span>
                                  {`Duration of course: ${course?.duration_in_month}-month`}
                                </span>
                              ) : localStorage.getItem("lng") == "ru" ? (
                                <span>
                                  {`Продолжительность курса: ${course?.duration_in_month}-месяц`}
                                </span>
                              ) : (
                                <span>
                                  {`Kurs davomiyligi: ${course?.duration_in_month}-oy`}
                                </span>
                              )}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {/* Course type: {course?.course_type} */}
                              {localStorage.getItem("lng") == "en" ? (
                                <span>{`Course type: ${
                                  course?.course_type == "socie"
                                    ? "PRE-1"
                                    : "PRE-2"
                                }`}</span>
                              ) : localStorage.getItem("lng") == "ru" ? (
                                <span>{`Тип курса: ${
                                  course?.course_type == "socie"
                                    ? "PRE-1"
                                    : "PRE-2"
                                }`}</span>
                              ) : (
                                <span>{`Kurs turi: ${
                                  course?.course_type == "socie"
                                    ? "PRE-1"
                                    : "PRE-2"
                                }`}</span>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Box>
                  </Card>
                </Link>
              ))
            : "No courses"}
        </div>
        {/* <Box
          style={{
            textAlign: "center",
            fontSize: "20px",
            marginTop: "1%",
          }}
        >
          {allCourses && allCourses.length < coursePagination ? (
            <Typography></Typography>
          ) : (
            <Button onClick={showMoreBtn} style={{ color: "#4d7df2" }}>
              Show more
            </Button>
          )}
        </Box> */}
      </Container>
    </div>
  );
}
export default BlogGrid;
