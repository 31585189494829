import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import styles from "../index.module.scss";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  Paper: {
    width: "100%",
    padding: "0.75rem",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:first-child": {
      paddingTop: "24px",
    },
    "&:last-child": {
      paddingBottom: "30px",
    },
  },
  Grid: {
    display: "flex",
    justifyContent: "space-around",
    background: "red",
  },
  button: {
    fontSize: "1.125rem",
  },
  thumbWrap: {
    lineHeight: 1,
    position: "relative",
    "& img": {
      height: 250,
      objectFit: "cover",
      width: "100%",
    },
  },
  overlayContent: {
    position: "absolute",
    top: 19,
    right: 16,
  },
  avatar: {
    width: 45,
    height: 45,
  },
  icon: {
    color: theme.palette.common.white,
    transform: "rotateY(180deg)",
  },
}));

const Info = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const ToSocieBtn = () => {
    history.push(`info/socie`);
  };
  const ToSolBtn = () => {
    history.push(`info/sol`);
  };

  return (
    <div>
      <Box
        color="primary.main"
        style={{
          display: "flex",
          flexWrap: "wrap",
          paddingRight: "20px",
          marginTop: "50px",
        }}
        className={styles.cardBox}
      >
        <Card
          className={`blog-grid-item ${styles.card}`}
          style={{
            position: "relative",
            marginLeft: "1.5%",
            width: "48%",
            marginTop: "10px",
            paddingBottom: "20px",
          }}
        >
          <Box
            className={classes.thumbWrap}
            style={{ height: "200px", overflow: "hidden" }}
          >
            <img
              className="img-fluid"
              alt="img"
              src="https://res.cloudinary.com/apple-angren/image/upload/v1658922844/%D0%BF%D0%BE%D0%B4%D0%BA%D1%83%D1%80%D1%81%D1%8B_%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%D0%B4%D0%B8%D1%87%D0%BD%D0%B0%D1%8F_%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0_%D1%84%D0%BE%D1%82%D0%BE_opoyww.jpg"
              style={{ width: "100%" }}
            />
          </Box>
          <Box>
            <CardContent style={{ paddingBottom: "20px" }}>
              <Box style={{ textAlign: "center", marginBottom: "30px" }}>
                <Box mb={1} fontSize="h4.fontSize" fontWeight="500">
                  {t("parent.yearProgram")}
                </Box>
                {/* <Box style={{ textAlign: "center" }}>
                  Подготовительные курсы при ЦНО Университета Инха в Ташкенте –
                  это курсы для старшеклассников (10 и 11 классов) по подготовке
                  к поступлению в высшие учебные заведения. Основные предметы –
                  математика, физика, подготовка к IELTS. Ученики могут выбрать
                  язык обучения: русский или узбекский. Для удобства учащихся
                  имеется 2 смены обучения: утренняя (9.00-13.00) и дневная
                  (14.00-18.00). Длительность одного занятия – 80 минут. Занятия
                  проводятся 3 раза в неделю. Программа обучения рассчитана на 9
                  месяцев.
                </Box> */}
              </Box>
              <Box
                style={{
                  width: "97%",
                  position: "absolute",
                  bottom: "0",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "10px",
                }}
              >
                <Button
                  onClick={ToSocieBtn}
                  color="primary"
                  variant="contained"
                  style={{
                    fontSize: "15px",
                    width: "100%",
                    backgroundColor: "rgb(83, 65, 154)",
                  }}
                >
                  {t("parent.details")}
                </Button>
              </Box>
              {/* <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    <h6
                      className="text-primary"
                      style={{
                        margin: 0,
                        padding: 0,
                        marginTop: "20px",
                      }}
                    >
                      Duration of course: <b> 4 month </b>
                    </h6>
                    <h6 style={{ margin: 0, padding: 0 }}>Course type: Pre</h6>
                  </Typography>
                </Box> */}
            </CardContent>
          </Box>
        </Card>
        <Card
          className={`blog-grid-item ${styles.card}`}
          style={{
            position: "relative",
            marginLeft: "1.5%",
            width: "48%",
            marginTop: "10px",
            paddingBottom: "20px",
          }}
        >
          <Box
          // className={classes.thumbWrap}
          >
            <Box
              className={classes.thumbWrap}
              style={{ height: "200px", overflow: "hidden" }}
            >
              <img
                className="img-fluid"
                alt="img"
                src="https://res.cloudinary.com/apple-angren/image/upload/v1658922875/%D0%BF%D0%BE%D0%B4%D0%BA%D1%83%D1%80%D1%81%D1%8B_%D0%B4%D0%B2%D1%83%D1%85%D0%B3%D0%BE%D0%B4%D0%B8%D1%87%D0%BD%D0%B0%D1%8F_%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0_%D1%84%D0%BE%D1%82%D0%BE_aydlbg.jpg"
                style={{ width: "100%" }}
              />
            </Box>
          </Box>
          <Box>
            <CardContent>
              <Box style={{ textAlign: "center", marginBottom: "30px" }}>
                <Box mb={1} fontSize="h4.fontSize" fontWeight="500">
                  {t("parent.twoYearsProgram")}
                </Box>
                {/* <Box style={{ textAlign: "center" }}>
                  Учебные курсы в Центре Непрерывного Образования при
                  университете Инха в Ташкенте — это курсы для детей от 12 до 15
                  лет. Курсы предназначены для получения теоретических и
                  практических знаний по предметам математика, физика,
                  английский язык и программирование. Обучение проходит с нуля.
                  Ученики могут выбрать язык обучения: русский или узбекский.
                  Для удобства учащихся имеется 2 смены обучения: утренняя
                  (9.00-13.00) и дневная (14.00-18.00).
                </Box> */}
              </Box>
              {/* <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    <h6
                      className="text-primary"
                      style={{
                        margin: 0,
                        padding: 0,
                        marginTop: "20px",
                      }}
                    >
                      Duration of course: <b> 4 month </b>
                    </h6>
                    <h6 style={{ margin: 0, padding: 0 }}>Course type: Pre</h6>
                  </Typography>
                </Box> */}
              <Box
                style={{
                  width: "97%",
                  position: "absolute",
                  bottom: "0",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "10px",
                }}
              >
                <Button
                  onClick={ToSolBtn}
                  color="primary"
                  variant="contained"
                  style={{
                    fontSize: "15px",
                    width: "100%",
                    backgroundColor: "rgb(83, 65, 154)",
                  }}
                >
                  {t("parent.details")}
                </Button>
              </Box>
            </CardContent>
          </Box>
        </Card>
      </Box>
    </div>
  );
};

export default Info;
