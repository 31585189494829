import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SmallTitleBar } from "components/GlobalComponents";
import { Box, Container, Typography } from "@material-ui/core";
import { CustomCard } from "components/GlobalComponents";
import { getTimetable } from "fetch/teacher/timetable/apies";
import { t } from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TeacherTimetable() {
  const classes = useStyles();
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const userId = JSON.parse(localStorage.getItem("preUni_user_id"));
  // timetable
  const [monday, setMonday] = useState([]);
  const [tuesday, setTuesday] = useState([]);
  const [wednesday, setWednesday] = useState([]);
  const [thursday, setThursday] = useState([]);
  const [friday, setFriday] = useState([]);
  const [saturday, setSaturday] = useState([]);
  const [sunday, setSunday] = useState([]);

  const [responseData, setResponseData] = useState();
  useEffect(() => {
    if (responseData) {
      setMonday([]);
      setTuesday([]);
      setWednesday([]);
      setThursday([]);
      setFriday([]);
      setSaturday([]);
      setSunday([]);
      responseData.map((item) => {
        item.week_days.map((el) => {
          switch (el) {
            case 1:
              const list1 = {
                id: 1,
                room: item.room?.name,
                time: `${item.start_lesson_time}-${item.end_lesson_time}`,
                courseName: `${
                  localStorage.getItem("lng") == "en"
                    ? item?.course?.name_en
                    : localStorage.getItem("lng") == "ru"
                    ? item?.course?.name_ru
                    : item?.course?.name_uz
                }`,
                teacher: `${item.teacher?.first_name} ${item.teacher?.last_name}`,
                group: item.name,
              };
              setMonday((prevState) => [...prevState, list1]);
              break;
            case 2:
              const list2 = {
                id: 2,
                room: item.room?.name,
                time: `${item.start_lesson_time}-${item.end_lesson_time}`,
                courseName: `${
                  localStorage.getItem("lng") == "en"
                    ? item?.course?.name_en
                    : localStorage.getItem("lng") == "ru"
                    ? item?.course?.name_ru
                    : item?.course?.name_uz
                }`,
                teacher: `${item.teacher?.first_name} ${item.teacher?.last_name}`,
                group: item.name,
              };
              setTuesday((prevState) => [...prevState, list2]);
              break;
            case 3:
              const list3 = {
                id: 3,
                room: item.room?.name,
                time: `${item.start_lesson_time}-${item.end_lesson_time}`,
                courseName: `${
                  localStorage.getItem("lng") == "en"
                    ? item?.course?.name_en
                    : localStorage.getItem("lng") == "ru"
                    ? item?.course?.name_ru
                    : item?.course?.name_uz
                }`,
                teacher: `${item.teacher?.first_name} ${item.teacher?.last_name}`,
                group: item.name,
              };
              setWednesday((prevState) => [...prevState, list3]);
              break;
            case 4:
              const list4 = {
                id: 4,
                room: item.room?.name,
                time: `${item.start_lesson_time}-${item.end_lesson_time}`,
                courseName: `${
                  localStorage.getItem("lng") == "en"
                    ? item?.course?.name_en
                    : localStorage.getItem("lng") == "ru"
                    ? item?.course?.name_ru
                    : item?.course?.name_uz
                }`,
                teacher: `${item.teacher?.first_name} ${item.teacher?.last_name}`,
                group: item.name,
              };
              setThursday((prevState) => [...prevState, list4]);
              break;
            case 5:
              const list5 = {
                id: 5,
                room: item.room?.name,
                time: `${item.start_lesson_time}-${item.end_lesson_time}`,
                courseName: `${
                  localStorage.getItem("lng") == "en"
                    ? item?.course?.name_en
                    : localStorage.getItem("lng") == "ru"
                    ? item?.course?.name_ru
                    : item?.course?.name_uz
                }`,
                teacher: `${item.teacher?.first_name} ${item.teacher?.last_name}`,
                group: item.name,
              };
              setFriday((prevState) => [...prevState, list5]);
              break;
            case 6:
              const list6 = {
                id: 6,
                room: item.room?.name,
                time: `${item.start_lesson_time}-${item.end_lesson_time}`,
                courseName: `${
                  localStorage.getItem("lng") == "en"
                    ? item?.course?.name_en
                    : localStorage.getItem("lng") == "ru"
                    ? item?.course?.name_ru
                    : item?.course?.name_uz
                }`,
                teacher: `${item.teacher?.first_name} ${item.teacher?.last_name}`,
                group: item.name,
              };
              setSaturday((prevState) => [...prevState, list6]);
              break;
            case 7:
              const list7 = {
                id: 7,
                room: item.room?.name,
                time: `${item.start_lesson_time}-${item.end_lesson_time}`,
                courseName: `${
                  localStorage.getItem("lng") == "en"
                    ? item?.course?.name_en
                    : localStorage.getItem("lng") == "ru"
                    ? item?.course?.name_ru
                    : item?.course?.name_uz
                }`,
                teacher: `${item.teacher?.first_name} ${item.teacher?.last_name}`,
                group: item.name,
              };
              setSunday((prevState) => [...prevState, list7]);
              break;
          }
        });
      });
    }
  }, [responseData]);

  useEffect(() => {
    getTimetable(token, "teacherId", userId).then((res) => {
      setResponseData(res.data);
    });
  }, []);

  return (
    <div className={classes.root}>
      <SmallTitleBar title={t("admin.timetable")} />
      <div className="page-space">
        <Container>
          {monday.length === 0 &&
          tuesday.length === 0 &&
          wednesday.length === 0 &&
          thursday.length === 0 &&
          friday.length === 0 &&
          saturday.length === 0 &&
          sunday.length === 0 ? (
            <CustomCard>{t("teacher.noTimetableYet")}!</CustomCard>
          ) : (
            ""
          )}
          <Box className="v-timeline-wrap">
            {monday.length !== 0 && (
              <div className="v-timeline">
                <div className="v-timeline-icon">
                  {/* <i className="material-icons">calendar_today</i> */}
                  <span className="v-timeline-date">
                    {t("weekDays.monday")}
                  </span>
                </div>
                {monday.map((el, index) => (
                  <div
                    key={index}
                    className="v-timeline-list"
                    style={{ marginBottom: "5px" }}
                  >
                    <div className="v-timeline-meta">
                      <div className="v-timeline-time">{el.time}</div>
                    </div>
                    <div className="v-timeline-block">
                      <div className="v-timeline-content">
                        <Box mb={1}>
                          <Typography variant="h6" className="v-timeline-title">
                            {el.courseName}
                          </Typography>
                        </Box>
                        <Typography>
                          {" "}
                          {t("admin.room")}: {el.room}
                        </Typography>
                        <Typography>
                          {t("admin.teacher")}: {el.teacher}
                        </Typography>
                        <Typography>
                          {" "}
                          {t("admin.group")}: {el.group}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {tuesday.length !== 0 && (
              <div className="v-timeline">
                <div className="v-timeline-icon">
                  {/* <i className="material-icons">calendar_today</i> */}
                  <span className="v-timeline-date">
                    {" "}
                    {t("weekDays.tuesday")}
                  </span>
                </div>
                {tuesday.map((el, index) => (
                  <div
                    key={index}
                    className="v-timeline-list"
                    style={{ marginBottom: "5px" }}
                  >
                    <div className="v-timeline-meta">
                      <div className="v-timeline-time">{el.time}</div>
                    </div>
                    <div className="v-timeline-block">
                      <div className="v-timeline-content">
                        <Box mb={1}>
                          <Typography variant="h6" className="v-timeline-title">
                            {el.courseName}
                          </Typography>
                        </Box>
                        <Typography>
                          {t("admin.room")}: {el.room}
                        </Typography>
                        <Typography>
                          {t("admin.teacher")}: {el.teacher}
                        </Typography>
                        <Typography>
                          {t("admin.group")}: {el.group}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {wednesday.length !== 0 && (
              <div className="v-timeline">
                <div className="v-timeline-icon">
                  {/* <i className="material-icons">calendar_today</i> */}
                  <span className="v-timeline-date">
                    {" "}
                    {t("weekDays.wednesday")}
                  </span>
                </div>
                {wednesday.map((el, index) => (
                  <div
                    key={index}
                    className="v-timeline-list"
                    style={{ marginBottom: "5px" }}
                  >
                    <div className="v-timeline-meta">
                      <div className="v-timeline-time">{el.time}</div>
                    </div>
                    <div className="v-timeline-block">
                      <div className="v-timeline-content">
                        <Box mb={1}>
                          <Typography variant="h6" className="v-timeline-title">
                            {el.courseName}
                          </Typography>
                        </Box>
                        <Typography>
                          {t("admin.room")}: {el.room}
                        </Typography>
                        <Typography>
                          {t("admin.teacher")}: {el.teacher}
                        </Typography>
                        <Typography>
                          {t("admin.group")}: {el.group}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {thursday.length !== 0 && (
              <div className="v-timeline">
                <div className="v-timeline-icon">
                  {/* <i className="material-icons">calendar_today</i> */}
                  <span className="v-timeline-date">
                    {" "}
                    {t("weekDays.thursday")}
                  </span>
                </div>
                {thursday.map((el, index) => (
                  <div
                    key={index}
                    className="v-timeline-list"
                    style={{ marginBottom: "5px" }}
                  >
                    <div className="v-timeline-meta">
                      <div className="v-timeline-time">{el.time}</div>
                    </div>
                    <div className="v-timeline-block">
                      <div className="v-timeline-content">
                        <Box mb={1}>
                          <Typography variant="h6" className="v-timeline-title">
                            {el.courseName}
                          </Typography>
                        </Box>
                        <Typography>
                          {t("admin.room")}: {el.room}
                        </Typography>
                        <Typography>
                          {t("admin.teacher")}: {el.teacher}
                        </Typography>
                        <Typography>
                          {t("admin.group")}: {el.group}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {friday.length !== 0 && (
              <div className="v-timeline">
                <div className="v-timeline-icon">
                  {/* <i className="material-icons">calendar_today</i> */}
                  <span className="v-timeline-date">
                    {" "}
                    {t("weekDays.friday")}
                  </span>
                </div>
                {friday.map((el, index) => (
                  <div
                    key={index}
                    className="v-timeline-list"
                    style={{ marginBottom: "5px" }}
                  >
                    <div className="v-timeline-meta">
                      <div className="v-timeline-time">{el.time}</div>
                    </div>
                    <div className="v-timeline-block">
                      <div className="v-timeline-content">
                        <Box mb={1}>
                          <Typography variant="h6" className="v-timeline-title">
                            {el.courseName}
                          </Typography>
                        </Box>
                        <Typography>
                          {t("admin.room")}: {el.room}
                        </Typography>
                        <Typography>
                          {t("admin.teacher")}: {el.teacher}
                        </Typography>
                        <Typography>
                          {t("admin.group")}: {el.group}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {saturday.length !== 0 && (
              <div className="v-timeline">
                <div className="v-timeline-icon">
                  {/* <i className="material-icons">calendar_today</i> */}
                  <span className="v-timeline-date">
                    {" "}
                    {t("weekDays.saturday")}
                  </span>
                </div>
                {saturday.map((el, index) => (
                  <div
                    key={index}
                    className="v-timeline-list"
                    style={{ marginBottom: "5px" }}
                  >
                    <div className="v-timeline-meta">
                      <div className="v-timeline-time">{el.time}</div>
                    </div>
                    <div className="v-timeline-block">
                      <div className="v-timeline-content">
                        <Box mb={1}>
                          <Typography variant="h6" className="v-timeline-title">
                            {el.courseName}
                          </Typography>
                        </Box>
                        <Typography>
                          {t("admin.room")}: {el.room}
                        </Typography>
                        <Typography>
                          {t("admin.teacher")}: {el.teacher}
                        </Typography>
                        <Typography>
                          {t("admin.group")}: {el.group}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {sunday.length !== 0 && (
              <div className="v-timeline">
                <div className="v-timeline-icon">
                  {/* <i className="material-icons">calendar_today</i> */}
                  <span className="v-timeline-date">
                    {" "}
                    {t("weekDays.sunday")}
                  </span>
                </div>
                {sunday.map((el, index) => (
                  <div
                    key={index}
                    className="v-timeline-list"
                    style={{ marginBottom: "5px" }}
                  >
                    <div className="v-timeline-meta">
                      <div className="v-timeline-time">{el.time}</div>
                    </div>
                    <div className="v-timeline-block">
                      <div className="v-timeline-content">
                        <Box mb={1}>
                          <Typography variant="h6" className="v-timeline-title">
                            {el.courseName}
                          </Typography>
                        </Box>
                        <Typography>
                          {t("admin.room")}: {el.room}
                        </Typography>
                        <Typography>
                          {t("admin.teacher")}: {el.teacher}
                        </Typography>
                        <Typography>
                          {t("admin.group")}: {el.group}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Box>
        </Container>
      </div>
    </div>
  );
}
