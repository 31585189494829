import React, { useEffect, useState } from "react";
import "./all.css";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import Validator from "validatorjs";
import { t } from "i18next";
import { NotificationManager } from "react-notifications";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

import {
  Stepper,
  Step,
  StepLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Box,
  Button,
  Modal,
  TextField,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Checkbox,
  Input,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import {
  getRegions,
  getCoursesType,
  createAddStudentToCourse,
} from "fetch/student";

import {
  getStudents,
  createStudent,
  deleteStudent,
  updateStudent,
  getAllStudentCourses,
  getStudentCourses,
  getStudentsLength,
  findUserByFirstname,
} from "fetch/admin/students/apies";
import { getUser } from "fetch/users";
import {
  createImage,
  createParentPassport,
  getContract,
  getStudentAppliedCourses,
} from "fetch/parent/children/apies";
import { getStats } from "fetch/admin/dashboard/apies";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },
  paperDelete: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },
}));

function AdminAllStudents(props) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  // const parentId = JSON.parse(localStorage.getItem("preUni_user_id"));
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [disableBtn, setDisableBtn] = useState(false);
  const [loader, setLoader] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [disablePagin, setDisablePagin] = useState(true);
  const [changeBtn, setChangeBtn] = useState({ change: "" });
  const [coursesResult, setCoursesResult] = useState([]);
  const [regionParentOptions, setRegionParentOptions] = useState();
  const [districtParentOptions, setDistrictParentOptions] = useState();
  const [parentInformation, setParentInformation] = useState();
  useEffect(() => {
    getRegions(token).then((res) => {
      setRegionOptions(res.data);
      setRegionParentOptions(res.data);
    });
  }, []);
  // get parent information
  // useEffect(() => {
  //   getUser(token, parentId).then((res) => {
  //     setParentInformation(res.data);
  //     console.log(res.data);
  //   });
  // }, []);
  // Table
  const actions = [
    {
      icon: "edit",
      tooltip: `${t("admin.editStudent")}`,
      onClick: (e, rowData) => openModalForEdit(e, rowData),
    },
    {
      icon: "delete",
      tooltip: `${t("admin.deleteStudent")}`,
      onClick: (e, rowData) => openModalForDelete(e, rowData.id),
    },
    {
      icon: "visibility",
      tooltip: `${t("admin.viewInDetail")}`,
      onClick: (e, rowData) =>
        props.history.push(`/app/admin/students/${rowData.id}`),
    },
  ];

  const columns = [
    { title: `${t("admin.firsName")}`, field: "first_name" },
    { title: `${t("admin.lastName")}`, field: "last_name" },
    { title: `${t("parent.email")}`, field: "email" },
    {
      title: `${t("parent.appliedCourses")}`,
      field: "email",
      render: (rowData) => (
        <p>
          {rowData.courses?.length > 0
            ? rowData.courses.map((item, index) => (
                <span>
                  {`${
                    item.course == null
                      ? ""
                      : localStorage.getItem("lng") == "uz"
                      ? item?.course?.name_uz
                      : localStorage.getItem("lng") == "en"
                      ? item?.course?.name_en
                      : item?.course?.name_ru
                  } ${
                    item.course !== null
                      ? item?.course?.course_type == "socie"
                        ? `(${t("parent.pre1")}) `
                        : `(${t("parent.pre2")}) `
                      : ""
                  }`}
                </span>
              ))
            : ""}
        </p>
      ),
    },
    // { title: "Distirict", field: "district.name_en" },
    // { title: "Address", field: "address" },
    { title: `${t("parent.phone")}:`, field: "phone_number" },
  ];
  // Table
  const actionsCourse = [
    {
      icon: "edit",
      tooltip: "Edit Student",
      onClick: (e, rowData) => openModalForEdit(e, rowData),
    },
    {
      icon: "delete",
      tooltip: "Delete Student",
      onClick: (e, rowData) => deleteStudentBtn(e, rowData.id),
    },
    {
      icon: "visibility",
      tooltip: "View in detail",
      onClick: (e, rowData) =>
        props.history.push(`/app/admin/students/${rowData.id}`),
    },
  ];

  const columnsCourse = [
    { title: "First Name", field: "first_name" },
    { title: "Last Name", field: "last_name" },
    { title: "Email", field: "email" },
    // { title: "Region", field: "region.name_en" },
    // { title: "Distirict", field: "district.name_en" },
    // { title: "Address", field: "address" },
    { title: "Phone", field: "phone_number" },
  ];

  // all student applied courses
  // const [allStudentsCourses, setAllStudentsCourses] = useState();
  // useEffect(() => {
  //   getStudentCourses(token, 41).then((res) => {
  //     setAllStudentsCourses(res.data);
  //     console.log(res.data);
  //   });
  // }, []);

  // all students
  const [allStudents, setAllStudents] = useState();
  const [studentsLength, setStudentsLength] = useState();
  const [studentCourses, setStudentCourses] = useState([]);
  const [studentsResult, setStudentsResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [disabledPage, setDisabledPage] = useState(false);
  const [disabledPageNext, setDisabledPageNext] = useState(false);

  useEffect(() => {
    getStudents(token, 5, currentPage)
      .then((res) => {
        setAllStudents(res.data);
        console.log(allStudents?.length);
        if (!res?.data?.length) {
          setDisabledPageNext(true);
        }
        // if (allStudents?.length > 0) {
        //   allStudents?.forEach((element) => {
        //     getStudentCourses(token, element?.id).then((res) => {
        //       if (res.data.length > 0) {
        //         res.data.forEach((course) => {
        //           if (course.course !== null) {
        //             // setStudentCourses((prevState) => [...prevState, course]);
        //             // console.log(studentCourses, "studentCourses");
        //             // console.log(allStudents, "allStudents");
        //             if (element.id == course.student.id) {
        //               // setStudentsResult((prevState) => [...prevState, element]);
        //               setCoursesResult((prevState) => [...prevState, course]);
        //               // console.log([course]);
        //             }
        //           }
        //         });
        //       }
        //     });
        //     // console.log("ELEMENT");
        //   });
        // }
        // console.log(coursesResult);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentPage]);
  useEffect(() => {
    getStats(token).then((res) => {
      setStudentsLength(res.data.studentsCount);
      console.log(studentsLength);
    });
  }, []);
  // Pagination
  const nextPage = () => {
    if (
      allStudents.length &&
      studentsLength - allStudents.length !== currentPage
    ) {
      setDisabledPage(false);
      // getStudents(token, 5, currentPage).then((res) => {
      //   setAllStudents(res.data);
      //   console.log(res);
      // });
      setCurrentPage(currentPage + 5);
    }
  };
  const prevPage = () => {
    setDisabledPageNext(false);
    // getStudents(token, 5, currentPage).then((res) => {
    //   setAllStudents(res.data);
    //   console.log(res);
    // });
    if (currentPage != 0) {
      setDisabledPage(false);
      setCurrentPage(currentPage - 5);
      console.log(currentPage);
    } else {
      setDisabledPage(true);
    }
  };
  // Search
  //   fullname
  const [searchStudentInputs, setSearchStudentInputs] = useState({
    fullname: "",
    rule: "required",
    startValidate: false,
  });
  let validationSearchStudent = new Validator(
    { fullname: searchStudentInputs.fullname },
    { fullname: searchStudentInputs.rule }
  );
  const handleSearchStudent = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setSearchStudentInputs((prevState) => ({
      ...prevState,
      fullname: result,
      startValidate: true,
    }));
    if (e.target.value === "") {
      setDisablePagin(true);
      getStudents(token, 5, currentPage)
        .then((res) => {
          setAllStudents(res.data);
          console.log(allStudents?.length);
          if (!res?.data?.length) {
            setDisabledPageNext(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [attendanceErr, setAttendanceErr] = useState(false);
  const searchStudentBtn = (e) => {
    e?.preventDefault();
    if (validationSearchStudent.passes()) {
      setAttendanceErr(false);
      findUserByFirstname(searchStudentInputs.fullname, token)
        .then((res) => {
          if (res?.data?.length) {
            setDisablePagin(false);
            console.log(res);
            console.log(allStudents);
            setAllStudents(res.data);
          } else {
            setAttendanceErr(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setAttendanceErr(true);
        });
    } else {
      setSearchStudentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  const getKeyCode = (e) => {
    if (e.keyCode === 13) {
      searchStudentBtn();
    }
  };

  // console.log(coursesResult);
  // console.log(studentCourses);

  const [regionOptions, setRegionOptions] = useState();
  const [districtOptions, setDistrictOptions] = useState();
  useEffect(() => {
    getRegions(token).then((res) => {
      setRegionOptions(res.data);
    });
  }, []);

  // form
  // email
  const [emailInputs, setEmailInputs] = useState({
    email: "",
    rule: "required|email",
    startValidate: false,
  });

  const validationEmail = new Validator(
    { email: emailInputs.email },
    { email: emailInputs.rule }
  );

  const [emailTaken, setEmailTaken] = useState(false);
  const handleEmail = (e) => {
    setEmailTaken(false);
    // if (e.target.value !== "") {
    //   if (changeBtn.change === "create") {
    //     studentEmailValidation(e.target.value).then((res) => {
    //       setEmailTaken(res.data.isExisting);
    //     });
    //   } else if (changeBtn.change === "edit") {
    //     if (emailInputs.prevEmail !== e.target.value) {
    //       studentEmailValidation(e.target.value).then((res) => {
    //         setEmailTaken(res.data.isExisting);
    //       });
    //     }
    //   }
    // }

    setEmailInputs((prevState) => ({
      ...prevState,
      email: e.target.value,
      startValidate: true,
    }));
  };

  // password
  const [passwordInputs, setPasswordInputs] = useState({
    password: "",
    rule: "required|min:8",
    startValidate: false,
  });

  const validationPassword = new Validator(
    { password: passwordInputs.password },
    { password: passwordInputs.rule }
  );

  const handlePassword = (e) => {
    e.target.maxLength = 32;
    setPasswordInputs((prevState) => ({
      ...prevState,
      password: e.target.value,
      startValidate: changeBtn.change === "create" ? true : false,
    }));
  };

  // show password
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // firstname
  const [firstnameInputs, setFirstnameInputs] = useState({
    firstname: "",
    rule: "required",
    startValidate: false,
  });
  let validationFirstname = new Validator(
    { firstname: firstnameInputs.firstname },
    { firstname: firstnameInputs.rule }
  );
  const handleFirstname = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setFirstnameInputs((prevState) => ({
      ...prevState,
      firstname: result,
      startValidate: true,
    }));
  };
  //   lastname
  const [lastnameInputs, setLastnameInputs] = useState({
    lastname: "",
    rule: "required",
    startValidate: false,
  });
  let validationLastname = new Validator(
    { lastname: lastnameInputs.lastname },
    { lastname: lastnameInputs.rule }
  );
  const handleLastname = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setLastnameInputs((prevState) => ({
      ...prevState,
      lastname: result,
      startValidate: true,
    }));
  };
  //   middlename
  const [middlenameInputs, setMiddlenameInputs] = useState({
    middlename: "",
    rule: "required",
    startValidate: false,
  });
  let validationMiddlename = new Validator(
    { middlename: middlenameInputs.middlename },
    { middlename: middlenameInputs.rule }
  );
  const handleMiddlename = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setMiddlenameInputs((prevState) => ({
      ...prevState,
      middlename: result,
      startValidate: true,
    }));
  };

  // gender
  const [genderInputs, setGenderInputs] = useState({
    gender: "",
    rule: "required",
    startValidate: false,
  });
  let validationGender = new Validator(
    { gender: genderInputs.gender },
    { gender: genderInputs.rule }
  );
  const handleGender = (e) => {
    setGenderInputs((prevState) => ({
      ...prevState,
      gender: e.target.value,
      startValidate: true,
    }));
  };

  // birthday
  const [birthdayInputs, setBirthdayInputs] = useState({
    birthday: "",
    rule: "required",
    startValidate: false,
  });
  let validationBirthday = new Validator(
    { birthday: birthdayInputs.birthday },
    { birthday: birthdayInputs.rule }
  );
  function formatDate(date) {
    console.log(date);
    console.log(typeof date);
    let newDate = date.split("-");

    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
  }
  const handleBirthday = (e) => {
    setBirthdayInputs((prevState) => ({
      ...prevState,
      birthday: e.target.value,
      startValidate: true,
    }));
  };

  // ========== STUDENT ADDRESS START ==========
  // region
  const [regionInputs, setRegionInputs] = useState({
    regionId: "",
    rule: "required",
    startValidate: false,
  });
  let validationRegion = new Validator(
    { regionId: regionInputs.regionId },
    { regionId: regionInputs.rule }
  );
  const handleRegion = (e) => {
    setRegionInputs((prevState) => ({
      ...prevState,
      regionId: e.target.value,
      startValidate: true,
    }));
    setDistrictInputs((prevState) => ({
      ...prevState,
      districtId: "",
    }));
  };

  useEffect(() => {
    if (regionInputs.regionId) {
      const { districts } = regionOptions.find(
        (option) => option.id === regionInputs.regionId
      );
      setDistrictOptions(districts);
    }
  }, [regionInputs.regionId]);

  // district
  const [districtInputs, setDistrictInputs] = useState({
    districtId: "",
    rule: "required",
    startValidate: false,
  });
  let validationDistrict = new Validator(
    { districtId: districtInputs.districtId },
    { districtId: districtInputs.rule }
  );
  const handleDistrict = (e) => {
    setDistrictInputs((prevState) => ({
      ...prevState,
      districtId: e.target.value,
      startValidate: true,
    }));
  };

  // Address
  const [addressInputs, setAddressInputs] = useState({
    address: "",
    rule: "required",
    startValidate: false,
  });
  let validationAddress = new Validator(
    { address: addressInputs.address },
    { address: addressInputs.rule }
  );
  const handleAddress = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setAddressInputs((prevState) => ({
      ...prevState,
      address: result,
      startValidate: true,
    }));
  };

  // Phone
  Validator.register(
    "telephone",
    function (value) {
      return value.match("998[0-9]{0,}");
    },
    "The phone format is invalid"
  );

  const [phoneInputs, setPhoneInputs] = useState({
    phone: "",
    rule: "required|telephone|min:12|max:12",
    startValidate: false,
  });

  let validationPhone = new Validator(
    { phone: phoneInputs.phone },
    { phone: phoneInputs.rule }
  );

  const handlePhone = (e) => {
    e.target.maxLength = 12;
    const result = e.target.value.toUpperCase().replace(/[^0-9]/gi, "");
    setPhoneInputs((prevState) => ({
      ...prevState,
      phone: result,
      startValidate: true,
    }));
  };
  // ========== STUDENT ADDRESS END ==========
  // ========== PARENT ADDRESS START ==========
  // region
  const [regionParentInputs, setRegionParentInputs] = useState({
    regionId: "",
    rule: "required",
    startValidate: false,
  });
  let validationRegionParent = new Validator(
    { regionId: regionParentInputs.regionId },
    { regionId: regionParentInputs.rule }
  );
  const handleParentRegion = (e) => {
    setRegionParentInputs((prevState) => ({
      ...prevState,
      regionId: e.target.value,
      startValidate: true,
    }));
    setDistrictParentInputs((prevState) => ({
      ...prevState,
      districtId: "",
    }));
  };

  useEffect(() => {
    if (regionParentInputs.regionId) {
      const { districts } = regionParentOptions.find(
        (option) => option.id === regionParentInputs.regionId
      );
      setDistrictParentOptions(districts);
    }
  }, [regionParentInputs.regionId]);

  // district
  const [districtParentInputs, setDistrictParentInputs] = useState({
    districtId: "",
    rule: "required",
    startValidate: false,
  });
  let validationDistrictParent = new Validator(
    { districtId: districtParentInputs.districtId },
    { districtId: districtParentInputs.rule }
  );
  const handleDistrictParent = (e) => {
    setDistrictParentInputs((prevState) => ({
      ...prevState,
      districtId: e.target.value,
      startValidate: true,
    }));
  };

  // Address
  const [addressParentInputs, setAddressParentInputs] = useState({
    address: "",
    rule: "required",
    startValidate: false,
  });
  let validationParentAddress = new Validator(
    { address: addressParentInputs.address },
    { address: addressParentInputs.rule }
  );
  const handleAddressParent = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setAddressParentInputs((prevState) => ({
      ...prevState,
      address: result,
      startValidate: true,
    }));
  };

  // Phone
  Validator.register(
    "telephone",
    function (value) {
      return value.match("998[0-9]{0,}");
    },
    "The phone format is invalid"
  );

  const [phoneParentInputs, setPhoneParentInputs] = useState({
    phone: "",
    rule: "required|telephone|min:12|max:12",
    startValidate: false,
  });
  let validationParentPhone = new Validator(
    { phone: phoneParentInputs.phone },
    { phone: phoneParentInputs.rule }
  );

  const handleParentPhone = (e) => {
    e.target.maxLength = 12;
    const result = e.target.value.toUpperCase().replace(/[^0-9]/gi, "");
    setPhoneParentInputs((prevState) => ({
      ...prevState,
      phone: result,
      startValidate: true,
    }));
  };
  // ========== PARENT ADDRESS END ==========

  // course
  const [courseInputs, setCourseInputs] = useState({
    course: "",
    rule: "required",
    startValidate: false,
  });
  let validationCourse = new Validator(
    { course: courseInputs.course },
    { course: courseInputs.rule }
  );
  // Get course Pre-1 and Pre-2
  const [socieCourse, setSocieCourse] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedCoursesPrice, setSelectedCoursesPrice] = useState(0);
  const resultPrice = [];

  const handleCourseType = (e) => {
    setCourseInputs((prevState) => ({
      ...prevState,
      course: e.target.value,
      startValidate: true,
    }));
    if (e.target.value == "kids") {
      setDisableBtn(false);
      setSocieCourse(false);
    } else if (e.target.value == "socie") {
      getCoursesType(token, "socie").then((res) => {
        setSocieCourse(res.data);
        console.log(res);
      });
      setSocieCourse(false);
      setSelectedCourses([]);
      setSelectedCoursesPrice(0);
      setDisableBtn(true);
    } else if (e.target.value == "sol") {
      getCoursesType(token, "sol").then((res) => {
        setSocieCourse(res.data);
        console.log(res);
      });
      setSocieCourse(false);
      setSelectedCourses([]);
      setSelectedCoursesPrice(0);
      setDisableBtn(true);
    }
  };

  const formatToCurrency = (amount) => {
    return resultPrice.push(
      "" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&.")
    );
  };
  formatToCurrency(selectedCoursesPrice);
  // inc Price
  const incrementCounter = (clickedPrice) => {
    setSelectedCoursesPrice(selectedCoursesPrice + clickedPrice);
    // setDiscount(((selectedCoursesPrice + clickedPrice) / 100) * 10);
    // console.log(discount);
  };
  // dex Price
  const decrementCounter = (clickedPrice) =>
    setSelectedCoursesPrice(selectedCoursesPrice - clickedPrice);

  const getPrice = (e) => {
    for (let i = 0; i < socieCourse.length; i++) {
      if (socieCourse[i].id == e.target.value) {
        if (socieCourse[i].prices.length > 0) {
          const ids = socieCourse[i].prices.map((object) => {
            return object.id;
          });
          const max = Math.max(...ids);
          for (let j = 0; j < socieCourse[i].prices.length; j++) {
            if (socieCourse[i].prices[j].id == max) {
              if (e.target.checked) {
                incrementCounter(socieCourse[i].prices[j].cost);
              } else {
                decrementCounter(socieCourse[i].prices[j].cost);
              }
            }
          }
        } else {
          console.log("No Prices");
        }
      }
      // if (socieCourse[i].id == e.target.value) {
      //   if (socieCourse[i].prices.length > 0) {
      //     let clickedPrice = socieCourse[i].prices[0].cost;
      //     for (let j = 0; j < socieCourse[i].prices.length; j++) {
      //       if (socieCourse[i]?.id == socieCourse[i]?.prices[j]?.id) {
      //         console.log(socieCourse[i].id);
      //         if (e.target.checked) {
      //           incrementCounter(socieCourse[i]?.prices[j]?.cost);
      //         } else {
      //           decrementCounter(socieCourse[i]?.prices[j]?.cost);
      //         }
      //       }
      //     }
      //   } else {
      //     console.log("No Prices");
      //   }
      // }
    }
  };
  const handleCheckbox = (e) => {
    getPrice(e);
    if (e.target.checked) {
      if (!selectedCourses.includes(Number(e.target.value))) {
        selectedCourses.push(Number(e.target.value));
      }
    } else {
      if (selectedCourses.includes(Number(e.target.value))) {
        const elPosition = selectedCourses.indexOf(Number(e.target.value));
        selectedCourses.splice(elPosition, 1);
      }
    }

    setSelectedCourses(selectedCourses);
    // console.log(selectedCoursesPrice);
    // console.log(selectedCourses);

    // const disCount = (selectedCoursesPrice / 100) * 10;
    if (selectedCourses.length > 0) {
      setDisableBtn(false);

      // if (selectedCourses.length >= 3) {
      //   setDiscount((selectedCoursesPrice / 100) * 10);
      // }
    } else {
      setDisableBtn(true);
    }
  };

  // passport serial code
  const [passportSerialCodeInputs, setPassportSerialCodeInputs] = useState({
    serialNumber: "",
    rule: "required",
    startValidate: false,
  });
  let validationSerialCode = new Validator(
    { serialNumber: passportSerialCodeInputs.serialNumber },
    { serialNumber: passportSerialCodeInputs.rule }
  );
  const handlePassportSerailCode = (e) => {
    e.target.maxLength = 4;
    const result = e.target.value.toUpperCase().replace(/[^A-Z]/gi, "");

    setPassportSerialCodeInputs((prevState) => ({
      ...prevState,
      serialNumber: result,
      startValidate: true,
    }));
  };
  // passport serial number
  const [passportSerialInputs, setPassportSerialInputs] = useState({
    serialNumber: "",
    rule: "required",
    startValidate: false,
  });
  let validationSerialNumber = new Validator(
    { serialNumber: passportSerialInputs.serialNumber },
    { serialNumber: passportSerialInputs.rule }
  );
  const handlePassportSerail = (e) => {
    e.target.maxLength = 7;
    const result = e.target.value.toUpperCase().replace(/[^0-9]/gi, "");
    setPassportSerialInputs((prevState) => ({
      ...prevState,
      serialNumber: result,
      startValidate: true,
    }));
  };

  // passport given date
  const [givenDateInputs, setGivenDateInputs] = useState({
    givenDate: "",
    rule: "required",
    startValidate: false,
  });
  let validationGivenDate = new Validator(
    { givenDate: givenDateInputs.givenDate },
    { givenDate: givenDateInputs.rule }
  );
  const handleGivenDate = (e) => {
    setGivenDateInputs((prevState) => ({
      ...prevState,
      givenDate: e.target.value,
      startValidate: true,
    }));
  };

  // passport due date
  const [dueDateInputs, setDueDateInputs] = useState({
    dueDate: "",
    rule: "required",
    startValidate: false,
  });
  let validationDueDate = new Validator(
    { dueDate: dueDateInputs.dueDate },
    { dueDate: dueDateInputs.rule }
  );
  const handleDueDate = (e) => {
    setDueDateInputs((prevState) => ({
      ...prevState,
      dueDate: e.target.value,
      startValidate: true,
    }));
  };

  // passport department
  const [passportDepartmentInputs, setpassportDepartmentInputs] = useState({
    department: "",
    rule: "required",
    startValidate: false,
  });
  let validationDepartment = new Validator(
    { department: passportDepartmentInputs.department },
    { department: passportDepartmentInputs.rule }
  );
  const handlePassportDepartment = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 " ' " "]/gi, "");
    setpassportDepartmentInputs((prevState) => ({
      ...prevState,
      department: result,
      startValidate: true,
    }));
  };
  // ========== PASSPORT DATA PARENT START ==========
  // passport serial code
  const [passportSerialCodeParentInputs, setPassportSerialCodeParentInputs] =
    useState({
      serialNumber: "",
      rule: "required",
      startValidate: false,
    });
  let validationSerialCodeParent = new Validator(
    { serialNumber: passportSerialCodeParentInputs.serialNumber },
    { serialNumber: passportSerialCodeParentInputs.rule }
  );
  const handlePassportSerailCodeParent = (e) => {
    e.target.maxLength = 2;
    const result = e.target.value.toUpperCase().replace(/[^A-Z]/gi, "");

    setPassportSerialCodeParentInputs((prevState) => ({
      ...prevState,
      serialNumber: result,
      startValidate: true,
    }));
  };
  // passport serial number parent
  const [passportSerialParentInputs, setPassportSerialParentInputs] = useState({
    serialNumber: "",
    rule: "required",
    startValidate: false,
  });
  let validationSerialNumberParent = new Validator(
    { serialNumber: passportSerialParentInputs.serialNumber },
    { serialNumber: passportSerialParentInputs.rule }
  );
  const handlePassportSerailParent = (e) => {
    e.target.maxLength = 7;
    const result = e.target.value.toUpperCase().replace(/[^0-9]/gi, "");
    setPassportSerialParentInputs((prevState) => ({
      ...prevState,
      serialNumber: result,
      startValidate: true,
    }));
  };

  // passport given date
  const [givenDateParentInputs, setGivenDateParentInputs] = useState({
    givenDate: "",
    rule: "required",
    startValidate: false,
  });
  let validationGivenDateParent = new Validator(
    { givenDate: givenDateParentInputs.givenDate },
    { givenDate: givenDateParentInputs.rule }
  );
  const handleGivenDateParent = (e) => {
    setGivenDateParentInputs((prevState) => ({
      ...prevState,
      givenDate: e.target.value,
      startValidate: true,
    }));
  };

  // passport due date
  const [dueDateParentInputs, setDueDateParentInputs] = useState({
    dueDate: "",
    rule: "required",
    startValidate: false,
  });
  let validationDueDateParent = new Validator(
    { dueDate: dueDateParentInputs.dueDate },
    { dueDate: dueDateParentInputs.rule }
  );
  const handleDueDateParent = (e) => {
    setDueDateParentInputs((prevState) => ({
      ...prevState,
      dueDate: e.target.value,
      startValidate: true,
    }));
  };

  // passport department
  const [passportDepartmentParentInputs, setPassportDepartmentParentInputs] =
    useState({
      department: "",
      rule: "required",
      startValidate: false,
    });
  let validationDepartmentParent = new Validator(
    { department: passportDepartmentParentInputs.department },
    { department: passportDepartmentParentInputs.rule }
  );
  const handlePassportDepartmentParent = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 " ' " "]/gi, "");
    setPassportDepartmentParentInputs((prevState) => ({
      ...prevState,
      department: result,
      startValidate: true,
    }));
  };

  // firstname parent
  const [firstnameParentInputs, setFirstnameParentInputs] = useState({
    firstname: "",
    rule: "required",
    startValidate: false,
  });
  let validationFirstnameParent = new Validator(
    { firstname: firstnameParentInputs.firstname },
    { firstname: firstnameParentInputs.rule }
  );
  const handleFirstnameParent = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setFirstnameParentInputs((prevState) => ({
      ...prevState,
      firstname: result,
      startValidate: true,
    }));
  };
  //   lastname
  const [lastnameParentInputs, setLastnameParentInputs] = useState({
    lastname: "",
    rule: "required",
    startValidate: false,
  });
  let validationLastnameParent = new Validator(
    { lastname: lastnameParentInputs.lastname },
    { lastname: lastnameParentInputs.rule }
  );
  const handleLastnameParent = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setLastnameParentInputs((prevState) => ({
      ...prevState,
      lastname: result,
      startValidate: true,
    }));
  };
  //   middlename
  const [middlenameParentInputs, setMiddlenameParentInputs] = useState({
    middlename: "",
    rule: "required",
    startValidate: false,
  });
  let validationMiddlenameParent = new Validator(
    { middlename: middlenameParentInputs.middlename },
    { middlename: middlenameParentInputs.rule }
  );
  const handleMiddlenameParent = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setMiddlenameParentInputs((prevState) => ({
      ...prevState,
      middlename: result,
      startValidate: true,
    }));
  };

  // ========== PASSPORT DATA PARENT END ==========

  // course image
  const [imageInputs, setImageInputs] = useState({
    file: "",
    preview: "",
    error: "",
  });

  const handleCourseImage = (e) => {
    setImageInputs((prevState) => ({
      ...prevState,
      file: "",
      preview: "",
      error: "",
    }));
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      var extension = file.name.split(".").pop().toLowerCase();
      var isSuccess = ["jpg", "jpeg", "png"].indexOf(extension) > -1;
    }
    if (isSuccess) {
      reader.onloadend = () => {
        setImageInputs((prevState) => ({
          ...prevState,
          file: file,
          preview: reader.result,
        }));
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      console.log("error img");
      setImageInputs((prevState) => ({
        ...prevState,
        error: "Wrong format",
      }));
    }
  };

  // open and close modal
  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
    setChangeBtn({ change: "" });
    setEmailTaken(false);
    setDisableBtn(false);
    setSocieCourse(false);
    setEmailInputs((prevState) => ({
      ...prevState,
      email: "",
      startValidate: false,
    }));
    setPasswordInputs((prevState) => ({
      ...prevState,
      password: "",
      startValidate: false,
    }));
    setFirstnameInputs((prevState) => ({
      ...prevState,
      firstname: "",
      startValidate: false,
    }));
    setLastnameInputs((prevState) => ({
      ...prevState,
      lastname: "",
      startValidate: false,
    }));
    setMiddlenameInputs((prevState) => ({
      ...prevState,
      middlename: "",
      startValidate: false,
    }));
    setGenderInputs((prevState) => ({
      ...prevState,
      gender: "",
      startValidate: false,
    }));
    setBirthdayInputs((prevState) => ({
      ...prevState,
      birthday: "",
      startValidate: false,
    }));
    setRegionInputs((prevState) => ({
      ...prevState,
      regionId: "",
      startValidate: false,
    }));
    setDistrictInputs((prevState) => ({
      ...prevState,
      districtId: "",
      startValidate: false,
    }));
    setPhoneInputs((prevState) => ({
      ...prevState,
      phone: "",
      startValidate: false,
    }));
    setCourseInputs((prevState) => ({
      ...prevState,
      course: "",
      startValidate: false,
    }));
    setCourseInputs((prevState) => ({
      ...prevState,
      gender: "",
      startValidate: false,
    }));
    setAddressInputs((prevState) => ({
      ...prevState,
      address: "",
      startValidate: false,
    }));
    setImageInputs((prevState) => ({
      ...prevState,
      file: "",
      preview: "",
      error: "",
    }));
    setPassportSerialCodeInputs((prevState) => ({
      ...prevState,
      serialNumber: "",
      startValidate: false,
    }));
    setPassportSerialInputs((prevState) => ({
      ...prevState,
      serialNumber: "",
      startValidate: false,
    }));
    setGivenDateInputs((prevState) => ({
      ...prevState,
      givenDate: "",
      startValidate: false,
    }));
    setDueDateInputs((prevState) => ({
      ...prevState,
      dueDate: "",
      startValidate: false,
    }));
    setpassportDepartmentInputs((prevState) => ({
      ...prevState,
      department: "",
      startValidate: false,
    }));
    setPassportSerialCodeParentInputs((prevState) => ({
      ...prevState,
      serialNumber: "",
      startValidate: false,
    }));
    setPassportSerialParentInputs((prevState) => ({
      ...prevState,
      serialNumber: "",
      startValidate: false,
    }));
    setGivenDateParentInputs((prevState) => ({
      ...prevState,
      givenDate: "",
      startValidate: false,
    }));
    setDueDateParentInputs((prevState) => ({
      ...prevState,
      dueDate: "",
      startValidate: false,
    }));
    setPassportDepartmentParentInputs((prevState) => ({
      ...prevState,
      department: "",
      startValidate: false,
    }));
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const openModalForCreate = () => {
    setOpen(true);
    setChangeBtn({ change: "create" });
  };

  const [studentID, setStudentID] = useState();
  const [studentDeleteId, setStudentDeleteId] = useState();
  const [studentParentId, setStudentParentId] = useState();
  const [childEmail, setchildEmail] = useState();
  const openModalForEdit = (e, child) => {
    setStudentParentId(child?.parent?.id);
    e.preventDefault();
    setOpen(true);
    setChangeBtn({ change: "edit" });
    setStudentID(child.id);
    setchildEmail(child.email);
    console.log(child);
    getUser(token, child?.parent?.id).then((res) => {
      setParentInformation(res.data);
      console.log(res.data);
    });

    setEmailInputs((prevState) => ({
      ...prevState,
      email: child.email,
      prevEmail: child.email,
    }));
    setPasswordInputs((prevState) => ({
      ...prevState,
      password: "",
    }));
    setFirstnameInputs((prevState) => ({
      ...prevState,
      firstname: child.first_name,
    }));
    setMiddlenameInputs((prevState) => ({
      ...prevState,
      middlename: child.middle_name,
    }));
    setLastnameInputs((prevState) => ({
      ...prevState,
      lastname: child.last_name,
    }));
    setFirstnameParentInputs((prevState) => ({
      ...prevState,
      firstname: child.parent.first_name,
    }));
    setMiddlenameParentInputs((prevState) => ({
      ...prevState,
      middlename:
        child.parent.middle_name == null ? "" : child.parent.middle_name,
    }));
    setLastnameParentInputs((prevState) => ({
      ...prevState,
      lastname: child.parent.last_name,
    }));
    setGenderInputs((prevState) => ({
      ...prevState,
      gender: child.gender,
    }));
    setBirthdayInputs((prevState) => ({
      ...prevState,
      birthday: moment(child.birth_date).format().substring(0, 10),
    }));
    setRegionInputs((prevState) => ({
      ...prevState,
      regionId: child.region.id,
    }));
    setDistrictInputs((prevState) => ({
      ...prevState,
      districtId: child.district.id,
    }));
    setAddressInputs((prevState) => ({
      ...prevState,
      address: child.address,
    }));
    setPhoneInputs((prevState) => ({
      ...prevState,
      phone: child.phone_number,
    }));
    setPassportSerialCodeInputs((prevState) => ({
      ...prevState,
      serialNumber: child.passport_serial_number.slice(0, 2),
    }));
    setPassportSerialInputs((prevState) => ({
      ...prevState,
      serialNumber: child.passport_serial_number.slice(2, 9),
    }));
    setGivenDateInputs((prevState) => ({
      ...prevState,
      givenDate: moment(child.passport_given_date).format().substring(0, 10),
    }));
    setDueDateInputs((prevState) => ({
      ...prevState,
      dueDate: moment(child.passport_due_date).format().substring(0, 10),
    }));
    setpassportDepartmentInputs((prevState) => ({
      ...prevState,
      department: child.passport_department,
    }));
    setPassportSerialCodeParentInputs((prevState) => ({
      ...prevState,
      serialNumber: child.parent.passport_serial_number.slice(0, 2) || "",
    }));
    setPassportSerialParentInputs((prevState) => ({
      ...prevState,
      serialNumber: child.parent.passport_serial_number.slice(2, 9) || "",
    }));
    setGivenDateParentInputs((prevState) => ({
      ...prevState,
      givenDate: child.parent.passport_given_date
        ? moment(child.parent.passport_given_date).format().substring(0, 10)
        : "",
    }));
    console.log(child.parent.passport_given_date);
    console.log(child.parent.passport_due_date);
    setDueDateParentInputs((prevState) => ({
      ...prevState,
      dueDate: child.parent.passport_due_date
        ? moment(child.parent.passport_due_date).format().substring(0, 10)
        : "",
    }));
    setPassportDepartmentParentInputs((prevState) => ({
      ...prevState,
      department: child.parent.passport_department || "",
    }));
    getUser(token, child?.parent?.id).then((res) => {
      // console.log(res);
      setRegionParentInputs((prevState) => ({
        ...prevState,
        regionId: res.data.region ? res.data.region.id : "",
      }));
      setDistrictParentInputs((prevState) => ({
        ...prevState,
        districtId: res.data.district ? res.data.district.id : "",
      }));
    });
    setAddressParentInputs((prevState) => ({
      ...prevState,
      address: child?.parent?.address || "",
    }));
    setPhoneParentInputs((prevState) => ({
      ...prevState,
      phone: child?.parent?.phone_number || "",
    }));
    // console.log(res);

    if (child.passport_image == null) {
      setImageInputs((prevState) => ({
        ...prevState,
        preview: "",
        previewChange: "",
      }));
    } else {
      setImageInputs((prevState) => ({
        ...prevState,
        preview: `${process.env.REACT_APP_PRE_UNI_API}/users/${child.id}/passport`,
        previewChange: `${process.env.REACT_APP_PRE_UNI_API}/users/${child.id}/passport`,
      }));
    }
  };
  const deleteStudentBtn = () => {
    deleteStudent(token, studentDeleteId)
      .then((res) => {
        if (res.status === 200) {
          setOpenDelete(false);
          const newArr = allStudents.filter((el) => el.id !== studentDeleteId);
          setAllStudents((prevState) => newArr);
          NotificationManager.success(`${t("admin.notifDeletedSuccGr")}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const openModalForDelete = (e, child) => {
    setStudentDeleteId(child);
    setOpenDelete(true);
    console.log(child);
  };

  // stepper
  const [activeStep, setActiveStep] = useState(0);

  const nextButton = (e) => {
    console.log(childEmail);
    e.preventDefault();
    if (
      validationEmail.passes() &&
      validationFirstname.passes() &&
      validationLastname.passes() &&
      validationMiddlename.passes() &&
      validationBirthday.passes() &&
      emailTaken === false
    ) {
      if (
        changeBtn.change == "create" &&
        validationPassword.passes() &&
        validationEmail.passes()
      ) {
        setActiveStep(1);
      }
      if (changeBtn.change == "edit") {
        setActiveStep(1);
      }
    } else {
      if (changeBtn.change == "create") {
        setEmailInputs((prevState) => ({
          ...prevState,
          startValidate: true,
        }));
      }
      if (changeBtn.change == "create") {
        setPasswordInputs((prevState) => ({
          ...prevState,
          startValidate: true,
        }));
      }
      setFirstnameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setLastnameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setMiddlenameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setGenderInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setBirthdayInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };
  const nextButton2 = (e) => {
    e.preventDefault();
    if (
      validationGender.passes() &&
      validationRegion.passes() &&
      validationDistrict.passes() &&
      validationAddress.passes() &&
      validationPhone.passes() &&
      emailTaken === false
    ) {
      setActiveStep(2);
    } else {
      setRegionInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setDistrictInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setAddressInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPhoneInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };
  const nextButton3 = (e) => {
    e.preventDefault();
    if (
      validationSerialCode.passes() &&
      validationSerialNumber.passes() &&
      validationGivenDate.passes() &&
      validationDepartment.passes() &&
      emailTaken === false
    ) {
      setActiveStep(3);
    } else {
      setPassportSerialCodeInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPassportSerialInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setGivenDateInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setpassportDepartmentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };
  const nextButton4 = (e) => {
    e.preventDefault();
    if (validationCourse.passes()) {
      setActiveStep(4);
    } else {
      setCourseInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  const createStudentBtn = (e) => {
    e.preventDefault();
    if (
      validationEmail.passes() &&
      validationPassword.passes() &&
      validationFirstname.passes() &&
      validationLastname.passes() &&
      validationBirthday.passes() &&
      validationGender.passes() &&
      validationRegion.passes() &&
      validationDistrict.passes() &&
      validationAddress.passes() &&
      validationPhone.passes()
    ) {
      const data = {
        email: emailInputs.email,
        password: passwordInputs.password,
        first_name: firstnameInputs.firstname,
        last_name: lastnameInputs.lastname,
        gender: genderInputs.gender,
        birth_date: birthdayInputs.birthday,
        regionId: regionInputs.regionId,
        districtId: districtInputs.districtId,
        address: addressInputs.address,
        phone_number: phoneInputs.phone,
      };
      console.log(data);
      createStudent(token, data).then((res) => {
        if (res.status === 201) {
          handleClose();
          NotificationManager.success(`${t("admin.notifCreatedSuccSt")}`);
          setAllStudents((prevState) => [res.data, ...prevState]);
        }
      });
    } else {
      setEmailInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPasswordInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setFirstnameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setLastnameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setGenderInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setBirthdayInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setRegionInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setDistrictInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setAddressInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPhoneInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  const updateStudentBtn = (e) => {
    console.log(parentInformation);
    e.preventDefault();
    if (
      validationSerialCodeParent.passes() &&
      validationFirstnameParent.passes() &&
      validationLastnameParent.passes() &&
      validationMiddlenameParent.passes() &&
      validationSerialNumberParent.passes() &&
      validationGivenDateParent.passes() &&
      validationDepartmentParent.passes() &&
      validationRegionParent.passes() &&
      validationDistrictParent.passes() &&
      validationParentAddress.passes() &&
      validationParentPhone.passes() &&
      validationEmail.passes()
    ) {
      const data = {
        email: emailInputs.email,
        first_name: firstnameInputs.firstname,
        last_name: lastnameInputs.lastname,
        middle_name: middlenameInputs.middlename,
        gender: genderInputs.gender,
        birth_date: birthdayInputs.birthday,
        regionId: regionInputs.regionId,
        districtId: districtInputs.districtId,
        address: addressInputs.address,
        phone_number: phoneInputs.phone,
        passport_serial_number: `${passportSerialCodeInputs.serialNumber}${passportSerialInputs.serialNumber}`,
        passport_given_date: givenDateInputs.givenDate,
        passport_due_date: dueDateInputs.dueDate,
        passport_department: passportDepartmentInputs.department,
      };
      console.log(data);
      const dataParent = {
        first_name: firstnameParentInputs.firstname,
        last_name: lastnameParentInputs.lastname,
        middle_name: middlenameParentInputs.middlename,
        gender: parentInformation?.gender,
        birth_date: parentInformation?.birth_date,
        regionId: regionParentInputs.regionId,
        districtId: districtParentInputs.districtId,
        address: addressParentInputs.address,
        phone_number: phoneParentInputs.phone,
        passport_serial_number: `${passportSerialCodeParentInputs.serialNumber}${passportSerialParentInputs.serialNumber}`,
        passport_given_date: givenDateParentInputs?.givenDate,
        passport_due_date: dueDateParentInputs?.dueDate,
        passport_department: passportDepartmentParentInputs.department,
      };
      console.log(dataParent.passport_given_date);
      console.log(dataParent.passport_due_date);

      if (passwordInputs.password !== "") {
        data.password = passwordInputs.password;
      }
      if (studentID) {
        setDisableBtn(true);
        setLoader(true);
        createParentPassport(token, studentParentId, dataParent).then((res) => {
          console.log(res);
        });
        updateStudent(token, studentID, data).then((res) => {
          if (res.status === 200) {
            console.log(res);
            getContract(studentID, token).then((res) => {});
            if (imageInputs.preview !== imageInputs.previewChange) {
              createImage(token, imageInputs.file, studentID).then((res) => {
                console.log(res);
              });
            }

            const regionIndex = regionOptions.filter(
              (el) => el.id === data.regionId
            );
            const districtIndex = districtOptions.filter(
              (el) => el.id === data.districtId
            );
            const newData = {
              ...data,
              id: studentID,
              region: regionIndex[0],
              district: districtIndex[0],
            };
            const newArr = allStudents.filter((el) => el.id !== studentID);
            const newSortedArr = [...newArr, newData].sort(function (a, b) {
              return a.id - b.id;
            });

            handleClose();
            NotificationManager.success(`${t("admin.notifUpdatedSuccSt")}`);
            setAllStudents(newSortedArr);
            setDisableBtn(false);

            window.location.reload();
          }
        });
      }
    } else {
      setEmailInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPassportSerialCodeParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPassportSerialParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setGivenDateParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPassportDepartmentParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setRegionParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setDistrictParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setAddressParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPhoneParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setFirstnameParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setLastnameParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setMiddlenameParentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  return (
    <div>
      <div className="tables-wrapper search-table-wrap">
        <Container maxWidth="lg">
          <Box mt={12} display="flex" justifyContent="flex-end">
            {/* <Button
                            variant="contained"
                            color="primary"
                            onClick={openModalForCreate}
                        >
                            Add student
                        </Button> */}
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={open}
              onClose={handleClose}
            >
              <div
                style={modalStyle}
                className={`${classes.paper} modalAddChild`}
              >
                <Box my={3} display="flex" justifyContent="space-between">
                  <Typography variant="h6">
                    {t("admin.updateStudent")}
                  </Typography>
                  <CloseIcon
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
                <Box mb={3}>
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    style={{ padding: 0 }}
                  >
                    <Step>
                      <StepLabel>{t("parent.step")}-1</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t("parent.step")}-2</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t("parent.step")}-3</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t("parent.step")}-4</StepLabel>
                    </Step>
                  </Stepper>
                </Box>
                <Box>
                  <h5 style={{ textAlign: "center" }}>
                    {activeStep == 0
                      ? `${t("parent.studentInfo")}`
                      : activeStep == 1
                      ? `${t("parent.studentAddress")}`
                      : activeStep == 2
                      ? `${t("parent.studentPassport")}`
                      : activeStep == 3
                      ? `${t("admin.parrentPassport")}`
                      : `${t("parent.parentPassport")}`}
                  </h5>
                </Box>
                {activeStep === 0 && (
                  <Box>
                    {/*Email*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="email"
                          variant="outlined"
                          className="outlined-input"
                          placeholder={t("parent.emailPlaceholder")}
                          label={`${t("parent.email")}:`}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={emailInputs.email}
                          onChange={(e) => handleEmail(e)}
                          error={
                            (emailInputs.startValidate &&
                              (validationEmail.passes() === true
                                ? false
                                : true)) ||
                            (emailTaken === true ? true : false)
                          }
                        />
                        <FormHelperText>
                          {emailInputs.startValidate &&
                            validationEmail.errors.first("email")}
                        </FormHelperText>
                        <FormHelperText>
                          {emailTaken && "The email is already taken."}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    {/*Pass*/}
                    {/* <Box my={3}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        size="small"
                        error={
                          passwordInputs.startValidate &&
                          (validationPassword.passes() === true ? false : true)
                        }
                      >
                        <InputLabel
                          htmlFor="standard-adornment-password"
                          shrink={true}
                        >
                          {t("parent.password")}:
                        </InputLabel>
                        <OutlinedInput
                          id="standard-adornment-password"
                          required
                          fullWidth
                          labelWidth={70}
                          placeholder={`${t("parent.passPlaceholder")}`}
                          type={showPassword ? "text" : "password"}
                          value={passwordInputs.password}
                          onChange={(e) => handlePassword(e)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText style={{ marginLeft: 0 }}>
                          {passwordInputs.startValidate &&
                            validationPassword.errors.first("password")}
                        </FormHelperText>
                      </FormControl>
                    </Box> */}
                    {/*First Name*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.firsname")}: `}
                          placeholder={`${t("parent.firsnamePlaceholder")}`}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={firstnameInputs.firstname}
                          onChange={(e) => handleFirstname(e)}
                          error={
                            firstnameInputs.startValidate &&
                            (validationFirstname.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {firstnameInputs.startValidate &&
                            validationFirstname.errors.first("firstname")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Last Name*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.lastName")}: `}
                          placeholder={t("registerForm.lastnamePlaceholder")}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={lastnameInputs.lastname}
                          onChange={(e) => handleLastname(e)}
                          error={
                            lastnameInputs.startValidate &&
                            (validationLastname.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {lastnameInputs.startValidate &&
                            validationLastname.errors.first("lastname")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Middle Name*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.middleName")}: `}
                          placeholder={t("registerForm.middlenamePlaceholder")}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={middlenameInputs.middlename}
                          onChange={(e) => handleMiddlename(e)}
                          error={
                            middlenameInputs.startValidate &&
                            (validationMiddlename.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {middlenameInputs.startValidate &&
                            validationMiddlename.errors.first("lastname")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Gender*/}
                    <Box my={3}>
                      <FormControl
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                        error={
                          genderInputs.startValidate &&
                          (validationGender.passes() === true ? false : true)
                        }
                      >
                        <InputLabel id="select-gender-label" shrink={true}>
                          {t("parent.selectGender")}:
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="select-gender-label"
                          label="Select gender: "
                          variant="outlined"
                          value={genderInputs.gender}
                          onChange={(e) => handleGender(e)}
                        >
                          <MenuItem value="male">{t("parent.male")}</MenuItem>
                          <MenuItem value="female">
                            {t("parent.female")}
                          </MenuItem>
                        </Select>
                        <FormHelperText style={{ marginLeft: "0" }}>
                          {genderInputs.startValidate &&
                            validationGender.errors.first("gender")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Birthday*/}
                    <Box my={3}>
                      <FormControl fullWidth error>
                        <TextField
                          required
                          fullWidth
                          type="date"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.birthday")}:`}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={birthdayInputs.birthday}
                          onChange={(e) => handleBirthday(e)}
                          error={
                            birthdayInputs.startValidate &&
                            (validationBirthday.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText style={{ marginLeft: 0 }}>
                          {birthdayInputs.startValidate &&
                            validationBirthday.errors.first("birthday")}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <Box my={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => nextButton(e)}
                      >
                        {t("parent.next")}
                      </Button>
                    </Box>
                  </Box>
                )}
                {activeStep === 1 && (
                  <Box>
                    {/*Regions*/}
                    <Box my={3}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        size="small"
                        error={
                          regionInputs.startValidate &&
                          (validationRegion.passes() === true ? false : true)
                        }
                      >
                        <InputLabel id="select-region-label" shrink={true}>
                          {t("parent.selectRegion")}:
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="select-region-label"
                          label="Select region:"
                          variant="outlined"
                          value={regionInputs.regionId}
                          onChange={(e) => handleRegion(e)}
                        >
                          {regionOptions &&
                            regionOptions.map((option, index) => (
                              <MenuItem key={index} value={option.id}>
                                {option.name_en}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText style={{ marginLeft: 0 }}>
                          {regionInputs.startValidate &&
                            validationRegion.errors.first("regionId")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*District*/}
                    <Box my={3}>
                      <FormControl
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                        error={
                          districtInputs.startValidate &&
                          (validationDistrict.passes() === true ? false : true)
                        }
                      >
                        <InputLabel id="select-district-label" shrink={true}>
                          {t("parent.selectDistrict")}:
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="select-district-label"
                          label={`${t("parent.selectRegion")}:`}
                          variant="outlined"
                          value={districtInputs.districtId}
                          onChange={(e) => handleDistrict(e)}
                        >
                          {districtOptions &&
                            districtOptions.map((option, index) => (
                              <MenuItem key={index} value={option.id}>
                                {option.name_en}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText style={{ marginLeft: 0 }}>
                          {districtInputs.startValidate &&
                            validationDistrict.errors.first("districtId")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Address*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.address")}:`}
                          placeholder={t("parent.addressPlaceholder")}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={addressInputs.address}
                          onChange={(e) => handleAddress(e)}
                          error={
                            addressInputs.startValidate &&
                            (validationAddress.passes() === true ? false : true)
                          }
                        />
                        <FormHelperText>
                          {addressInputs.startValidate &&
                            validationAddress.errors.first("address")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Phone Number*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.phone")}:`}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          placeholder="998"
                          value={`${phoneInputs.phone}`}
                          onChange={(e) => handlePhone(e)}
                          error={
                            phoneInputs.startValidate &&
                            (validationPhone.passes() === true ? false : true)
                          }
                        />
                        <FormHelperText>
                          {phoneInputs.startValidate &&
                            validationPhone.errors.first("phone")}
                        </FormHelperText>
                      </FormControl>

                      <Box my={3}>
                        <Button
                          style={{ marginRight: "10px" }}
                          onClick={() => setActiveStep(0)}
                        >
                          {t("parent.back")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => nextButton2(e)}
                        >
                          {t("parent.next")}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}
                {activeStep === 2 && (
                  <Box>
                    {/*Pass serial number*/}
                    <Box my={3} style={{ display: "flex" }}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.passportSerial")}:`}
                          placeholder={t("parent.passportSerialPlaceh")}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={passportSerialCodeInputs.serialNumber}
                          onChange={(e) => handlePassportSerailCode(e)}
                          error={
                            passportSerialCodeInputs.startValidate &&
                            (validationSerialCode.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {passportSerialCodeInputs.startValidate &&
                            validationSerialCode.errors.first(
                              "passport serial"
                            )}
                        </FormHelperText>
                      </FormControl>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.passportSerialNum")}:`}
                          placeholder={t("parent.passportSerialNumPlaceh")}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={passportSerialInputs.serialNumber}
                          onChange={(e) => handlePassportSerail(e)}
                          error={
                            passportSerialInputs.startValidate &&
                            (validationSerialNumber.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {passportSerialInputs.startValidate &&
                            validationSerialNumber.errors.first(
                              "passport serial number"
                            )}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Pass given date*/}
                    <Box my={3}>
                      <FormControl fullWidth error>
                        <TextField
                          required
                          fullWidth
                          type="date"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={t("parent.passportGiven")}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={givenDateInputs.givenDate}
                          onChange={(e) => handleGivenDate(e)}
                          error={
                            givenDateInputs.startValidate &&
                            (validationGivenDate.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText style={{ marginLeft: 0 }}>
                          {givenDateInputs.startValidate &&
                            validationGivenDate.errors.first("given date")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Pass due date*/}
                    <Box my={3}>
                      <FormControl fullWidth error>
                        <TextField
                          required
                          fullWidth
                          type="date"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={t("parent.passportDue")}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={dueDateInputs.dueDate}
                          onChange={(e) => handleDueDate(e)}
                          error={
                            dueDateInputs.startValidate &&
                            (validationDueDate.passes() === true ? false : true)
                          }
                        />
                        <FormHelperText style={{ marginLeft: 0 }}>
                          {dueDateInputs.startValidate &&
                            validationDueDate.errors.first("given date")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Pass department*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={t("parent.passportDeparment")}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={passportDepartmentInputs.department}
                          onChange={(e) => handlePassportDepartment(e)}
                          error={
                            passportDepartmentInputs.startValidate &&
                            (validationDepartment.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {passportDepartmentInputs.startValidate &&
                            validationDepartment.errors.first(
                              "passport department"
                            )}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/* Pass Image  */}
                    <Box mt={2} mb={1}>
                      <Typography variant="subtitle2">
                        {t("parent.uploadPassport")}:
                      </Typography>
                    </Box>
                    <Input
                      type="file"
                      accept="image/*"
                      id="courseImg"
                      style={{ display: "none" }}
                      onChange={handleCourseImage}
                    />
                    <label
                      htmlFor="courseImg"
                      style={{ width: "100%", cursor: "pointer" }}
                    >
                      <div
                        style={{
                          height: "200px",
                          backgroundColor: "#e3f2fd",
                          color: "#2196f3",
                          borderRadius: "8px",
                          fontSize: "24px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {imageInputs.preview ? (
                          <img
                            src={imageInputs.preview}
                            height={"100%"}
                            width={"100%"}
                            style={{ objectFit: "cover", borderRadius: "8px" }}
                          />
                        ) : (
                          "+"
                        )}
                      </div>
                    </label>
                    {imageInputs.error && (
                      <p style={{ color: "#e53935", fontSize: "11px" }}>
                        {imageInputs.error}
                      </p>
                    )}

                    <Box my={3}>
                      <Button
                        style={{ marginRight: "10px" }}
                        onClick={() => setActiveStep(1)}
                      >
                        {t("parent.back")}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={(e) => nextButton3(e)}
                        onClick={(e) => nextButton3(e)}
                      >
                        {t("parent.next")}
                      </Button>

                      {/* <Button
                      color="secondary"
                      variant="contained"
                      style={{ marginLeft: "10px" }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button> */}
                    </Box>
                  </Box>
                )}
                {activeStep === 3 && (
                  <Box>
                    {/*Pass serial number*/}
                    <Box my={3} style={{ display: "flex" }}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={t("parent.passportSerial")}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={passportSerialCodeParentInputs.serialNumber}
                          onChange={(e) => handlePassportSerailCodeParent(e)}
                          error={
                            passportSerialCodeParentInputs.startValidate &&
                            (validationSerialCodeParent.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {passportSerialCodeParentInputs.startValidate &&
                            validationSerialCodeParent.errors.first(
                              "passport serial"
                            )}
                        </FormHelperText>
                      </FormControl>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.passportSerialNum")}`}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={passportSerialParentInputs.serialNumber}
                          onChange={(e) => handlePassportSerailParent(e)}
                          error={
                            passportSerialParentInputs.startValidate &&
                            (validationSerialNumberParent.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {passportSerialParentInputs.startValidate &&
                            validationSerialNumberParent.errors.first(
                              "passport serial number"
                            )}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Pass given date*/}
                    <Box my={3}>
                      <FormControl fullWidth error>
                        <TextField
                          required
                          fullWidth
                          type="date"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.passportGiven")}:`}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={givenDateParentInputs.givenDate}
                          onChange={(e) => handleGivenDateParent(e)}
                          error={
                            givenDateParentInputs.startValidate &&
                            (validationGivenDateParent.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText style={{ marginLeft: 0 }}>
                          {givenDateParentInputs.startValidate &&
                            validationGivenDateParent.errors.first(
                              "given date"
                            )}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Pass due date*/}
                    <Box my={3}>
                      <FormControl fullWidth error>
                        <TextField
                          required
                          fullWidth
                          type="date"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.passportDue")}:`}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={dueDateParentInputs.dueDate}
                          onChange={(e) => handleDueDateParent(e)}
                          error={
                            dueDateParentInputs.startValidate &&
                            (validationDueDateParent.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText style={{ marginLeft: 0 }}>
                          {dueDateParentInputs.startValidate &&
                            validationDueDateParent.errors.first("given date")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Pass department*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.passportDeparment")}:`}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={passportDepartmentParentInputs.department}
                          onChange={(e) => handlePassportDepartmentParent(e)}
                          error={
                            passportDepartmentParentInputs.startValidate &&
                            (validationDepartmentParent.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {passportDepartmentParentInputs.startValidate &&
                            validationDepartmentParent.errors.first(
                              "passport department"
                            )}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <h5 style={{ textAlign: "center" }}>
                      {t("parent.parentPersonal")}
                    </h5>
                    {/*First Name*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.firsname")}: `}
                          placeholder={`${t("admin.parrentNamePlace")}: `}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={firstnameParentInputs.firstname}
                          onChange={(e) => handleFirstnameParent(e)}
                          error={
                            firstnameParentInputs.startValidate &&
                            (validationFirstnameParent.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {firstnameParentInputs.startValidate &&
                            validationFirstnameParent.errors.first("firstname")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Last Name*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("admin.lastName")}: `}
                          placeholder={`${t("admin.parrentLastPlace")}: `}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={lastnameParentInputs.lastname}
                          onChange={(e) => handleLastnameParent(e)}
                          error={
                            lastnameParentInputs.startValidate &&
                            (validationLastnameParent.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {lastnameParentInputs.startValidate &&
                            validationLastnameParent.errors.first("lastname")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Middle Name*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.middleName")}:`}
                          placeholder={`${t("admin.parrentMiddlePlace")}: `}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={middlenameParentInputs.middlename}
                          onChange={(e) => handleMiddlenameParent(e)}
                          error={
                            middlenameParentInputs.startValidate &&
                            (validationMiddlenameParent.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {middlenameParentInputs.startValidate &&
                            validationMiddlenameParent.errors.first("lastname")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    <h5 style={{ textAlign: "center" }}>
                      {t("parent.parentAddress")}
                    </h5>
                    {/*Regions*/}
                    <Box my={3}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        size="small"
                        error={
                          regionParentInputs.startValidate &&
                          (validationRegionParent.passes() === true
                            ? false
                            : true)
                        }
                      >
                        <InputLabel id="select-region-label" shrink={true}>
                          {t("parent.selectRegion")}:
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="select-region-label"
                          label="Select region:"
                          variant="outlined"
                          value={regionParentInputs.regionId}
                          onChange={(e) => handleParentRegion(e)}
                        >
                          {regionParentOptions &&
                            regionParentOptions.map((option, index) => (
                              <MenuItem key={index} value={option.id}>
                                {option.name_en}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText style={{ marginLeft: 0 }}>
                          {regionParentInputs.startValidate &&
                            validationRegionParent.errors.first("regionId")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*District*/}
                    <Box my={3}>
                      <FormControl
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                        error={
                          districtParentInputs.startValidate &&
                          (validationDistrictParent.passes() === true
                            ? false
                            : true)
                        }
                      >
                        <InputLabel id="select-district-label" shrink={true}>
                          {t("parent.selectDistrict")}:
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="select-district-label"
                          label="Select district:"
                          variant="outlined"
                          value={districtParentInputs.districtId}
                          onChange={(e) => handleDistrictParent(e)}
                        >
                          {districtParentOptions &&
                            districtParentOptions.map((option, index) => (
                              <MenuItem key={index} value={option.id}>
                                {option.name_en}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText style={{ marginLeft: 0 }}>
                          {districtInputs.startValidate &&
                            validationDistrict.errors.first("districtId")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Address*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.address")}:`}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          value={addressParentInputs.address}
                          onChange={(e) => handleAddressParent(e)}
                          error={
                            addressParentInputs.startValidate &&
                            (validationParentAddress.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {addressParentInputs.startValidate &&
                            validationParentAddress.errors.first("address")}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {/*Phone Number*/}
                    <Box my={3}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="outlined-required"
                          variant="outlined"
                          className="outlined-input"
                          label={`${t("parent.phone")}:`}
                          InputLabelProps={{
                            shrink: true,
                            pl: "2px",
                          }}
                          placeholder="998"
                          value={`${phoneParentInputs.phone}`}
                          onChange={(e) => handleParentPhone(e)}
                          error={
                            phoneParentInputs.startValidate &&
                            (validationParentPhone.passes() === true
                              ? false
                              : true)
                          }
                        />
                        <FormHelperText>
                          {phoneParentInputs.startValidate &&
                            validationParentPhone.errors.first("phone")}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <Box my={3}>
                      <Button
                        style={{ marginRight: "10px" }}
                        onClick={() => setActiveStep(2)}
                      >
                        {t("parent.back")}
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        disabled={disableBtn}
                        onClick={(e) => updateStudentBtn(e)}
                      >
                        {loader ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          `${t("parent.update")}`
                        )}
                      </Button>

                      {/* <Button
                      color="secondary"
                      variant="contained"
                      style={{ marginLeft: "10px" }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button> */}
                    </Box>
                  </Box>
                )}
              </div>
            </Modal>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={openDelete}
              onClose={handleCloseDelete}
            >
              <div style={modalStyle} className={`${classes.paperDelete}`}>
                <h1 style={{ textAlign: "center" }}>
                  {t("admin.agreeDelete")}
                </h1>

                <Box
                  my={3}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "20px" }}
                    onClick={(e) => setOpenDelete(false)}
                  >
                    {t("admin.no")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#dc3545" }}
                    onClick={(e) => deleteStudentBtn(e)}
                  >
                    {t("admin.yes")}
                  </Button>
                </Box>
              </div>
            </Modal>
          </Box>
          <Box px={{ xs: "12px", lg: 0 }} pt={4} className="page-space">
            {/*Search Name*/}
            <Box
              py={2}
              px={2}
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
              }}
            >
              <FormControl error fullWidth>
                <TextField
                  required
                  fullWidth
                  type="text"
                  id="outlined-required"
                  variant="outlined"
                  className="outlined-input"
                  placeholder={`${t("parent.firsnamePlaceholder")}:`}
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={searchStudentInputs.fullname}
                  onChange={(e) => handleSearchStudent(e)}
                  onKeyDown={(e) => getKeyCode(e)}
                  error={
                    searchStudentInputs.startValidate &&
                    (validationSearchStudent.passes() === true ? false : true)
                  }
                />
                <FormHelperText>
                  {searchStudentInputs.startValidate &&
                    validationSearchStudent.errors.first("fullname")}
                </FormHelperText>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "20px" }}
                onClick={(e) => searchStudentBtn(e)}
              >
                {t("searchPlaceholder")}
              </Button>
            </Box>
            {attendanceErr ? (
              <div className="alert alert-warning" role="alert">
                {t("admin.studentNotFound")}
              </div>
            ) : (
              ""
            )}
            <MaterialTable
              title={`${t("admin.allStudents")}:`}
              columns={columns}
              data={allStudents}
              actions={actions}
              localization={{
                header: {
                  actions: `${t("admin.actions")}`,
                },
              }}
              options={{
                actionsColumnIndex: -1,
                paging: false,
                search: false,
              }}
            />
            {disablePagin && (
              <Box my={2}>
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end align-items-center">
                    <span className="me-2">{`${
                      currentPage + allStudents?.length
                    } of ${studentsLength && studentsLength}`}</span>
                    <li
                      className="page-item"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => prevPage(e)}
                      disabled={disabledPage}
                    >
                      <a
                        className="page-link"
                        aria-label="Previous"
                        style={
                          disabledPage
                            ? { backgroundColor: "#e9ecef" }
                            : { position: "relative" }
                        }
                      >
                        <span aria-hidden="true">«</span>
                      </a>
                    </li>

                    <li
                      className="page-item"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => nextPage(e)}
                      disabled={disabledPageNext}
                    >
                      <a
                        className="page-link"
                        aria-label="Next"
                        style={
                          disabledPageNext
                            ? { backgroundColor: "#e9ecef" }
                            : { position: "relative" }
                        }
                      >
                        <span aria-hidden="true">»</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </Box>
            )}
          </Box>

          {/* <Box px={{ xs: "12px", lg: 0 }} pt={4} className="page-space">
            <MaterialTable
              title="All students"
              columns={columnsCourse}
              data={studentsResult}
              actions={actionsCourse}
              options={{
                actionsColumnIndex: -1,
              }}
            />
          </Box> */}

          {/* <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Firs Name</TableCell>
                  <TableCell align="right">Last Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Phone</TableCell>
                  <TableCell align="right">Courses</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allStudents?.length > 0
                  ? allStudents?.map((item, index) => {
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item?.courses?.course?.name_en}
                        </TableCell>
                        <TableCell align="right">calories</TableCell>
                        <TableCell align="right">fat</TableCell>
                        <TableCell align="right">carbs</TableCell>
                        <TableCell align="right">protein</TableCell>
                      </TableRow>;
                    })
                  : "No students applied in the course were found"}
              </TableBody>
            </Table>
          </TableContainer> */}
        </Container>
      </div>
    </div>
  );
}

export default AdminAllStudents;
