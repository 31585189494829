import React, { Fragment, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Validator from "validatorjs";
import { NotificationManager } from "react-notifications";
import MaterialTable from "material-table";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import moment from "moment";
import styles from "./createLesson.module.scss";
import {
  MenuItem,
  Select,
  InputLabel,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import {
  createLesson,
  getLessonsByGroupId,
  updateLesson,
} from "fetch/teacher/groups/apies";
import { t } from "i18next";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },
}));

function CreateLesson(props) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const groupId = props.groupId;
  const groupName = props.groupName;

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const navigateBtn = (e, rowData) => {
    e.preventDefault();
    props.history.push(
      `${
        process.env.PUBLIC_URL
      }/app/teacher/groups/${groupId}/${encodeURIComponent(groupName)}/${
        rowData.id
      }/${rowData.startDate}`
    );
  };

  const [allLessons, setAllLessons] = useState();

  // All lessons
  useEffect(() => {
    if (groupId) {
      getLessonsByGroupId(token, groupId).then((res) => {
        setAllLessons(res?.data?.reverse());
        console.log(res.data);
      });
    }
  }, [groupId]);
  function formatDate(date) {
    let newDate = date.split("-");

    return `${newDate[1]}-${newDate[2]}-${newDate[0]}`;
  }
  // Lesson table
  const columns = [
    { title: t("admin.name"), field: "name" },
    {
      title: t("teacher.date"),
      field: "startDate",
      render: (rowData) => (
        <p>
          {moment(rowData.startDate?.substring(0, 10)).format("DD-MM-YYYY")}
        </p>
      ),
    },
  ];

  const actions = [
    {
      icon: "edit",
      tooltip: "Edit Lesson",
      onClick: (e, rowData) => openModalForEdit(e, rowData),
    },
    {
      icon: () => <HowToRegIcon />,
      tooltip: "Take Attendance",
      onClick: (e, rowData) => navigateBtn(e, rowData),
    },
  ];

  // Modal
  const [open, setOpen] = useState(false);
  const [changeBtn, setChangeBtn] = useState({ change: "" });

  // Form
  // Lesson name
  const [lessonNameInputs, setLessonNameInputs] = useState({
    name: "",
    rule: "required",
    startValidate: false,
  });

  const validationLessonName = new Validator(
    { name: lessonNameInputs.name },
    { name: lessonNameInputs.rule }
  );
  const handleLessonName = (e) => {
    setLessonNameInputs((prevState) => ({
      ...prevState,
      name: e.target.value,
      startValidate: true,
    }));
  };

  // isExam
  const [isExamInputs, setIsExamInputs] = useState({
    isExam: "",
    rule: "required",
    startValidate: false,
  });
  let validationIsExam = new Validator(
    { isExam: isExamInputs.isExam },
    { isExam: isExamInputs.rule }
  );
  const handleIsExam = (e) => {
    setIsExamInputs((prevState) => ({
      ...prevState,
      isExam: e.target.value,
      startValidate: true,
    }));
  };
  function formatDate(date) {
    let newDate = date.split("-");

    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
  }
  // Start date
  const [startDateInputs, setStartDateInputs] = useState({
    startDate: "",
    rule: "required",
    startValidate: false,
  });

  const validationStartDate = new Validator(
    { startDate: startDateInputs.startDate },
    { startDate: startDateInputs.rule }
  );

  const handleStartDate = (e) => {
    setStartDateInputs((prevState) => ({
      ...prevState,
      startDate: e.target.value,
      startValidate: true,
    }));
  };

  // Modal open and close
  const openModalForCreate = () => {
    setChangeBtn({ change: "create" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setChangeBtn({ change: "" });
    setLessonNameInputs((prevState) => ({
      ...prevState,
      name: "",
      startValidate: false,
    }));
    setIsExamInputs((prevState) => ({
      ...prevState,
      isExam: "",
      startValidate: false,
    }));
    setStartDateInputs((prevState) => ({
      ...prevState,
      startDate: "",
      startValidate: false,
    }));
  };

  const createLessonBtn = () => {
    if (
      validationLessonName.passes() &&
      validationIsExam.passes() &&
      validationStartDate.passes()
    ) {
      const data = {
        name: lessonNameInputs.name,
        startDate: startDateInputs.startDate,
        groupId: Number(groupId),
        isExam: isExamInputs.isExam == "exam" ? true : false,
      };

      createLesson(token, data).then((res) => {
        handleClose();
        NotificationManager.success("Lesson created successfully");
        setAllLessons((prevState) => [res.data, ...prevState]);
        window.location.reload();
      });
    } else {
      setLessonNameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setIsExamInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setStartDateInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  function formatDate(date) {
    let newDate = date.split("-");

    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
  }

  const [lessonID, setLessonID] = useState();
  const openModalForEdit = (e, rowData) => {
    e.preventDefault();
    setOpen(true);
    setChangeBtn({ change: "edit" });
    setLessonID(rowData.id);
    setLessonNameInputs((prevState) => ({
      ...prevState,
      name: rowData.name,
    }));
    setIsExamInputs((prevState) => ({
      ...prevState,
      isExam: rowData.isExam ? "exam" : "lesson",
    }));
    setStartDateInputs((prevState) => ({
      ...prevState,
      startDate: moment(rowData.startDate).format().substring(0, 10),
    }));
    console.log(startDateInputs.startDate);
  };

  const updateLessonBtn = (e) => {
    e.preventDefault();
    if (validationLessonName.passes()) {
      const data = {
        name: lessonNameInputs.name,
        startDate: startDateInputs.startDate,
        groupId: Number(groupId),
        isExam: isExamInputs.isExam == "exam" ? true : false,
      };
      if (lessonID) {
        updateLesson(token, lessonID, data).then((res) => {
          if (res.status === 200) {
            // const newItem = {
            //   id: lessonID,

            //   name: data.name,
            //   isExam: data.isExam,
            // };
            // const newArr = allLessons.filter((el) => el.id !== lessonID);
            // const newSortedArr = [...newArr, newItem].sort(function (a, b) {
            //   return a.id - b.id;
            // });
            handleClose();
            NotificationManager.success("Lesson updated successfully");
            getLessonsByGroupId(token, groupId).then((res) => {
              setAllLessons(res?.data?.reverse());
              console.log(res.data);
            });
            // setAllLessons((prevState) => newSortedArr.reverse());
          }
        });
      }
    } else {
      setLessonNameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      // setIsExamInputs((prevState) => ({
      //   ...prevState,
      //   startValidate: true,
      // }));
    }
  };

  return (
    <div>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={openModalForCreate}
        >
          {t("teacher.createLesson")}
        </Button>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div
            style={modalStyle}
            className={`${classes.paper} ${styles.modalForLesson}`}
          >
            <Typography variant="h6">
              {changeBtn.change === "create"
                ? t("teacher.createLesson")
                : t("teacher.updateLesson")}
            </Typography>
            <Box my={3}>
              <FormControl error fullWidth>
                <TextField
                  required
                  fullWidth
                  type="text"
                  variant="outlined"
                  className="outlined-input"
                  label={`${t("teacher.lessonTitle")}: `}
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={lessonNameInputs.name}
                  onChange={(e) => handleLessonName(e)}
                  error={
                    lessonNameInputs.startValidate &&
                    (validationLessonName.passes() === true ? false : true)
                  }
                />
                <FormHelperText>
                  {lessonNameInputs.startValidate &&
                    validationLessonName.errors.first("name")}
                </FormHelperText>
              </FormControl>
            </Box>
            {changeBtn.change === "edit" ? (
              <Fragment>
                <Box my={3}>
                  <FormControl fullWidth error>
                    <TextField
                      required
                      fullWidth
                      type="date"
                      id="outlined-required"
                      variant="outlined"
                      className="outlined-input"
                      label={`${t("teacher.startDate")}:`}
                      InputLabelProps={{
                        shrink: true,
                        pl: "2px",
                      }}
                      value={startDateInputs.startDate}
                      onChange={(e) => handleStartDate(e)}
                      error={
                        startDateInputs.startValidate &&
                        (validationStartDate.passes() === true ? false : true)
                      }
                    />

                    <FormHelperText style={{ marginLeft: 0 }}>
                      {startDateInputs.startValidate &&
                        validationStartDate.errors.first("startDate")}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box my={3}>
                  <FormControl
                    required
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={
                      isExamInputs.startValidate &&
                      (validationIsExam.passes() === true ? false : true)
                    }
                  >
                    <InputLabel id="select-gender-label" shrink={true}>
                      {t("teacher.selectType")}:
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="select-gender-label"
                      label="Select type: "
                      variant="outlined"
                      value={isExamInputs.isExam}
                      onChange={(e) => handleIsExam(e)}
                    >
                      <MenuItem value="exam">{t("parent.exam")}</MenuItem>
                      <MenuItem value="lesson">{t("parent.lesson")}</MenuItem>
                    </Select>
                    <FormHelperText style={{ marginLeft: "0" }}>
                      {isExamInputs.startValidate &&
                        validationIsExam.errors.first("type")}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Fragment>
            ) : (
              ""
            )}

            {changeBtn.change == "create" ? (
              <div>
                {/*isExam*/}
                <Box my={3}>
                  <FormControl
                    required
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={
                      isExamInputs.startValidate &&
                      (validationIsExam.passes() === true ? false : true)
                    }
                  >
                    <InputLabel id="select-gender-label" shrink={true}>
                      {t("teacher.selectType")}:
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="select-gender-label"
                      label="Select type: "
                      variant="outlined"
                      value={isExamInputs.isExam}
                      onChange={(e) => handleIsExam(e)}
                    >
                      <MenuItem value="exam">{t("parent.exam")}</MenuItem>
                      <MenuItem value="lesson">{t("parent.lesson")}</MenuItem>
                    </Select>
                    <FormHelperText style={{ marginLeft: "0" }}>
                      {isExamInputs.startValidate &&
                        validationIsExam.errors.first("type")}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box my={3}>
                  <FormControl fullWidth error>
                    <TextField
                      required
                      fullWidth
                      type="date"
                      id="outlined-required"
                      variant="outlined"
                      className="outlined-input"
                      label={`${t("teacher.startDate")}:`}
                      InputLabelProps={{
                        shrink: true,
                        pl: "2px",
                      }}
                      value={startDateInputs.startDate}
                      onChange={(e) => handleStartDate(e)}
                      error={
                        startDateInputs.startValidate &&
                        (validationStartDate.passes() === true ? false : true)
                      }
                    />

                    <FormHelperText style={{ marginLeft: 0 }}>
                      {startDateInputs.startValidate &&
                        validationStartDate.errors.first("startDate")}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </div>
            ) : (
              ""
            )}
            <Box my={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={(e) =>
                  changeBtn.change === "create"
                    ? createLessonBtn(e)
                    : updateLessonBtn(e)
                }
              >
                {changeBtn.change === "create"
                  ? t("parent.create")
                  : t("parent.update")}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                style={{ marginLeft: "10px" }}
                onClick={handleClose}
              >
                {t("parent.cancel")}
              </Button>
            </Box>
          </div>
        </Modal>
      </Box>
      <Box px={{ xs: "12px", lg: 0 }} p={4} className="page-space">
        <MaterialTable
          title={t("teacher.lessons")}
          columns={columns}
          data={allLessons}
          actions={actions}
          options={{
            actionsColumnIndex: -1,
          }}
          localization={{
            toolbar: {
              searchPlaceholder: t("searchPlaceholder"),
            },
            header: {
              actions: t("admin.actions"),
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withRouter(CreateLesson);
