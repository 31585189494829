import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import Icon from "@material-ui/core/Icon";
import { Container, Box, Button, Tabs } from "@material-ui/core";
import { SmallTitleBar } from "components/GlobalComponents";

import AttendanceResults from "../../../admin/students/add/Components/AttendanceResults";
import HomeworkGradeResults from "../../../admin/students/add/Components/HomeworkGradeResults";
import ExamGradeResults from "../../../admin/students/add/Components/ExamGradeResults";
import { t } from "i18next";

const useStyles = makeStyles((theme) => ({
  tabsWrap: {
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  appWrap: {
    boxShadow: "none",
  },
  toolbar: {
    padding: "0",
    marginLeft: -12,
    marginRight: -12,
    "& button": {
      minHeight: 50,
    },
    "& .MuiTab-wrapper": {
      fontSize: "1rem",
    },
    "& .Mui-selected": {
      backgroundColor: `rgba(0,0,0,0.1)`,
    },
  },
  searchBarWrap: {
    "& .MuiInput-underline::before": {
      borderBottom: `1px solid ${theme.palette.common.white}`,
    },
    "& .MuiInputBase-input::placeholder": {
      color: theme.palette.common.white,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderColor: theme.palette.common.white,
    },
    "& .MuiInput-underline::after": {
      borderBottom: `1px solid ${theme.palette.common.white}`,
    },
    "& .MuiInputBase-root": {
      width: 360,
      "& input": {
        color: theme.palette.common.white,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginBottom: 20,
      },
    },
    "& .MuiSvgIcon-root": {
      fill: theme.palette.common.white,
    },
  },
}));

export default function StudentResults(props) {
  const userId = JSON.parse(localStorage.getItem("preUni_user_id"));
  const groupId = props.match.params.groupId;
  const groupName = props.match.params.groupName;
  const classes = useStyles();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goBack = (e) => {
    e.preventDefault();
    props.history.goBack();
  };

  return (
    <div className="contact-grid">
      <SmallTitleBar title={groupName} />

      <Box
        className={`title-contact-block ${classes.searchBarWrap}`}
        pt={0}
        bgcolor="background.paper"
        px={{ xs: "12px", md: 0 }}
        pb={3}
      >
        <Container>
          <Box
            textAlign={{ xs: "center", sm: "right" }}
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Button variant="outlined" color="default" onClick={goBack}>
              <Icon style={{ fontSize: 18, marginRight: "2px" }}>
                arrow_back{" "}
              </Icon>
              {t("parent.back")}
            </Button>
          </Box>
        </Container>
      </Box>

      <Box className={classes.tabsWrap} bgcolor="background.paper">
        <Container>
          <AppBar position="static" className={classes.appWrap}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
              aria-label="scrollable auto tabs example"
              className={`${classes.toolbar} contact-grid-tabs`}
            >
              <Tab label={t("admin.attendance")} value="1" />
              {/* <Tab label="Homework grades" value="2" /> */}
              {/* <Tab label="Exam grades" value="2" /> */}
            </Tabs>
          </AppBar>
        </Container>
      </Box>

      <Container maxWidth="lg">
        <TabContext value={value}>
          <TabPanel value="1">
            <AttendanceResults
              studentId={userId}
              groupId={groupId}
              groupName={groupName}
            />
          </TabPanel>
          {/* <TabPanel value="2">
                        <HomeworkGradeResults studentId={userId} groupId={groupId} groupName={groupName} />
                    </TabPanel> */}
          {/* <TabPanel value="2">
            <ExamGradeResults
              studentId={userId}
              groupId={groupId}
              groupName={groupName}
            />
          </TabPanel> */}
        </TabContext>
      </Container>
    </div>
  );
}
