// https://cec.inha.uz/api/courses/all
const axios = require("axios").default;

// get all courses
const getAllCourses = async (takeCourse) => {
  console.log(takeCourse);
  const res = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/courses/all?skip=0&take=${takeCourse}`
  );
  console.log(res);
  try {
    const result = await res;
    return result;
  } catch (err) {
    console.log(err);
  }
};

export { getAllCourses };
