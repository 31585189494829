import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { t } from "i18next";
import {
  Grid,
  Box,
  Container,
  Typography,
  Divider,
  Avatar,
  Button,
  Card,
  CardContent,
} from "@material-ui/core";
import { getStudent } from "fetch/student/groups/apies";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "1.25rem",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    borderRadius: 4,
  },
  cardContent: {
    padding: "0 !important",
  },
  divider: {
    marginTop: "0.625rem",
  },
}));

function StudentGroups(props) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const userId = JSON.parse(localStorage.getItem("preUni_user_id"));
  const [studentGroups, setStudentGroups] = useState();
  const classes = useStyles();

  useEffect(() => {
    getStudent(token, userId).then((res) => {
      console.log(res.data);
      setStudentGroups(res.data.groupStudents);
    });
  }, []);

  const navigateBtn = (e, link) => {
    e.preventDefault();
    props.history.push(`${process.env.PUBLIC_URL}/app/student/groups/${link}`);
  };

  return (
    <div className="hk-user-profile">
      <Container maxWidth="lg">
        <Box px={{ xs: "12px", lg: 0 }} className="page-space">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8} md={6}>
              <Card
                className={`${classes.card} ${
                  props.cardClasses ? props.cardClasses : ""
                }`}
              >
                <Typography variant="h6">
                  {t("sidebarItemTeacher.bar2")}
                </Typography>
                <Divider className={classes.divider} />
                <CardContent className={classes.cardContent}>
                  {studentGroups && studentGroups.length !== 0
                    ? studentGroups.map((item, index) => (
                        <Box key={index} className="user-activity">
                          <Box display="flex" p={{ xs: 2, md: 3 }}>
                            <Box
                              pl={2}
                              pr={{ xs: 0, md: 2 }}
                              className="content-wrap"
                            >
                              <Box>
                                <Box
                                  display={{ xs: "block", sm: "flex" }}
                                  alignItems="center"
                                  mb={1}
                                >
                                  <Box
                                    pr="5px"
                                    className="user-name"
                                    component="span"
                                    fontWeight={500}
                                  >
                                    {item.group.name}
                                  </Box>
                                </Box>
                                <Typography variant="body2" color="textPrimary">
                                  {item.group.description}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box display="flex" justifyContent="end" mb="10px">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(e) =>
                                navigateBtn(
                                  e,
                                  `${item.group.id}/${item.group.name}`
                                )
                              }
                            >
                              {t("myResults")}
                            </Button>
                          </Box>
                          <Divider />
                        </Box>
                      ))
                    : `${t("teacher.noGorups")}`}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default withRouter(StudentGroups);
