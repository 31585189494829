import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { SmallTitleBar } from "components/GlobalComponents";
import Icon from "@material-ui/core/Icon";
import { Box, Container, Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  getGroupStudents,
  postHomeworkGrade,
  getHomeworkGrades,
} from "fetch/teacher/groups/apies";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function HomeworkGrades(props) {
  const classes = useStyles();
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const groupId = props.match.params.groupId;
  const groupName = props.match.params.groupName;
  const hwId = props.match.params.hwId;
  const hwName = props.match.params.hwName;

  const [allStudents, setAllStudents] = useState([]);
  // All students
  useEffect(() => {
    if (groupId) {
      getGroupStudents(token, groupId).then((res) => {
        const students = [];
        res.data.groupStudents.filter((item) => {
          students.push(item.student);
        });
        const list = students.filter((el) => el !== null);
        setAllStudents(list);
      });
    }
  }, [groupId]);

  const [grade, setGrade] = useState(new Map());
  useEffect(() => {
    if (allStudents) {
      allStudents.map((item) => {
        setGrade((map) => new Map(map.set(item.id, "")));
      });
      getHomeworkGrades(token, groupId).then((res) => {
        console.log(res.data);
        res.data.map((el) => {
          if (el.homework.id == hwId) {
            console.log("wo");
            setGrade((map) => new Map(map.set(el.student.id, el.grade)));
          }
        });
      });
    }
  }, [allStudents]);

  const handleGrade = (event, id) => {
    setGrade((map) => new Map(map.set(id, event.target.value)));
    const data = {
      homeworkId: Number(hwId),
      studentId: Number(id),
      grade: Number(grade.get(id)),
    };
    postHomeworkGrade(token, data).then((res) => {
      console.log(res);
    });
  };

  const goBack = (e) => {
    e.preventDefault();
    props.history.goBack();
  };
  return (
    <div className="contact-grid">
      <SmallTitleBar title="Homework Grades" />
      <Box
        className={`title-contact-block ${classes.searchBarWrap}`}
        pt={0}
        bgcolor="background.paper"
        px={{ xs: "12px", md: 0 }}
        pb={3}
      >
        <Container>
          <Box
            textAlign={{ xs: "center", sm: "right" }}
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Button variant="outlined" color="default" onClick={goBack}>
              <Icon style={{ fontSize: 18, marginRight: "2px" }}>
                arrow_back{" "}
              </Icon>
              Back
            </Button>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg">
        <Box mt={8}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="50%">Name</TableCell>
                  <TableCell>
                    Homework: {hwName} , Group: {groupName}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allStudents.length !== 0 ? (
                  allStudents.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.first_name} {row.last_name}
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          id="outlined-number"
                          type="number"
                          variant="outlined"
                          size="small"
                          style={{ width: "100px" }}
                          value={grade.get(row.id)}
                          onChange={(e) => handleGrade(e, row.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>No data !</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </div>
  );
}

export default HomeworkGrades;
