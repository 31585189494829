/**
 * Forget Password
*/
import React, { useState, useEffect } from 'react';

import { redirectUrl } from "../fetch/auth"

function GmailRegister(props) {

    useEffect(() => {
        const { token } = props.match.params;
        console.log(token)
        redirectUrl(token).then(res => {
            console.log(res.data, "gmail")
        })
    }, []);



    return (
        <div>
            HHH
        </div>
    );
}



export default GmailRegister;