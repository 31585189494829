import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { Box, Checkbox } from "@material-ui/core";
import { getAttendance, getAttendanceChild } from "fetch/admin/students/apies";
import { getLessonGradesByStudentId } from "fetch/teacher/groups/apies";
import { t } from "i18next";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function AttendanceResults({ studentId, groupId }) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const parentId = Number(localStorage.getItem("preUni_user_id"));
  const idStudent = window.location.href.split("/")[6];
  const classes = useStyles();
  const [studentAttends, setStudentAttends] = useState([]);
  // const [lessons, setLessons] = useState([]);
  function formatDate(date) {
    let newDate = date.split("-");

    return `${newDate[1]}-${newDate[2]}-${newDate[0]}`;
  }

  useEffect(() => {
    console.log(parentId);
    getAttendance(token, groupId).then((res) => {
      const list = res.data.filter(
        (el) => el?.student?.id === Number(idStudent)
      );
      setStudentAttends(list);

      console.log("studentAttends: ", studentAttends);
      // setLessons(res?.data);
      // const filtered = lessons?.filter(
      //   (item) => item.student.id === Number(idStudent)
      // );
      // console.log(res.data);
      //   if (res.status == 200) {
      //     getAttendanceChild(token, groupId, res.data[0].student.parent.id).then(
      //       (res) => {
      //         const list = res.data.filter((el) => el.student.id == studentId);
      //         setStudentAttends(list);
      //         console.log(res);
      //       }
      //     );
      //   }
    });
  }, []);

  // useEffect(() => {
  //   getLessonGradesByStudentId(token, groupId, parentId, 601)
  //     .then((res) => {
  //       const filtered = res?.data?.filter(
  //         (item) => item.student.id === Number(idStudent)
  //       );
  //       console.log("filtered: ", filtered);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  return (
    <Box mt={8}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell align="left">Phone</TableCell> */}
              <TableCell>{t("admin.fullName")}</TableCell>
              <TableCell align="left">{t("parent.lessonName")}</TableCell>
              <TableCell align="left">{t("parent.typeLesson")}</TableCell>
              <TableCell align="left">{t("parent.lessonDate")}</TableCell>
              <TableCell align="left">{t("admin.status")}</TableCell>
              <TableCell align="left">{t("parent.doneHomework")}</TableCell>
              {/* <TableCell align="left">Exam grade</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {studentAttends.length !== 0 ? (
              studentAttends.map((item, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {`${item?.student?.first_name} ${item?.student?.last_name}`}
                  </TableCell>
                  {/* <TableCell component="th" scope="row">
                    {`${item?.student?.phone_number}`}
                  </TableCell> */}
                  <TableCell component="th" scope="row">
                    {` ${item?.lesson?.name}`}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {` ${
                      item?.lesson?.isExam
                        ? t("parent.exam")
                        : t("parent.lesson")
                    }`}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {` ${moment(
                      item?.lesson?.startDate.substring(0, 10)
                    ).format("DD-MM-YYYY")}`}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {`${
                      !item.is_attended
                        ? t("admin.absent")
                        : item.is_attended == true && item.is_late == true
                        ? t("admin.late")
                        : item.is_attended == true
                        ? t("admin.present")
                        : ""
                    }`}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {/* <Checkbox
                      checked={item?.no_homework}
                      disabled={true}
                      inputProps={{
                        "aria-label": "primary checkbox",
                      }}
                    /> */}
                    {`${!item?.no_homework ? "+" : "-"}`}
                  </TableCell>
                  {/* <TableCell component="th" scope="row">
                    1
                  </TableCell> */}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>No data !</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default AttendanceResults;
