import React, { Fragment } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styles from "./news.module.scss";

const News = () => {
  const { t } = useTranslation();
  return (
    <div id="news">
      <Box>
        <div className="section-title mt-3">
          <h2>{t("news")}</h2>
        </div>
      </Box>

      <div className="row align-items-center" data-aos="fade-up">
        <div className="col-md-5 d-flex justify-content-center">
          <img
            src="https://res.cloudinary.com/apple-angren/image/upload/v1658318871/INHA%20teachers/news-1_ahrj5x.jpg"
            className="img-fluid"
            alt="news-1"
            style={{ width: "400px", borderRadius: "40px" }}
          />
        </div>
        <div className="col-md-7 pt-4">
          <h3>{t("news1_1")}</h3>
          <p className="fst-italic">{t("news1_2")}</p>
          <p>{t("news1_3")}</p>
        </div>
      </div>

      <div className="row mb-5" data-aos="fade-up">
        <div className="col text-center mt-5">
          <h3>{t("news2_1")}</h3>
          <p className="fst-italic">{t("news2_2")}</p>
          <p>{t("news2_3")}</p>

          <div className={`imgBox  ${styles.imgBox}`}>
            <img
              src="https://res.cloudinary.com/apple-angren/image/upload/v1658318871/INHA%20teachers/news-2_1_junvql.jpg"
              className="img-fluid"
              alt="news-1"
              style={{ borderRadius: "40px" }}
            />
            <img
              src="https://res.cloudinary.com/apple-angren/image/upload/v1658318871/INHA%20teachers/news-2_2_ackyi2.jpg"
              className="img-fluid"
              alt="news-1"
              style={{ borderRadius: "40px" }}
            />
            <img
              src="https://res.cloudinary.com/apple-angren/image/upload/v1658318871/INHA%20teachers/news-2_3_uannlq.jpg"
              className="img-fluid"
              alt="news-1"
              style={{ borderRadius: "40px" }}
            />
          </div>
        </div>
      </div>

      <div className="row align-items-center" data-aos="fade-up">
        <div className="col-md-5 d-flex justify-content-center">
          <img
            style={{ width: "350px", borderRadius: "40px" }}
            src="https://res.cloudinary.com/apple-angren/image/upload/v1658318871/INHA%20teachers/news-3_nqdmmw.jpg"
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="col-md-7 pt-4">
          <h3>{t("news3_1")}</h3>
          <p className="fst-italic">{t("news3_2")}</p>
          <p>{t("news3_3")}</p>
        </div>
      </div>

      <div className="row align-items-center" data-aos="fade-up">
        <div className="col-md-5 d-flex justify-content-center">
          <img
            style={{ width: "350px", borderRadius: "40px" }}
            src="https://res.cloudinary.com/apple-angren/image/upload/v1660807515/New_meugci.jpg"
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="col-md-7 pt-4">
          <h3>{t("news4_1")}</h3>
          <p className="fst-italic">{t("news3_2")}</p>
          <p>{t("news4_2")}</p>
        </div>
      </div>
    </div>
  );
};

export default News;
