import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { Button, Box } from "@material-ui/core";

import mailSendImage from "./images/26663.jpg";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "#def3f8",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}));

function SuccessPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="sm">
        <Typography variant="h2" component="h1" gutterBottom>
          You have successfully registered!
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ marginBottom: "20px" }}
        >
          {"Please check your email."}
          {"We have sent the confirmation link."}
        </Typography>
        {/* <Typography variant="body1">Sticky footer placeholder.</Typography> */}
        <Box
          style={{ cursor: "pointer", padding: "10px", borderRadius: "5px" }}
          component={Link}
          to="/auth"
          color="#fff"
          bgcolor="rgb(77, 125, 242)"
        >
          Home
        </Box>
        <Box display="flex" justifyContent="center">
          <img
            src={mailSendImage}
            alt=""
            width="50%"
            style={{
              borderRadius: "50%",
              border: "2ps solid black",
            }}
          />
        </Box>
      </Container>
      {/* <footer className={classes.footer}>
                <Container maxWidth="sm">
                    <Typography variant="body1">My sticky footer can be found here.</Typography>
                    <Copyright />
                </Container>
            </footer> */}
    </div>
  );
}

export default SuccessPage;
