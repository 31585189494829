const axios = require("axios").default;

const getRegions = async () => {
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/regions`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

function loginUser(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_PRE_UNI_API}/auth/login`,
    requestOptions
  );
}

function handleLoginResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function registerUser(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${process.env.REACT_APP_PRE_UNI_API}/users`, requestOptions);
}

function handleRegisterResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

const postForgotPassword = async (data) => {
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/forgot-password`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const gmailRegister = async () => {
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const redirectUrl = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/userdata`, {
    headers: {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const parentEmailValidation = async (email) => {
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/users/find?userType=parent&email=${email}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

export {
  getRegions,
  registerUser,
  postForgotPassword,
  gmailRegister,
  redirectUrl,
  loginUser,
  handleLoginResponse,
  handleRegisterResponse,
  parentEmailValidation,
};
