import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import {
  TextField,
  Button,
  Fab,
  Box,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { CustomCard } from "components/GlobalComponents";
import Validator from "validatorjs";
import { JWT_LOGIN_FAILURE } from "../actions/Types";
import {
  getRegions,
  gmailRegister,
  registerUser,
  handleRegisterResponse,
  parentEmailValidation,
} from "../fetch/auth";
import { t } from "i18next";

function Register(props) {
  const [regionOptions, setRegionOptions] = useState();
  const [districtOptions, setDistrictOptions] = useState();
  const dispatch = useDispatch();
  Validator.register(
    "telephone",
    function (value, requirement, attribute) {
      // requirement parameter defaults to null
      return value.match("[+]998[0-9]{0,}");
    },
    "The phone format is invalid"
  );

  useEffect(() => {
    getRegions().then((res) => {
      setRegionOptions(res.data);
    });
  }, []);

  //   email
  const [emailInputs, setEmailInputs] = useState({
    email: "",
    rule: "required|email",
    startValidate: false,
  });

  let validationEmail = new Validator(
    { email: emailInputs.email },
    { email: emailInputs.rule }
  );

  const [emailTaken, setEmailTaken] = useState(false);
  const handleEmail = (e) => {
    setEmailTaken(false);
    if (e.target.value !== "") {
      parentEmailValidation(e.target.value).then((res) => {
        setEmailTaken(res.data.isExisting);
      });
    }
    setEmailInputs((prevState) => ({
      ...prevState,
      email: e.target.value,
      startValidate: true,
    }));
  };

  //   password
  const [passwordInputs, setPasswordInputs] = useState({
    password: "",
    rule: "required|min:8",
    startValidate: false,
  });
  let validationPassword = new Validator(
    { password: passwordInputs.password },
    { password: passwordInputs.rule }
  );
  const handlePassword = (e) => {
    setPasswordInputs((prevState) => ({
      ...prevState,
      password: e.target.value,
      startValidate: true,
    }));
  };

  //   firstname
  const [firstnameInputs, setFirstnameInputs] = useState({
    firstname: "",
    rule: "required",
    startValidate: false,
  });
  let validationFirstname = new Validator(
    { firstname: firstnameInputs.firstname },
    { firstname: firstnameInputs.rule }
  );
  const handleFirstname = (e) => {
    setFirstnameInputs((prevState) => ({
      ...prevState,
      firstname: e.target.value,
      startValidate: true,
    }));
  };

  //   lastname
  const [lastnameInputs, setLastnameInputs] = useState({
    lastname: "",
    rule: "required",
    startValidate: false,
  });
  let validationLastname = new Validator(
    { lastname: lastnameInputs.lastname },
    { lastname: lastnameInputs.rule }
  );
  const handleLastname = (e) => {
    setLastnameInputs((prevState) => ({
      ...prevState,
      lastname: e.target.value,
      startValidate: true,
    }));
  };

  //   middleName
  const [middleNameInputs, setMiddleNameInputs] = useState({
    middlename: "",
    rule: "required",
    startValidate: false,
  });
  let validationMiddlename = new Validator(
    { middlename: middleNameInputs.middlename },
    { middlename: middleNameInputs.rule }
  );
  const handleMiddlename = (e) => {
    setMiddleNameInputs((prevState) => ({
      ...prevState,
      middlename: e.target.value,
      startValidate: true,
    }));
  };

  // gender
  const [genderInputs, setGenderInputs] = useState({
    gender: "",
    rule: "required",
    startValidate: false,
  });
  let validationGender = new Validator(
    { gender: genderInputs.gender },
    { gender: genderInputs.rule }
  );
  const handleGender = (e) => {
    setGenderInputs((prevState) => ({
      ...prevState,
      gender: e.target.value,
      startValidate: true,
    }));
  };

  // birthday
  const [birthdayInputs, setBirthdayInputs] = useState({
    birthday: "",
    rule: "required",
    startValidate: false,
  });
  let validationBirthday = new Validator(
    { birthday: birthdayInputs.birthday },
    { birthday: birthdayInputs.rule }
  );
  const handleBirthday = (e) => {
    setBirthdayInputs((prevState) => ({
      ...prevState,
      birthday: e.target.value,
      startValidate: true,
    }));
  };

  // region
  const [regionInputs, setRegionInputs] = useState({
    region: "",
    rule: "required",
    startValidate: false,
  });
  let validationRegion = new Validator(
    { region: regionInputs.region },
    { region: regionInputs.rule }
  );
  const handleRegion = (e) => {
    setRegionInputs((prevState) => ({
      ...prevState,
      region: e.target.value,
      startValidate: true,
    }));

    const regionId = e.target.value;
    const { districts } = regionOptions.find(
      (option) => option.id === regionId
    );
    setDistrictOptions(districts);
  };

  // district
  const [districtInputs, setDistrictInputs] = useState({
    district: "",
    rule: "required",
    startValidate: false,
  });
  let validationDistrict = new Validator(
    { district: districtInputs.district },
    { district: districtInputs.rule }
  );
  const handleDistrict = (e) => {
    setDistrictInputs((prevState) => ({
      ...prevState,
      district: e.target.value,
      startValidate: true,
    }));
  };

  // address
  const [addressInputs, setAddressInputs] = useState({
    address: "",
    rule: "required",
    startValidate: false,
  });
  let validationAddress = new Validator(
    { address: addressInputs.address },
    { address: addressInputs.rule }
  );
  const handleAddress = (e) => {
    setAddressInputs((prevState) => ({
      ...prevState,
      address: e.target.value,
      startValidate: true,
    }));
  };

  // phone
  const [phoneInputs, setPhoneInputs] = useState({
    phone: "",
    rule: "required|telephone|min:13|max:13",
    startValidate: false,
  });
  let validationPhone = new Validator(
    { phone: phoneInputs.phone },
    { phone: phoneInputs.rule }
  );
  const handlePhone = (e) => {
    setPhoneInputs((prevState) => ({
      ...prevState,
      phone: e.target.value,
      startValidate: true,
    }));
  };

  const [disableBtn, setDisabledBtn] = useState(false);

  const registerBtn = () => {
    if (
      validationEmail.passes() &&
      validationPassword.passes() &&
      validationFirstname.passes() &&
      validationLastname.passes() &&
      validationMiddlename.passes() &&
      emailTaken === false
    ) {
      setDisabledBtn(true);
      const data = {
        email: emailInputs.email,
        password: passwordInputs.password,
        first_name: firstnameInputs.firstname,
        last_name: lastnameInputs.lastname,
        middle_name: middleNameInputs.middlename,
      };

      console.log(data);
      registerUser(data)
        .then(handleRegisterResponse)
        .then(
          (user) => {
            setDisabledBtn(false);
            props.history.push("/success");
          },
          (error) => {
            setDisabledBtn(false);
            dispatch({ type: JWT_LOGIN_FAILURE, payload: error });
            NotificationManager.error(error);
          }
        );
    } else {
      setEmailInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPasswordInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setFirstnameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setLastnameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setMiddleNameInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  const getKeydown = (e) => {
    if (e.keyCode == 13) {
      registerBtn();
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const gmailRegisterBtn = (e) => {
    e.preventDefault();
    console.log("eeee");
    gmailRegister().then((res) => {
      console.log(res);
    });
  };

  return (
    <CustomCard>
      <form className="login-form text-center">
        <Typography variant="subtitle2">{t("register")} :</Typography>
        <Box my={3}>
          {/* email */}
          <FormControl error fullWidth>
            <TextField
              required
              fullWidth
              type="email"
              variant="outlined"
              className="outlined-input"
              placeholder={t("loginForm.emailPlaceholder")}
              value={emailInputs.email}
              onChange={handleEmail}
              onKeyDown={getKeydown}
              error={
                emailInputs.startValidate &&
                (validationEmail.passes() === true ? false : true) &&
                (emailTaken === true ? true : false)
              }
            />
            <FormHelperText>
              {emailInputs.startValidate &&
                validationEmail.errors.first("email")}
            </FormHelperText>
            <FormHelperText>
              {emailTaken && "The email is already taken."}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box mb={3}>
          {/* password */}
          <FormControl error fullWidth>
            <OutlinedInput
              required
              fullWidth
              type={showPassword ? "text" : "password"}
              variant="outlined"
              className="outlined-input"
              placeholder={t("loginForm.passPlaceholder")}
              value={passwordInputs.password}
              onChange={handlePassword}
              onKeyDown={getKeydown}
              error={
                passwordInputs.startValidate &&
                (validationPassword.passes() === true ? false : true)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>
              {passwordInputs.startValidate &&
                validationPassword.errors.first("password")}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box my={3}>
          {/* firstname */}
          <FormControl error fullWidth>
            <TextField
              required
              fullWidth
              type="text"
              variant="outlined"
              className="outlined-input"
              placeholder={t("registerForm.firsnamePlaceholder")}
              value={firstnameInputs.firstname}
              onChange={handleFirstname}
              onKeyDown={getKeydown}
              error={
                firstnameInputs.startValidate &&
                (validationFirstname.passes() === true ? false : true)
              }
            />
            <FormHelperText>
              {firstnameInputs.startValidate &&
                validationFirstname.errors.first("firstname")}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box my={3}>
          {/* lastname */}
          <FormControl error fullWidth>
            <TextField
              required
              fullWidth
              type="text"
              variant="outlined"
              className="outlined-input"
              placeholder={t("registerForm.lastnamePlaceholder")}
              value={lastnameInputs.lastname}
              onChange={handleLastname}
              onKeyDown={getKeydown}
              error={
                lastnameInputs.startValidate &&
                (validationLastname.passes() === true ? false : true)
              }
            />
            <FormHelperText>
              {lastnameInputs.startValidate &&
                validationLastname.errors.first("lastname")}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box my={3}>
          {/* middlename */}
          <FormControl error fullWidth>
            <TextField
              required
              fullWidth
              type="text"
              variant="outlined"
              className="outlined-input"
              placeholder={t("registerForm.middlenamePlaceholder")}
              value={middleNameInputs.middlename}
              onChange={handleMiddlename}
              onKeyDown={getKeydown}
              error={
                middleNameInputs.startValidate &&
                (validationMiddlename.passes() === true ? false : true)
              }
            />
            <FormHelperText>
              {middleNameInputs.startValidate &&
                validationMiddlename.errors.first("middlename")}
            </FormHelperText>
          </FormControl>
        </Box>
        {/* gender */}
        {/* <Box my={3}>
          <FormControl error fullWidth>
            <Select
              required
              fullWidth
              variant="outlined"
              className="outlined-input"
              displayEmpty
              value={genderInputs.gender}
              onChange={handleGender}
              error={
                genderInputs.startValidate &&
                (validationGender.passes() === true ? false : true)
              }
            >
              <MenuItem value="" disabled>
                Please select your gender
              </MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </Select>
            <FormHelperText> {genderInputs.startValidate &&
              validationGender.errors.first("gender")}  </FormHelperText>
          </FormControl>
        </Box> */}
        {/* birthday */}
        {/* <Box my={3}>
          <FormControl error fullWidth>
            <TextField
              required
              fullWidth
              type="date"
              variant="outlined"
              className="outlined-input"
              placeholder="Please enter your birthday."
              value={birthdayInputs.birthday}
              onChange={handleBirthday}
              error={
                birthdayInputs.startValidate &&
                (validationBirthday.passes() === true ? false : true)
              }
            />
            <FormHelperText> {birthdayInputs.startValidate &&
              validationBirthday.errors.first("birthday")}</FormHelperText>
          </FormControl>
        </Box> */}
        {/* region */}
        {/* <Box my={3}>
          <FormControl error fullWidth>
            <Select
              required
              fullWidth
              variant="outlined"
              className="outlined-input"
              displayEmpty
              value={regionInputs.region}
              onChange={handleRegion}
              error={
                regionInputs.startValidate &&
                (validationRegion.passes() === true ? false : true)
              }
            >
              <MenuItem value="" disabled>
                Please select your region
              </MenuItem>
              {regionOptions &&
                regionOptions.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name_uz}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText> {regionInputs.startValidate &&
              validationRegion.errors.first("region")}  </FormHelperText>
          </FormControl>
        </Box> */}
        {/* district */}
        {/* <Box my={3}>
          <FormControl error fullWidth >
            <Select
              required
              fullWidth
              variant="outlined"
              className="outlined-input"
              displayEmpty
              value={districtInputs.district}
              onChange={handleDistrict}
              error={
                districtInputs.startValidate &&
                (validationDistrict.passes() === true ? false : true)
              }
            >
              <MenuItem value="" disabled>
                Please enter your district
              </MenuItem>

              {districtOptions &&
                districtOptions.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name_uz}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText> {districtInputs.startValidate &&
              validationDistrict.errors.first("district")} </FormHelperText>
          </FormControl>
        </Box> */}
        {/* address */}
        {/* <Box my={3}>
          <FormControl error fullWidth>
            <TextField
              required
              fullWidth
              type="text"
              variant="outlined"
              className="outlined-input"
              placeholder="Please enter your address."
              value={addressInputs.address}
              onChange={handleAddress}
              error={
                addressInputs.startValidate &&
                (validationAddress.passes() === true ? false : true)
              }
            />
            <FormHelperText>
              {
                addressInputs.startValidate &&
                validationAddress.errors.first("address")
              }
            </FormHelperText>
          </FormControl>
        </Box> */}
        {/* phone */}
        {/* <Box my={3}>
          <FormControl error fullWidth>
            <TextField
              required
              fullWidth
              type="text"
              variant="outlined"
              className="outlined-input"
              placeholder="Please enter your phone."
              value={phoneInputs.phone}
              onChange={handlePhone}
              error={
                phoneInputs.startValidate &&
                (validationPhone.passes() === true ? false : true)
              }
            />
            <FormHelperText>
              {
                phoneInputs.startValidate && validationPhone.errors.first("phone")
              }
            </FormHelperText>
          </FormControl>
        </Box> */}
        <Box mb="20px">
          <span style={{ cursor: disableBtn ? "not-allowed" : "pointer" }}>
            <Button
              color="primary"
              className="btn-block blockBtn w-100"
              variant="contained"
              disabled={disableBtn}
              onClick={(e) => registerBtn(e)}
              style={{ textTransform: "unset" }}
            >
              {disableBtn ? (
                <CircularProgress size={15} color="inherit" />
              ) : (
                `${t("register")}`
              )}
            </Button>
          </span>
        </Box>
        {/* <Typography variant="subtitle2">or</Typography> */}
        {/* <Typography variant="subtitle2">Sign up with</Typography> */}
        {/* <div className="social-login-wrapper">
          <div className="social-list">
            <Fab
              size="small"
              variant="circular"
              className="text-white google-color"
              onClick={(e) => gmailRegisterBtn(e)}
            >
              <i className="fab fa-google"></i>
            </Fab>
          </div>
        </div> */}
      </form>
    </CustomCard>
  );
}

export default withRouter(Register);
