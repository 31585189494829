import { t } from "i18next";
import { Box } from "@material-ui/core";
const ContactUs = () => {
  return (
    <>
      {/* ======= Contact Section ======= */}

      {/* End Contact Section */}
      {/* ======= Contact Section ======= */}
      <section
        className="contact"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration={500}
      >
        <div className="container">
          <Box>
            <div className="section-title mt-3">
              <h2>{t("contactus")}</h2>
            </div>
          </Box>
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="info-box">
                    <i>
                      <img
                        src="https://res.cloudinary.com/apple-angren/image/upload/v1658697136/my-icons/location_ghfled.png"
                        alt="location"
                      />
                    </i>
                    <h3>{t("address.ourAddress")}</h3>
                    <a
                      href="https://goo.gl/maps/FuotEJNTZ4Fh1r398"
                      target="_blank"
                      style={{ color: "#444" }}
                    >
                      {t("address.name1")}
                      {t("address.name2")}
                      {t("address.name3")}
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i>
                      <img
                        src="https://res.cloudinary.com/apple-angren/image/upload/v1658697136/my-icons/email_yct80j.png"
                        alt="email"
                      />
                    </i>
                    <h3>{t("address.emailUs")} </h3>
                    <p>
                      <a
                        href="mailto:info@example.com"
                        style={{ color: "#444" }}
                      >
                        info@example.com
                      </a>
                      <br />
                      <a
                        style={{ color: "#444" }}
                        href="mailto:contact@example.com"
                      >
                        contact@example.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i>
                      <img
                        src="https://res.cloudinary.com/apple-angren/image/upload/v1658697136/my-icons/phone-call_be0mpk.png"
                        alt="call"
                      />
                    </i>
                    <h3>{t("address.callUs")} </h3>
                    <p>
                      <a href="tel:+998712899995">+998 71 289 99 95</a>
                      <br />
                      <a href="tel:+998712899999">+998 71 289 99 99</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6" style={{ height: "100%" }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="php-email-form">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.6359508726446!2d69.33234131492554!3d41.33852900698645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef48a8ed4d0e9%3A0x3772abeffc72e7b8!2sInha%20University%20in%20Tashkent!5e0!3m2!1sen!2sbg!4v1658698347732!5m2!1sen!2sbg"
                      frameBorder={0}
                      style={{ border: 0, width: "100%", height: "335px" }}
                      allowFullScreen=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Contact Section */}
      {/* ======= Map Section ======= */}
      <section className="map mt-2">
        <div className="container-fluid p-0"></div>
      </section>
      {/* End Map Section */}
    </>
  );
};

export default ContactUs;
