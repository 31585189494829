/**
 * Forget Password
 */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  Button,
  Box,
  Typography,
  Divider,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { CustomCard } from "components/GlobalComponents";
import { Link } from "react-router-dom";
import Validator from "validatorjs";
import { NotificationManager } from "react-notifications";
import { postForgotPassword } from "../fetch/auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";

function ForgotPassword(props) {
  const { t } = useTranslation();
  const settings = useSelector((state) => state.settings);
  const { isDarkModeActive } = settings;

  //   email
  const [emailInputs, setEmailInputs] = useState({
    email: "",
    rule: "required|email",
    startValidate: false,
  });
  let validationEmail = new Validator(
    { email: emailInputs.email },
    { email: emailInputs.rule }
  );
  const handleEmail = (e) => {
    setEmailInputs((prevState) => ({
      ...prevState,
      email: e.target.value,
      startValidate: true,
    }));
  };

  const [disableBtn, setDisabledBtn] = useState(false);

  const forgotPasswordBtn = (e) => {
    e.preventDefault();
    if (validationEmail.passes()) {
      setDisabledBtn(true);
      const data = {
        email: emailInputs.email,
      };
      postForgotPassword(data)
        .then((res) => {
          if (res.status === 201) {
            NotificationManager.success("New password sended");
            setDisabledBtn(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setDisabledBtn(false);
          NotificationManager.error("User not found !");
        });
    } else {
      validationEmail.errors.first("email");
      setEmailInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  return (
    <div>
      <div className="session-wrapper session-wrapper-v2">
        <Box
          className="session-box"
          mx="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="100%">
            <CustomCard>
              <form className="login-form text-center">
                <Typography variant="h6" color="textPrimary" className="title">
                  {t("forgotYoutPass")}
                </Typography>
                <Box my={2}>
                  <FormControl error fullWidth>
                    <TextField
                      required
                      fullWidth
                      type="email"
                      variant="outlined"
                      className="outlined-input"
                      placeholder={`${t("loginForm.emailPlaceholder")}`}
                      value={emailInputs.email}
                      onChange={handleEmail}
                      error={
                        emailInputs.startValidate &&
                        (validationEmail.passes() === true ? false : true)
                      }
                    />
                    <FormHelperText>
                      {emailInputs.startValidate &&
                        validationEmail.errors.first("email")}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box py={3}>
                  <span
                    style={{ cursor: disableBtn ? "not-allowed" : "pointer" }}
                  >
                    <Button
                      color="primary"
                      className="btn-block blockBtn w-100"
                      variant="contained"
                      size="large"
                      disabled={disableBtn}
                      onClick={(e) => forgotPasswordBtn(e)}
                    >
                      {disableBtn ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        `${t("resetMyPass")}`
                      )}
                    </Button>
                  </span>
                </Box>
                <Divider></Divider>
                <Box pt={2} fontSize="subtitle2.fontSize">
                  <Box
                    style={{ cursor: "pointer" }}
                    color="primary.main"
                    component={Link}
                    to="/auth"
                  >
                    {t("backToSignIn")}
                  </Box>
                </Box>
              </form>
            </CustomCard>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default ForgotPassword;
