import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  AsyncParentDashboardComponent,
  AsyncParentTimetableComponent,
} from "Users";

import Sol from "./dashboard/components/sol";
import Socie from "./dashboard/components/socie";
import ParentAllCourses from "./courses/all";
import ParentAddToCourse from "./courses/add";
import ParentChildren from "./children/index";
import ChildGroups from "./children/components";
import StudentResults from "Users/student/groups/components";
import ContactUs from "../../components/ContactUs/ContactUs";
import TeachersLayout from "./Teachers/TeachersLayout";
import NewsLayout from "./News/NewsLayout";
import Info from "./dashboard/Info/Info";

const Parent = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/courses`}
        component={AsyncParentDashboardComponent}
      ></Route>
      <Route exact path={`${match.url}/courses/info`} component={Info}></Route>
      <Route
        exact
        path={`${match.url}/courses/info/socie`}
        component={Socie}
      ></Route>
      <Route
        exact
        path={`${match.url}/courses/info/sol`}
        component={Sol}
      ></Route>
      <Route
        exact
        path={`${match.url}/timetable`}
        component={AsyncParentTimetableComponent}
      ></Route>
      <Route
        exact
        path={`${match.url}/courses/all`}
        component={ParentAllCourses}
      ></Route>
      <Route
        exact
        path={`${match.url}/courses/:id`}
        component={ParentAddToCourse}
      ></Route>
      <Route
        exact
        path={`${match.url}/children`}
        component={ParentChildren}
      ></Route>
      <Route
        exact
        path={`${match.url}/teachers`}
        component={TeachersLayout}
      ></Route>
      <Route exact path={`${match.url}/news`} component={NewsLayout}></Route>
      <Route
        exact
        path={`${match.url}/contactus`}
        component={ContactUs}
      ></Route>
      <Route
        exact
        path={`${match.url}/children/:id`}
        component={ChildGroups}
      ></Route>
      <Route
        exact
        path={`${match.url}/child-results/:studentId/:groupId/:groupName`}
        component={StudentResults}
      ></Route>
    </Switch>
  );
};
export default Parent;
