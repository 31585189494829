import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Container, Grid, Paper, Typography } from "@material-ui/core";
import { CustomCard } from "components/GlobalComponents";
import DonutEchart from "components/GlobalComponents/Charts/DonutEchart";
import IntlMessages from "util/IntlMessages";
import { getTimetable } from "fetch/teacher/timetable/apies";
import UserActivities from "../groups/Components/UserActivities";
import { t } from "i18next";
import { getTeacher, getTeacherInfo } from "fetch/teacher/groups/apies";
import { getUser } from "fetch/users";

const useStyles = makeStyles((theme) => ({
  Paper: {
    padding: "0.75rem",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:first-child": {
      paddingTop: "24px",
    },
    "&:last-child": {
      paddingBottom: "30px",
    },
  },
  pad0: {
    padding: 0,
  },
  taskList: {
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));

function TeacherDashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const userId = JSON.parse(localStorage.getItem("preUni_user_id"));
  const [visitorStats, setVisitorStats] = useState([
    {
      value: 335,
      name: `${t("teacher.new")} (30%)`,
      itemStyle: { color: theme.palette.primary.main },
    },
    {
      value: 310,
      name: `${t("teacher.existing")} (40%)`,
      itemStyle: { color: theme.palette.secondary.main },
    },
    {
      value: 234,
      name: `${t("teacher.returning")} (30%)`,
      itemStyle: { color: theme.palette.success.main },
    },
    {
      value: 264,
      name: `${t("teacher.returning")} (30%)`,
      itemStyle: { color: "purple" },
    },
  ]);

  useEffect(() => {
    let newVisitorStats = visitorStats;
    for (let i = 0; i < newVisitorStats.length; i++) {
      if (i === 0) {
        newVisitorStats[i].itemStyle.color = theme.palette.primary.main;
      } else if (i === 1) {
        newVisitorStats[i].itemStyle.color = theme.palette.secondary.main;
      } else {
        newVisitorStats[i].itemStyle.color = theme.palette.success.main;
      }
    }
    setVisitorStats(newVisitorStats);
  }, []);

  // {
  //   icon: "schedule",
  //   iconColor: "text-primary",
  //   title: `${t("teacher.totalHours")}`,
  //   count: "10 hrs",
  // },
  const [stats, setStats] = useState([]);
  // {
  //   icon: "supervised_user_circle",
  //   iconColor: "text-success",
  //   title: `${t("sidebarItemTeacher.bar2")}`,
  //   count: "71",
  // },
  // {
  //   icon: "person",
  //   iconColor: "text-danger",
  //   title: `${t("teacher.myStudents")}`,
  //   count: "32,451",
  // },

  const [todayActivities, setTodayActivities] = useState([]);
  const [teacherGroups, setTeacherGroups] = useState();
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const currentDate = new Date();
    const day = currentDate.getDay();
    let finalDay;
    if (day === 0) {
      finalDay = 7;
    } else {
      finalDay = day;
    }
    if (finalDay) {
      getTimetable(token, "teacherId", userId).then((res) => {
        console.log(res.data);
        let newArr = [];
        res.data.filter((el) => {
          if (el.week_days.includes(finalDay)) {
            newArr.push(el);
          }
        });
        setTodayActivities(newArr);
      });
    }
    getTeacher(token, userId)
      .then((res) => {
        setTeacherGroups(res.data.groups);
      })
      .catch((err) => {
        const errMess = err?.message?.split(" ");
        const lastLength = errMess?.length - 1;
        const unAuth = errMess?.[lastLength];

        if (unAuth == 401) {
          localStorage.removeItem("preUni_token");
          props.history.push(`${process.env.PUBLIC_URL}/auth`);
        }
      });
    getTeacherInfo(token, userId).then((res) => {
      console.log(res.data);
      setStats((prevState) => [
        ...prevState,
        {
          icon: "supervised_user_circle",
          iconColor: "text-success",
          title: `${t("sidebarItemTeacher.bar2")}`,
          count: res?.data?.groupsCount,
        },
        {
          icon: "person",
          iconColor: "text-danger",
          title: `${t("teacher.myStudents")}`,
          count: res?.data?.studentsCount,
        },
      ]);
    });
    getUser(token, userId).then((res) => {
      setUserInfo(res.data.first_name);
    });
  }, []);

  return (
    <div className="new-dashboard">
      <Container maxWidth="lg">
        <Box px={2} pt={4}>
          <Typography variant="h3">{`${t("admin.hiAdmin1")}, ${
            userInfo !== undefined ? `${userInfo},` : ""
          } ${t("admin.hiAdmin2")}`}</Typography>
        </Box>
        <Box>
          <Paper className={classes.Paper} square>
            <Grid container spacing={3} className="welcome-stat">
              {stats.map((data, i) => (
                <Grid item xs={12} sm={12} md={4} key={i}>
                  <CustomCard>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center">
                        <Box mr={1} className="icon-wrap">
                          <i
                            className={`material-icons ${data.iconColor} font-ls`}
                          >
                            {data.icon}
                          </i>
                        </Box>
                        <Box className="font-weight-med">
                          <IntlMessages id={data.title} />
                        </Box>
                      </Box>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          {data.count}
                        </Typography>
                      </Box>
                    </Box>
                  </CustomCard>
                </Grid>
              ))}
            </Grid>
          </Paper>
          <Paper className={classes.Paper} square>
            <Grid container spacing={3} className="stats-wrap">
              <Grid item xs={12} sm={12} md={6}>
                <UserActivities teacherGroups={teacherGroups} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomCard
                  title={`${t("teacher.myGroupsStats")}`}
                  showDivider={true}
                >
                  <DonutEchart
                    data={visitorStats}
                    radius="50%"
                    showLegend={true}
                    height={"364px"}
                    fontSize={10}
                  />
                </CustomCard>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}

export default TeacherDashboard;
