import News from "../../../components/News/News";

const NewsLayout = () => {
  return (
    <div style={{ padding: "0 20px", boxSizing: "border-box" }}>
      <News />
    </div>
  );
};

export default NewsLayout;
