import React from "react";
import { Route, Switch } from "react-router-dom";
import TeacherDashboard from "./dashboard";
import TeacherTimetable from "./timetable";
import TeacherGroups from "./groups";

import GroupStudents from "./groups/students/GroupStudents";

import Attendance from "./groups/attendance/Attendance";
import HomeworkGrades from "./groups/hwGrades/HomeworkGrades";
import ExamGrades from "./groups/examGrades/ExamGrades";

const Teacher = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/dashboard`}
        component={TeacherDashboard}
      ></Route>
      <Route
        exact
        path={`${match.url}/timetable`}
        component={TeacherTimetable}
      ></Route>
      <Route
        exact
        path={`${match.url}/groups`}
        component={TeacherGroups}
      ></Route>
      <Route
        exact
        path={`${match.url}/groups/:id/:name`}
        component={GroupStudents}
      ></Route>
      <Route
        exact
        path={`${match.url}/groups/:groupId/:groupName/:lessonId/:date`}
        component={Attendance}
      ></Route>
      <Route
        exact
        path={`${match.url}/grade/:groupId/:groupName/:hwId/:hwName`}
        component={HomeworkGrades}
      ></Route>
      <Route
        exact
        path={`${match.url}/examGrade/:groupId/:groupName/:examId/:examName`}
        component={ExamGrades}
      ></Route>
    </Switch>
  );
};
export default Teacher;
