//Component
import { TeacherGroupCard } from "../../teacher/groups/Components/TeacherGroupCard";
import {
  Typography,
  Divider,
  Avatar,
  Grid,
  Box,
  Container,
  Button,
  Modal,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import { t } from "i18next";
import { useEffect } from "react";
import {
  getAllUsers,
  searchAllUsers,
  updateRole,
} from "fetch/admin/dashboard/apies";
import { useState } from "react";
import MaterialTable from "material-table";
import CloseIcon from "@material-ui/icons/Close";
import Validator from "validatorjs";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "20px",
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },
}));

const initialState = ["teacher", "student", "parent", "admin"];

const Permissions = () => {
  const [allStudents, setAllStudents] = useState();
  const [allRoles, setAllRoles] = useState(initialState);
  const [selectedRole, setSelectedRole] = useState("teacher");
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [studentsLength, setStudentsLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [disabledPage, setDisabledPage] = useState(false);
  const [disabledPageNext, setDisabledPageNext] = useState(false);
  // const [role, setRole] = useState("");
  const token = JSON.parse(localStorage.getItem("preUni_token"));

  useEffect(() => {
    getAllUsers(token, currentPage)
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
        // setStudentsLength(res.data.length);
        setStudentsLength(res.headers["content-length"]);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [currentPage]);

  // roleType
  const [roleType, setRoleType] = useState({
    roleId: null,
    rule: "required",
    startValidate: false,
  });

  const validationRoleType = new Validator(
    { roleId: roleType.roleId },
    { roleId: roleType.rule }
  );

  const handleRoleType = (e) => {
    setRoleType((prevState) => ({
      ...prevState,
      roleId: e.target.value,
      startValidate: true,
    }));
    console.log(e.target.value);
  };

  // TABLE - START
  const columns = [
    { title: `${t("admin.firsName")}`, field: "first_name" },
    { title: `${t("admin.lastName")}`, field: "last_name" },
    { title: `${t("parent.email")}`, field: "email" },
    { title: `Role`, field: "user_type" },
  ];

  const actions = [
    {
      icon: "edit",
      view: "<h1>test</h1>",
      tooltip: `${t("admin.editStudent")}`,
      onClick: (e, rowData) => openModal(e, rowData),
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const openModal = (e, rowData) => {
    // setAllRoles(allRoles?.filter((role) => role !== rowData.user_type));
    setUserId(rowData?.id);
    setRoleType((prevState) => ({
      ...prevState,
      roleId: rowData.user_type,
    }));
    setOpen(true);
    // setRole(rowData?.user_type);
    console.log(rowData);
  };

  const parseToId = (role) => {
    switch (role) {
      case "student": {
        return 0;
        break;
      }
      case "parent": {
        return 1;
        break;
      }

      case "admin": {
        return 2;
        break;
      }

      case "teacher": {
        return 3;
        break;
      }

      default: {
        return undefined;
        break;
      }
    }
  };

  const update = () => {
    const data = {
      userId: Number(userId),
      roleId: parseToId(roleType.roleId),
    };

    if (roleType.roleId) {
      console.log(data);
      updateRole(data, token)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Pagination
  const nextPage = () => {
    if (users.length && studentsLength - users.length !== currentPage) {
      setDisabledPage(false);
      // getStudents(token, 5, currentPage).then((res) => {
      //   setAllStudents(res.data);
      //   console.log(res);
      // });
      setCurrentPage(currentPage + 5);
    }
  };
  const prevPage = () => {
    setDisabledPageNext(false);
    // getStudents(token, 5, currentPage).then((res) => {
    //   setAllStudents(res.data);
    //   console.log(res);
    // });
    if (currentPage != 0) {
      setDisabledPage(false);
      setCurrentPage(currentPage - 5);
      console.log(currentPage);
    } else {
      setDisabledPage(true);
    }
  };
  // TABLE - END

  // Search
  //   fullname
  const [searchStudentInputs, setSearchStudentInputs] = useState({
    fullname: "",
    rule: "required",
    startValidate: false,
  });
  let validationSearchStudent = new Validator(
    { fullname: searchStudentInputs.fullname },
    { fullname: searchStudentInputs.rule }
  );
  const handleSearchStudent = (e) => {
    const result = e.target.value.replace(
      /[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi,
      ""
    );
    setSearchStudentInputs((prevState) => ({
      ...prevState,
      fullname: result,
      startValidate: true,
    }));
    if (result?.length === 0) {
      getAllUsers(token, currentPage)
        .then((res) => {
          setUsers(res.data);
          setLoading(false);
          // setStudentsLength(res.data.length);
          setStudentsLength(res.headers["content-length"]);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    // if (e.target.value == "") {
    //   alert("Show all student");
    // }
  };

  const [attendanceErr, setAttendanceErr] = useState(false);
  const searchStudentBtn = (e) => {
    const name = searchStudentInputs.fullname.toUpperCase();
    const userType = selectedRole === "all" ? null : selectedRole;
    // e.preventDefault();
    if (validationSearchStudent.passes()) {
      console.log(name);
      setAttendanceErr(false);
      searchAllUsers(token, name, userType)
        .then((res) => {
          console.log(res);
          if (res?.data?.length) {
            console.log(allStudents);
            setUsers(res.data);
          } else {
            setAttendanceErr(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setAttendanceErr(true);
        });
    } else {
      setSearchStudentInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  const handleSelectRole = (e) => {
    setSelectedRole(e.target.value);
  };

  const getKeyCode = (e) => e.keyCode === 13 && searchStudentBtn();

  return (
    <Container maxWidth="lg">
      <Box px={{ xs: "12px", lg: 0 }} pt={4} className="page-space">
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
        >
          <div
            style={modalStyle}
            className={`${classes.paper} modalForAddCourse`}
          >
            <Box my={3} display="flex" justifyContent="space-between">
              <Typography variant="h6">Edit role</Typography>
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </Box>
            <Box my={3}>
              <FormControl required fullWidth variant="outlined" size="small">
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  shrink={true}
                >
                  Select role:
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-outlined-label"
                  label={`${t("admin.selectCourseType")}:`}
                  id="demo-simple-select-outlined"
                  variant="outlined"
                  value={roleType.roleId}
                  onChange={handleRoleType}
                >
                  {allRoles?.map((role, index) => (
                    <MenuItem value={role} key={index}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box my={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => update(e)}
              >
                Update
              </Button>
            </Box>
          </div>
        </Modal>
        {/*Search Name*/}
        <Box
          py={2}
          px={2}
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            justifyContent: "space-between",
          }}
        >
          <FormControl error fullWidth>
            <TextField
              required
              fullWidth
              type="text"
              id="outlined-required"
              variant="outlined"
              className="outlined-input"
              placeholder={`${t("parent.firsname")}:`}
              InputLabelProps={{
                shrink: true,
                pl: "2px",
              }}
              value={searchStudentInputs.fullname}
              onChange={(e) => handleSearchStudent(e)}
              onKeyUp={(e) => getKeyCode(e)}
              error={
                searchStudentInputs.startValidate &&
                (validationSearchStudent.passes() === true ? false : true)
              }
            />
            <FormHelperText>
              {searchStudentInputs.startValidate &&
                validationSearchStudent.errors.first("fullname")}
            </FormHelperText>
          </FormControl>
          <FormControl
            required
            fullWidth
            variant="outlined"
            size="small"
            className="ms-3"
            style={{ width: "20%" }}
          >
            <InputLabel id="demo-simple-select-outlined-label" shrink={true}>
              {t("admin.selectRole")}:
            </InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-outlined-label"
              label={`${t("admin.selectCourseType")}:`}
              id="demo-simple-select-outlined"
              variant="outlined"
              value={selectedRole}
              onChange={handleSelectRole}
            >
              {allRoles?.map((role, inx) => (
                <MenuItem value={role} key={inx}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            className="ms-3"
            color="primary"
            onClick={(e) => searchStudentBtn(e)}
          >
            {t("searchPlaceholder")}
          </Button>
        </Box>
        {attendanceErr ? (
          <div className="alert alert-warning" role="alert">
            {t("admin.studentNotFound")}
          </div>
        ) : (
          ""
        )}
        <MaterialTable
          title={`All users:`}
          columns={columns}
          data={users}
          actions={actions}
          localization={{
            header: {
              actions: `${t("admin.actions")}`,
            },
          }}
          options={{
            actionsColumnIndex: -1,
            paging: false,
            search: false,
          }}
        />
      </Box>
      <Box my={2}>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end align-items-center">
            <span className="me-2">{`${
              currentPage + users?.length
            } of ${studentsLength}`}</span>
            <li
              className="page-item"
              style={{ cursor: "pointer" }}
              onClick={(e) => prevPage(e)}
              disabled={disabledPage}
            >
              <a
                className="page-link"
                aria-label="Previous"
                style={
                  disabledPage
                    ? { backgroundColor: "#e9ecef" }
                    : { position: "relative" }
                }
              >
                <span aria-hidden="true">«</span>
              </a>
            </li>

            <li
              className="page-item"
              style={{ cursor: "pointer" }}
              onClick={(e) => nextPage(e)}
              disabled={disabledPageNext}
            >
              <a
                className="page-link"
                aria-label="Next"
                style={
                  disabledPageNext
                    ? { backgroundColor: "#e9ecef" }
                    : { position: "relative" }
                }
              >
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </Box>
    </Container>
  );
};

export default Permissions;
