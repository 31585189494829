import React, { useEffect, useState } from "react";
import { t } from "i18next";
import {
  Container,
  Box,
  Button,
  Icon,
  Grid,
  Divider,
  Typography,
  Modal,
  FormControl,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { getTeacherById } from "fetch/student";
import { TeacherGroupCard } from "Users/teacher/groups/Components/TeacherGroupCard";
import { getLessonsByGroupId } from "fetch/teacher/groups/apies";
import { getGroupAttend } from "fetch/admin/students/apies";
import { NotificationManager } from "react-notifications";
import { makeStyles } from "@material-ui/styles";
import Validator from "validatorjs";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "20px",
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },
}));

function AdminTeachersInfo(props) {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const teacherId = props.match.params.id;
  const [teacherGroups, setTeacherGroups] = useState();
  const [groupId, setGroupId] = useState(null);
  // Modal
  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log(teacherId);
    getTeacherById(token, teacherId)
      .then((res) => {
        setTeacherGroups(res.data);
        console.log(res);
        // res?.data?.groups?.map((group) => {
        //   getGroupAttend(token, group.id)
        //     .then((res) => {
        //       console.log(res);
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
        //   // return group.id;
        // });
        // setGroupsId(ids);
        // console.log("groupsId: ", groupsId);
      })
      .catch((err) => {
        console.log(err);
      });

    // getLessonsByGroupId()
  }, []);
  const goBack = (e) => {
    e.preventDefault();
    props.history.goBack();
  };

  const navigateBtn = (e, link) => {
    e.preventDefault();
    console.log(link);
    props.history.push(
      `${process.env.PUBLIC_URL}/app/admin/teachers/${teacherId}/${link}`
    );
  };

  const formatToEnter = (text) => {
    const resultDesc = text.split("\n");

    return (
      <div>
        {resultDesc?.map((item, index) => (
          <p>{item}</p>
        ))}
      </div>
    );
  };

  const downloadExel1 = (e, data) => {
    console.log(data);
    getGroupAttend(token, data?.id)
      .then((res) => {
        console.log(res);
        window.open(
          `${process.env.REACT_APP_PRE_UNI_API}/attendances/excel?groupId=${data?.id}`,
          "_blank",
          "noopener,noreferrer"
        );
        // history.push(
        //   `${process.env.REACT_APP_PRE_UNI_API}/users/${studentId}/passport`
        // );
      })
      .catch((err) => {
        NotificationManager.warning(`${t("admin.notifAttenErr")}`);
        // console.log("ATTENDANCES NOT FOUND");
      });
  };

  // From To ===== START =====
  const [fromDateInputs, setFromDateInputs] = useState({
    fromDate: "",
  });

  const handleFromDate = (e) => {
    setFromDateInputs((prevState) => ({
      ...prevState,
      fromDate: e.target.value,
    }));
  };
  // From date
  const [toDateInputs, setToDateInputs] = useState({
    toDate: "",
  });

  const handletoDate = (e) => {
    setToDateInputs((prevState) => ({
      ...prevState,
      toDate: e.target.value,
    }));
  };
  // From To ===== END =====

  // open and close Modal
  const handleClose = () => {
    setOpen(false);
    setFromDateInputs((prevState) => ({
      ...prevState,
      fromDate: "",
    }));
    setToDateInputs((prevState) => ({
      ...prevState,
      toDate: "",
    }));
  };

  const openModal = (e, data) => {
    setOpen(true);
    setGroupId(data.id);
  };

  // Download excel
  const downloadExel = () => {
    const from = fromDateInputs.fromDate;
    const to = toDateInputs.toDate;
    console.log("groupId: ", groupId);
    // console.log("From: ", from);
    // console.log("To: ", to);

    if ((from === "" && to !== "") || (from !== "" && to === "")) {
      NotificationManager.warning(`Please fill all fields!`);
    } else {
      getGroupAttend(token, Number(groupId), from, to)
        .then((res) => {
          window.open(
            `${
              process.env.REACT_APP_PRE_UNI_API
            }/attendances/excel?groupId=${groupId}${
              from && to ? `&from=${from}&to=${to}` : ""
            }`,
            "_blank",
            "noopener,noreferrer"
          );
          handleClose();
        })
        .catch((err) => {
          NotificationManager.warning(`${t("admin.notifAttenErr")}`);
        });
    }
  };

  return (
    <div className="tables-wrapper search-table-wrap">
      <Container maxWidth="lg">
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
        >
          <div
            style={modalStyle}
            className={`${classes.paper} modalForAddCourse`}
          >
            <Box my={3} style={{ display: "flex" }}>
              <FormControl style={{ marginRight: "8px" }} fullWidth error>
                <TextField
                  required
                  fullWidth
                  type="date"
                  id="outlined-required"
                  variant="outlined"
                  className="outlined-input"
                  label={`${t("admin.from")}:`}
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={fromDateInputs.fromDate}
                  onChange={(e) => handleFromDate(e)}
                />
                <FormHelperText style={{ color: "#717275" }}>
                  {t("optional")}
                </FormHelperText>
              </FormControl>
              <FormControl style={{ marginRight: "8px" }} fullWidth error>
                <TextField
                  required
                  fullWidth
                  type="date"
                  id="outlined-required"
                  variant="outlined"
                  className="outlined-input"
                  label={`${t("admin.to")}:`}
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={toDateInputs.toDate}
                  onChange={(e) => handletoDate(e)}
                />
              </FormControl>
            </Box>

            <Box my={3} className="d-flex justify-content-end">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                className="me-2"
              >
                {t("parent.cancel")}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={downloadExel}
              >
                {t("admin.dowloadExcel")}
              </Button>
            </Box>
          </div>
        </Modal>
        <Box px={{ xs: "12px", lg: 0 }} className="page-space">
          <Button color="primary" onClick={goBack}>
            <Icon style={{ fontSize: 18, marginRight: "2px" }}>
              arrow_back{" "}
            </Icon>
            {t("parent.back")}
          </Button>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={6}>
            <TeacherGroupCard showDivider={true}>
              <div className="d-flex justify-content-between">
                <Typography variant="h6" color="textPrimary">
                  All groups
                </Typography>
                <Typography variant="h6" color="textPrimary">
                  {/* Teacher:{" "} */}
                  <span style={{ cursor: "pointer", color: "#4d7df2" }}>
                    {`${teacherGroups?.first_name} ${teacherGroups?.last_name}`}
                  </span>
                </Typography>
              </div>
              {teacherGroups && teacherGroups?.groups?.length !== 0
                ? teacherGroups.groups.map((item, index) => (
                    <Box key={index} className="user-activity">
                      <Box display="flex" p={{ xs: 2, md: 3 }}>
                        <Box
                          pl={2}
                          pr={{ xs: 0, md: 2 }}
                          className="content-wrap"
                        >
                          <Box>
                            <Box
                              display={{ xs: "block", sm: "flex" }}
                              alignItems="center"
                              mb={1}
                            >
                              <Box
                                pr="5px"
                                className="user-name"
                                component="span"
                                fontWeight={500}
                              >
                                {item.name}
                              </Box>
                              {/* <Typography variant="subtitle2">{item.username}</Typography> */}
                            </Box>
                            <Typography variant="body2" color="textPrimary">
                              {formatToEnter(item.description)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="end" mb="10px">
                        <Button
                          style={{
                            backgroundColor: "rgb(11 121 70)",
                            color: "#fff",
                          }}
                          className="me-2"
                          variant="contained"
                          // color="primary"
                          // onClick={(e) => downloadExel(e, item)}
                          onClick={(e) => openModal(e, item)}
                        >
                          Excel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) =>
                            navigateBtn(
                              e,
                              `${item.id}/${encodeURIComponent(
                                item.name
                              )}/${teacherId}`
                            )
                          }
                        >
                          {t("teacher.manageGroup")}
                        </Button>
                      </Box>
                      <Divider />
                    </Box>
                  ))
                : `${t("teacher.noGorups")}`}
            </TeacherGroupCard>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default AdminTeachersInfo;
