import appLocaleData from 'react-intl/locale-data/uz';
import uzMessages from '../locales/uz';

const UzLang = {
    messages: {
        ...uzMessages
    },
    locale: 'uz',
    data: appLocaleData
};
export default UzLang;