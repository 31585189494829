import React, { useState } from "react";
import { Box, Typography, AppBar, Tabs, Tab } from "@material-ui/core";
import { Link } from "react-router-dom";
import Register from "./Register";
import Login from "./Login";
import { t } from "i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Tabset() {
  const [value, setValue] = useState(0);
  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <div>
      <div className="session-wrapper session-wrapper-v2">
        <Box
          className="session-box"
          mx="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="100%">
            {/* <Box textAlign="center" className="session-logo" >
                            {isDarkModeActive ?
                                <img className="img-fluid" alt="img" width="100" src={require(`assets/Images/hulk-light.png`).default} />
                                :
                                <img className="img-fluid" alt="img" width="100" src={require(`assets/Images/hulk-dark.png`).default} />
                            }
                        </Box> */}
            <div className="log-tab">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={t("login")} {...a11yProps(0)} />
                  <Tab label={t("register")} {...a11yProps(1)} />
                  <Box
                    label="Home"
                    style={{ position: "absolute", right: "9%", top: "22%" }}
                  >
                    <Link to={"/home"}>
                      <span
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      >
                        <img
                          src="https://res.cloudinary.com/apple-angren/image/upload/v1658748333/my-icons/home-gray_cz3gzh.png"
                          alt=""
                          style={{ width: "23px" }}
                        />
                      </span>
                    </Link>
                  </Box>
                </Tabs>
              </AppBar>
            </div>
            <TabPanel value={value} index={0} className="log-box">
              <Login />
            </TabPanel>
            <TabPanel value={value} index={1} className="log-box">
              <Register />
            </TabPanel>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default Tabset;
