/**
 * Hulk Page Loader
 */

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const HulkPageLoader = () => (
  <div className="hk-page-loader">
    <CircularProgress />
  </div>
);

export { HulkPageLoader };
