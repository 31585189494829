/**
 * User Detail
 */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  Typography,
  Fab,
  Box,
  Divider,
  Button,
  Modal,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { t } from "i18next";

import { SocialIcons } from "components/GlobalComponents";
import { getContract, getUserById } from "fetch/parent/children/apies";
import {
  getStudent,
  getStudentCourses,
  getStudentPassport,
} from "fetch/admin/students/apies";

const useStyles = makeStyles((theme) => ({
  fab: {
    boxShadow: "none",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  socialIcons: {
    "& a": {
      fontSize: 32,
      marginLeft: "5px",
      marginRight: "5px",
      "&:first-child": {
        marginLeft: 0,
      },
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
}));

export default function UserDetail(props) {
  let history = useHistory();
  const { studentData } = props;
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  let currentURL = window.location.href;
  const studentId = currentURL.split("/")[6];

  const [userPassport, setUserPassport] = useState();
  const [parentInfo, setParentInfo] = useState();

  useEffect(() => {
    getUserById(token, studentId).then((res) => {
      setUserPassport(res.data);
    });
    // getStudent(token, Number(studentId)).then((res) => {
    //   setParentInfo(res.data);
    // });
  }, []);

  const [studentCourses, setStudentCourses] = useState([]);
  useEffect(() => {
    if (studentId) {
      getStudentCourses(token, studentId).then((res) => {
        setStudentCourses(res.data);
      });
    }
  }, [studentId]);
  console.log(studentCourses);
  let studentCoursesCounter = 0;
  studentCourses?.forEach((element) => {
    if (element.course !== null) {
      studentCoursesCounter++;
    }
  });

  const classes = useStyles();
  // useEffect(() => {
  //   getContract(studentId, token).then((res) => {
  //     console.log("log-1", res);
  //   });
  // }, []);
  // const handeContract = () => {
  //   getContract(studentId, token).then((res) => {
  //     console.log("log-2", res);
  //   });
  // };

  const toPassImg = () => {
    getStudentPassport(studentId)
      .then((res) => {
        window.open(
          `${process.env.REACT_APP_PRE_UNI_API}/users/${studentId}/passport`,
          "_blank",
          "noopener,noreferrer"
        );
        // history.push(
        //   `${process.env.REACT_APP_PRE_UNI_API}/users/${studentId}/passport`
        // );
      })
      .catch((err) => {
        NotificationManager.warning("Not Found Passport");
      });
  };
  return (
    <div className="">
      <Box py={3} className="user-detail">
        <div className="user-avatar">
          <img
            src={require(`assets/Images/avatars/user.png`).default}
            alt="user images"
            className="avatar-wrap"
          />
        </div>
        <Typography variant="h6" style={{ marginBottom: "5px" }}>
          {userPassport && userPassport.first_name}{" "}
          {userPassport && userPassport.last_name}
        </Typography>
        {/* <Box mb={2} fontSize="subtitle2.fontSize" color="text.secondary">Production Mananger,Mistre Inc</Box> */}
        <div className="d-flex justify-content-center">
          <a
            href={`tel:${userPassport?.phone_number}`}
            className="d-flex"
            style={{ color: "#717275" }}
          >
            <Fab className={classes.fab} size="small" aria-label="phone">
              <i className="material-icons-outlined">phone_enabled</i>
            </Fab>
          </a>
          <a
            href={`mailto:${userPassport?.email}`}
            className="d-flex"
            style={{ color: "#717275" }}
          >
            <Fab className={classes.fab} size="small" aria-label="email">
              <i className="material-icons-outlined">mail_outline</i>
            </Fab>
          </a>
          <a
            onClick={toPassImg}
            target="_blank"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Fab className={classes.fab} size="small" aria-label="image">
              <img
                src="https://res.cloudinary.com/apple-angren/image/upload/v1657712733/id-card_miuyiy.png"
                alt=""
                style={{ width: "25px" }}
              />
            </Fab>
          </a>
        </div>
      </Box>
      <Divider />
      <div>
        <Box py={2}>
          <Box mb={2}>
            {studentCoursesCounter > 0 ? (
              <a
                target={"_blank"}
                href={`https://cec.inha.uz/api/students/${studentId}/contract`}
                className="btn btn-primary"
              >
                {t("parent.dowloadContract")}
              </a>
            ) : (
              ""
            )}
          </Box>
          <Box mb={2} fontSize="subtitle1.fontSize">
            {t("parent.contactInformation")}
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle2">
              {t("parent.emailAddres")}:
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {userPassport && (
                <a href={`mailto:${userPassport.email}`}>
                  {userPassport.email}
                </a>
              )}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle2">{t("parent.phone")}:</Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {userPassport && (
                <a href={`tel:${userPassport.phone_number}`}>
                  +{userPassport.phone_number}
                </a>
              )}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">{t("parent.address")}:</Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {userPassport && userPassport.address}
            </Typography>
          </Box>
          <Box my={2} fontSize="subtitle1.fontSize">
            {t("admin.parentInfo")}
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle2">{t("admin.fullName")}:</Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {userPassport &&
                `${userPassport.parent.first_name} ${userPassport.parent.last_name} ${userPassport?.parent?.middle_name}`}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle2">{t("parent.birthday")}:</Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {userPassport
                ? userPassport?.parent?.birth_date !== null
                  ? `${moment(userPassport.parent.birth_date)
                      .format("DD-MM-YYYY")
                      .substring(0, 10)}`
                  : ""
                : ""}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle2">
              {t("parent.emailAddres")}:
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {userPassport && (
                <a href={`mailto:${userPassport.parent.email}`}>
                  {userPassport.parent.email}
                </a>
              )}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle2">{t("parent.phone")}:</Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {userPassport && (
                <a href={`tel:${userPassport.parent.phone_number}`}>
                  +{userPassport.parent.phone_number}
                </a>
              )}
            </Typography>
          </Box>
        </Box>
        <Divider />
        {/* <Box display="flex" py={2} px={{ xs: 0, md: '12px' }} justifyContent="space-between" >
					<Box textAlign="center">
						<Typography variant="subtitle2">Followers</Typography>
						<Typography variant="body2" color="textPrimary">1,238</Typography>
					</Box>
					<Box textAlign="center">
						<Typography variant="subtitle2">Following</Typography>
						<Typography variant="body2" color="textPrimary">1,008</Typography>
					</Box>
					<Box textAlign="center">
						<Typography variant="subtitle2">Posts</Typography>
						<Typography variant="body2" color="textPrimary">638</Typography>
					</Box>
				</Box> */}
        {/* <Divider /> */}
        {/* <Box className={classes.socialIcons} py={3} textAlign="center">
					<SocialIcons />
				</Box> */}
        {/* <Divider /> */}
      </div>
    </div>
  );
}
