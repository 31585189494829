import React, { useEffect, useState } from "react";
import {
  Tabs,
  InputLabel,
  TableBody,
  Table,
  TableContainer,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  Input,
  MenuItem,
  Modal,
  Select,
  Typography,
  TextField,
} from "@material-ui/core";
import Validator from "validatorjs";
import { SmallTitleBar } from "components/GlobalComponents";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import { useHistory } from "react-router-dom";
import { t } from "i18next";
import {
  getFromToAtten,
  getFromToExam,
  getFromToExamByFirstname,
  getGroupsById,
  getUserByFirstname,
} from "fetch/admin/groups/apies";
import moment from "moment";
import {
  getAttendance,
  getAttendanceByGroupId,
  getExamGrades,
  getExams,
  getLessonsGrade,
} from "fetch/teacher/groups/apies";
import {
  getStudentsAttend,
  getStudentsExams,
} from "fetch/admin/students/apies";
import { NotificationManager } from "react-notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-paddingNone": {
      padding: "0 16px",
    },
    "& .MuiTableCell-body": {
      lineHeight: 1,
    },
    "& .MuiToolbar-root": {
      paddingRight: 20,
      "& >div:first-child": {
        fontSize: "1.25rem",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 500,
      },
    },
  },
  paper: {
    position: "absolute",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%, -50%) !important",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  appWrap: {
    boxShadow: "none",
  },
  toolbar: {
    padding: "0",
    marginLeft: -12,
    marginRight: -12,
    "& button": {
      minHeight: 50,
    },
    "& .MuiTab-wrapper": {
      fontSize: "1rem",
    },
    "& .Mui-selected": {
      backgroundColor: `rgba(0,0,0,0.1)`,
    },
  },
  content: {},
  menuButton: {},
}));
const GroupsInfo = () => {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const url = window.location.href;
  const groupId = url.split("/")[6];
  const [value, setValue] = useState("1");
  let history = useHistory();
  const [attendanceErr, setAttendanceErr] = useState(false);
  const [examErr, setExamErr] = useState(false);
  const [searchedStudent, setSearchedStudent] = useState([]);
  const [searchedStudentExam, setSearchedStudentExam] = useState([]);
  const [changeBtn, setChangeBtn] = useState("generate");
  const [changeExamBtn, setChangeExamBtn] = useState("generate");
  const [lastLesson, setLastLesson] = useState([]);
  const [lastExam, setLastExam] = useState([]);
  const [examsList, setExamsList] = useState([]);

  const [group, setGroup] = useState();

  useEffect(() => {
    getGroupsById(token, groupId).then((res) => {
      setGroup(res.data);
      console.log("group: ", res.data);

      //   for (let i = 0; i < res?.data?.length; i++) {
      //     if (res?.data?.[i].id == res?.data?.[0].id) {
      //       console.log(res.data[i]);
      //     }
      //   }
    });

    getAttendanceByGroupId(token, groupId)
      .then((res) => {
        const lastLessonId = res?.data?.[0]?.lesson?.id;
        const sortLasstId = res.data.filter((item) => item.student !== null);
        console.log(sortLasstId);
        const sorted = res.data.filter(
          (item) =>
            item.student !== null &&
            item.lesson.id === sortLasstId?.[0]?.lesson?.id
        );
        setLastLesson(sorted);
        console.log("sorted: ", sorted);
        console.log(res, "LAST LESSON");
      })
      .catch((err) => {
        console.log(err);
      });

    getExamGrades(token, groupId)
      .then((res) => {
        console.log(res);
        const sortLasstId = res.data.filter(
          (item) => item.exam !== null && item.student !== null
        );
        const lastExamIndex = sortLasstId?.length - 1;
        const sorted = res.data.filter(
          (item) =>
            item.student !== null &&
            item.exam !== null &&
            item.exam.id === sortLasstId?.[lastExamIndex]?.exam?.id
        );
        console.log(sortLasstId, "EXAMS LIST");
        setLastExam(sorted);
        // console.log(sorted);
      })
      .catch((err) => {
        console.log(err);
      });

    getExams(token, Number(groupId)).then((res) => {
      console.log(res);
      const sortedExam = res.data.filter((item) => item.isExam !== false);
      console.log(sortedExam);
      setExamsList(sortedExam);
      getLessonsGrade(token, Number(groupId), Number(sortedExam?.[0]?.id)).then(
        (res) => {
          setLastExam(res?.data);
          console.log("LESSON GRADES", lastExam);
        }
      );
    });
  }, []);

  // const toDowload = () => {
  //   getStudentsAttend(groupId, token)
  //     .then((res) => {
  //       console.log(res);
  //       window.open(
  //         `${process.env.REACT_APP_PRE_UNI_API}/attendances/excel?groupId=${groupId}`,
  //         "_blank",
  //         "noopener,noreferrer"
  //       );
  //     })
  //     .catch((err) => {
  //       NotificationManager.warning("Attendance Not Found");
  //       // console.log("ATTENDANCES NOT FOUND");
  //     });
  // };

  // ======================= Attendance START =====================================
  const [fromDateInputs, setFromDateInputs] = useState({
    fromDate: "",
    rule: "required",
    startValidate: false,
  });
  let validationFromDate = new Validator(
    { fromDate: fromDateInputs.fromDate },
    { fromDate: fromDateInputs.rule }
  );
  const handleFromDate = (e) => {
    setFromDateInputs((prevState) => ({
      ...prevState,
      fromDate: e.target.value,
      startValidate: true,
    }));
  };
  // From date
  const [toDateInputs, setToDateInputs] = useState({
    toDate: "",
    rule: "required",
    startValidate: false,
  });
  let validationToDate = new Validator(
    { toDate: toDateInputs.toDate },
    { toDate: toDateInputs.rule }
  );
  const handletoDate = (e) => {
    setToDateInputs((prevState) => ({
      ...prevState,
      toDate: e.target.value,
      startValidate: true,
    }));
    getStudentsAttend(groupId, fromDateInputs.fromDate, e.target.value, token)
      .then((res) => {
        console.log(res);
        // window.open(
        //   `${process.env.REACT_APP_PRE_UNI_API}/attendances/excel?groupId=${groupId}`,
        //   "_blank",
        //   "noopener,noreferrer"
        // );
        // history.push(
        //   `${process.env.REACT_APP_PRE_UNI_API}/users/${studentId}/passport`
        // );
      })
      .catch((err) => {
        NotificationManager.warning(`${t("admin.notifAttenErr")}`);
        // console.log("ATTENDANCES NOT FOUND");
      });
  };
  // fullname
  const [fullnameInputs, setFullnameInputs] = useState({
    fullname: "",
    rule: "required",
  });
  // let validationFullname = new Validator(
  //   { fullname: fullnameInputs.fullname },
  //   { fullname: fullnameInputs.rule }
  // );
  const handleFullname = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setFullnameInputs((prevState) => ({
      ...prevState,
      fullname: result,
    }));
  };

  // GenerateBtn

  // const frstName = fullnameInputs.fullname.split(" ")[0];
  const frstName = fullnameInputs.fullname;
  // const lastName = fullnameInputs.fullname.split(" ")[1];
  const fromDate = fromDateInputs.fromDate;
  const toDate = toDateInputs.toDate;
  const toDowload = () => {
    console.log(fromDate);
    console.log(toDate);
    getStudentsAttend(groupId, fromDate, toDate, token)
      .then((res) => {
        console.log(res);
        window.open(
          `${process.env.REACT_APP_PRE_UNI_API}/attendances/excel?groupId=${groupId}`,
          "_blank",
          "noopener,noreferrer"
        );
        // history.push(
        //   `${process.env.REACT_APP_PRE_UNI_API}/users/${studentId}/passport`
        // );
      })
      .catch((err) => {
        NotificationManager.warning(`${t("admin.notifAttenErr")}`);
        // console.log("ATTENDANCES NOT FOUND");
      });
  };

  const generateBtn = (e) => {
    if (validationFromDate.passes() && validationToDate.passes()) {
      setAttendanceErr(false);
      if (fullnameInputs.fullname == "") {
        getFromToAtten(fromDate, toDate, groupId, token)
          .then((res) => {
            console.log(res);
            const sorted = res.data.filter(
              (item) => item.student !== null && item.lesson !== null
            );
            console.log("sorted: ", sorted);
            console.log("res: ", res);
            if (sorted?.length) {
              setSearchedStudent(sorted);
              // setChangeBtn("clear");
            } else {
              setAttendanceErr(true);
            }
            //   if (res?.data?.length > 0) {
            //     console.log(res?.data);
            //     setAttendanceErr(false);
            //     for (let i = 0; i < res?.data?.length; i++) {
            //       if (
            //         res?.data?.[i]?.student?.first_name == frstName &&
            //         res?.data?.[i]?.student?.last_name == lastName
            //       ) {
            //         setLastLesson([]);

            //         setSearchedStudent((prevState) => [...prevState, res?.data[i]]);
            //         setChangeBtn("clear");
            //         console.log(searchedStudent);
            //       } else {
            //         setAttendanceErr(true);
            //         console.log("This student not found");
            //         // if (!searchedStudent) {
            //         //   console.log("This student not found");
            //         // }
            //       }
            //     }
            //     // setSearchedStudent(res.data);
            //     // console.log(res.data);
            //     console.log(searchedStudent);
            //   } else {
            //     setAttendanceErr((current) => !current);
            //   }
          })
          .catch((err) => {
            console.log(err, "ERROR");
            setAttendanceErr(true);
          });
      } else {
        getUserByFirstname(frstName, groupId, token)
          .then((res) => {
            console.log(res);
            if (res?.data?.length) {
              setSearchedStudent(res?.data);
            } else {
              setAttendanceErr(true);
            }
          })
          .catch((err) => {
            console.log(err, "ERROR");
            setAttendanceErr(true);
          });
      }
    } else {
      // setFullnameInputs((prevState) => ({
      //   ...prevState,
      //   startValidate: true,
      // }));

      setFromDateInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setToDateInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };
  const clearBtn = (e) => {
    setSearchedStudent([]);

    setFromDateInputs((prevState) => ({
      ...prevState,
      fromDate: "",
    }));
    setToDateInputs((prevState) => ({
      ...prevState,
      toDate: "",
    }));
    setFullnameInputs((prevState) => ({
      ...prevState,
      fullname: "",
    }));
    getAttendanceByGroupId(token, groupId)
      .then((res) => {
        setChangeBtn("generate");
        const lastLessonId = res?.data?.[0]?.lesson?.id;
        const sortLasstId = res.data.filter((item) => item.student !== null);
        console.log(sortLasstId);
        const sorted = res.data.filter(
          (item) =>
            item.student !== null &&
            item.lesson.id === sortLasstId?.[0]?.lesson?.id
        );
        setLastLesson(sorted);
        console.log("sorted: ", sorted);
        console.log(res, "LAST LESSON");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getKeydown = (e) => {
    if (e.keyCode == 13) {
      generateBtn();
    }
  };
  // ======================= Attendance END =====================================
  // ======================= Exam START =====================================
  const [fromDateExamInputs, setFromDateExamInputs] = useState({
    fromDate: "",
    rule: "required",
    startValidate: false,
  });
  let validationFromDateExam = new Validator(
    { fromDate: fromDateExamInputs.fromDate },
    { fromDate: fromDateExamInputs.rule }
  );
  const handleFromDateExam = (e) => {
    setFromDateExamInputs((prevState) => ({
      ...prevState,
      fromDate: e.target.value,
      startValidate: true,
    }));
  };
  // From date
  const [toDateExamInputs, setToDateExamInputs] = useState({
    toDate: "",
    rule: "required",
    startValidate: false,
  });
  let validationToDateExam = new Validator(
    { toDate: toDateExamInputs.toDate },
    { toDate: toDateExamInputs.rule }
  );
  const handletoDateExam = (e) => {
    setToDateExamInputs((prevState) => ({
      ...prevState,
      toDate: e.target.value,
      startValidate: true,
    }));
    getStudentsExams(
      groupId,
      fromDateExamInputs.fromDate,
      e.target.value,
      token
    )
      .then((res) => {
        console.log(res);
        // window.open(
        //   `${process.env.REACT_APP_PRE_UNI_API}/exam-grades/excel?groupId=${groupId}`,
        //   "_blank",
        //   "noopener,noreferrer"
        // );
        // history.push(
        //   `${process.env.REACT_APP_PRE_UNI_API}/users/${studentId}/passport`
        // );
      })
      .catch((err) => {
        // NotificationManager.warning("Exams Not Found");
        // console.log("ATTENDANCES NOT FOUND");
      });
  };
  // gender
  const [genderInputs, setGenderInputs] = useState({
    gender: "",
    rule: "required",
    startValidate: false,
  });
  let validationGender = new Validator(
    { gender: genderInputs.gender },
    { gender: genderInputs.rule }
  );
  const handleGender = (e) => {
    console.log(e.target.value);
    setGenderInputs((prevState) => ({
      ...prevState,
      gender: e.target.value,
      startValidate: true,
    }));
  };
  // fullname
  const [fullnameExamInputs, setFullnameExamInputs] = useState({
    fullname: "",
    rule: "required",
  });
  // let validationFullnameExam = new Validator(
  //   { fullname: fullnameExamInputs.fullname },
  //   { fullname: fullnameExamInputs.rule }
  // );
  const handleFullnameExam = (e) => {
    const result = e.target.value
      .toUpperCase()
      .replace(/[^a-z 0-9 [.*+?^=!:${}()|\[\]\/\\] " "]/gi, "");
    setFullnameExamInputs((prevState) => ({
      ...prevState,
      fullname: result,
    }));
  };

  // GenerateBtn
  const frstNameExam = fullnameExamInputs.fullname;
  // const lastNameExam = fullnameExamInputs.fullname.split(" ")[1];
  const fromDateExam = fromDateExamInputs.fromDate;
  const toDateExam = toDateExamInputs.toDate;
  const toDowloadExam = () => {
    getStudentsExams(groupId, fromDateExam, toDateExam, token)
      .then((res) => {
        console.log(res);
        window.open(
          `${process.env.REACT_APP_PRE_UNI_API}/lessons/excel?groupId=${groupId}`,
          "_blank",
          "noopener,noreferrer"
        );
        // history.push(
        //   `${process.env.REACT_APP_PRE_UNI_API}/users/${studentId}/passport`
        // );
      })
      .catch((err) => {
        NotificationManager.warning(`${t("admin.notifExamErr")}`);
        // console.log("ATTENDANCES NOT FOUND");
      });
  };
  const generateExamBtn = (e) => {
    // console.log(Number(genderInputs.gender));
    if (
      validationFromDateExam.passes() &&
      validationToDateExam.passes() &&
      validationGender.passes()
    ) {
      setExamErr(false);
      if (fullnameExamInputs.fullname == "") {
        console.log(genderInputs);
        getFromToExam(fromDateExam, toDateExam, groupId, token)
          .then((res) => {
            // console.log(frstNameExam);
            // console.log(lastNameExam);
            const sorted = res.data.filter(
              (item) => item.exam.id === Number(genderInputs.gender)
            );
            console.log("sorted: ", sorted);
            // console.log("res: ", res);
            if (sorted?.length) {
              setSearchedStudentExam(sorted);
              // setChangeExamBtn("clear");
            } else {
              setExamErr(true);
            }
          })
          .catch((err) => {
            console.log(err, "ERROR");
            setExamErr(true);
          });
      } else {
        getFromToExamByFirstname(
          fromDateExam,
          toDateExam,
          Number(groupId),
          frstNameExam,
          token,
          Number(examsList?.[0]?.id)
        )
          .then((res) => {
            // console.log(fromDateExam, toDateExam, groupId, frstNameExam, token);
            // console.log(lastNameExam);
            // const sorted = res.data.filter(
            //   (item) => item.exam.id === Number(genderInputs.gender)
            // );
            // console.log("sorted: ", sorted);
            console.log("res: ", res);
            if (res?.data?.length) {
              setSearchedStudentExam(res.data);
              // setChangeExamBtn("clear");
            } else {
              setExamErr(true);
            }
          })
          .catch((err) => {
            console.log(err, "ERROR");
            setExamErr(true);
          });
      }
    } else {
      // setFullnameExamInputs((prevState) => ({
      //   ...prevState,
      //   startValidate: true,
      // }));
      setFromDateExamInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setToDateExamInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setGenderInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };
  const clearExamBtn = (e) => {
    setSearchedStudentExam([]);

    setFromDateExamInputs((prevState) => ({
      ...prevState,
      fromDate: "",
    }));
    setToDateExamInputs((prevState) => ({
      ...prevState,
      toDate: "",
    }));
    setFullnameExamInputs((prevState) => ({
      ...prevState,
      fullname: "",
    }));
    getExamGrades(token, groupId)
      .then((res) => {
        setChangeExamBtn("generate");
        const sortLasstId = res.data.filter(
          (item) => item.exam !== null && item.student !== null
        );
        const lastExamIndex = sortLasstId?.length - 1;
        const sorted = res.data.filter(
          (item) =>
            item.student !== null &&
            item.exam !== null &&
            item.exam.id === sortLasstId?.[lastExamIndex]?.exam?.id
        );
        console.log(sortLasstId);
        setLastExam(sorted);
        // console.log(sorted);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getKeydownExam = (e) => {
    if (e.keyCode == 13) {
      generateExamBtn();
    }
  };
  // ======================= Exam END =====================================

  // TABS, GO BACK
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SmallTitleBar title={`${group?.name}`} center={true} />
      <Box
        className={`title-contact-block ${classes.searchBarWrap}`}
        pt={0}
        bgcolor="background.paper"
        px={{ xs: "12px", md: 0 }}
        pb={3}
      >
        <Container>
          <Box
            textAlign={{ xs: "center", sm: "right" }}
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Button variant="outlined" color="default" onClick={goBack}>
              <Icon style={{ fontSize: 18, marginRight: "2px" }}>
                arrow_back{" "}
              </Icon>
              {t("parent.back")}
            </Button>
          </Box>
        </Container>
      </Box>
      <Box className={classes.tabsWrap} bgcolor="background.paper">
        <Container>
          <AppBar position="static" className={classes.appWrap}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
              aria-label="scrollable auto tabs example"
              className={`${classes.toolbar} contact-grid-tabs`}
            >
              <Tab label={`${t("admin.attendance")}`} value="1" />
              {/* <Tab label="Homeworks" value="2" /> */}
              <Tab label={`${t("admin.exams")}`} value="2" />
            </Tabs>
          </AppBar>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <TabContext value={value}>
          <TabPanel value="1">
            <h4 style={{ textAlign: "center" }}>{t("admin.studentsList")}</h4>
            {/*From*/}
            <Box my={3} style={{ display: "flex" }}>
              <FormControl style={{ marginRight: "8px" }} fullWidth error>
                <TextField
                  required
                  fullWidth
                  type="date"
                  id="outlined-required"
                  variant="outlined"
                  className="outlined-input"
                  label={`${t("admin.from")}:`}
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={fromDateInputs.fromDate}
                  onChange={(e) => handleFromDate(e)}
                  error={
                    fromDateInputs.startValidate &&
                    (validationFromDate.passes() === true ? false : true)
                  }
                />
                <FormHelperText style={{ marginLeft: 0 }}>
                  {fromDateInputs.startValidate &&
                    validationFromDate.errors.first("from")}
                </FormHelperText>
              </FormControl>
              <FormControl style={{ marginRight: "8px" }} fullWidth error>
                <TextField
                  required
                  fullWidth
                  type="date"
                  id="outlined-required"
                  variant="outlined"
                  className="outlined-input"
                  label={`${t("admin.to")}:`}
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={toDateInputs.toDate}
                  onChange={(e) => handletoDate(e)}
                  error={
                    toDateInputs.startValidate &&
                    (validationToDate.passes() === true ? false : true)
                  }
                />
                <FormHelperText style={{ marginLeft: 0 }}>
                  {toDateInputs.startValidate &&
                    validationToDate.errors.first("to")}
                </FormHelperText>
              </FormControl>
              {/*Full Name*/}
              <FormControl error fullWidth style={{ marginRight: "8px" }}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  id="outlined-required"
                  variant="outlined"
                  className="outlined-input"
                  label={`${t("admin.fullName")}:`}
                  placeholder={`${t("admin.enterStudentFullName")}`}
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={fullnameInputs.fullname}
                  onChange={(e) => handleFullname(e)}
                  onKeyDown={getKeydown}
                />
                {/* <FormHelperText>
                  {fullnameInputs.startValidate &&
                    validationFullname.errors.first("fullname")}
                </FormHelperText> */}
              </FormControl>
            </Box>

            <Box my={3}>
              {attendanceErr ? (
                <div className="alert alert-warning" role="alert">
                  {t("admin.studentNotFound")}
                </div>
              ) : (
                ""
              )}
            </Box>
            {/* Button generate  */}
            <Box
              my={3}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "right",
              }}
            >
              {/* <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#dc3545",
                  marginRight: "10px",
                }}
              >
                Clear
              </Button> */}

              <Button
                variant="contained"
                style={
                  changeBtn == "generate"
                    ? { backgroundColor: "#4d7df2" }
                    : { backgroundColor: "#dc3545" }
                }
                color="primary"
                onClick={changeBtn == "generate" ? generateBtn : clearBtn}
              >
                {changeBtn == "generate"
                  ? `${t("admin.generate")}`
                  : `${t("admin.clear")}`}
              </Button>
            </Box>

            <Box my={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>№</TableCell>
                      <TableCell>{t("admin.fullName")}</TableCell>
                      <TableCell>{t("parent.phone")}</TableCell>
                      <TableCell align="left">
                        {t("admin.lessonDate")}
                      </TableCell>
                      <TableCell align="left">{t("admin.status")}</TableCell>
                      <TableCell align="left">
                        {t("admin.noHomework")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchedStudent?.length > 0 ? (
                      searchedStudent.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${item.student.first_name} ${item.student.last_name}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${item.student.phone_number}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {` ${moment(
                              item?.lesson?.startDate.substring(0, 10)
                            ).format("DD-MM-YYYY")}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${
                              !item.is_attended
                                ? t("admin.absent")
                                : item.is_attended == true &&
                                  item.is_late == true
                                ? t("admin.late")
                                : item.is_attended == true
                                ? t("admin.present")
                                : ""
                            }`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${item?.is_do_homework ? "+" : "-"}`}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : lastLesson?.length > 0 ? (
                      lastLesson?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${item?.student?.first_name} ${item?.student?.last_name}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${item?.student?.phone_number}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {` ${moment(
                              item?.lesson?.startDate.substring(0, 10)
                            ).format("DD-MM-YYYY")}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${
                              !item.is_attended
                                ? t("admin.absent")
                                : item.is_attended == true &&
                                  item.is_late == true
                                ? t("admin.late")
                                : item.is_attended == true
                                ? t("admin.present")
                                : ""
                            }`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${item?.is_do_homework ? "+" : "-"}`}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <h4 className="py-3">{t("admin.lasstlesson404")}</h4>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {lastLesson?.length ? (
                <Button variant="contained" color="primary" onClick={toDowload}>
                  {t("admin.dowloadExcel")}
                </Button>
              ) : (
                ""
              )}
            </Box>
          </TabPanel>

          <TabPanel value="2">
            <h4 style={{ textAlign: "center" }}>{t("admin.examsGrade")}</h4>
            {/*From*/}
            <Box my={3} style={{ display: "flex" }}>
              <FormControl style={{ marginRight: "8px" }} fullWidth error>
                <TextField
                  required
                  fullWidth
                  type="date"
                  id="outlined-required"
                  variant="outlined"
                  className="outlined-input"
                  label={`${t("admin.from")}:`}
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={fromDateExamInputs.fromDate}
                  onChange={(e) => handleFromDateExam(e)}
                  error={
                    fromDateExamInputs.startValidate &&
                    (validationFromDateExam.passes() === true ? false : true)
                  }
                />
                <FormHelperText style={{ marginLeft: 0 }}>
                  {fromDateExamInputs.startValidate &&
                    validationFromDateExam.errors.first("from")}
                </FormHelperText>
              </FormControl>
              <FormControl style={{ marginRight: "8px" }} fullWidth error>
                <TextField
                  required
                  fullWidth
                  type="date"
                  id="outlined-required"
                  variant="outlined"
                  className="outlined-input"
                  label={`${t("admin.to")}:`}
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={toDateExamInputs.toDate}
                  onChange={(e) => handletoDateExam(e)}
                  error={
                    toDateExamInputs.startValidate &&
                    (validationToDateExam.passes() === true ? false : true)
                  }
                />
                <FormHelperText style={{ marginLeft: 0 }}>
                  {toDateExamInputs.startValidate &&
                    validationToDateExam.errors.first("to")}
                </FormHelperText>
              </FormControl>

              {/*Full Name*/}
              <FormControl error fullWidth style={{ marginRight: "8px" }}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  id="outlined-required"
                  variant="outlined"
                  className="outlined-input"
                  label={`${t("admin.fullName")}:`}
                  placeholder={`${t("admin.enterStudentFullName")}`}
                  InputLabelProps={{
                    shrink: true,
                    pl: "2px",
                  }}
                  value={fullnameExamInputs.fullname}
                  onChange={(e) => handleFullnameExam(e)}
                  onKeyDown={getKeydownExam}
                />
                {/* <FormHelperText>
                  {fullnameExamInputs.startValidate &&
                    validationFullnameExam.errors.first("fullname")}
                </FormHelperText> */}
              </FormControl>
            </Box>
            {/* EXAMS LIST */}
            <Box my={3}>
              <FormControl
                required
                fullWidth
                variant="outlined"
                size="small"
                error={
                  genderInputs.startValidate &&
                  (validationGender.passes() === true ? false : true)
                }
              >
                <InputLabel id="select-gender-label" shrink={true}>
                  {t("admin.selectExam")}:
                </InputLabel>
                <Select
                  fullWidth
                  labelId="select-gender-label"
                  label={`${t("admin.selectExam")}:`}
                  variant="outlined"
                  value={genderInputs.gender}
                  onChange={(e) => handleGender(e)}
                >
                  {examsList?.length
                    ? examsList.map((item, index) => (
                        <MenuItem
                          value={item.id}
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{item.name}</span>
                        </MenuItem>
                      ))
                    : ""}
                </Select>
                <FormHelperText style={{ marginLeft: "0" }}>
                  {genderInputs.startValidate &&
                    validationGender.errors.first("exam")}
                </FormHelperText>
              </FormControl>
            </Box>

            <Box my={3}>
              {examErr ? (
                <div className="alert alert-warning" role="alert">
                  {t("admin.examNotFound")}
                </div>
              ) : (
                ""
              )}
            </Box>
            {/* Button generate  */}
            <Box
              my={3}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "right",
              }}
            >
              {/* <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#dc3545",
                  marginRight: "10px",
                }}
              >
                Clear
              </Button> */}

              <Button
                variant="contained"
                style={
                  changeExamBtn == "generate"
                    ? { backgroundColor: "#4d7df2" }
                    : { backgroundColor: "#dc3545" }
                }
                color="primary"
                onClick={
                  changeExamBtn == "generate" ? generateExamBtn : clearExamBtn
                }
              >
                {changeExamBtn == "generate"
                  ? `${t("admin.generate")}`
                  : `${t("admin.clear")}`}
              </Button>
            </Box>

            <Box my={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("admin.fullName")}</TableCell>
                      <TableCell align="left">{t("parent.phone")}</TableCell>
                      <TableCell align="left">{t("admin.examName")}</TableCell>
                      <TableCell align="left">{t("admin.examDate")}</TableCell>
                      <TableCell align="left">{t("admin.grade")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchedStudentExam?.length > 0 ? (
                      searchedStudentExam.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {`${item.student.first_name} ${item.student.last_name}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${item.student.phone_number}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {` ${item.lesson.name}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {moment(
                              item.lesson.startDate.substring(0, 10)
                            ).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.grade}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : lastExam?.length > 0 ? (
                      lastExam?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {`${item.student.first_name} ${item.student.last_name}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${item.student.phone_number}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {` ${item.lesson.name}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {moment(
                              item.lesson.startDate.substring(0, 10)
                            ).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.grade}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <h4 className="py-3">{t("admin.lasstExam404")}</h4>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {lastLesson?.length ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={toDowloadExam}
                >
                  {t("admin.dowloadExcel")}
                </Button>
              ) : (
                ""
              )}
            </Box>
          </TabPanel>
        </TabContext>
      </Container>
    </div>
  );
};

export default GroupsInfo;
