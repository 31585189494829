import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Button,
  Fab,
  Box,
  Typography,
  Divider,
  OutlinedInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { CustomCard } from "components/GlobalComponents";
import Validator from "validatorjs";
import { NotificationManager } from "react-notifications";
import { JWT_LOGIN_SUCCESS, JWT_LOGIN_FAILURE } from "../actions/Types";
import { loginUser, handleLoginResponse } from "../fetch/auth";
import { Link } from "react-router-dom";
import { t } from "i18next";
function Login(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //   email
  const [emailInputs, setEmailInputs] = useState({
    email: "",
    rule: "required|email",
    startValidate: false,
  });
  let validationEmail = new Validator(
    { email: emailInputs.email },
    { email: emailInputs.rule }
  );
  const handleEmail = (e) => {
    setEmailInputs((prevState) => ({
      ...prevState,
      email: e.target.value,
      startValidate: true,
    }));
  };

  //   password
  const [passwordInputs, setPasswordInputs] = useState({
    password: "",
    rule: "required",
    startValidate: false,
  });
  let validationPassword = new Validator(
    { password: passwordInputs.password },
    { password: passwordInputs.rule }
  );
  const handlePassword = (e) => {
    setPasswordInputs((prevState) => ({
      ...prevState,
      password: e.target.value,
      startValidate: true,
    }));
  };

  // Select role
  const [roleInputs, setRoleInputs] = useState({
    role: "",
    rule: "required",
    startValidate: false,
  });
  let validationRole = new Validator(
    { role: roleInputs.role },
    { role: roleInputs.rule }
  );
  const handleRole = (e) => {
    setRoleInputs((prevState) => ({
      ...prevState,
      role: e.target.value,
      startValidate: true,
    }));
  };

  const [disableBtn, setDisabledBtn] = useState(false);

  const loginBtn = () => {
    if (
      validationEmail.passes() &&
      validationPassword.passes() &&
      validationRole.passes()
    ) {
      setDisabledBtn(true);
      const data = {
        email: emailInputs.email,
        password: passwordInputs.password,
        type: roleInputs.role,
      };
      loginUser(data)
        .then(handleLoginResponse)
        .then((user) => {
          localStorage.setItem(
            "preUni_token",
            JSON.stringify(user.access_token)
          );
          localStorage.setItem("preUni_user_role", JSON.stringify(user.role));
          localStorage.setItem("preUni_user_id", JSON.stringify(user.userId));

          return user;
        })
        .then(
          (user) => {
            dispatch({ type: JWT_LOGIN_SUCCESS, payload: user });
            setDisabledBtn(false);
            props.history.push(`/app/${user.role.toLowerCase()}/dashboard`);
            if (user.role.toLowerCase() == "parent") {
              props.history.push(`/app/${user.role.toLowerCase()}/courses`);
            }
            NotificationManager.success(`${t("notifications.loggedSuccess")}`);
          },
          (error) => {
            dispatch({ type: JWT_LOGIN_FAILURE, payload: error });
            setDisabledBtn(false);
            NotificationManager.error(error);
          }
        );
    } else {
      validationEmail.errors.first("email");
      validationPassword.errors.first("password");
      setEmailInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setPasswordInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
      setRoleInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  const getKeydown = (e) => {
    if (e.keyCode == 13) {
      loginBtn();
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <CustomCard>
      <form className="login-form text-center">
        <Typography variant="subtitle2">{t("loginToContinue")}:</Typography>
        {/* <Typography variant="subtitle2" color="textPrimary" className="fw-500">Hulk</Typography> */}
        <Box my={3}>
          <FormControl error fullWidth>
            <TextField
              required
              fullWidth
              type="email"
              variant="outlined"
              className="outlined-input"
              placeholder={t("loginForm.emailPlaceholder")}
              value={emailInputs.email}
              onChange={handleEmail}
              onKeyDown={getKeydown}
              error={
                emailInputs.startValidate &&
                (validationEmail.passes() === true ? false : true)
              }
            />
            <FormHelperText>
              {emailInputs.startValidate &&
                validationEmail.errors.first("email")}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box mb={3}>
          <FormControl error fullWidth>
            <OutlinedInput
              required
              fullWidth
              type={showPassword ? "text" : "password"}
              variant="outlined"
              className="outlined-input"
              placeholder={t("loginForm.passPlaceholder")}
              value={passwordInputs.password}
              onChange={handlePassword}
              onKeyDown={getKeydown}
              error={
                passwordInputs.startValidate &&
                (validationPassword.passes() === true ? false : true)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>
              {passwordInputs.startValidate &&
                validationPassword.errors.first("password")}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box mb={3}>
          <FormControl
            required
            fullWidth
            variant="outlined"
            size="small"
            error={
              roleInputs.startValidate &&
              (validationRole.passes() === true ? false : true)
            }
          >
            <InputLabel id="select-gender-label" shrink={true}>
              {t("Select Role")}:
            </InputLabel>
            <Select
              fullWidth
              labelId="select-gender-label"
              label="Select gender: "
              variant="outlined"
              value={roleInputs.role}
              onChange={(e) => handleRole(e)}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="parent">Parent</MenuItem>
              <MenuItem value="student">Student</MenuItem>
              <MenuItem value="teacher">Teacher</MenuItem>
            </Select>
            <FormHelperText style={{ marginLeft: "0" }}>
              {roleInputs.startValidate &&
                validationRole.errors.first("gender")}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box mb="20px">
          <span style={{ cursor: disableBtn ? "not-allowed" : "pointer" }}>
            <Button
              color="primary"
              className="btn-block blockBtn w-100"
              variant="contained"
              disabled={disableBtn}
              size="large"
              onClick={(e) => loginBtn(e)}
            >
              {disableBtn ? (
                <CircularProgress size={15} color="inherit" />
              ) : (
                `${t("login")}`
              )}
            </Button>
          </span>
        </Box>
        {/* <Typography variant="subtitle2">or</Typography>
        <Typography variant="subtitle2">Login with</Typography> */}
        {/* <div className="social-login-wrapper">
          <div className="social-list">
            <Fab
              size="small"
              variant="circular"
              className="text-white google-color"
              // onClick={() =>
              //   dispatch(signinUserWithGoogle(props.history))
              // }
            >
              <i className="fab fa-google"></i>
            </Fab>
          </div>
        </div> */}
        <Divider></Divider>
        <Box display="flex" justifyContent="center" alignItems="center" pt={2}>
          {/* <Box fontSize="subtitle2.fontSize" className="border-right" pr={1}>
            <Box style={{ cursor: "pointer" }} color="primary.main">
              Can't log in ?
            </Box>
          </Box> */}
          <Box fontSize="subtitle2.fontSize" pl={1}>
            <Box
              style={{ cursor: "pointer" }}
              component={Link}
              to="/forgot-password"
              color="primary.main"
            >
              {t("forgotPass")}
            </Box>
          </Box>
        </Box>
      </form>
    </CustomCard>
  );
}

export default withRouter(Login);
