import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  AsyncAdminRoomsComponent,
  AsyncAdminDashboardComponent,
  AsyncAdminRolePermissionsComponent,
  AsyncAdminLogsComponent,
  AsyncAdminCoursesComponent,
  AsyncAdminTeachersComponent,
  AsyncAdminGroupsComponent,
  AsyncAdminTimetableComponent,
} from "Users";

import AdminAllStudents from "../admin/students/all";
import AdminAddtoGroup from "../admin/students/add";
import StudentsResults from "./students/add/Components/StudentsResults";
import GroupsInfo from "./groups/info/GroupsInfo";
import Permissions from "../admin/permissions/Permissions";
import infoTeacher from "../admin/teachers/info/index";
import TeacherLessons from "../admin/teachers/info/TeacherLessons";
import AttendanceAdmin from "../teacher/groups/attendance/AttendanceAdmin";

const Admin = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/dashboard`}
        component={AsyncAdminDashboardComponent}
      ></Route>
      <Route
        exact
        path={`${match.url}/permissions`}
        component={Permissions}
      ></Route>
      <Route
        exact
        path={`${match.url}/timetable`}
        component={AsyncAdminTimetableComponent}
      ></Route>
      <Route
        exact
        path={`${match.url}/courses`}
        component={AsyncAdminCoursesComponent}
      ></Route>
      <Route
        exact
        path={`${match.url}/teachers`}
        component={AsyncAdminTeachersComponent}
      ></Route>
      <Route
        exact
        path={`${match.url}/teachers/:id`}
        component={infoTeacher}
      ></Route>
      <Route
        exact
        path={`${match.url}/teachers/:id/:id/:name/:id`}
        component={TeacherLessons}
      ></Route>
      {/* `${process.env.PUBLIC_URL}/app/teacher/groups/${groupId}/${groupName}/${rowData.id}/${rowData.startDate}` */}
      <Route
        exact
        path={`${match.url}/teacher/groups/:groupId/:groupName/:lessonId/:date`}
        component={AttendanceAdmin}
      ></Route>
      <Route
        exact
        path={`${match.url}/groups`}
        component={AsyncAdminGroupsComponent}
      ></Route>
      <Route
        exact
        path={`${match.url}/groups/:id`}
        component={GroupsInfo}
      ></Route>
      <Route
        exact
        path={`${match.url}/students`}
        component={AdminAllStudents}
      ></Route>
      <Route
        exact
        path={`${match.url}/students/:id`}
        component={AdminAddtoGroup}
      ></Route>
      <Route
        exact
        path={`${match.url}/student-results/:studentId/:groupId/:groupName`}
        component={StudentsResults}
      ></Route>
      <Route
        exact
        path={`${match.url}/rooms`}
        component={AsyncAdminRoomsComponent}
      ></Route>
      <Route
        exact
        path={`${match.url}/role-permissions`}
        component={AsyncAdminRolePermissionsComponent}
      ></Route>
      <Route
        exact
        path={`${match.url}/logs`}
        component={AsyncAdminLogsComponent}
      ></Route>
    </Switch>
  );
};
export default Admin;
