const axios = require("axios").default;

const createStudent = async (token, data) => {
  console.log(token);
  console.log(data);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/students`,
    data,
    { headers: { Authorization: AuthStr } }
  );
  try {
    const result = await req;
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
  }
};
const createParentPassport = async (token, studentId, data) => {
  console.log(data, "PARENT PASSPORT");
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.put(
    `${process.env.REACT_APP_PRE_UNI_API}/users/${studentId}`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
  }
};

const updateStudent = async (token, studentId, data) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.put(
    `${process.env.REACT_APP_PRE_UNI_API}/students/${studentId}`,
    data,
    {
      headers: { Authorization: AuthStr },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const studentEmailValidation = async (email) => {
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/users/find?userType=student&email=${email}`,
    {
      headers: {
        "Content-Type": "aplication/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getStudents = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/students`, {
    headers: {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getUserById = async (token, id) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/users/${id}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getStudentAppliedCourses = async (token, studentId) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/students/courses?studentId=${studentId}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};
const getContract = async (contractId, token) => {
  console.log(contractId);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/students/${contractId}/contract`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

// createImage
const createImage = async (token, img, userId) => {
  console.log(token, img, userId);
  var formData = new FormData();
  formData.append("userId", userId);
  formData.append("file", img);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/users/upload-passport`,
    formData,
    { headers: { Authorization: AuthStr } }
  );
  try {
    const result = await req;
    console.log(result);

    return result;
  } catch (err) {
    console.log(err);
  }
};

export {
  createStudent,
  updateStudent,
  studentEmailValidation,
  getStudents,
  getStudentAppliedCourses,
  createImage,
  getUserById,
  createParentPassport,
  getContract,
};
