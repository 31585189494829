const axios = require("axios").default;

const getTeachers = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const res = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/teachers?take=10&&skip=0`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: AuthStr,
      },
    }
  );
  try {
    const result = await res;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getStudents = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/students`, {
    headers: {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    },
  });
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

// get courses
const getCourses = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const res = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/courses`, {
    headers: {
      "Content-type": "application/json",
      Authorization: AuthStr,
    },
  });
  try {
    const result = await res;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getStats = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const res = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/contracts/info`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: AuthStr,
      },
    }
  );
  try {
    const result = await res;
    return result;
  } catch (err) {
    console.log(err);
  }
};

// Get All Users
const getAllUsers = async (token, skip = 0, take = 5) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/users?skip=${skip}&take=${take}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const searchAllUsers = async (token, firsName, userType) => {
  console.log(userType);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/users/finduser?firstName=${firsName}&userType=${userType}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

// Update Role
const updateRole = async (data, token) => {
  console.log(data);
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.post(
    `${process.env.REACT_APP_PRE_UNI_API}/users/role`,
    data,
    { headers: { Authorization: AuthStr } }
  );
  console.log(data);

  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

export {
  searchAllUsers,
  getTeachers,
  getStudents,
  getCourses,
  getStats,
  getAllUsers,
  updateRole,
};
