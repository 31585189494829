const ja_JA = {
   "sidebar.dashboard" : "ダッシュボード",
   "sidebar.dashboard1" : "ダッシュボード1",
   "sidebar.dashboard2" : "ダッシュボード2",
   "sidebar.dashboard3" : "ダッシュボード3",
   "sidebar.videoPlayer" : "ビデオプレイヤー",
   "sidebar.multilevelmenu": "マルチレベルメニュー",
   "sidebar.fullpagemenu" : "全ページメニュー",
   "sidebar.level1": "レベル1",
   "sidebar.level2": "レベル2",
   "sidebar.level3": "レベル3",
   "sidebar.webanalytics": "ウェブ分析",
   "sidebar.saas": "サース",
   "sidebar.ecommerce": "eコマース",
   "sidebar.shop": "ショップ",
   "sidebar.cart": "カート",
   "sidebar.checkout": "チェックアウト",
   "sidebar.productdetails": "製品詳細",
   "sidebar.invoice": "請求書",
   "sidebar.signIn": "サインイン",
   "sidebar.chat": "チャット",
   "sidebar.email":"Eメール",
   "sidebar.calendar":"カレンダー",
   "sidebar.payment": "支払い",
   "sidebar.signaturePad": "署名パッド",
   "sidebar.userManagement": "ユーザー管理",
   "sidebar.contactGrid":"コンタクトグリッド",
   "sidebar.userProfile":"ユーザープロフィール",
   "sidebar.socialFeeds": "ソーシャルフィード",
   "sidebar.userSettings": "ユーザー設定",
   "sidebar.pages": "Pages",
   "sidebar.faq": "よくある質問",
   "sidebar.pricing": "価格",
   "sidebar.pricingv1" : "価格V1",
   "sidebar.pricingv2": "価格V2",
   "sidebar.pricingUpgrade": "価格のアップグレード",
   "sidebar.verticalTimeline": "垂直タイムライン",
   "sidebar.horizontalTimeline":" 水平タイムライン",
   "sidebar.horizontalStepper":"水平ステッパー",
   "sidebar.verticalStepper": "垂直ステッパー",
   "sidebar.blog":"ブログ",
   "sidebar.blogGrid": "ブロググリッド",
   "sidebar.blogDetails": "ブログの詳細",
   "sidebar.uiComponents":"UIコンポーネント",
   "sidebar.appBar":"アプリケーションバー",
   "sidebar.avatars": "アバター",
   "sidebar.buttons": "ボタン",
   "sidebar.bottomNavigations": "下部ナビゲーション",
   "sidebar.chip": "チップ",
   "sidebar.lists": "リスト",
   "sidebar.modals":"モーダル",
   "sidebar.tables": "テーブル",
   "sidebar.basicTable": "基本テーブル",
   "sidebar.agGrid": "AgGrid",
   "sidebar.searchTable" : "検索テーブル",
   "sidebar.customTable": "カスタムテーブル",
   "sidebar.sessions" : "セッション",
	"sidebar.layout1":"レイアウト1",
	"sidebar.layout2":"レイアウト2",
   "sidebar.signUp":"サインアップ",
   "sidebar.forgotPassword": "パスワードをお忘れですか",
   "sidebar.errorPages": "エラーページ",
   "sidebar.basic" : "ベーシック",
   "sidebar.upgrade": "アップグレード",
   "sidebar.fourzerofour": "404",
   "sidebar.fivezerozero": "500",
   "sidebar.timeline" : "タイムライン",
   "sidebar.stepper" : "ステッパー",
   "sidebar.profile": "プロフィール",
   "sidebar.modern": "モダン",
   "sidebar.standard": "標準",
   "sidebar.style1": "スタイル1",
   "sidebar.style2": "スタイル2",

   "widgets.newVisitors" : "新規訪問者",
   "widgets.newSales": "新規販売",
   "widgets.bounceRate": "直帰率",
   "widgets.earning": "収益",
   "widgets.QuickLinks" : "クイックリンク",
   "widgets.frequentlyUsedSectiopns": "よく使われるセクション",
   "widgets.newUser" : "新しい利用者",
   "widgets.welcome": "こんにちはジョン、おかえりなさい",
   "widgets.quickStats": "今日のクイック統計",
   "widgets.views": "ビュー",
   "widgets.growth": "成長",
   "widgets.rating": "格付け",
   "widgets.userGrowth" : "ユーザーの成長率",
   "widgets.avgUsers" : "平均ユーザー",
   "widgets.avgTime" : "平均時間",
   "widgets.customTable": "カスタムテーブル",
   "widgets.users": "ユーザー",
   "widgets.revenue" : "収益",
   "widgets.conversionRate" : "変換速度",
   "widgets.sessions": "セッション",
   "widgets.totalRevenue": "総収入",
   "widgets.totalOrders": "合計注文",
   "widgets.totalUsers": "総ユーザー数",
   "widgets.overallTrafficStatus": "全体的な交通状況",
   "widgets.liveChat": "ライブチャート",
   "widgets.tasksList" : "タスクリスト",
   "widgets.newUsers": "新しい利用者",
   "widgets.dynamicBarChart" : "動的な棒グラフ",
   "widgets.visitorsStats": "訪問者の統計",
   "widgets.recipients": "受取人",
   "widgets.openRate": "オープンレート",
   "widgets.marketing": "マーケティング",
   "widgets.sales": "売上高",
   "widgets.topProducts" : "トップ製品",
   "widgets.trends": "トレンド",
   "widgets.analyticsWebSessionsByRegion": "地域別の分析セッション",
   "widgets.keywordSearch": "キーワード検索",
   "widgets.visitorsDevicesUsed": "訪問者が使用するデバイス",
   "widgets.howmanyuserscamefromABC?" : "ABCから何人のユーザーが来ましたか？",
   "widgets.totalViews": "合計視聴回数",
   "widgets.totalSales": "総売上高",
   "widgets.viewMore": "もっと見る",
   "widgets.newAccounts": "新しいアカウント",

   "component.products" : "製品",
   "component.signup" : "サインアップ",
   "component.loadmore": "もっと読み込む",
   "component.categories" : "カテゴリー",
   "component.rating": "格付け",
   "component.priceFilter" : "価格フィルター",
   "component.productDetails" : "製品詳細",
   "component.backToProducts": "製品に戻る",
   "component.addToCart": "カートに追加",
   "component.viewCart" : "かごの中身を見る",
   "component.addtowishlist": "ほしい物リストに追加する",
   "component.yourProductCart": "あなたの製品カート",
   "component.continueShopping": "ショッピングを続ける",
   "component.myShoppingBag": "私の買い物袋",
   "component.yourBill": "あなたの請求書",
   "component.saveShoppingBag" : "ショッピングバッグを保存",
   "component.proceedToCheckout": "チェックアウトに進む",   
   "component.subtotal": "小計",
   "component.tax": "税金",
   "component.total": "合計",
   "component.shippingAddress" : "お届け先の住所",
   "component.payment": "支払い",
   "component.continueToPayment" : "支払いを続ける",
   "component.invoice" : "請求書",
   "component.getMembership" : "メンバーシップを取得",
   "component.alreadyHaveAccount?" : "すでにアカウントをお持ちですか？",
   "component.continueAsGuest?": "ゲストとして続行しますか？",
	"components.compose": "作曲",
	"component.firebase" : "火の基地",
	"component.auth0" : "Auth 0",
	"component.youtubevideoplayer":"Youtubeビデオプレーヤー",
	"component.vimeovideoplayer":"Vimeoビデオプレーヤー",

   "module.inbox": "受信トレイ",
   "module.drafts": "下書き",
   "module.sent": "送信済み",
   "module.trash": "ゴミ箱",
   "module.spam": "スパム",
   "module.primary" : "一次",
   "module.social": "ソーシャル",
   "module.marketing": "マーケティング",

   "component.contactGrid" :"コンタクトグリッド",
   "component.addContact": "連絡先を追加",
   "component.allContacts": "すべての連絡先",
   "component.recentlyAdded": "最近追加された",
   "component.favourite": "お気に入り",
   "component.todaysTasks":"今日の仕事",
   "component.recentFollowers" : "最近のフォロワー",
   "component.settings" : "設定",
   "component.home": "ホーム",
   "component.general" : "一般的な",
   "component.account": "アカウント",
   "component.email": "Eメール",
   "component.faq": "よくある質問",
   "component.generalEnquiries" : "一般的なお問い合わせ",
   "component.accounts": "アカウント",
   "component.features" : "特徴",
   "component.rulesAndPolicies" : "ルールとポリシー",
   "component.chooseYourPlan" : "プランを選択してください",
   "component.starter" : "スターター",
   "component.professional": "プロフェッショナル",
   "component.team": "チーム",
   "component.enterprise": "企業",
   "component.monthly": "毎月",
   "component.yearly": "毎年",
   "component.stillHaveQueries": "まだクエリがありますか？",
   "component.callUsAnyTime" : "電話してね",
   "component.dropUsALine" : "行をドロップ",
   "component.backToPricingV1" : "価格設定V1に戻る",
   "component.basic": "ベーシック",
   "component.standard": "標準",
   "component.premium": "プレミアム",
   "component.purchasePlan": "購入プラン",
   "component.personal" : "個人的な",
   "component.finish" : "終わり",
   "component.accountInformation" : "口座情報",
   "component.personalInformation" : "個人情報",
   "component.editBlog": "ブログを編集",
   "component.deleteBlog" : "ブログを削除",
   "component.recentBlog" : "最近のブログ",
   "component.simpleAppBar": "シンプルなアプリバー",
   "component.appBarWithAPrimarySearchField" : "プライマリ検索フィールドのあるアプリバー",
   "component.appBarWithMenu" : "メニュー付きアプリバー",
   "component.imagesAvatar" : "画像アバター",
   "component.letterAvatar" : "レターアバター",
   "component.sizes" : "サイズ",
   "component.iconAvatar" : "アイコンのアバター",
   "component.grouped" : "グループ化",
   "component.withBadge" : "バッジ付きアバター",
   "component.containedButtons" : "含まれるボタン",
   "component.textButtons" : "テキストボタン",
   "component.outlinedButtons" : "輪郭を描かれたボタン",
   "component.uploadButton" : "アップロードボタン",
   "component.buttonsWithIconsAndLabel": "アイコンとラベルが付いたボタン",
   "component.iconButtons": "アイコンボタン",
   "component.complexButtons": "複雑なボタン",
   "component.bottomNavigation": "ボトムナビゲーション",
   "component.bottomNavigationWithNoLabel": "ラベルなしの下部ナビゲーション",
   "component.outlinedChip": "輪郭を描かれたチップ",
   "component.chipArray": "チップアレイ",
   "component.simpleList" : "単純なリスト",
   "component.nesteList": "入れ子リスト",
   "component.alignListItems": "リストアイテムの整列",
   "component.listWithCheckBox": "チェックボックス付きリスト",
   "component.listWithSwitch": "スイッチ付きリスト",
   "component.simpleModal": "単純なモーダル",
   "component.transition": "遷移",
   "component.aggridTable":"Ag-Gridテーブル",
   "component.megaMenu": "メガメニュー",
   "horizontalMenu.general": "一般的な",
   "horizontalMenu.modules" : "モジュール",
   "horizontalMenu.components": "部品",
   "horizontalMenu.applications" : "用途",
   "component.upgradeYourPlanNow": "今すぐプランをアップグレード",
   "component.addYourDebitCreditCard" : "デビットカードまたはクレジットカードを追加する",
   "component.termsandconditions" : "規約と条件",
   "components.paymentGateways" : "支払いゲートウェイ",
   "components.connectPaymentGateways" : "支払いゲートウェイを接続する",
   "components.tutorials": "チェックリスト",
	"components.checklist": "チェックリストチュートリアル",
   "components.welcomeToHulk" : "ハルクへようこそ",
   "components.advancedTable" : "高度なテーブル",
   "components.chatPanel" : "チャットパネル",
   "components.signaturePad": "署名パッド",
   "components.help" : "助けて",
   "components.clicktoknowmore" : "クリックして詳細を知る",
   "components.clickhereifyouneedanyhelp" : "ここをクリックしてください"
}

export default ja_JA;