import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "../index.css";
import {
  Typography,
  Divider,
  Box,
  Button,
  Card,
  CardContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { getGroups } from "fetch/admin/groups/apies";
import { t } from "i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "1.25rem",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    borderRadius: 4,
  },
  cardContent: {
    padding: "0 !important",
  },
  divider: {
    marginTop: "0.625rem",
  },
}));

function UserActivities({ studentGroups, studentId, history }) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));

  const classes = useStyles();
  const navigateBtn = (e, group) => {
    e.preventDefault();
    history.push(
      `${process.env.PUBLIC_URL}/app/parent/child-results/${studentId}/${group.id}/${group.name}`
    );
  };

  // const [weeks, setWeeks] = useState(0);

  const weekDaysUz = ["", "Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];

  // getTeacherInfo
  const [teacherInfo, setTeacherInfo] = useState();
  console.log(studentGroups);
  // console.log(teacherInfo);

  // useEffect(() => {
  //   getGroups(token)
  //     .then((res) => {
  //       setTeacherInfo(res?.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const formatToEnter = (text) => {
    const resultDesc = text.split("\n");

    return (
      <div>
        {resultDesc?.map((item, index) => (
          <p>{item}</p>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Card title="Joined Groups" className={`${classes.card}`}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h6">{t("parent.joinedGroup")}</Typography>
        </Box>
        <Divider className={classes.divider} />
        <CardContent>
          {studentGroups && studentGroups.length !== 0 ? (
            studentGroups.map((item, index) => (
              <Box key={index} className="user-activity">
                <Box display="flex" p={{ xs: 2, md: 3 }}>
                  <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">
                    <Box>
                      <Box
                        display={{ xs: "block", sm: "flex" }}
                        alignItems="center"
                        mb={1}
                      >
                        <Box
                          pr="5px"
                          className="user-name"
                          component="span"
                          fontWeight={500}
                        >
                          {item.group.name}
                        </Box>
                      </Box>
                      <Typography variant="body2" color="textPrimary">
                        {formatToEnter(item.group.description)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="end" mb="10px">
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => navigateBtn(e, item.group)}
                  >
                    {t("parent.seeResults")}
                  </Button>
                </Box>
                <Divider />
              </Box>
            ))
          ) : (
            <Box display="flex" justifyContent="center" pt="10px">
              <Typography variant="body1">
                {t("admin.groupsNotFound")} !
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
      {/* <Card title="Info Groups" className={`${classes.card}`}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h6">Info Group</Typography>
        </Box>
        <Divider className={classes.divider} />
        <CardContent>
          {studentGroups && studentGroups.length !== 0 ? (
            <Table aria-label="simple table" className="tableInfoGroup">
              <TableHead>
                <TableRow>
                  <TableCell>Teacher</TableCell>
                  <TableCell>Week days</TableCell>
                  <TableCell align="left">Start time</TableCell>
                  <TableCell align="left">End time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentGroups.map((item, index) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {teacherInfo?.map((teacher, index) => (
                        <span>
                          {teacher.id == item.group.id
                            ? teacher.teacher.first_name
                            : ""}
                        </span>
                      ))}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.group?.week_days?.sort().map((week, index) => (
                        <span style={{ marginRight: "5px" }}>
                          {weekDaysUz[week]}
                        </span>
                      ))}
                    </TableCell>
                    <TableCell align="left">
                      {item?.group?.start_lesson_time}
                    </TableCell>
                    <TableCell align="left">
                      {item?.group?.end_lesson_time}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              pt="10px"
              style={{ width: "100%", textAlign: "center" }}
            >
              <Typography variant="body1">No Info !</Typography>
            </Box>
          )}
        </CardContent>
      </Card> */}
    </div>
  );
}

export default withRouter(UserActivities);
