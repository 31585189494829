const axios = require("axios").default;

// get timetable
const getTimetable = async (token, type, value) => {
  const AuthStr = "Bearer ".concat(token);
  const req = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/groups/timetable?${type}=${value}`,
    {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (err) {
    console.log(err);
  }
};

export { getTimetable };
