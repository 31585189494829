import React from "react";
import { withRouter } from "react-router-dom";
import { Typography, Divider, Box, Button } from "@material-ui/core";
import { AddToGroup } from "./AddToGroup";
import { NotificationManager } from "react-notifications";
import { t } from "i18next";

import { removeStudentFromGroup } from "fetch/admin/students/apies";

function UserActivities({
  studentGroups,
  studentId,
  setStudentGroups,
  history,
}) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));

  const navigateBtn = (e, group) => {
    e.preventDefault();
    console.log(group);
    history.push(
      `${process.env.PUBLIC_URL}/app/admin/student-results/${studentId}/${group.id}/${group.name}`
    );
  };

  const removeFromGroupBtn = (e, groupId) => {
    e.preventDefault();
    const data = {
      studentId: Number(studentId),
      groupId: Number(groupId),
    };
    removeStudentFromGroup(token, data).then((res) => {
      if (res.status === 200) {
        const newArr = studentGroups.filter((el) => {
          if (el.group.id !== groupId) {
            return el;
          }
        });
        setStudentGroups((prevState) => newArr);
        NotificationManager.success("Deleted successfully");
      }
    });
  };

  const formatToEnter = (text) => {
    const resultDesc = text.split("\n");

    return (
      <div>
        {resultDesc?.map((item, index) => (
          <p>{item}</p>
        ))}
      </div>
    );
  };
  return (
    <div>
      <AddToGroup
        title={`${t("parent.joinedGroup")}:`}
        showDivider={true}
        studentId={studentId}
        setStudentGroups={setStudentGroups}
      >
        {studentGroups && studentGroups.length !== 0 ? (
          studentGroups.map((item, index) => (
            <Box key={index} className="user-activity">
              <Box display="flex" p={{ xs: 2, md: 3 }}>
                <Box pl={2} pr={{ xs: 0, md: 2 }} className="content-wrap">
                  <Box>
                    <Box
                      display={{ xs: "block", sm: "flex" }}
                      alignItems="center"
                      mb={1}
                    >
                      <Box
                        pr="5px"
                        className="user-name"
                        component="span"
                        fontWeight={500}
                      >
                        {item.group.name}
                      </Box>
                      {/* <Typography variant="subtitle2">{item.group.name}</Typography> */}
                    </Box>
                    <Typography variant="body2" color="textPrimary">
                      {formatToEnter(item.group.description)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" justifyContent="end" mb="10px">
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: "10px" }}
                  onClick={(e) => navigateBtn(e, item.group)}
                >
                  {t("parent.seeResults")}
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={(e) => removeFromGroupBtn(e, item.group.id)}
                >
                  - {t("admin.removeFromGroup")}
                </Button>
              </Box>
              <Divider />
            </Box>
          ))
        ) : (
          <Box display="flex" justifyContent="center" pt="10px">
            <Typography variant="body1">
              {t("admin.groupsNotFound")} !
            </Typography>
          </Box>
        )}
      </AddToGroup>
    </div>
  );
}

export default withRouter(UserActivities);
