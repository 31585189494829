import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Box } from "@material-ui/core";
import { getExamGrades } from "fetch/admin/students/apies";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function ExamGradeResults({ studentId, groupId }) {
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const urlStudent = window.location.href.split("/")[6];
  const classes = useStyles();
  const [studentGrades, setStudentGrades] = useState([]);
  useEffect(() => {
    getExamGrades(token, groupId).then((res) => {
      const list = res.data.filter((el) => el.student.id == urlStudent);
      setStudentGrades(list);
      console.log(list);
    });
  }, []);

  return (
    <Box mt={8}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Exam name</TableCell>
              <TableCell>Grade</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentGrades.length !== 0 ? (
              studentGrades.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.exam.title}
                  </TableCell>
                  <TableCell>{row.grade}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>No data !</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ExamGradeResults;
