import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import "../../i18next";
import { useTranslation } from "react-i18next";

const HeaderNew = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    localStorage.setItem("lng", "ru");
  }, []);

  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    handleChangeLng(e.target.value);
  };

  const [openMobileNavbar, setOpenMobileNavbar] = useState(false);
  const handleOpenNavbar = () => {
    setOpenMobileNavbar((current) => !current);
  };

  document.body.style.overflow = `${openMobileNavbar ? "hidden" : "auto"}`;

  return (
    <div id="headerTop">
      <nav className="customNavbar">
        <div className="logo">
          <h2>{t("logo")}</h2>
        </div>
        <div
          className={`hamburger ${openMobileNavbar ? "toggle" : ""}`}
          onClick={handleOpenNavbar}
        >
          <div className="line1" />
          <div className="line2" />
          <div className="line3" />
        </div>
        <ul className={`nav-links ${openMobileNavbar ? "open" : ""}`}>
          <li className={` ${openMobileNavbar ? "fade" : ""}`}>
            <a href="tel:+998712899995" style={{ fontSize: "22px" }}>
              +998 71 289 99 95
            </a>
          </li>
          <li className={` ${openMobileNavbar ? "fade" : ""}`}>
            <a onClick={handleOpenNavbar} href="#headerTop">
              {t("home")}
            </a>
          </li>
          <li className={` ${openMobileNavbar ? "fade" : ""}`}>
            <a onClick={handleOpenNavbar} href="#ourCourse">
              {t("courses")}
            </a>
          </li>
          <li className={` ${openMobileNavbar ? "fade" : ""}`}>
            <a onClick={handleOpenNavbar} href="#teachers">
              {t("teachers")}
            </a>
          </li>
          <li className={` ${openMobileNavbar ? "fade" : ""}`}>
            <a onClick={handleOpenNavbar} href="#news">
              {t("news")}
            </a>
          </li>
          <li className={` ${openMobileNavbar ? "fade" : ""}`}>
            <a onClick={handleOpenNavbar} href="#footer">
              {t("contactus")}
            </a>
          </li>

          <li className={` ${openMobileNavbar ? "fade" : ""}`}>
            <Link to={"auth"} className="loginBtn">
              {t("login")}
            </Link>
          </li>
          <li className={` ${openMobileNavbar ? "fade" : ""}`}>
            <div className="select">
              <select id="selectLang" onChange={handleChange}>
                <option
                  value="ru"
                  selected={localStorage.getItem("lng") == "ru" ? true : false}
                >
                  RU
                </option>
                <option
                  value="uz"
                  selected={localStorage.getItem("lng") == "uz" ? true : false}
                >
                  UZ
                </option>

                <option
                  value="en"
                  selected={localStorage.getItem("lng") == "en" ? true : false}
                >
                  EN
                </option>
              </select>
              <span className="focus"></span>
            </div>
          </li>
        </ul>
      </nav>

      <header
        id="header"
        className="fixed-top d-flex align-items-center header-transparent"
      >
        <div className="container d-flex justify-content-between align-items-center"></div>
      </header>

      <section
        id="hero"
        className="d-flex justify-cntent-center align-items-center"
      >
        <div className="headerItem">
          <h2 className="animate__animated animate__fadeInDown">
            {t("introHeader")}
          </h2>
          <p className="animate__animated animate__fadeInUp">
            {t("infoHeader")}
          </p>
          {/* <a
            href="#ourCourse"
            className="btn-get-started animate__animated animate__fadeInUp"
          >
            Read More
          </a> */}
        </div>
      </section>
    </div>
  );
};

export default HeaderNew;
