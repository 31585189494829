/**
 * admin header component
 */
/* eslint-disable */
import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import NavListItem from "./NavListItem";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  toggleThirdMenu,
  toggleMenu,
  toggleFourthMenu,
  onLoadToggleMenu,
} from "actions";
import { t } from "i18next";

function SidebarContent(props) {
  const userRole = JSON.parse(localStorage.getItem("preUni_user_role"));
  const menuListReducer = useSelector((state) => state.menuListReducer);
  const dispatch = useDispatch();
  //   let routes = menuListReducer.navLinks;

  const [navLinks, setNavLinks] = useState("");

  const adminMenu = menuListReducer.navLinks.filter((el, index) => {
    if (el.admin_role) {
      if (el.admin_role == "Admin") {
        return el;
      }
    }
  });

  const parentMenu = menuListReducer.navLinks.filter((el) => {
    if (el.parent_role)
      if (el.parent_role == "Parent") {
        return el;
      }
  });

  const studentMenu = menuListReducer.navLinks.filter((el) => {
    if (el.student_role == "Student") {
      return el;
    }
  });

  const teacherMenu = menuListReducer.navLinks.filter((el) => {
    if (el.teacher_role == "Teacher") {
      return el;
    }
  });

  useEffect(() => {
    switch (userRole) {
      case "Admin":
        setNavLinks(adminMenu);
        break;
      case "Parent":
        setNavLinks(parentMenu);
        break;
      case "Student":
        setNavLinks(studentMenu);
        break;
      case "Teacher":
        setNavLinks(teacherMenu);
        break;
    }
  }, []);

  const getPlanName = (name) => {
    let newName = name.replace("-", " ");
    return newName;
  };
  useEffect(() => {
    let currentURL = window.location.href;
    let currentIndex;
    for (let i = 0; i < navLinks.length; i++) {
      if (navLinks[i].menu == currentURL.split("/")[4]) {
        currentIndex = i;
      }
    }
    onLoadToggle(currentIndex);
  }, []);

  const onLoadToggle = (index) => {
    dispatch(onLoadToggleMenu(index));
    //setNavLinks(routes);
  };

  const toggleMenufunction = (index) => {
    dispatch(toggleMenu(index));
    //setNavLinks(routes);
  };
  const toggleThirdMenuAndCloseSidebar = (index) => {
    dispatch(toggleThirdMenu(index));
    if (props.closeSidebar) {
      props.closeSidebar();
    }
  };
  const toggleThirdMenufunction = (index) => {
    dispatch(toggleThirdMenu(index));
    // setNavLinks(routes);
  };

  const toggleFourthMenufunction = (fourthindex) => {
    dispatch(toggleFourthMenu(fourthindex));
    // setNavLinks(routes);
    if (props.closeSidebar) {
      props.closeSidebar();
    }
  };

  const { closeSidebar } = props;
  // console.log(parentMenu);
  return (
    <div>
      <List className="menu-wrap" style={{ padding: 0 }}>
        {navLinks &&
          navLinks.map((Navlink, index) => {
            return (
              <NavListItem
                menu={Navlink}
                title={t(
                  `sidebarItem${
                    Navlink?.admin_role
                      ? "Admin"
                      : Navlink?.parent_role
                      ? "Parent"
                      : Navlink?.student_role
                      ? "Student"
                      : "Teacher"
                  }.bar${index}`
                )}
                key={index}
                toggleMenu={() => toggleMenufunction(Navlink.id)}
                toggleFourthMenu={(e) => toggleFourthMenufunction(e)}
                toggleThirdMenu={(e) => toggleThirdMenufunction(e)}
                toggleThirdMenuAndCloseSidebar={(e) =>
                  toggleThirdMenuAndCloseSidebar(e)
                }
                closeSidebar={closeSidebar}
              />
            );
          })}
      </List>
    </div>
  );
}

export default SidebarContent;
