const axios = require("axios").default;

/// GET COURSE

const getCourses = async (token) => {
  const AuthStr = "Bearer ".concat(token);
  const res = await axios.get(`${process.env.REACT_APP_PRE_UNI_API}/courses`, {
    headers: {
      "Content-type": "application/json",
      Authorization: AuthStr,
    },
  });
  try {
    const result = await res;
    return result;
  } catch (err) {
    console.log(err);
  }
};

// COURSE PAGINATION
const getCoursePagination = async (token, takeCourse) => {
  const AuthStr = "Bearer ".concat(token);
  const res = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/courses?skip=0&take=${takeCourse}&courseType=kids`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: AuthStr,
      },
    }
  );
  try {
    const result = await res;
    return result;
  } catch (err) {
    console.log(err);
  }
};

const getCourseById = async (token, courseId) => {
  const AuthStr = "Bearer ".concat(token);
  const res = await axios.get(
    `${process.env.REACT_APP_PRE_UNI_API}/courses/${courseId}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: AuthStr,
      },
    }
  );
  try {
    const result = await res;
    return result;
  } catch (err) {
    console.log(err);
  }
};

export { getCourses, getCoursePagination, getCourseById };
