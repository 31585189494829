import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { NotificationManager } from "react-notifications";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import styles from "./attendance.module.scss";
import {
  Box,
  Modal,
  Container,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { SmallTitleBar } from "components/GlobalComponents";

import {
  getAttendance,
  postAttendance,
  getGroupStudents,
  getLessonsByGroupId,
  getLessonGrades,
  postLessonGrades,
  finishedLesson,
} from "fetch/teacher/groups/apies";
import { t } from "i18next";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  paperDelete: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Attendance(props) {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const groupId = props.match.params.groupId;
  const groupName = decodeURIComponent(props.match.params.groupName);
  const lessonId = props.match.params.lessonId;
  const lessonDate = props.match.params.date;
  // Modal
  const [open, setOpen] = useState(false);

  function formatDate(date) {
    let newDate = date.split("-");

    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
  }
  // formatDate(lessonDate.substring(0, 10));
  // console.log(formatDate(lessonDate.substring(0, 10)));

  const [allStudents, setAllStudents] = useState([]);

  // All students
  useEffect(() => {
    if (groupId) {
      getGroupStudents(token, groupId).then((res) => {
        const students = [];
        res.data.groupStudents.filter((item) => {
          students.push(item.student);
        });
        const list = students.filter((el) => el !== null);
        setAllStudents(
          list.sort(function (a, b) {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
        );
        // console.log(allStudents);
      });
    }
  }, [groupId]);

  const [isExam, setIsExam] = useState();
  const [isFinished, setIsFinished] = useState(false);
  const [lessonName, setLessonName] = useState("");
  useEffect(() => {
    getLessonsByGroupId(token, groupId).then((res) => {
      const sorted = res?.data?.filter((item) => item?.id == Number(lessonId));
      setIsExam(sorted[0]?.isExam);
      setIsFinished(sorted[0]?.isFinished);
      setLessonName(sorted[0]?.name);
    });
  }, []);

  const [come, setCome] = useState(new Map());
  const [present, setPresent] = useState(new Map());
  const [absent, setAbsent] = useState(new Map());
  const [late, setLate] = useState(new Map());
  const [isDoHomeWork, setIsDoHomeWork] = useState([]);
  const [allStudentsData, setAllStudentsData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  useEffect(() => {
    if (allStudents) {
      allStudents.map((item) => {
        setCome((map) => new Map(map.set(item.id, false)));
        setPresent((map) => new Map(map.set(item.id, false)));
        setAbsent((map) => new Map(map.set(item.id, false)));
        setLate((map) => new Map(map.set(item.id, false)));
      });
      getAttendance(token, groupId, lessonId).then((res) => {
        const sortId = res.data.filter(
          (item) => item.student !== null && item.lesson.id == lessonId
        );
        setAllStudentsData(sortId);
        // console.log(sortId);
        sortId.map((el) => {
          if (el.lesson.startDate === lessonDate) {
            setCome((map) => new Map(map.set(el.student.id, el.no_homework)));
            setPresent(
              (map) => new Map(map.set(el.student.id, el.is_attended))
            );
            setAbsent((map) => new Map(map.set(el.student.id, el.is_attended)));
            setLate((map) => new Map(map.set(el.student.id, el.is_late)));
          }
        });
        // console.log("COMEEEE", sortId);
      });
    }
  }, [allStudents]);

  // Get Lesson-Grades
  const [grade, setGrade] = useState(new Map());
  const [gradeBackuo, setGradeBackuo] = useState(new Map());
  useEffect(() => {
    // allStudents.map((item) => {
    //   console.log(item);
    // });
    getLessonGrades(token, groupId, lessonId).then((res) => {
      res?.data?.map((el) => {
        setGrade((map) => new Map(map.set(el?.student?.id, el?.grade)));
        setGradeBackuo((map) => new Map(map.set(el?.student?.id, el?.grade)));
      });

      // console.log(res.data);
      // console.log(grade);
      // const sorted = res.data.filter((item) => item.lesson.id == lessonId);
      // console.log(sorted);

      // console.log(allStudents);
    });
  }, []);

  const [isSuccess, setIsSuccess] = useState(false);
  const saveExamBtn = () => {
    // setIsSuccess(false);
    // console.log(grade.get(Array.from(grade.keys())[1]));
    for (let i = 0; i < grade.size; i++) {
      // console.log(Array.from(grade.keys())[i]);
      const data = {
        grade: Number(grade.get(Array.from(grade.keys())[i])),
        lessonId: Number(lessonId),
        studentId: Number(Array.from(grade.keys())[i]),
      };
      postLessonGrades(token, data).then((res) => {
        if (res.status == 201) {
          setIsChanged(true);
          setIsSuccess((prevState) => [true]);
          // getLessonGrades(token, groupId, lessonId).then((res) => {
          //   res?.data?.map((el) => {
          //     setGrade((map) => new Map(map.set(el?.student?.id, el?.grade)));
          //     setGradeBackuo(
          //       (map) => new Map(map.set(el?.student?.id, el?.grade))
          //     );
          //   });

          //   // console.log(res.data);
          //   // console.log(grade);
          //   // const sorted = res.data.filter((item) => item.lesson.id == lessonId);
          //   // console.log(sorted);

          //   // console.log(allStudents);
          // });
          // if (isSuccess) {
          // NotificationManager.success("Exams grade saved successfully");
          // }
        }
      });
    }
    // NotificationManager.success("Exams grade saved successfully");
  };
  const [gradeData, setGradeData] = useState(0);
  const [isChanged, setIsChanged] = useState(true);
  const handleGrade = (event, id) => {
    const oldGrade = allStudents?.filter((item) => item.id === Number(id))?.[0];
    if (Number(event.target.value) !== gradeBackuo.get(Number(id))) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
    setGrade((map) => new Map(map.set(id, event.target.value)));
    setGradeData(event.target.value);
  };
  const getKeydown = (e) => {
    if (e.keyCode == 13) {
      saveExamBtn();
    }
  };

  // Finish Lesson
  const finishLesson = () => {
    if (!isChanged) {
      saveExamBtn();
    }
    finishedLesson(token, lessonId).then((res) => {
      // console.log(res);
      if (res.status === 201) {
        handleClose();
        // setIsFinished(true);

        NotificationManager.success(t("admin.finishedLesson"));
      }
    });
  };

  // gender
  const [genderInputs, setGenderInputs] = useState({
    gender: "",
    rule: "required",
  });

  const [studentData, setStudentData] = useState();
  const handleGender = (e) => {
    // console.log(studentData);

    setGenderInputs((prevState) => ({
      ...prevState,
      gender: e.target.value,
    }));

    if (e.target.value == "absent") {
      const dataAbsent = {
        lessonId: Number(lessonId),
        studentId: studentData[0]?.student?.id,
        isCome: false,
        isLate: false,
        isDoHomework: false,
      };
      postAttendance(token, dataAbsent).then((res) => {
        // console.log(res);
        if (res.status == 201) {
          getAttendance(token, groupId).then((res) => {
            const sortId = res.data.filter((item) => item.student !== null);
            setAllStudentsData(sortId);
          });
        }
      });
    } else if (e.target.value == "present") {
      const dataPresent = {
        lessonId: Number(lessonId),
        studentId: studentData[0]?.student?.id,
        isCome: true,
        isLate: false,
        isDoHomework: studentData[0]?.is_do_homework,
      };
      postAttendance(token, dataPresent).then((res) => {
        // console.log(res);
        if (res.status == 201) {
          getAttendance(token, groupId).then((res) => {
            const sortId = res.data.filter((item) => item.student !== null);
            setAllStudentsData(sortId);
          });
        }
      });
    } else if (e.target.value == "late") {
      const dataPresent = {
        lessonId: Number(lessonId),
        studentId: studentData[0]?.student?.id,
        isCome: true,
        isLate: true,
        isDoHomework: studentData[0]?.is_do_homework,
      };
      postAttendance(token, dataPresent).then((res) => {
        // console.log(res);
        if (res.status == 201) {
          getAttendance(token, groupId).then((res) => {
            const sortId = res.data.filter((item) => item.student !== null);
            setAllStudentsData(sortId);
          });
        }
      });
    } else {
      console.log("SLECTED VALUE ERROR");
    }

    // console.log(data);

    // console.log(e.target.value);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const openModalForAttend = (e, data) => {
    const sortAttend = allStudentsData.filter(
      (item) =>
        item.student !== null &&
        item.student.id == data.id &&
        item.lesson.id == lessonId
    );
    setOpenDelete(true);
    setStudentData(sortAttend);
    if (sortAttend[0]?.is_attended == false) {
      setGenderInputs((prevState) => ({
        ...prevState,
        gender: "absent",
      }));
    } else if (
      sortAttend[0]?.is_attended == true &&
      sortAttend[0]?.is_late == true
    ) {
      setGenderInputs((prevState) => ({
        ...prevState,
        gender: "late",
      }));
    } else if (sortAttend[0]?.is_attended == true) {
      setGenderInputs((prevState) => ({
        ...prevState,
        gender: "present",
      }));
    }
  };

  const handleCome = (event, id) => {
    setCome((map) => new Map(map.set(id, !event.target.checked)));
    // const sortData = allStudentsData.filter(
    //   (item) => item.lesson.id == Number(lessonId) && item.student.id == id
    // );
    // console.log("cme-asdasd", come);
    const data = {
      lessonId: Number(lessonId),
      studentId: id,
      isCome: present.get(id),
      isLate: late.get(id),
      isDoHomework: come.get(id),
    };
    // console.log(data);
    postAttendance(token, data).then((res) => {
      if (res.status == 201) {
        getAttendance(token, groupId, lessonId).then((res) => {
          const sortId = res.data.filter((item) => item.student !== null);
          setAllStudentsData(sortId);
          // console.log("AFTER ATTENDED", sortId);
        });
      }
      // console.log(data);
      // console.log(res);
    });
  };
  const handlePresent = (event, id) => {
    setPresent((map) => new Map(map.set(id, event.target.checked)));
    // setAbsent((map) => new Map(map.set(id, event.target.checked)));
    if (!event.target.checked) {
      setLate((map) => new Map(map.set(id, event.target.checked)));
      setCome((map) => new Map(map.set(id, !event.target.checked)));
      setGrade((map) => new Map(map.set(id, 0)));

      const data = {
        lessonId: Number(lessonId),
        studentId: id,
        isCome: false,
        isLate: false,
        isDoHomework: true,
      };

      postAttendance(token, data).then((res) => {
        if (res.status == 201) {
          getAttendance(token, groupId, lessonId).then((res) => {
            const sortId = res.data.filter((item) => item.student !== null);
            setAllStudentsData(sortId);
            // console.log("AFTER ATTENDED", sortId);
          });
        }
        // console.log(data);
        // console.log(res);
      });

      const data2 = {
        grade: 0,
        lessonId: Number(lessonId),
        studentId: Number(id),
      };
      postLessonGrades(token, data2);
    } else {
      const sortData = allStudentsData.filter(
        (item) => item.lesson.id == Number(lessonId) && item.student.id == id
      );
      // console.log("allStudentsData", allStudentsData);
      // console.log("sortData", sortData);
      const dataPresentCheck = {
        lessonId: Number(lessonId),
        studentId: id,
        isCome: present.get(id),
        isLate: late.get(id),
        isDoHomework: come.get(id),
      };
      // console.log(dataPresentCheck);
      postAttendance(token, dataPresentCheck).then((res) => {
        // console.log(res);
        if (res.status == 201) {
          getAttendance(token, groupId, lessonId).then((res) => {
            const sortId = res.data.filter((item) => item.student !== null);
            setAllStudentsData(sortId);
          });
        }
        // console.log(data);
        // console.log(res);
      });
    }
  };
  const handleLate = (event, id) => {
    // setLate((map) => new Map(map.set(id, event.target.checked)));
    const sortData = allStudentsData.filter(
      (item) => item.lesson.id == Number(lessonId) && item.student.id == id
    );
    if (!present.get(id)) {
      setLate((map) => new Map(map.set(id, event.target.checked)));
      setPresent((map) => new Map(map.set(id, event.target.checked)));
      const dataLateCheckTrue = {
        lessonId: Number(lessonId),
        studentId: id,
        isCome: late.get(id),
        isLate: late.get(id),
        isDoHomework: come.get(id),
      };

      // console.log(sortData);
      // console.log("dataLateCheckTrue", dataLateCheckTrue);
      // console.log(dataLateCheckTrue);
      postAttendance(token, dataLateCheckTrue).then((res) => {
        // console.log(res);
        if (res.status == 201) {
          getAttendance(token, groupId, lessonId).then((res) => {
            const sortId = res.data.filter((item) => item.student !== null);
            setAllStudentsData(sortId);
          });
        }
        // console.log(data);
        // console.log(res);
      });
    } else {
      setLate((map) => new Map(map.set(id, event.target.checked)));
      const dataLateCheckFalse = {
        lessonId: Number(lessonId),
        studentId: id,
        isCome: present.get(id),
        isLate: late.get(id),
        isDoHomework: sortData[0]?.no_homework,
      };

      // console.log(sortData);
      // console.log("sortData");
      // console.log(dataLateCheckFalse);
      postAttendance(token, dataLateCheckFalse).then((res) => {
        // console.log(res);
        if (res.status == 201) {
          getAttendance(token, groupId, lessonId).then((res) => {
            const sortId = res.data.filter((item) => item.student !== null);
            setAllStudentsData(sortId);
          });
        }
        // console.log(data);
        // console.log(res);
      });
    }
  };

  const goBack = (e) => {
    e.preventDefault();
    props.history.goBack();
  };

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="contact-grid">
      <SmallTitleBar
        title={`${t("teacher.date")}: ${formatDate(
          lessonDate.substring(0, 10)
        )} ${t("admin.group")}: ${groupName} ${t(
          "teacher.lessons"
        )}: ${lessonName}`}
      />
      {/* <SmallTitleBar title={isExam ? "Exams Grade" : "Take Attendance"} /> */}
      <Box
        className={`title-contact-block ${classes.searchBarWrap} `}
        pt={0}
        bgcolor="background.paper"
        px={{ xs: "12px", md: 0 }}
        pb={3}
      >
        <Container>
          <Box
            textAlign={{ xs: "center", sm: "right" }}
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Button variant="outlined" color="default" onClick={goBack}>
              <Icon style={{ fontSize: 18, marginRight: "2px" }}>
                arrow_back{" "}
              </Icon>
              {t("parent.back")}
            </Button>
          </Box>
        </Container>
      </Box>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={`${classes.paper}`}>
          <Typography variant="h6" className="text-center">
            {t("admin.warningFinshLs")}
          </Typography>
          <Box my={3} className="d-flex justify-content-end">
            <Button
              color="secondary"
              variant="contained"
              style={{ marginRight: "10px" }}
              onClick={handleClose}
            >
              {t("parent.cancel")}
            </Button>
            <Button color="primary" variant="contained" onClick={finishLesson}>
              {t("admin.finishLesson")}
            </Button>
          </Box>
        </div>
      </Modal>
      <Container maxWidth="lg">
        {/* <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openDelete}
          onClose={handleCloseDelete}
        >
          <div style={modalStyle} className={`${classes.paperDelete}`}>
            <h3 style={{ textAlign: "center" }}>Attendance</h3>
            <FormControl required fullWidth variant="outlined" size="small">
              <InputLabel id="select-gender-label" shrink={true}>
                Select late
              </InputLabel>
              <Select
                fullWidth
                labelId="select-gender-label"
                label="Select late: "
                variant="outlined"
                value={genderInputs.gender}
                onChange={(e) => handleGender(e)}
              >
                <MenuItem value="absent">Absent</MenuItem>
                <MenuItem value="present">Present</MenuItem>
                <MenuItem value="late">Late</MenuItem>
              </Select>
            </FormControl>
            <Box my={3} style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "20px" }}
                onClick={(e) => setOpenDelete(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#dc3545" }}
                onClick={(e) => setOpenDelete(false)}
              >
                Save
              </Button>
            </Box>
          </div>
        </Modal> */}

        <Box mt={8}>
          {/* <h6 className={`${styles.boxMobile} text-center`}>
            {t("teacher.date")}: {formatDate(lessonDate.substring(0, 10))}
          </h6>
          <h6 className={`${styles.boxMobile} text-center`}>
            {t("admin.group")}: {groupName}
          </h6> */}
          {/* <h6 className={`${styles.boxMobile} text-center`}>
            {t("teacher.lessons")}: {lessonName}
          </h6> */}
          <TableContainer component={Paper}>
            <Table
              className={`${classes.table} ${styles.customTable}`}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow className={styles.mobileRowFirst}>
                  <TableCell>№</TableCell>
                  <TableCell>{t("admin.fullName")}</TableCell>
                  {/* <TableCell>
                    {t("teacher.date")}:{" "}
                    {formatDate(lessonDate.substring(0, 10))} {t("admin.group")}
                    : {groupName}
                  </TableCell> */}

                  <TableCell>{t("admin.present")}</TableCell>
                  <TableCell>{t("admin.late")}</TableCell>
                  <TableCell>{t("parent.doneHomework")}</TableCell>
                  <TableCell>
                    {isExam ? t("teacher.examGrade") : t("teacher.lessonGrade")}
                  </TableCell>
                </TableRow>
                <Box className={styles.boxMobile}>
                  {allStudents.length !== 0
                    ? allStudents.map((row, index) => (
                        <>
                          <TableRow className={styles.mobileRow}>
                            {/* <TableCell className={styles.fullName}>
                            </TableCell> */}
                            <TableCell className={styles.fullName}>
                              {index + 1}.{" "}
                              {`${row.first_name} ${row.last_name}`}
                            </TableCell>
                            <TableCell className={styles.attenBox}>
                              <Box>
                                <span>Present</span>
                                <Checkbox
                                  checked={present.get(row.id) ? true : false}
                                  onChange={(e) => handlePresent(e, row?.id)}
                                  // disabled={isFinished}
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              </Box>
                              <Box>
                                <span>Late</span>
                                <Checkbox
                                  checked={
                                    late.get(row.id) && present.get(row.id)
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => handleLate(e, row?.id)}
                                  // disabled={isFinished}
                                  disabled={present.get(row.id) ? false : true}
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              </Box>
                              <Box>
                                <span>{t("parent.doneHomework")}</span>
                                <Checkbox
                                  checked={!come.get(row.id) ? true : false}
                                  // checked={come.get(row.id) ? true : false}
                                  onChange={(e) => handleCome(e, row?.id)}
                                  // disabled={isFinished}
                                  disabled={present.get(row.id) ? false : true}
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              </Box>

                              <Box>
                                <span>Grade</span>
                                <TextField
                                  className={styles.grade}
                                  id="outlined-number"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  value={grade.get(row.id)}
                                  style={{ width: "100px" }}
                                  onChange={(e) => handleGrade(e, row.id)}
                                  onKeyDown={getKeydown}
                                  disabled={present.get(row.id) ? false : true}
                                  // disabled={isFinished}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    : ""}
                </Box>
              </TableHead>
              <TableBody className={styles.tableBody}>
                {allStudents.length !== 0 ? (
                  allStudents.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell className={styles.fullName}>
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.first_name} {row.last_name}
                      </TableCell>
                      {/* <TableCell component="th" scope="row"></TableCell> */}

                      <TableCell component="th" scope="row">
                        {/* <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => openModalForAttend(e, row)}
                        >
                          Attendance
                        </Button> */}
                        <Checkbox
                          checked={present.get(row.id) ? true : false}
                          onChange={(e) => handlePresent(e, row?.id)}
                          // disabled={isFinished}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Checkbox
                          checked={
                            late.get(row.id) && present.get(row.id)
                              ? true
                              : false
                          }
                          onChange={(e) => handleLate(e, row?.id)}
                          // disabled={
                          //   isFinished
                          //     ? true
                          //     : present.get(row.id)
                          //     ? false
                          //     : true
                          // }
                          disabled={present.get(row.id) ? false : true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Checkbox
                          checked={!come.get(row.id) ? true : false}
                          onChange={(e) => handleCome(e, row?.id)}
                          // disabled={
                          //   isFinished
                          //     ? true
                          //     : present.get(row.id)
                          //     ? false
                          //     : true
                          // }
                          disabled={present.get(row.id) ? false : true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          id="outlined-number"
                          type="number"
                          variant="outlined"
                          size="small"
                          value={grade.get(row.id)}
                          style={{ width: "100px" }}
                          onChange={(e) => handleGrade(e, row.id)}
                          onKeyDown={getKeydown}
                          // disabled={
                          //   isFinished
                          //     ? true
                          //     : present.get(row.id)
                          //     ? false
                          //     : true
                          // }
                          disabled={present.get(row.id) ? false : true}
                        />
                      </TableCell>

                      {/* <TableCell component="th" scope="row">
                        <Checkbox
                          checked={absent.get(row.id) ? false : true}
                          onChange={(e) => handleAbsent(e, row?.id)}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell> */}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>No data !</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box py={3} style={{ display: "flex", justifyContent: "end" }}>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={saveExamBtn}
              disabled={isChanged}
              style={{ marginRight: "15px" }}
            >
              {t("admin.save_grade")}
            </Button> */}

            {allStudents?.length ? (
              <Button
                variant="contained"
                color="primary"
                // onClick={finishLesson}
                onClick={openModal}
                // disabled={isFinished}
              >
                {
                  // isFinished
                  //   ? t("admin.finishedLesson")
                  //   : t("admin.finishLesson")
                  t("admin.finishLesson")
                }
              </Button>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default Attendance;
