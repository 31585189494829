/**
 * Recent Followers
 */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Validator from "validatorjs";
import styles from "./appliedCourses.module.scss";
import { t } from "i18next";
import {
  Container,
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  IconButton,
  Avatar,
  OutlinedInput,
  InputAdornment,
  Paper,
  Grid,
  Chip,
  Input,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
} from "@material-ui/core";
import IntlMessages from "util/IntlMessages";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getCoursesType, createAddStudentToCourse } from "fetch/student";
import {
  getContract,
  getStudentAppliedCourses,
} from "fetch/parent/children/apies";

//Component
import { CustomCard } from "components/GlobalComponents";
import { removeStudentCourse } from "fetch/admin/students/apies";
import { NotificationManager } from "react-notifications";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    "@media (max-width:1560px)": {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
  root: {
    position: "relative",
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width:375px)": {
      width: 280,
    },
  },

  Paper: {
    padding: "0.75rem",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:first-child": {
      paddingTop: "24px",
    },
    "&:last-child": {
      paddingBottom: "30px",
    },
  },
}));

function AppliedCourses(props) {
  const { courses } = props;
  const [modalStyle] = useState(getModalStyle);
  const [disableBtn, setDisableBtn] = useState(false);
  const [loader, setLoader] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  const token = JSON.parse(localStorage.getItem("preUni_token"));
  const classes = useStyles();
  let currentURL = window.location.href;
  const id = currentURL.split("/")[6];
  // console.log(courses);
  const [studentCourses, setStudentCourses] = useState([]);
  useEffect(() => {
    setStudentCourses(courses);
  }, [courses]);
  console.log(studentCourses);
  let studentCoursesCounter = 0;
  studentCourses?.forEach((element) => {
    if (element.course !== null) {
      studentCoursesCounter++;
    }
  });

  // course
  const [courseInputs, setCourseInputs] = useState({
    course: "",
    rule: "required",
    startValidate: false,
  });
  let validationCourse = new Validator(
    { course: courseInputs.course },
    { course: courseInputs.rule }
  );
  // Get course Pre-1 and Pre-2
  const [socieCourse, setSocieCourse] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedCoursesPrice, setSelectedCoursesPrice] = useState(0);
  const resultPrice = [];

  // handle modal
  const handleClose = () => {
    setOpen(false);
    setDisableBtn(false);
    setSocieCourse(false);
    setErrorAlert(false);
    setCourseInputs((prevState) => ({
      ...prevState,
      course: "",
      startValidate: false,
    }));
  };

  const openModalForCreate = () => {
    setOpen(true);
  };

  const handleCourseType = (e) => {
    setCourseInputs((prevState) => ({
      ...prevState,
      course: e.target.value,
      startValidate: true,
    }));
    if (e.target.value == "kids") {
      setDisableBtn(false);
      setSocieCourse(false);
    } else if (e.target.value == "socie") {
      getCoursesType(token, "socie").then((res) => {
        setSocieCourse(res.data);
      });
      setSocieCourse(false);
      setSelectedCourses([]);
      setSelectedCoursesPrice(0);
      setDisableBtn(true);
    } else if (e.target.value == "sol") {
      getCoursesType(token, "sol").then((res) => {
        setSocieCourse(res.data);
      });
      setSocieCourse(false);
      setSelectedCourses([]);
      setSelectedCoursesPrice(0);
      setDisableBtn(true);
    }
  };

  const formatToCurrency = (amount) => {
    return resultPrice.push(
      "" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&.")
    );
  };
  formatToCurrency(selectedCoursesPrice);
  // inc Price
  const incrementCounter = (clickedPrice) => {
    setSelectedCoursesPrice(selectedCoursesPrice + clickedPrice);
    // setDiscount(((selectedCoursesPrice + clickedPrice) / 100) * 10);
    // console.log(discount);
  };
  // dex Price
  const decrementCounter = (clickedPrice) =>
    setSelectedCoursesPrice(selectedCoursesPrice - clickedPrice);

  const getPrice = (e) => {
    for (let i = 0; i < socieCourse.length; i++) {
      if (socieCourse[i].id == e.target.value) {
        if (socieCourse[i].prices.length > 0) {
          const ids = socieCourse[i].prices.map((object) => {
            return object.id;
          });
          const max = Math.max(...ids);
          for (let j = 0; j < socieCourse[i].prices.length; j++) {
            if (socieCourse[i].prices[j].id == max) {
              if (e.target.checked) {
                incrementCounter(socieCourse[i].prices[j].cost);
              } else {
                decrementCounter(socieCourse[i].prices[j].cost);
              }
            }
          }
        } else {
          console.log("No Prices");
        }
      }
    }
  };
  const handleCheckbox = (e) => {
    console.log(selectedCourses);
    getPrice(e);
    if (e.target.checked) {
      if (!selectedCourses.includes(Number(e.target.value))) {
        selectedCourses.push(Number(e.target.value));
      }
    } else {
      if (selectedCourses.includes(Number(e.target.value))) {
        const elPosition = selectedCourses.indexOf(Number(e.target.value));
        selectedCourses.splice(elPosition, 1);
      }
    }

    setSelectedCourses(selectedCourses);
    // console.log(selectedCoursesPrice);
    // console.log(selectedCourses);

    // const disCount = (selectedCoursesPrice / 100) * 10;
    if (selectedCourses.length > 0) {
      setDisableBtn(false);

      // if (selectedCourses.length >= 3) {
      //   setDiscount((selectedCoursesPrice / 100) * 10);
      // }
    } else {
      setDisableBtn(true);
    }
  };

  const [errorAlert, setErrorAlert] = useState(false);
  const [errorAlertFocus, setErrorAlertFocus] = useState(false);
  const createStudentBtn = (e) => {
    e.preventDefault();

    console.log(studentCourses);
    if (validationCourse.passes()) {
      setDisableBtn(true);
      setLoader(true);

      selectedCourses.forEach((courseId) => {
        const data = {
          student_id: Number(id),
          course_id: courseId,
        };
        if (studentCoursesCounter > 0) {
          console.log(studentCourses);
          setErrorAlert(true);
          if (errorAlert) {
            setErrorAlertFocus(true);
            setTimeout(() => {
              setErrorAlertFocus(false);
            }, 500);
          }
        } else {
          handleClose();

          createAddStudentToCourse(data, token)
            .then((res) => {
              if (res.status === 201) {
                getContract(id, token).then((res) => {
                  // console.log("log-1", res);
                });
                getContract(id, token).then((res) => {
                  // console.log("log-1", res);
                });
                getContract(id, token).then((res) => {
                  // console.log("log-1", res);
                });
                console.log(res.data, "createAddStudentToCourse-1");
                NotificationManager.success(
                  "The student has joined successfully the course"
                );
                window.location.reload();
              }
            })
            .catch((err) => {
              console.log(err);
              NotificationManager.warning(
                `The student has already joined to this course`
              );
            });
        }
        // NotificationManager.success("Deleted successfully");
      });
      setDisableBtn(false);
      setLoader(false);
    } else {
      setCourseInputs((prevState) => ({
        ...prevState,
        startValidate: true,
      }));
    }
  };

  const removeFromCourseBtn = (e, courseId) => {
    e.preventDefault();
    if (studentCourses?.length == 0) {
      window.location.reload();
    }
    // console.log(id);
    const data = {
      student_id: Number(id),
      course_id: Number(courseId),
    };
    removeStudentCourse(token, data).then((res) => {
      if (res.status === 200) {
        getContract(id, token).then((res) => {
          console.log("log-1", res);
        });
        const newArr = studentCourses.filter((el) => {
          if (el?.course?.id !== courseId) {
            return el;
          }
        });
        setStudentCourses((prevState) => newArr);
        NotificationManager.success("Deleted successfully");
      }
    });
  };
  return (
    <div>
      <CustomCard
        cardClasses="recent-followers-wrap"
        title={`${t("parent.appliedCourses")}`}
        showDivider={true}
      >
        <Box pt={3}>
          {studentCourses.length !== 0 ? (
            studentCourses.map((item, key) => {
              if (item.course) {
                return (
                  <Box key={key} display="flex" alignItems="start" mb={2}>
                    <Box>
                      {
                        <Avatar
                          variant="rounded"
                          className={classes.large}
                          alt="img"
                          src={`${process.env.REACT_APP_PRE_UNI_API}/courses/${item.course.id}/image`}
                        />
                      }
                    </Box>
                    <Box pl={2} className="content-wrap">
                      <Box>
                        {localStorage.getItem("lng") == "uz"
                          ? item.course.name_uz
                          : localStorage.getItem("lng") == "en"
                          ? item.course.name_en
                          : item.course.name_ru}
                        <p>
                          {item.course.course_type == "socie"
                            ? t("parent.pre1")
                            : t("parent.pre2")}
                        </p>
                      </Box>
                      <Box fontSize="subtitle2.fontSize" color="primary.main">
                        {t("admin.durationCourse")}:{" "}
                        {item.course.duration_in_month} {t("admin.month")}
                      </Box>
                    </Box>
                  </Box>
                );
              }
            })
          ) : (
            <Box display="flex" justifyContent="center" pt="10px">
              <Typography variant="body1">{t("admin.noCourses")} !</Typography>
            </Box>
          )}
        </Box>
        <Box pt={3}>
          <Button
            color="primary"
            variant="contained"
            onClick={openModalForCreate}
          >
            <span>
              {studentCourses?.length > 0 &&
              studentCourses?.[0]?.course !== null
                ? `${t("admin.changeCourse")}`
                : studentCourses?.[0]?.course == null
                ? `${t("admin.addCourse")}`
                : `${t("admin.addCourse")}`}
            </span>
          </Button>
        </Box>
      </CustomCard>

      <Container maxWidth="lg">
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
        >
          <div style={modalStyle} className={`${classes.paper} modalAddChild`}>
            <Box my={3} display="flex" justifyContent="space-between">
              <Typography variant="h6">{t("admin.addCourse")}</Typography>
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </Box>
            <Box>
              {/*Course*/}
              <Box my={3}>
                <FormControl
                  required
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={
                    courseInputs.startValidate &&
                    (validationCourse.passes() === true ? false : true)
                  }
                >
                  <InputLabel id="select-gender-label" shrink={true}>
                    {t("admin.selectCourse")}:
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="select-course-label"
                    label="Select course: "
                    variant="outlined"
                    value={courseInputs.course}
                    onChange={(e) => handleCourseType(e)}
                  >
                    <MenuItem value="kids">{t("parent.kids")}</MenuItem>
                    <MenuItem value="socie">{t("parent.pre1")}</MenuItem>
                    <MenuItem value="sol">{t("parent.pre2")}</MenuItem>
                  </Select>
                  <FormHelperText style={{ marginLeft: "0" }}>
                    {courseInputs.startValidate &&
                      validationCourse.errors.first("course")}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box>
                {!socieCourse ? (
                  `${t("admin.noSelectedCourse")}`
                ) : (
                  <Typography>
                    {socieCourse?.length > 0 ? (
                      <h3 style={{ textAlign: "center" }}>
                        {t("admin.price")}
                      </h3>
                    ) : (
                      ""
                    )}

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6 style={{ color: "#dc3545" }}>
                        {`${resultPrice[0]} ${t("parent.valyuta")}`}
                      </h6>
                      <h6 style={{ color: "#0d6efd", textAlign: "center" }}>
                        {selectedCourses.length > 2
                          ? `${(parseInt(selectedCoursesPrice) / 100) * 10} `
                          : "0 "}
                        {`${t("parent.valyuta")} ${t("parent.discount")}`}
                      </h6>
                      <h6 style={{ color: "#198754" }}>
                        {selectedCourses.length > 2 ? (
                          <span>10% {t("parent.discount")}</span>
                        ) : (
                          <span>0% {t("parent.discount")}</span>
                        )}
                      </h6>
                    </Box>
                    <Box style={{ marginRight: "20px", display: "flex" }}>
                      {socieCourse &&
                        socieCourse.map((item, index) => (
                          <Box
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography>
                              {localStorage.getItem("lng") == "uz"
                                ? item?.name_uz
                                : localStorage.getItem("lng") == "en"
                                ? item?.name_en
                                : item?.name_ru}
                            </Typography>
                            <Checkbox
                              value={item.id}
                              onChange={handleCheckbox}
                            />
                          </Box>
                        ))}
                    </Box>
                  </Typography>
                )}
              </Box>
              <Box my={3}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disableBtn}
                  onClick={(e) => createStudentBtn(e)}
                >
                  {loader ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <span>
                      {studentCourses?.length > 0 &&
                      studentCourses?.[0]?.course !== null
                        ? `${t("admin.changeCourse")}`
                        : studentCourses?.[0]?.course == null
                        ? `${t("admin.addCourse")}`
                        : `${t("admin.addCourse")}`}
                    </span>
                  )}
                </Button>
              </Box>
              {errorAlert && studentCourses?.length > 0 ? (
                <CustomCard
                  cardClasses="recent-followers-wrap"
                  title={`${errorAlert ? `${t("admin.infoSubCourse")}` : ""}`}
                  showDivider={true}
                >
                  <Box
                    pt={3}
                    className={`${styles.scaleOrg} ${
                      errorAlertFocus ? `${styles.scale2}` : ""
                    }`}
                  >
                    {studentCourses.length !== 0 ? (
                      studentCourses.map((item, key) => {
                        if (item.course) {
                          return (
                            <Box
                              key={key}
                              display="flex"
                              alignItems="start"
                              style={{ justifyContent: "space-between" }}
                              mb={2}
                            >
                              <Box>
                                {
                                  <Avatar
                                    variant="rounded"
                                    className={classes.large}
                                    alt="img"
                                    src={`${process.env.REACT_APP_PRE_UNI_API}/courses/${item.course.id}/image`}
                                  />
                                }
                              </Box>
                              <Box pl={2} className="content-wrap">
                                <Box>
                                  {" "}
                                  {localStorage.getItem("lng") == "uz"
                                    ? item.course.name_uz
                                    : localStorage.getItem("lng") == "en"
                                    ? item.course.name_en
                                    : item.course.name_ru}
                                </Box>
                                <Box
                                  fontSize="subtitle2.fontSize"
                                  color="primary.main"
                                >
                                  {t("admin.durationCourse")}:{" "}
                                  {item.course.duration_in_month}{" "}
                                  {t("admin.month")}
                                </Box>
                              </Box>
                              <Box>
                                {item.course.course_type == "socie"
                                  ? t("parent.pre1")
                                  : t("parent.pre2")}
                              </Box>

                              <Box pl={2}>
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  onClick={(e) =>
                                    removeFromCourseBtn(e, item.course.id)
                                  }
                                >
                                  {t("admin.remove")}
                                </Button>
                              </Box>
                            </Box>
                          );
                        }
                      })
                    ) : (
                      <Box display="flex" justifyContent="center" pt="10px">
                        <Typography variant="body1">
                          {t("admin.noCourses")} !
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </CustomCard>
              ) : (
                ""
              )}
            </Box>
          </div>
        </Modal>
      </Container>
    </div>
  );
}

export default AppliedCourses;
